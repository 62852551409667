import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";

const awardData = [
  {
    imageSrc: assetsManager.trailBlazer1,
    altText: "Trail Blazer",
    description:
      "Digital Trailblazer Awards 2024 presented by Express Computer: Outstanding contributions towards nurturing and advancing the domestic AI ecosystem in India.",
    link: "/awards-recognition",
  },
  {
    imageSrc: assetsManager.eletsAtmaNirbhar,
    altText: "Elets AtmaNirbhar Awards",
    description:
      "Elets AtmaNirbhar Awards 2024 under the category Digital Governance (Technology initiatives by government departments - AI, ML, IoT etc.)",
    link: "/awards-recognition",
  },
  {
    imageSrc: assetsManager.impactAwards,
    altText: "Impact Leader",
    description:
      "Impact Leader of the Year 2024 at Global Spin Innovation Summit.",
    link: "/awards-recognition",
  },
  {
    imageSrc: assetsManager.adoptionInAi,
    altText: "Adoption in AI",
    description:
      "Adoption of AI, Data Analytics, and Predictive Technologies by ET Government: Literacy divide, Language divide and Digital divide",
    link: "/awards-recognition",
  },
  {
    imageSrc: assetsManager.eletsEducation,
    altText: "Other Award",
    description:
      "Elets Education Innovation Awards: Innovative Initiative for Providing Accessible & Inclusive Education",
    link: "/awards-recognition",
  },
  {
    imageSrc: assetsManager.eletsTechnology,
    altText: "Other Award",
    description:
      "Elets Technology Excellence Awards: Bridging the digital divide",
    link: "/awards-recognition",
  },
];

const AwardsRecognition = () => {
  const awardsRecognition = useScrollToRef();

  return (
    <div className="flex flex-col gap-9" ref={awardsRecognition}>
      <div className="flex flex-col gap-[.94rem]">
        <div>
          <p className="heading1 font-bold text-[#242424]">
            Bhashini’s Awards & Recognition
          </p>
          <p className="heading3">Celebrating Achievements and Excellence</p>
        </div>
        <div className="w-full flex flex-col gap-9">
          {awardData.map((award, index) => (
            <div className="award-item flex gap-9" key={index}>
              <div className="image-container w-[40%] h-full flex flex-col gap-9">
                <img
                  src={award.imageSrc}
                  alt={award.altText}
                  className="h-full w-full"
                />
              </div>
              <div className="text-container w-[60%] h-full flex flex-col gap-4 my-auto">
                <p className="font-medium text-[#242424] my-auto">
                  {award.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AwardsRecognition;
