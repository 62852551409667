import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import rdProjects from "../../utils/rdProject";

const Sitemap = () => {
  const anushandhanMitraItems = rdProjects.map((project) => ({
    title: project.name,
    link: `/sahyogi/anushandhan-mitra/${project.id}`,
  }));

  const sitemapItems = [
    {
      title: "About",
      items: [
        { title: "About Us", link: "/about-bhashini" },
        { title: "Vision & Mission", link: "/vision-mission" },
        { title: "Our Objectives", link: "/our-objectives" },
        { title: "Our Team", link: "/our-team" },
        { title: "Bhahsini EcoSystem", link: "/bhashini-ecosystem" },
        { title: "Bhashini At Work", link: "/bhashini-at-work" },
      ],
    },
    {
      title: "Arpan",
      items: [
        { title: "Our Services", link: "/services" },
        { title: "Our Products", link: "/product" },
        { title: "Careers", link: "/career" },
        { title: "Tender/Eoi", link: "/tender" },
        { title: "Premise", link: "/on-premise" },
      ],
    },
    {
      title: "Sahyogi",
      items: [
        { title: "Be our Sahyogi", link: "/sahyogi" },
        { title: "Translation Service Providers", link: "/sahyogi" },
        { title: "Startups", link: "/Anu" },
      ],
    },
    {
      title: "Startups",
      items: [
        {
          title: "Bhashini Startup Velocity",
          items: [
            {
              title: "About BSV",
              link: "/sahyogi/startup/about-bsv",
            },
            {
              title: "Stages",
              link: "/sahyogi/startup/stages",
            },
            {
              title: "Timelines",
              link: "/sahyogi/startup/timelines",
            },
            {
              title: "Eligibility",
              link: "/sahyogi/startup/timelines",
            },
            {
              title: "Contact Us",
              link: "/sahyogi/startup/Contact%20Us",
            },
            {
              title: "Result",
              link: "/sahyogi/startup/result",
            },
            {
              title: "Winners",
              link: "/sahyogi/startup/winners",
            },
          ],
        },
        {
          title: "Bhashini Sprint Velocity",
          items: [
            {
              title: "About Sprint",
              link: "/sahyogi/startup/about-sprint",
            },
            {
              title: "Stages",
              link: "/sahyogi/startup/sprint-stages",
            },
            {
              title: "Awards & Outcomes",
              link: "/sahyogi/startup/sprint-awards-outcomes",
            },
            {
              title: "Timelines",
              link: "/sahyogi/startup/sprint-timelines",
            },
            {
              title: "Problem Statement",
              link: "/sahyogi/startup/sprinth-problem-statement",
            },
            {
              title: "Eligibility",
              link: "/sahyogi/startup/sprint-eligibility",
            },
            {
              title: "Rules & Regulations",
              link: "/sahyogi/startup/sprint-guidelines",
            },
            {
              title: "Result",
              link: "/sahyogi/startup/sprint-result",
            },
          ],
        },
      ],
    },
    {
      title: "Anusandhan Mitra",
      items: anushandhanMitraItems,
    },
    {
      title: "Sanchalak",
      items: [{ title: "Sanchalak", link: "/sanchalak" }],
    },
    {
      title: "Prayog",
      items: [
        { title: "Parikshan App", link: "/parikshan-app" },
        { title: "Vatika", link: "/vatika" },
        {
          title: "Abhiyantikee Prayogshala",
          link: "/Abhiyantrikee-Prayogshala",
        },
        { title: "Avinya Prayogshala", link: "/avinya-prayogshala" },
      ],
    },
    {
      title: "Pravakta",
      items: [
        { title: "Featured", link: "/featured" },
        { title: "Minister's Insights", link: "/ministers-insights" },
        { title: "Leadership Corner", link: "/leadership-corner" },
        { title: "Global Voices", link: "/global-voices" },
        { title: "Leadership Corner", link: "/leadership-corner" },
        { title: "Awards & Recognition", link: "/awards-recognition" },
        { title: "Bhashini in News", link: "/bhashini-in-news" },
        { title: "Workshops", link: "/workshops" },
      ],
    },
    {
      title: "Bhashadaan",
      items: [{ title: "Bhashadaan", link: "/bhashadan" }],
    },
    // {
    //   title: "Footer",
    //   items: [
    //     // first column
    //     { title: "Home", link: "/" },
    //     { title: "About", link: "/about" },
    //     { title: "Arpan", link: "/arpan" },
    //     { title: "Sahyogi", link: "/arpan" },
    //     { title: "Careers", link: "/arpan" },
    //     { title: "Sanchalak", link: "/arpan" },
    //     { title: "Contact Us", link: "/arpan" },
    //     { title: "ULCA", link: "/arpan" },

    //     // second column
    //     { title: "Pravakta", link: "/featured" },
    //     { title: "Mitra", link: "/sahyogi/anushandhan-mitra" },
    //     { title: "Startups", link: "/sahyogi/startup" },
    //     { title: "Parikshan App", link: "/parikshan-app" },
    //     { title: "FAQ", link: "/faq" },
    //     { title: "Sitemap", link: "/sitemap" },
    //     { title: "Terms & Conditions", link: "/termsAndConditions" },
    //     { title: "Privacy Policy", link: "/privacy-policy" },

    //     // third column
    //     { title: "Contact Us", link: "/connect" },
    //   ],
    // },
  ];

  const footerItems = [
    // First Column
    [
      { title: "Home", link: "/" },
      { title: "About", link: "/about" },
      { title: "Arpan", link: "/arpan" },
      { title: "Sahyogi", link: "/sahyogi" },
      { title: "Careers", link: "/careers" },
      { title: "Sanchalak", link: "/sanchalak" },
      { title: "Contact Us", link: "/contact-us" },
      { title: "ULCA", link: "/ulca" },
    ],
    // Second Column
    [
      { title: "Pravakta", link: "/pravakta" },
      { title: "Mitra", link: "/mitra" },
      { title: "Startups", link: "/startups" },
      { title: "Parikshan App", link: "/parikshan-app" },
      { title: "FAQ", link: "/faq" },
      { title: "Sitemap", link: "/sitemap" },
      { title: "Terms & Conditions", link: "/terms-and-conditions" },
      { title: "Privacy Policy", link: "/privacy-policy" },
    ],
    // Third Column
    [{ title: "Contact Us", link: "/connect" }],
  ];

  const renderItems = (items) => (
    <ul className="flex flex-col gap-2 list-disc ml-4">
      {items.map((item) => (
        <li key={item.link || item.title}>
          {item.link ? (
            <Link
              to={item.link}
              className="underline text-primary font-medium text-[1.125rem]"
            >
              {item.title}
            </Link>
          ) : (
            <>
              <p className="text-[#170F49] font-medium text-[1.125rem]">
                {item.title}
              </p>
              {item.items && renderItems(item.items)}{" "}
              {/* Render nested items */}
            </>
          )}
        </li>
      ))}
    </ul>
  );

  const renderFooterItems = (items) => (
    <ul className="flex flex-col gap-2 list-none">
      {items.map((item) => (
        <li key={item.link}>
          <Link
            to={item.link}
            className="underline text-primary font-medium text-[1.125rem]"
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="w-full flex-col flex gap-[4.5rem]">
      <div className="sitemap-banner-bg">
        <div className="container w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 flex flex-col">
          <div className="my-auto text-white">
            <p className="heading1 font-medium">Sitemap</p>
            <p className="heading3">
              Get complete navigation through this page
            </p>
          </div>
          <Breadcrumbs />
        </div>
      </div>
      <div className="container">
        <div className="flex flex-col sm:flex-row md:mx-16 mx-4 gap-9 flex-wrap">
          {sitemapItems.map((sitemapItem) => (
            <div
              className="flex flex-col p-6 gap-2 rounded-[.875rem] border-2 w-full sm:w-[30%] max-h-[25.5rem] overflow-y-auto"
              key={sitemapItem.title}
            >
              <p className="text-[#170F49] font-medium text-[1.375rem]">
                {sitemapItem.title}
              </p>
              {renderItems(sitemapItem.items)}
            </div>
          ))}
          <footer className=" w-full py-12 mt-12 text-black rounded-[.875rem] border-2">
            <div className="container mx-auto flex flex-col md:flex-row gap-12 px-4 md:px-16">
              {/* Column 1 */}
              <div className="flex-1">{renderFooterItems(footerItems[0])}</div>

              {/* Column 2 */}
              <div className="flex-1">{renderFooterItems(footerItems[1])}</div>

              {/* Column 3 */}
              <div className="flex-1">{renderFooterItems(footerItems[2])}</div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
