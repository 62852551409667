import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormField from "./FormField";
import axios from "axios";
import { SAMUDAYE_BACKEND_URL } from "../../config/config";
import { toast } from "react-toastify";

const EmailVerification = ({ formData, errors, handleOtpChange }) => {
  const [timer, setTimer] = useState(180); // Timer state in seconds
  const [canResend, setCanResend] = useState(false);

  const resendOtp = async () => {
    try {
      await axios.post(`${SAMUDAYE_BACKEND_URL}/send_otp`, {
        email_id: formData.email,
      });
      toast.success("OTP sent successfully");
    } catch (err) {
      toast.error(err.response.data.detail);
    }
  };
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setCanResend(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = () => {
    if (canResend) {
      resendOtp();
      setTimer(180); // Set timer for 3 minutes (180 seconds)
      setCanResend(false);
    }
  };

  return (
    <Grid container sx={{ gap: "1.12rem" }}>
      <Typography
        variant="body2"
        sx={{
          color: "#646464",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "1.5rem",
        }}
      >
        {`We have sent a verification code to ${formData.email} to verify your email address and activate your account.`}
      </Typography>
      <FormField
        isRequired={true}
        error={errors?.otp}
        name="otp"
        helperText={errors?.otp}
        onChange={handleOtpChange}
        label="Verification Code"
        type="text"
        placeholder="Enter the verification code"
      />
      <Grid
        item
        sm={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            alignSelf: "flex-end",
            color: "#646464",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "1.5rem",
          }}
        >
          Resend code in{" "}
          {timer > 0
            ? `${Math.floor(timer / 60)}:${("0" + (timer % 60)).slice(-2)}`
            : "00:00"}
        </Typography>
      </Grid>
      <Grid
        item
        sm={12}
        sx={{
          alignSelf: "flex-end",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textAlign: "right",
            alignSelf: "flex-end",
            color: "#646464",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "1.5rem",
          }}
        >
          Didn&apos;t receive the code?{" "}
          <span
            style={{
              cursor: `${canResend ? "pointer" : "not-allowed"}`,
              color: `${canResend ? "#2947A3" : "#646464"}`,
            }}
            onClick={canResend && handleResendClick}
          >
            Resend
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmailVerification;
