import React from "react";

import withLayout from "../../hocs/withLayout";
import OurServices from "../../components/OurServices/OurServices";
import ArpanBanner from "../../components/ArpanBanner/ArpanBanner";
import OurProduct from "../../components/OurProduct/OurProduct";
import TenderEOI from "../../components/TenderEOI/TenderEoi";
import OnPrem from "../../components/OnPrem/OnPrem";
import OurCareers from "../../components/OurCareers/OurCareers";

const Arpan = () => {
  return <div>Arpan Page Content</div>;
};
export const apranItems = [
  {
    label: "Our Services",
    component: OurServices,
    path: "services",
  },
  {
    label: "Our Products",
    component: OurProduct,
    path: "product",
  },
  {
    label: "Careers",
    component: OurCareers,
    path: "career",
  },
  {
    label: "Tender/EOI",
    component: TenderEOI,
    path: "tender",
  },
  // {
  //   label: "On Premise",
  //   component: OnPrem,
  //   path: "on-premise",
  // },
];

export default withLayout(apranItems, <ArpanBanner />)(Arpan);
