import React, { useState } from "react";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";
import { handleExternalLinks } from "../../utils/utilities";

const BhashiniSetu = () => {
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modalName) => {
    setActiveModal((prev) => (prev === modalName ? null : modalName));
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
    toggleModal(null);
  };
  return (
    <div className="flex flex-col gap-9 mt-9">
      <div className="flex h-full gap-8">
        <div className="w-full flex flex-col gap-6">
          {/* DIBD */}
          <div className="flex flex-col w-full">
            <div
              onClick={() => toggleModal("dibd")}
              className="relative w-full flex text-white cursor-pointer font-bold items-center justify-between rounded eco-system-header px-[2.87rem] pt-[2.19rem] pb-[1.75rem]"
            >
              <p className="heading3 ecosystem-title uppercase">
                Digital India Bhashini Division
              </p>
              <div className="py-[0.38rem] px-3 bg-white rounded">
                <img src={assetsManager.digitalIndiaLogo} alt="bhashini" />
              </div>
              {activeModal === "dibd" && (
                <div
                  className="shadow-3xl flex gap-9 flex-col absolute top-0 left-0 w-full z-50 eco-system-header px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded"
                  onClick={handleChildClick}
                >
                  <div className="w-full flex text-white cursor-pointer font-bold items-center justify-between">
                    <p className="heading3 ecosystem-title">
                      Digital India Bhashini Division
                    </p>
                    <div className="py-[0.38rem] px-3 bg-white rounded">
                      <img
                        src={assetsManager.digitalIndiaLogo}
                        alt="bhashini"
                      />
                    </div>
                  </div>
                  <p className="text-sm">
                    Digital India Bhashini Division is an Independent business
                    division under Digital India Corporation that is a not for
                    profit organization set up by the Ministry of Electronics
                    and Information Technology (MeitY), Govt. of India, under
                    Section 8 of Companies Act 2013. DIBD leads and guides in
                    realizing the vision, objectives and goals of the Digital
                    India program. It provides strategic support to
                    Ministries/Departments of Centre/States for carrying forward
                    the mission of Digital India by way of Capacity Building for
                    e-Governance projects, promoting best practices, encouraging
                    Public-Private Partnerships (PPP), nurturing innovation and
                    technology in various domains.
                  </p>
                </div>
              )}
              <div className="flex w-full gap-6 absolute bottom-[-35%] z-10">
                <div
                  onClick={(e) => {
                    handleChildClick(e);
                    toggleModal("bhashiniMission");
                  }}
                  className="cursor-pointer w-[35%] md:w-[45%] bg-[#F7F7FE] dark:bg-black dark:text-white dark:border dark:border-gray-400  shadow-3xl text-black text-center py-6"
                >
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0 h"
                    alt="bhashini"
                  />
                  <p className="paragraph1 font-semibold">Mission</p>
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0 right-[-50%]"
                    alt="bhashini"
                  />
                  {activeModal === "bhashiniMission" && (
                    <div
                      className="text-white md:w-[40rem] shadow-3xl flex gap-9 flex-col absolute top-0 left-[-15%] md:left-0 z-20 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded"
                      onClick={handleChildClick}
                    >
                      <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                        <p className="heading3 ecosystem-title text-center">
                          Bhashini Mission
                        </p>
                        <p className="paragraph1 font-normal">
                          “To create a knowledge-based society by transcending
                          the language barriers and providing content and
                          services to citizens, in their own language, both in
                          the form of speech and text.”
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  onClick={(e) => {
                    handleChildClick(e);
                    toggleModal("campaignAndOutComes");
                  }}
                  className="cursor-pointer w-[35%] md:w-[45%] bg-[#F7F7FE] dark:bg-black dark:text-white dark:border dark:border-gray-400  shadow-3xl text-black text-center py-6"
                >
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0"
                    alt="bhashini"
                  />
                  <p className="md:text-base text-[.625rem]  font-semibold">
                    Campaign And Outcomes
                  </p>
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0 right-[-50%]"
                    alt="bhashini"
                  />
                  {activeModal === "campaignAndOutComes" && (
                    <div
                      className="text-white shadow-3xl flex gap-9 flex-col absolute top-0 left-[-10%] w-[90%] md:left-[47%] md:w-[47%] z-[99999] bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded"
                      onClick={handleChildClick}
                    >
                      <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                        <p className="heading3 ecosystem-title text-center">
                          Campaign & Challenges
                        </p>
                        <p className="paragraph1 font-normal">
                          Bhashini hosts competitions to crowd-source
                          multilingual solutions for India{"'"}s language tech
                          needs.
                        </p>
                      </div>
                      <div className="flex flex-wrap gap-[.62rem] m-auto">
                        <button
                          onClick={() =>
                            handleExternalLinks(
                              "https://innovateindia.mygov.in/bhashini-challenge"
                            )
                          }
                          className="flex py-2 px-4 bg-white text-black rounded w-[14rem] h-[6.5rem]"
                        >
                          <img
                            className="w-full"
                            src={assetsManager.setuGrandInnovation}
                            loading="lazy"
                            alt="bhashini startup velocity"
                          />
                        </button>
                        <Link to="/sahyogi/startup/sprint/about-sprint">
                          <div className="flex py-2 px-4 bg-white text-black rounded w-[14rem] h-[6.5rem]">
                            <img
                              className="w-full"
                              src={assetsManager.setuBhashinisprint}
                              loading="lazy"
                              alt="techathon"
                            />
                          </div>
                        </Link>
                        <Link to="https://bhashatechathon.com/" target="_blank">
                          <div className="flex py-2 px-4 bg-white text-black rounded w-[14rem] h-[6.5rem]">
                            <img
                              className="w-full"
                              src={assetsManager.setuBhashiniTecho}
                              loading="lazy"
                              alt="techathon"
                            />
                          </div>
                        </Link>
                        <button
                          onClick={() =>
                            handleExternalLinks(
                              "https://hack2skill.com/build-for-bharat-hackathon-ondc-google-cloud"
                            )
                          }
                          className="flex py-2 px-4 bg-white text-black rounded w-[14rem] h-[6.5rem]"
                        >
                          <img
                            className="w-full h-full"
                            src={assetsManager.setuOndc}
                            loading="lazy"
                            alt="techathon"
                          />
                        </button>
                        <Link to="/sahyogi/startup/bsv/about-bsv">
                          <div className="flex py-2 px-4 bg-white text-black rounded w-[14rem] h-[6.5rem]">
                            <img
                              className="w-full"
                              src={assetsManager.setuBhashiniStartupVelocity}
                              loading="lazy"
                              alt="techathon"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Market App */}

          <div className="flex flex-col items-center my-8 relative">
            {/* Wrapper for the title and lines */}
            <div className="flex items-center w-full mb-12 relative">
              {/* Horizontal lines with pseudo-elements for the turning effect */}
              <div className="flex-grow h-0.5 bg-gray-300 relative">
                <div className="absolute left-0 top-full w-0.5 h-10 md:h-[3rem] bg-gray-300">
                  <div className="absolute left-full top-full w-full h-0.5 bg-gray-300"></div>
                </div>
              </div>
              <span className="mx-4 text-blue-600 text-xs tracking-wide uppercase relative z-[1]">
                Market Apps
              </span>
              <div className="flex-grow h-0.5 bg-gray-300 relative">
                <div className="absolute right-0 top-full w-0.5 h-10 md:h-[3rem] bg-gray-300">
                  <div className="absolute right-full top-full w-full h-0.5 bg-gray-300"></div>
                </div>
              </div>
            </div>

            {/* Div below the lines */}
            <div className="w-full flex justify-center absolute  top-[42%]">
              <div
                className="w-[90%] text-center relative border-gray-300 shadow-md rounded-md cursor-pointer"
                onClick={() => toggleModal("ecosystemLayer")}
              >
                <div className="bg-white  dark:bg-black dark:border dark:border-gray-300 py-4 px-6 rounded-md">
                  <p className="md:text-lg text-[.625rem] font-semibold">
                    Ecosystem Layer
                  </p>
                </div>
                {/* Lines connecting to the box */}
                <div className="absolute top-[50%]  left-[-5.5%] w-[5.5%] h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
                <div className="absolute top-[50%] right-[-5.5%] w-[5.5%] h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
              </div>
            </div>
            {activeModal === "ecosystemLayer" && (
              <div
                className="absolute md:top-[42%] top-[-40%] left-[5.5%] w-[90%] h-[45rem] md:h-[21rem] bg-blue-gradient shadow-md rounded-md z-50"
                onClick={handleChildClick}
              >
                <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                  <p className="heading3">Ecosystem Layer</p>
                  <div className="bg-white text-black px-6 py-3 flex flex-col gap-2 font-medium">
                    <p>Marketplace</p>
                    <p>
                      Contribution Apps, end user application, Translators
                      directory, Procurements, etc..
                    </p>
                  </div>
                  <p>
                    The ecosystem apps layer of Bhashini comprises
                    industry-developed tools and applications, not necessarily
                    open source, aimed at leveraging the Bhashini architecture.
                    These applications may involve collaborations with
                    ministries and include functionalities such as translation
                    systems for government documents, oral reading fluency
                    evaluation for school children, voice-based mobile payment
                    apps, and Indian language chatbots and digital assistants
                    for accessing government schemes.
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Oss App*/}
          <div className="flex flex-col items-center my-2 relative">
            {/* Wrapper for the title and lines */}
            <div className="flex items-center w-full mb-12 relative">
              {/* Horizontal lines with pseudo-elements for the turning effect */}
              <div className="flex-grow h-0.5 bg-gray-300 relative">
                <div className="absolute left-0 top-full w-0.5 md:h-[3rem] h-[2.5rem] bg-gray-300">
                  <div className="absolute left-full top-full w-full h-0.5 bg-gray-300"></div>
                </div>
              </div>
              <span className="mx-4 text-blue-600 text-xs tracking-wide uppercase relative z-[1]">
                OSS Apps
              </span>
              <div className="flex-grow h-0.5 bg-gray-300 relative">
                <div className="absolute right-0 top-full w-0.5 md:h-[3rem] h-[2.5rem] bg-gray-300">
                  <div className="absolute right-full top-full w-full h-0.5 bg-gray-300"></div>
                </div>
              </div>
            </div>

            {/* Div below the lines */}
            <div className="w-full flex justify-center absolute  top-[42%]">
              <div
                className="w-[90%] text-center relative border-gray-300 shadow-md rounded-md cursor-pointer"
                onClick={() => toggleModal("referenceApplicationLayer")}
              >
                <div className="bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  py-4 px-6 rounded-md">
                  <p className="md:text-lg text-[.625rem] font-semibold">
                    Reference Application Layer
                  </p>
                </div>
                {/* Lines connecting to the box */}
                <div className="absolute top-[50%]  left-[-5.5%] w-[5.5%] h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
                <div className="absolute top-[50%] right-[-5.5%] w-[5.5%] h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
              </div>
            </div>
            {activeModal === "referenceApplicationLayer" && (
              <div
                className="absolute  md:top-[42%] top-[-40%] left-[5.5%] w-[90%] h-[45rem] md:h-[21rem] bg-blue-gradient shadow-md rounded-md z-50"
                onClick={handleChildClick}
              >
                <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                  <div className="flex flex-col">
                    <p className="heading3">Reference Application Layer</p>
                    <p>Reference contribution and end user apps/platforms</p>
                  </div>
                  <p className="paragraph1">
                    The MT, ASR, TTS and OCR models developed in the foundation
                    layer can be used to create applications in various domains.
                    A set of open source reference applications may be developed
                    to demonstrate innovative ways to leverage Bhashini’s
                    resources. Applications may use the models on an as-is basis
                    or might fine tune the models to target specific
                    applications. Reference applications should also implement
                    an ULCA interface to help contribute post-editing results
                    and statistics back into the data repository.
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Digital Public Good */}
          <div className="flex flex-col items-center my-8 relative">
            {/* Wrapper for the title and lines */}
            <div className="flex items-center w-full mb-12 relative">
              {/* Horizontal lines with pseudo-elements for the turning effect */}
              <div className="flex-grow h-0.5 bg-gray-300 relative">
                <div className="absolute left-0 top-full w-0.5 md:h-[22.5rem] h-[19rem] bg-gray-300">
                  <div className="absolute left-full top-full w-full h-0.5 bg-gray-300"></div>
                </div>
              </div>
              <span className="mx-4 text-blue-600 text-xs tracking-wide uppercase relative ">
                Digital Public Good
              </span>
              <div className="flex-grow h-0.5 bg-gray-300 relative">
                <div className="absolute right-0 top-full w-0.5 md:h-[22.5rem] h-[19rem] bg-gray-300">
                  <div className="absolute right-full top-full w-full h-0.5 bg-gray-300"></div>
                </div>
              </div>
            </div>

            {/* Last div connecting to lines */}
            <div className="flex flex-col gap-6 w-full items-center">
              <div
                className="w-[90%] text-center relative border-gray-300 shadow-md rounded-md cursor-pointer"
                onClick={() => {
                  toggleModal("ulca");
                }}
              >
                <div className="bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  py-4 px-6 rounded-md">
                  <p className="md:text-lg text-[.625rem] font-semibold">
                    ULCA - Unified Language Contribution API{" "}
                  </p>
                </div>
                {activeModal === "ulca" && (
                  <div className="absolute md:top-[42%] top-[-40%] left-[5.5%] w-[90%] h-[45rem] md:h-[21rem] bg-blue-gradient transform -translate-y-1/2 z-20">
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">
                          ULCA - Unified Language Contribution API
                        </p>
                        <p>
                          ULCA is a standard API and open scalable data platform
                          supporting various types of datasets for Indian
                          languages datasets and models.
                        </p>
                      </div>
                      <p className="paragraph1">
                        Bhashini shall capture all data and model contributions
                        through the Unified Language Contribution API (ULCA).
                        ULCA will standardize all data and model contributions
                        for benchmarking. This is critical to remove data silos,
                        duplication and ensure the dataset discoverability and
                        quality.{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-[90%] flex text-center relative md:gap-6 gap-2 md:text-lg text-[.625rem] ">
                <div
                  className="w-1/4 shadow-md py-4 px-2 md:px-6 rounded-md bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  font-semibold cursor-pointer"
                  onClick={() => toggleModal("dataRepo")}
                >
                  Data Repository
                </div>
                <div
                  className="w-1/4 shadow-md py-4 px-2 md:px-6 rounded-md bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  font-semibold cursor-pointer"
                  onClick={() => toggleModal("trainingDataset")}
                >
                  Training Dataset
                </div>
                <div
                  className="w-1/4 shadow-md py-4 px-2 md:px-6 rounded-md bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  font-semibold cursor-pointer"
                  onClick={() => toggleModal("benchmarkingDataset")}
                >
                  Benchmarking Dataset
                </div>
                <div
                  className="w-1/4 shadow-md py-4 px-2 md:px-6 rounded-md bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  font-semibold cursor-pointer"
                  onClick={() => toggleModal("modelRepo")}
                >
                  Model Repository
                </div>
                {activeModal === "dataRepo" && (
                  <div
                    className="absolute md:top-[250%] top-[10%] left-0 md:w-[50%] w-[100%] h-[30rem] bg-blue-gradient transform -translate-y-1/2 z-20 cursor-pointer "
                    onClick={handleChildClick}
                  >
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">Data Repository</p>
                      </div>
                      <p className="paragraph1">
                        The data repository of Bhashini aims to be the largest
                        collection of datasets driving language technologies for
                        Indian languages. It encompasses training and benchmark
                        datasets, which are ingested, validated, labeled, and
                        curated using the Unified Language Contribution API
                        (ULCA) backbone and reference open-source tools. The
                        repository sources data from public, non-personal data
                        from government agencies, web-scraped data,
                        contributions from previous language projects,
                        open-source corpora, corporate sector contributions, NGO
                        and research foundation collections, and crowdsourced
                        data. Additionally, it includes gold standard
                        human-curated data regularly updated under Bhashini.
                        Managed by NHLT, this repository is a cornerstone for
                        advancing language technology in India
                      </p>
                    </div>
                  </div>
                )}
                {activeModal === "trainingDataset" && (
                  <div
                    className="absolute h-[30rem] md:top-[250%] top-[10%]  left-[25%] md:w-[50%] md:h-[21rem] bg-blue-gradient transform -translate-y-1/2 z-20 cursor-pointer"
                    onClick={handleChildClick}
                  >
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">Training Dataset</p>
                      </div>
                      <p className="paragraph1">
                      Training datasets (MT, ASR, TTS, OCR & other NLP related datasets)
                      </p>
                    </div>
                  </div>
                )}
                {activeModal === "benchmarkingDataset" && (
                  <div
                    className="absolute md:top-[250%] top-[10%]  left-[50%] w-[50%] h-[21rem] bg-blue-gradient transform -translate-y-1/2 z-20 cursor-pointer"
                    onClick={handleChildClick}
                  >
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">Benchmarking Dataset</p>
                      </div>
                      <p className="paragraph1">
                        Benchmark datasets (MT, ASR, TTS, OCR & NLP related
                        datasets)
                      </p>
                    </div>
                  </div>
                )}
                {activeModal === "modelRepo" && (
                  <div
                    className="absolute top-[250%] left-[-10%] md:left-[50%] w-[125%] md:w-[50%] h-auto md:h-[21rem] bg-blue-gradient transform -translate-y-1/2 z-20 cursor-pointer"
                    onClick={handleChildClick}
                  >
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">Model Repository</p>
                      </div>
                      <p className="paragraph1">
                        Bhashini will aim to create state of the art open source
                        models (MT, OCR, ASR, TTS) for Indian languages. The
                        model contributions shall come from research groups,
                        startups and volunteers working on language
                        technologies. The contributors shall be encouraged to
                        share models across languages and domains so as to
                        create a comprehensive open source model repository.
                      </p>
                    </div>
                  </div>
                )}
              </div>{" "}
              <div className="w-[90%] text-center relative border-gray-300 shadow-md rounded-md">
                <div
                  className="bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  py-4 px-6 rounded-md cursor-pointer"
                  onClick={() => toggleModal("foundationLayer")}
                >
                  <p className="md:text-lg text-[.625rem] font-semibold">
                    Foundation Layer
                  </p>
                </div>
                {activeModal === "foundationLayer" && (
                  <div
                    onClick={handleChildClick}
                    className="absolute top-[-350%] left-0 w-[100%] h-[30rem] bg-blue-gradient transform -translate-y-1/2 z-20 cursor-pointer"
                  >
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">Foundation Layer</p>
                        <div className="flex flex-wrap gap-3 mt-9 m-auto">
                          <div className="bg-white px-4 py-2 rounded text-black">
                            <p>Crowd sourcing reference portal/app</p>
                          </div>
                          <div className="bg-white px-4 py-2 rounded text-black">
                            <p>Open APIs</p>
                          </div>
                          <div className="bg-white px-4 py-2 rounded text-black">
                            <p>Benchmarking tools(continuous, transparent)</p>
                          </div>
                          <div className="bg-white px-4 py-2 rounded text-black">
                            <p>Dashboard/Catalogs</p>
                          </div>
                          <div className="bg-white px-4 py-2 rounded text-black">
                            <p>Data Collection and Curation tools</p>
                          </div>
                          <div className="bg-white px-4 py-2 rounded text-black">
                            <p>Leaderboards</p>
                          </div>
                        </div>
                      </div>
                      <p className="paragraph1">
                        The foundation layer of Bhashini comprises applications
                        and utilities designed to facilitate collaboration among
                        ecosystem partners. Key components include crowdsourcing
                        reference applications, such as Bolo India, Likho India,
                        and Padho India, to gather Indian language data from the
                        public. These tools will integrate with the data
                        repository via ULCA, encouraging decentralized data
                        collection. Data creation and curation tools will ensure
                        data quality through pipelines for collecting,
                        validating, tagging, and curating various data types. An
                        open API will allow benchmarking of any model compliant
                        with the ULCA API, enabling application access to these
                        models. Benchmarking tools will establish a transparent
                        system to test models using extensive and diverse
                        datasets, validated manually by human curators. These
                        datasets will support continuous benchmarking to measure
                        model performance, with results published and
                        potentially featured on a leaderboard, contingent on
                        model owner approval.
                      </p>
                    </div>
                  </div>
                )}
              </div>{" "}
              <div className="w-[90%] text-center relative border-gray-300 shadow-md rounded-md">
                <div
                  className="bg-white dark:bg-black dark:text-white dark:border dark:border-gray-400  py-4 px-6 rounded-md"
                  onClick={() => toggleModal("nltmCloud")}
                >
                  <p className="md:text-lg text-[.625rem] font-semibold">
                    NLTM Cloud
                  </p>
                </div>
                <div className="absolute top-[50%]  left-[-5.5%] w-[5.5%] h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
                <div className="absolute top-[50%] right-[-5.5%] w-[5.5%] h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
                {activeModal === "nltmCloud" && (
                  <div
                    className="absolute top-[50%] left-0 w-[100%] h-[21rem] bg-blue-gradient transform -translate-y-1/2 z-20 cursor-pointer"
                    onClick={handleChildClick}
                  >
                    <div className="w-[90%] flex flex-col gap-9 text-center relative border-gray-300 m-auto justify-center text-white items-center h-full">
                      <div className="flex flex-col">
                        <p className="heading3">NLTM Cloud</p>
                      </div>
                      <p className="paragraph1">
                        The Bhashini cloud is a comprehensive platform hosting
                        the foundational components and data repositories
                        essential for language AI development. It includes
                        training and benchmark datasets for machine translation
                        (MT), automatic speech recognition (ASR), text-to-speech
                        (TTS), optical character recognition (OCR), and other
                        NLP tasks, along with open-source models for these
                        applications. The foundation layer also features tools
                        for data validation, benchmarking, and API access, as
                        well as resources for crowdsourcing. Leveraging high-end
                        GPUs from CDAC and hardware from the National
                        Supercomputing Mission, Bhashini supports model training
                        and inference services. To foster innovation, it offers
                        high compute cloud credits to language tech startups.
                        Adhering to a cloud-agnostic policy, Bhashini ensures
                        vendor neutrality across various cloud deployments,
                        supporting seamless application operation across public,
                        private, and hybrid clouds. This approach mandates
                        abstraction of infrastructure components to open
                        standards and automation of application deployment,
                        promoting flexibility and interoperability across
                        different cloud environments.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BhashiniSetu;
