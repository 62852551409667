import React from "react";

const StartupEligibility = () => {
  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold">
        Eligibility Criteria:
        <span className="text-primary">Bhashini Startup Velocity 1.0</span>
      </p>
      <div className="flex flex-col gap-5">
        <p>
          1. Participating teams must be an Indian company registered under
          Companies Act or comply with the definition of Start-up as per the
          latest notification of DIPP (Available at http://startupindia.gov.in).
        </p>
        <p>
          2. If Participating Team is not yet registered, they are still allowed
          to participate, but are required to get registered if they get
          selected for the final submission.
        </p>
      </div>
    </div>
  );
};

export default StartupEligibility;
