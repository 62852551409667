import assetsManager from "../assets/assetsManager";

export const services = [
  {
    name: "Automatic Speech Recognition",
    abbr: "ASR",
    isAvailable: true,
    icon: assetsManager.asrIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate",
      },
    ],
    desc: "Powerful technology that allows computers to understand spoken language and convert it into text.",
    languageSupport: [
      "Assamese",
      "Bodo",
      "Dogri",
      "Kashmiri",
      "Konkani",
      "Manipuri",
      "English",
      "Hindi",
      "Bengali",
      "Gujarati",
      "Nepali",
      "Marathi",
      "Maithili",
      "Odia",
      "Punjabi",
      "Santhali",
      "Sindhi",
      "Sanskrit",
      "Urdu",
      "Kannada",
      "Malayalam",
      "Tamil",
      "Telugu",
    ],
    languagesComingSoon: ["Sinhala"],
  },
  {
    name: "Neural Machine Translation",
    abbr: "NMT",
    isAvailable: true,
    icon: assetsManager.nmtIcon,
    cardContent: [
      // {
      //   title: "WAV, MP3, FLAC, OGG",
      //   subText: "Audio Format (Supporting)",
      // },
      // {
      //   title: "8000Hz, 16000Hz, 48000Hz",
      //   subText: "Sampling Rate",
      // }
    ],
    title: "Neural Machine Translation",
    desc: "Automatically translates text between languages",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Bodo",
      "Dogri",
      "English",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Oriya",
      "Punjabi",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Text to Speech",
    abbr: "TTS",
    isAvailable: true,
    icon: assetsManager.ttsIcon,
    cardContent: [
      {
        title: "WAV",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "2(Male,Female)",
        subText: "No of Speakers (Supporting)",
      },
    ],
    desc: "A text-to-speech (TTS) model converts written text into spoken words using synthesized voices, typically offering options for both male and female speakers.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Bodo",
      "English",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Oriya",
      "Punjabi",
      "Rajasthani",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [
      "Dogri",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Nepali",
      "Sanskrit",
      "Santali",
      "Sindhi",
    ],
  },
  {
    name: "Transliteration",
    abbr: "",
    isAvailable: true,
    icon: assetsManager.transliterationIcon,
    cardContent: [],
    desc: "A transliteration model converts text from one writing system to another while preserving phonetic equivalence, typically used for converting between different scripts or languages.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "English",
      "Bodo",
      "Hindi",
      "Kannada",
      "Konkani",
      "Gujarati",
      "Kashmiri",
      "Maithili",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Odia",
      "Punjabi",
      "Sanskrit",
      "Sindhi",
      "Sinhala",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Optical Character Recognition",
    abbr: "OCR",
    isAvailable: true,
    icon: assetsManager.ocrIcon,
    cardContent: [
      {
        title: "PNG, JPG, BMP",
        subText: "File Format (Supporting)",
      },
    ],
    desc: "Bhashini provides OCR models for scene text, printed and handwritten text using exclusive models. OCR support is provided in English and various Bhartiya languages.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "English",
      "Gujarati",
      "Gurumukhi",
      "Hindi",
      "Kannada",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Odiya",
      "Tamil",
      "Telugu",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Text Language Detection",
    abbr: "TLD",
    isAvailable: true,
    icon: assetsManager.tldIcon,
    cardContent: [
      // {
      //   title: "WAV, MP3, FLAC, OGG",
      //   subText: "Audio Format (Supporting)",
      // },
      // {
      //   title: "1600Hz",
      //   subText: "Sampling Rate (Supporting)",
      // }
    ],
    title: "Text Language Detection",
    desc: "Detects language from text input automatically.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Bodo",
      "Dogri",
      "English",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Oriya",
      "Punjabi",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Audio Language Detection",
    abbr: "ALD",
    isAvailable: true,
    icon: assetsManager.aldIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz",
        subText: "Sampling Rate (Supporting)",
      },
    ],
    title: "Audio Language Detection",
    desc: "Detects language from text input automatically.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "English",
      "Gujarati",
      "Hindi",
      "Malayalam",
      "Marathi",
      "Odia",
      "Punjabi",
      "Tamil",
      "Telugu",

      "Kannada",
    ],
    languagesComingSoon: [
      "Bhojpuri",
      "Kashmiri",
      "Manipuri",
      "Urdu",
      "Bodo",
      "Dogri",
      "Konkani",
      "Maithili",
      "Nepali",
      "Sanskrit",
      "Santali",
      "Sindhi",
    ],
  },
  {
    name: "Inverse Text Normalization",
    abbr: "ITN",
    isAvailable: true,
    icon: assetsManager.itnIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate",
      },
    ],
    desc: "A part of the ASR post-processing pipeline.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Bodo",
      "Dogri",
      "English",
      "Hindi",
      "Gujarati",
      "Marathi",
      "Odiya",
      "Punjabi",
      "Tamil",
      "Telugu",
      "Kannada",
      "Malayalam",
      "Urdu",
      "Bhojpuri",
      "Sanskrit",
    ],
    languagesComingSoon: [
      "Konkani",
      "Kashmiri",
      "Maithili",
      "Manipuri",
      "Nepali",

      "Santali",
      "Sindhi",
      // Bodo
      // Dogri
      // Konkani
      // Kashmiri
      // Maithili
      // Manipuri
      // Nepali
      // Sanskrit
      // Santali
      // Sindhi
      // urdu
    ],
  },
  {
    name: "Punctuation",
    abbr: "",
    isAvailable: true,
    icon: assetsManager.punctuationIcon,
    cardContent: [
      // {
      //   title: "WAV, MP3, FLAC, OGG",
      //   subText: "Audio Format (Supporting)",
      // },
      // {
      //   title: "8000Hz, 16000Hz, 48000Hz",
      //   subText: "Sampling Rate",
      // }
    ],
    desc: "Inserts periods, commas, and other punctuation marks for a grammatically correct structure.",
    languageSupport: [
      "Gujarati",
      "Marathi",
      "Panjabi",
      "Bengali",
      "Odia",
      "Tamil",
      "Telugu",
      "Kannada",
      "English",
      "Malayalam",
      "Hindi",
    ],
    languagesComingSoon: [
      "Bodo",
      "Dogri",
      "Konkani",
      "Kashmiri",
      "Maithili",
      "Manipuri",
      "Nepali",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Urdu",
    ],
  },
  {
    name: "Voice Activity Detection",
    abbr: "VAD ",
    isAvailable: true,
    icon: assetsManager.vadIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate",
      },
    ],
    desc: "Detects voice activity in audio signals.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Bodo",
      "Dogri",
      "English",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Oriya",
      "Punjabi",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Profanity Filter",
    abbr: "PF",
    isAvailable: true,
    icon: assetsManager.profanityIcon,
    cardContent: [
      // {
      //   title: "WAV, MP3, FLAC, OGG",
      //   subText: "Audio Format (Supporting)",
      // },
      // {
      //   title: "8000Hz, 16000Hz, 48000Hz",
      //   subText: "Sampling Rate",
      // }
    ],
    desc: "Profanity filter within Bhashini is an extremely capable addition to NLP services offered by Bhashini such as ASR, Translation and TTS which can identify and remove unigram, bigram and trigram profane words and phrases.",
    languageSupport: [
      "English",
      "Assamese",
      "Bengali",
      "Dogri",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Konkani",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Oriya",
      "Punjabi",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Denoiser",
    abbr: "",
    isAvailable: true,
    icon: assetsManager.denoiserIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate",
      },
    ],
    desc: "Reduce background noise in audio.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "English",
      "Bodo",
      "Hindi",
      "Kannada",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Name Entity Recognition",
    abbr: "NER",
    isAvailable: true,
    icon: assetsManager.nerIcon,
    cardContent: [
      {
        title: "Person, Location, Organization",
        subText: "Entities (Supporting)",
      },
    ],
    desc: "Identifies entities such as person, location, and organization from text",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Malayalam",
      "Marathi",
      "Odia",
      "Punjabi",
      "Tamil",
      "Telugu",
      "English",
    ],
    languagesComingSoon: [
      "Bodo",
      "Dogri",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Manipuri",
      "Nepali",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Urdu",
      "Rajasthani",
      "Sinhala",
      "Bhojpuri",
    ],
  },
  {
    name: "Gender Classification",
    abbr: "GC",
    isAvailable: true,
    icon: assetsManager.gcIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate (Supporting)",
      },
    ],
    desc: "Identify the gender of individuals within audio content",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Malayalam",
      "Marathi",
      "Odia",
      "Punjabi",
      "Tamil",
      "Telugu",
      "English",
      "Bodo",
      "Dogri",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Manipuri",
      "Nepali",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Urdu",
      "Rajasthani",
      "Sinhala",
      "Bhojpuri",
    ],
    languagesComingSoon: [],
  },
  {
    name: "Text Normalization",
    abbr: "TN",
    isAvailable: true,
    icon: assetsManager.tnIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate",
      },
    ],
    desc: "Text Normalization converts text from written from into its verbalized form.",
    languageSupport: [
      "Hindi",
      "English",
      "Marathi",
      "Gujarati",
      "Odia",
      "Punjabi",
      "Tamil",
      "Telugu",
      "Malayalam",
      "Kannada",
    ],
    languagesComingSoon: [
      "Assamese",
      "Bengali",

      "Bodo",
      "Dogri",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Manipuri",
      "Nepali",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Urdu",
      "Rajasthani",
      "Sinhala",
      "Bhojpuri",
    ],
  },

  {
    name: "Glossary",
    abbr: "",
    isAvailable: true,
    icon: assetsManager.glossaryIcon,
    cardContent: [],
    desc: "Glossary service specializes in replacing domain-specific terminologies during translation between two languages, particularly nouns and phrases, to ensure  contextually accurate and precise communication.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "Bodo",
      "Dogri",
      "English",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Oriya",
      "Punjabi",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Tamil",
      "Telugu",
      "Urdu",
    ],
    languagesComingSoon: [],
  },

  {
    name: "Speaker Detection",
    abbr: "",
    isAvailable: false,
    icon: assetsManager.speakerdetectionIcon,
    cardContent: [
      {
        title: "WAV, MP3, FLAC, OGG",
        subText: "Audio Format (Supporting)",
      },
      {
        title: "8000Hz, 16000Hz, 48000Hz",
        subText: "Sampling Rate",
      },
    ],
    title: "Speaker Detection",
    desc: "Distinguish between different speakers",
    languageSupport: [
      "Assamese",
      "Bengali",
      "English",
      "Bodo",
      "Hindi",
      "Kannada",
    ],
    languagesComingSoon: [],
  },

  {
    name: "Speaker Diarization",
    abbr: "",
    isAvailable: false,
    icon: assetsManager.speakerderiazationIcon,
    cardContent: [],
    desc: "A glossary is a list of terms in a particular domain of knowledge with the definitions for those terms.",
    languageSupport: [
      "Assamese",
      "Bengali",
      "English",
      "Bodo",
      "Hindi",
      "Kannada",
    ],
    languagesComingSoon: [],
  },
];
