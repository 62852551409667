/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, {  useEffect } from "react";
import "../../components/GlobalFont.scss";


import startupMahakumbh from '../../assets/images/MahakumbhStartup.jpg'
import { Fancybox } from "@fancyapps/ui";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";


const Events = (props) => {

  useEffect(() => {
    const reftp = document.getElementById("main");
    if (props.skipContent === true) {
      reftp.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.skipContent]);

  Fancybox.bind("[data-fancybox]", {});
  return (
    <div>
      <div className="w-full text-white h-[24rem] px-[9.06rem] pl-8 pr-44 serviceBanner flex flex-col pravakta-banner-bg">
      <div className="my-auto text-white">
        <p className="heading1 font-medium">Bhashini’s Events</p>
    
      </div>
      <Breadcrumbs />
    </div>

      <div className="py-12 bg-white">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <Link to="/international-mother-languages-day">
                  <img
                    src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother%20Language%20Day/JMD_5220.JPG"
                    className="w-full h-48 object-cover"
                    alt="International Mother Languages Day"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-lg font-semibold">International Mother Languages Day</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <Link to="/g20">
                  <img
                    src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-2.jpeg"
                    className="w-full h-48 object-cover"
                    alt="G20"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-lg font-semibold">G20</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <Link to="/cocosda">
                  <img
                    src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0453.JPG"
                    className="w-full h-48 object-cover"
                    alt="26th International Conference on Oriental COCOSDA 2023"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-lg font-semibold">26th International Conference on Oriental COCOSDA 2023</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <Link to="/gpai">
                  <img
                    src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5043.jpg"
                    className="w-full h-48 object-cover"
                    alt="GPAI"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-lg font-semibold">GPAI</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <Link to="/iitf">
                  <img
                    src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage29.jpeg"
                    className="w-full h-48 object-cover"
                    alt="IITF"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-lg font-semibold">IITF</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <Link to="/startup-mahakumbh">
                  <img
                    src={startupMahakumbh}
                    className="w-full h-48 object-cover"
                    alt="Startup Mahakumbh"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-lg font-semibold">Startup Mahakumbh</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
