import React from 'react'
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Coscoda = () => {
  const images = [
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0403.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0407.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0411.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0453.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0456.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/COCOSDA/IMG_0486.JPG",
  ];

  return (
    <div className="flex flex-wrap gap-2 mx-16">
    {images.map((image, index) => (
      <div key={index} className="w-1/2 md:w-1/4 h-1/2">
        <a href={image} data-fancybox="gallery" className="block h-full">
          <img src={image} alt={`G20 Event ${index + 1}`} className="h-full" />
        </a>
      </div>
    ))}
  </div>
  )
}

export default Coscoda