import React from "react";
import { docsManager } from "../../assets/documents/docsManager";
import { Link } from "react-router-dom";
import useScrollToRef from "../../hooks/useScrollToRef";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const ArchivedTender = () => {
  const tenderRef = useScrollToRef();
  const tenderData = [
    {
      id: 1,
      title: "Notification of results for RFQ - RFQ DIBD/NOV-23/PROJ-NITI",
      desc: [
        {
          id: 2,
          title:
            "Ref to RFQ DIBD/NOV-23/PROJ-NITI dated 6th December 2023 released for financial rates discovery for document and video translation from Bhashini empaneled translation service providers",
          link: docsManager.tenderEoi1,
        },
      ],
    },
    {
      id: 2,
      title: "EOI for Empanelment of Gen Ai at DIBD - GENAI/24/1",
      desc: [
        {
          id: 1,
          title:
            "Invitation for the Expression of Interest (EOI) for onboarding of the interested Cloud Service Providers for Bhashini (National Language Translation Mission) Project",
          link: docsManager.tenderEoi2,
        },
        {
          id: 2,
          title:
            "Corrigendum for “Expression of Interest for Empanelment of Gen Ai service provider for Bhashini Eco System” for DIBD, DIC.",
          link: docsManager.tenderEoi3,
        },
        {
          id: 3,
          title:
            "Corrigendum 2 for “Expression of Interest for Empanelment of Gen Ai service provider for Bhashini Eco System” for DIBD, DIC.",
          link: docsManager.tenderEoi4,
        },
      ],
    },
    {
      id: 3,
      title:
        "RFI for onboarding of Cloud Service Provider - DIBD/JAN-24/RFI-CSP",
      desc: [
        {
          id: 1,
          title:
            "Invitation for the Request For Information (RFI) for discussion with the MeitY empaneled Cloud Service Providers for Bhashini",
          link: docsManager.tenderEoi5,
        },
        {
          id: 2,
          title:
            "Corrigendum in regards to extension of the date for RFI - MeitY empanelled Cloud Service Providers",
          link: docsManager.tenderEoi6,
        },
        {
          id: 3,
          title:
            "Corrigendum 2 for “Request for Information for MeitY Empaneled Cloud Service Providers for Bhashini Project” for DIBD, DIC.",
          link: docsManager.tenderEoi7,
        },
        {
          id: 4,
          title:
            "Corrigendum 3 for “Request for Information for MeitY Empaneled Cloud Service Providers for Bhashini Project” for DIBD, DIC.",
          link: docsManager.tenderEoi8,
        },
      ],
    },
    {
      id: 4,
      title: "RFQ for Translation of Documents - RFQ DIBD/NOV-23/PROJ-NITI",
      desc: [
        {
          id: 1,
          title:
            "Translation project of 19363 documents in 22 scheduled Indian languages and 5 International Languages",
          link: docsManager.tenderEoi9,
        },
      ],
    },
    {
      id: 5,
      title: "RFQ Project for Video Translations - DIBD/NOV-23/PROJ-NITI",
      desc: [
        {
          id: 1,
          title:
            "Translation project of 1102 Videos in 22 scheduled Indian languages and 5 International Languages",
          link: docsManager.tenderEoi10,
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col gap-[4.06rem]">
      <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4  flex flex-col  archiveTenderBg">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Archived Tenders</p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-col gap-9 px-16" ref={tenderRef}>
        <div>
          <div className="w-full">
            <table className="w-full">
              <thead>
                <tr className="bg-[#DFEAF5] border-b-2 border-primary">
                  <th className="text-left smallText p-4">Sr. No.</th>
                  <th className="text-left smallText p-4">
                    Tender/EOI/RFQ/RFI Title
                  </th>
                  <th className="text-left smallText p-4">Description</th>
                  <th className="text-left smallText p-4">
                    Reference Files/Link
                  </th>
                </tr>
              </thead>
              <tbody>
                {tenderData.map((tender) =>
                  tender.desc.map((desc, index) => (
                    <tr
                      key={desc.id}
                      className="border border-[#EAECF0] bg-[#F9FAFB]"
                    >
                      {index === 0 && (
                        <>
                          <td
                            rowSpan={tender.desc.length}
                            className="p-4 paragraph2 border-r-2  border-b-2 border-primary border-l-2 "
                          >
                            {tender.id}
                          </td>
                          <td
                            rowSpan={tender.desc.length}
                            className="p-4 paragraph2 border-r-2  border-b-2 border-primary"
                          >
                            {tender.title}
                          </td>
                        </>
                      )}
                      <td className="p-4 paragraph2 border-b-2 border-primary">
                        {desc.title}
                      </td>
                      <td className="p-4 paragraph2 border-r-2  border-b-2 border-l-2 border-primary">
                        <Link
                          target="_blank"
                          to={desc.link}
                          className="text-primary font-semibold "
                        >
                          View Pdf
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
                {/* Placeholder for additional tenders */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivedTender;
