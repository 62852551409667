import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import FormField from "./FormField";

const LanguageExpertise = ({
  language,
  formData,
  handleChange,
  errors,
  otherExpertise,
  setOtherExpertise,
}) => {
  const [languageFields, setLanguageFields] = useState(1); // Start with one field shown

  const handleOtherDomainExpertiseChange = (e) => {
    // Update the formData to hold the value of other_domain_expertise under domain_expertise
    setOtherExpertise(e.target.value);
  };

  const addMoreFields = () => {
    if (languageFields < 3) {
      setLanguageFields(languageFields + 1); // Show one more field
    }
  };

  const removeField = (fieldType) => {
    if (fieldType === "secondary" && languageFields > 1) {
      setLanguageFields(languageFields - 1);
    } else if (fieldType === "tertiary" && languageFields > 2) {
      setLanguageFields(languageFields - 1);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormField
          value={formData.domain_expertise}
          error={errors?.domain_specialization}
          helperText={errors?.domain_specialization}
          label="Domain Expertise"
          name="domain_expertise"
          placeholder="Domain Expertise"
          isSelect
          options={[
            {
              value: "Natural Language Processing (NLP)",
              label: "Natural Language Processing (NLP)",
            },
            { value: "Machine Translation", label: "Machine Translation" },
            {
              value: "Text-to-Speech Systems",
              label: "Text-to-Speech Systems",
            },
            {
              value: "Optical Character Recognition",
              label: "Optical Character Recognition",
            },
            {
              value: "Voice Activity Detection",
              label: "Voice Activity Detection",
            },
            {
              value: "Translation Validation",
              label: "Translation Validation",
            },
            { value: "Localization", label: "Localization" },
            { value: "Corpora Creation", label: "Corpora Creation" },
            {
              value: "Other (please specify)",
              label: "Other (please specify)",
            },
          ]}
          onChange={handleChange}
        />
      </Grid>

      {/* Show the input field for "Other" if selected */}
      {formData.domain_expertise === "Other (please specify)" && (
        <Grid item xs={12}>
          <FormField
            value={otherExpertise}
            error={errors?.other_domain_expertise}
            helperText={errors?.other_domain_expertise}
            name="other_domain_expertise" // Track this separately for the input field
            label="Specify Other Domain Expertise"
            placeholder="Specify Other Domain Expertise"
            onChange={handleOtherDomainExpertiseChange} // Use the separate handler
          />
        </Grid>
      )}

      {/* Primary Language Expertise and Proficiency */}
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormField
            error={errors?.primary_language_expertise}
            helperText={errors?.primary_language_expertise}
            name="primary_language_expertise"
            label="Primary Language Expertise"
            placeholder="Select Primary Language"
            isSelect
            value={formData.primary_language_expertise}
            onChange={handleChange}
            options={language.map((lang) => ({
              value: lang,
              label: lang,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            error={errors?.primary_proficiency_level}
            helperText={errors?.primary_proficiency_level}
            id="primary-proficiency-level"
            name="primary_proficiency_level"
            label="Proficiency Level"
            placeholder="Select Proficiency Level"
            isSelect
            value={formData.primary_proficiency_level}
            onChange={handleChange}
            options={[
              { value: "basic", label: "Basic" },
              { value: "intermediate", label: "Intermediate" },
              { value: "advanced", label: "Advanced" },
            ]}
          />
        </Grid>
      </Grid>

      {/* Conditionally render Secondary Language Expertise */}
      {languageFields >= 2 && (
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormField
              id="secondary-language-expertise"
              name="secondary_language_expertise"
              label="Secondary Language Expertise"
              placeholder="Select Secondary Language"
              isSelect
              value={formData.secondary_language_expertise || ""}
              onChange={handleChange}
              options={language.map((lang) => ({
                value: lang,
                label: lang,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              id="secondary-proficiency-level"
              name="secondary_proficiency_level"
              label="Proficiency Level"
              placeholder="Select Proficiency Level"
              isSelect
              value={formData.secondary_proficiency_level || ""}
              onChange={handleChange}
              options={[
                { value: "basic", label: "Basic" },
                { value: "intermediate", label: "Intermediate" },
                { value: "advanced", label: "Advanced" },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => removeField("secondary")} color="error">
              Remove Secondary Language
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Conditionally render Tertiary Language Expertise */}
      {languageFields >= 3 && (
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormField
              id="ternary-language-expertise"
              name="ternary_language_expertise"
              label="Tertiary Language Expertise"
              placeholder="Select Tertiary Language"
              isSelect
              value={formData.ternary_language_expertise || ""}
              onChange={handleChange}
              options={language.map((lang) => ({
                value: lang,
                label: lang,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              id="ternary-proficiency-level"
              name="ternary_proficiency_level"
              label="Proficiency Level"
              placeholder="Select Proficiency Level"
              isSelect
              value={formData.ternary_proficiency_level || ""}
              onChange={handleChange}
              options={[
                { value: "basic", label: "Basic" },
                { value: "intermediate", label: "Intermediate" },
                { value: "advanced", label: "Advanced" },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => removeField("tertiary")} color="error">
              Remove Tertiary Language
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Add More Button */}
      {languageFields < 3 && (
        <Grid item xs={12}>
          <Button onClick={addMoreFields}>Add More</Button>
        </Grid>
      )}
    </Grid>
  );
};

export default LanguageExpertise;
