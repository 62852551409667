import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";

const OnPrem = () => {
  const onPremRef = useScrollToRef()
  return (
    <div className="flex flex-col gap-9" ref={onPremRef}>
        <p className="heading1 font-semibold text-[#242424]">
          Bhashini’s On - Premise
        </p>
        <div className="w-full on-prem py-[3.75rem] px-[2.69rem] flex flex-col gap-9 text-white text-center">
          <p className="heading3 font-medium">
            Seamlessly access multilingual Natural Language Processing (NLP)
            capabilities in Bhartiya languages, even within air-gapped systems,
            without the need for internet access. Empower your on-premise
            systems with cutting-edge linguistic features, enhancing
            functionality and accessibility in diverse language environments.
            <br/>
            <br/>
            
            Stay tuned for this exciting launch!
          </p>
          <p className="heading1 font-semibold">Coming Soon...</p>
        </div>
    </div>
  );
};

export default OnPrem;
