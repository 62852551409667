import React from "react";
import assetsManager from "../../assets/assetsManager";

const StartUpVelocityWinners = () => {
  const winner = [
    {
      name: "DocTunes",
      image: assetsManager.docTunes,
      problemStatementNo: 1,
      problemStatement:
        "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      name: "Truper",
      image: assetsManager.truper,
      problemStatementNo: 2,
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      name: "DocTunes",
      image: assetsManager.kiffer,
      problemStatementNo: 3,
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      name: "DocTunes",
      image: assetsManager.openAi,
      problemStatementNo: 4,
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      name: "DocTunes",
      image: assetsManager.saddleHq,
      problemStatementNo: 5,
      problemStatement:
        "Voice Driven Management information System powered by blockchain",
    },
    {
      name: "DocTunes",
      image: assetsManager.bharatPi,
      problemStatementNo: 6,
      problemStatement:
        "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
  ];

  const runnerUp = [
    {
      name: "DocTunes",
      image: assetsManager.aadalatAi,
      problemStatementNo: 1,
      problemStatement:
        "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      name: "Truper",
      image: assetsManager.doctune1,
      problemStatementNo: 2,
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      name: "DocTunes",
      image: assetsManager.pulayatsaAi,
      problemStatementNo: 3,
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      name: "DocTunes",
      image: assetsManager.gnani1,
      problemStatementNo: 4,
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      name: "DocTunes",
      image: assetsManager.celestia1,
      problemStatementNo: 5,
      problemStatement:
        "Voice Driven Management information System powered by blockchain",
    },
    {
      name: "DocTunes",
      image: assetsManager.canebot,
      problemStatementNo: 6,
      problemStatement:
        "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
  ];

  return (
    <div className="flex flex-col gap-[6.25rem]">
      <div className="flex bsvWinnerBg pt-[8.5rem] pb-[7.25rem] pl-[3.37rem]">
        <div className="flex flex-col gap-1">
          <p className="heading1 font-semibold text-white">
            StartUp Velocity Winners
          </p>
          <p className="heading3 text-white">
            The best of the best from the StartUp Velocity program
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-9">
        <p className="heading1 font-semibold">Winning Teams</p>
        <div className="flex flex-wrap gap-4">
          {winner.map((team, index) => (
            <div
              key={index}
              className="w-[40%] rounded-lg border border-gray-300 p-4 flex flex-col gap-10"
            >
              <div className="flex flex-col gap-2">
                <p className="heading3 font-semibold">
                  Problem Statement {team.problemStatementNo}
                </p>
                <p className="paragraph1">{team.problemStatement}</p>
              </div>
              <div className="w-full h-full">
                <img src={team.image} alt={team.name} loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-9">
        <p className="heading1 font-semibold">Runner-Up Teams</p>
        <div className="flex flex-wrap gap-4">
          {runnerUp.map((team, index) => (
            <div
              key={index}
              className="w-[40%] rounded-lg border border-gray-300 p-4 flex flex-col gap-10"
            >
              <div className="flex flex-col gap-2">
                <p className="heading3 font-semibold">
                  Problem Statement {team.problemStatementNo}
                </p>
                <p className="paragraph1">{team.problemStatement}</p>
              </div>
              <div className="h-full">
                <img src={team.image} alt={team.name} loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartUpVelocityWinners;
