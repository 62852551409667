// useScrollToRef.js

import { useEffect, useRef } from 'react';

function useScrollToRef() {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return ref;
}

export default useScrollToRef;
