import React from "react";
import prayogBanner from "../../assets/video/prayogBanner.mp4";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
const PrayogBanner = () => {
  return (
    <div className="relative h-[388px] w-full overflow-hidden">
      <video
        autoPlay
        loop
        muted
        controls={false}
        className="absolute inset-0 w-full h-full object-cover z-[-1]"
      >
        <source src={prayogBanner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0"
      style={{
        backgroundImage: "linear-gradient(45deg, black, transparent)",
      }}
      ></div>
      <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4   flex flex-col relative">
        <div className="flex flex-col gap-9 my-auto">
          <div className="my-auto text-white">
            <p className="heading1 font-medium">
              Prayog: AI-Powered Innovation
            </p>
            <p className="paragraph1">
              Access our Parikshan App, Models & Datasets Vatika, Prayogshala
              through onboarding application
            </p>
          </div>
          <Link to="https://dashboard.bhashini.co.in/user/register" target="_blank" className="px-6 py-3 bg-primary w-max rounded">
            <p className="pragraph2">
            Request to Access for Parikshan App

            </p>
          </Link>
        </div>

        <Breadcrumbs />
      </div>
    </div>
  );
};

export default PrayogBanner;
