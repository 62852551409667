import { useEffect, useState } from "react";


/**
 * @description A hook that returns the current date and time in a formatted way.
 * @returns {{date: string, time: string}} An object containing the formatted date and time.
 * @example
 * const { date, time } = useGetDateAndTime();
 * console.log(date); // "September 14, 2021"
 * console.log(time); // "12:00 PM"
 * @see
 * https://reactjs.org/docs/hooks-intro.html
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
*/

const useGetDateAndTime = () => {
  const formatDateTime = (dateTime) => {
    const dateOptions = { day: "numeric", month: "long", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    // @ts-ignore
    const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
    // @ts-ignore
    const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };
  const [formattedDateTime, setFormattedDateTime] = useState(
    formatDateTime(new Date())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedDateTime(formatDateTime(new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return formattedDateTime;
};

export default useGetDateAndTime;
