import React from "react";
import { Link } from "react-router-dom";

const StartupContact = () => {
  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 gap-9">
        Contact Details:
        <span className="text-primary">Bhashini Startup Velocity 1.0</span>
      </p>
      <p>
        For any queries or concerns, please contact us at the following email
        addresses:
        <Link
          to="mailto: 
        ceo-dibd@digitalindia.gov.in"
          className="text-primary"
        >
          ceo-dibd@digitalindia.gov.in { " "} or { " "}
        </Link>
        <Link
          to="mailto:
        ajay.rajawat@digitalindia.gov.in"
          className="text-primary"
        >
          ajay.rajawat@digitalindia.gov.in
        </Link>
      </p>
    </div>
  );
};

export default StartupContact;
