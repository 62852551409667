import { Link } from "react-router-dom";
import assetsManager from "../../assets/assetsManager";
import React from "react";
import { useTranslation } from "react-i18next";

const MobileAppBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="2xl:container">
      <div className="flex md:mx-16 md:mt-[6.25rem] m-4 justify-between">
        <div className="flex flex-col gap-9">
          <div className="flex flex-col gap-4">
            <p className="heading1 font-semibold">
              {t("homePage.downloadAppBanner.title")}
            </p>
            <p className="heading3">
              {t("homePage.downloadAppBanner.subtitle")}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-xl font-semibold">
              {t("homePage.downloadAppBanner.downloadApp")}
            </p>

            <div className="flex gap-4">
              <div className="flex flex-col gap-4">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dibd.bhashini&pcampaignid=web_share"
                >
                  <img
                    src={assetsManager.playStore}
                    alt="playstore"
                    loading="lazy"
                  />
                </Link>
                <div>
                <img
                  height={124}
                  width={124}
                  src={assetsManager.playStoreQr}
                  alt="android"
                  loading="lazy"
                />
                </div>
              
              </div>
              <div className="flex flex-col gap-4">
                <Link to="https://apps.apple.com/in/app/bhashini/id6446089978">
                  <img
                    src={assetsManager.appStore}
                    alt="appstore"
                    loading="lazy"
                  />
                </Link>
                <div className="flex gap-5">
                  <img
                    height={124}
                    width={124}
                    src={assetsManager.appStoreQr}
                    alt="apple"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-end hidden md:block">
          <img
            src={assetsManager.mobileBannerImg}
            alt="mobileAppBanner"
            loading="lazy"
            className="h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileAppBanner;
