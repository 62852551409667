import React from "react";
import GovtOrg from "./GovtOrg/GovtOrg";
import ContactUs from "./ContactUs/ContactUs";
import { Link } from "react-router-dom";
import assetsManager from "../../assets/assetsManager";
import { handleExternalLinks } from "../../utils/utilities";
import MobileAppBanner from "../MobileAppBanner/MobileAppBanner";
// import TrendingSocialMedia from './TrendingSocialMedia/TrendingSocialMedia'
export const Footer = () => {
  const footerLink1 = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about-bhashini",
    },
    {
      name: "Arpan",
      path: "/services",
    },
    {
      name: "Sahyogi",
      path: "/sahyogi",
    },
    {
      name: "Careers",
      path: "/career",
    },
    {
      name: "Bhashadaan",
      path: "https://bhashini.gov.in/bhashadaan/en/home",
    },
  ];

  const footerLink2 = [
    {
      name: "Sanchalak",
      path: "/sanchalak",
    },

    {
      name: "Contact Us",
      path: "/connect",
    },

    {
      name: "ULCA",
      path: "https://bhashini.gov.in/ulca",
    },
    {
      name: "EcoSystem",
      path: "/bhashini-ecosystem",
    },
    {
      name: "Pravakta",
      path: "/pravakta",
    },
  ];

  const footerLink3 = [
    {
      name: "Mitra",
      path: "/sahyogi/anushandhan-mitra",
    },
    {
      name: "Startups",
      path: "/sahyogi/startup",
    },
    {
      name: "Parikshan App",
      path: "/parikshan-app",
    },
  ];

  return (
    <footer className="flex flex-col mt-[4rem] ">
      <MobileAppBanner/>
      <ContactUs />
      <GovtOrg />

      <div className=" bg-primaryDark1 text-white">
        <div className="2xl:container flex flex-col gap-4 mt-7 md:mt-[4.94rem] md:mb-[2.38rem] mb-[1.38rem] px-4 md:px-16">
          <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-col gap-6 md:w-2/5 w-full  ">
            <div className="py-2 px-6 bg-white w-[9.5rem] h-[4.5rem] rounded">
              <img
                src={assetsManager.bhashiniLogo}
                alt="logo"
                className="w-full h-full"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col gap-1">
              <p className="md:text-lg text-[1.125rem] font-semibold">
                Digital India Bhashini Division
              </p>
              <p className="text-[.875rem] font-medium">
                Independent Division under Digital India Corporation
              </p>
              <p className="paragraph1">
                Electronics Niketan, 6-CGO Complex, New Delhi - 110003
              </p>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex gap-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                    fill="white"
                  />
                </svg>
                <p className="md:text-lg text-base font-medium bhashini-skip-translation">
                  ceo-dibd[at]digitalindia[dot]gov[dot]in
                </p>
              </div>
              <div className="flex flex-col gap-[3.25rem]">
                <div className="flex gap-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.0022 10.999H22.0022C22.0022 5.869 18.1292 2 12.9922 2V4C17.0542 4 20.0022 6.943 20.0022 10.999Z"
                      fill="white"
                    />
                    <path
                      d="M13.0003 7.99829C15.1033 7.99829 16.0003 8.89529 16.0003 10.9983H18.0003C18.0003 7.77329 16.2253 5.99829 13.0003 5.99829V7.99829ZM16.4223 13.4413C16.2301 13.2666 15.9776 13.1735 15.7181 13.1815C15.4585 13.1895 15.2123 13.2981 15.0313 13.4843L12.6383 15.9453C12.0623 15.8353 10.9043 15.4743 9.71228 14.2853C8.52028 13.0923 8.15928 11.9313 8.05228 11.3593L10.5113 8.96529C10.6977 8.78441 10.8064 8.53811 10.8144 8.27849C10.8225 8.01888 10.7292 7.76633 10.5543 7.57429L6.85928 3.51129C6.68432 3.31864 6.44116 3.20179 6.18143 3.18555C5.92171 3.1693 5.66588 3.25494 5.46828 3.42429L3.29828 5.28529C3.12539 5.4588 3.0222 5.68974 3.00828 5.93429C2.99328 6.18429 2.70728 12.1063 7.29928 16.7003C11.3053 20.7053 16.3233 20.9983 17.7053 20.9983C17.9073 20.9983 18.0313 20.9923 18.0643 20.9903C18.3088 20.9766 18.5396 20.8729 18.7123 20.6993L20.5723 18.5283C20.7417 18.3308 20.8276 18.0751 20.8115 17.8153C20.7954 17.5556 20.6788 17.3124 20.4863 17.1373L16.4223 13.4413Z"
                      fill="white"
                    />
                  </svg>
                  <p className="heading3 font-medium">011-2430 1361</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between w-3/5">
            <div className="flex flex-col">
              <div className="flex flex-col  gap-6 mt-[3.38rem] md:mt-0">
                {footerLink1.map((link, index) => (
                  <Link key={index} target={link.name === "Bhashadaan" && "_blank"} to={link.path} className="paragraph1">
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="flex flex-col ">
              <div className="flex flex-col gap-6 mt-[3.38rem] md:mt-0">
                {footerLink2.map((link, index) => (
                  <Link key={index} target={link.name === "ULCA" && "_blank"} to={link.path} className="paragraph1">
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="flex flex-col ">
              <div className="flex flex-col gap-6 mt-[3.38rem] md:mt-0">
                {footerLink3.map((link, index) => (
                  <Link key={index} to={link.path} className="paragraph1">
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-[3.38rem] md:mt-0">
              <p className="paragraph1 font-bold">Get The Bhashini App</p>
              <div className="flex flex-col gap-2">
                <div className="flex gap-4">
                  <button
                    onClick={() =>
                      handleExternalLinks(
                        "https://play.google.com/store/apps/details?id=com.dibd.bhashini&pcampaignid=web_share"
                      )
                    }
                    className="mr-4"
                  >
                    <img
                      src={assetsManager.playStore}
                      alt="playstore"
                      loading="lazy"
                    />
                  </button>
                  <button
                    onClick={() =>
                      handleExternalLinks(
                        "https://apps.apple.com/in/app/bhashini/id1570975989"
                      )
                    }
                  >
                    <img
                      src={assetsManager.appStore}
                      alt="appstore"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
              <p>
                <span className="smallText">Join Us</span>
                <div className="flex gap-4">
                  <button
                    onClick={() =>
                      handleExternalLinks("https://www.youtube.com/@_Bhashini")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M17.812 5.14163H6.145C3.855 5.14163 2 6.97663 2 9.24063V15.0086C2 17.2726 3.856 19.1086 6.145 19.1086H17.812C20.102 19.1086 21.957 17.2726 21.957 15.0086V9.24063C21.957 6.97663 20.101 5.14062 17.812 5.14062V5.14163ZM15.009 12.4046L9.552 14.9796C9.51872 14.9957 9.48192 15.003 9.44503 15.001C9.40815 14.999 9.37237 14.9876 9.34103 14.9681C9.3097 14.9485 9.28382 14.9214 9.2658 14.8891C9.24779 14.8568 9.23822 14.8206 9.238 14.7836V9.47463C9.23867 9.4375 9.24872 9.40114 9.26722 9.36895C9.28573 9.33676 9.31208 9.30977 9.34382 9.2905C9.37556 9.27124 9.41167 9.26032 9.44877 9.25877C9.48587 9.25721 9.52276 9.26508 9.556 9.28163L15.014 12.0166C15.0504 12.0348 15.0809 12.0628 15.102 12.0975C15.1232 12.1321 15.1341 12.1721 15.1336 12.2127C15.1331 12.2533 15.1211 12.293 15.0991 12.3271C15.077 12.3612 15.0458 12.3884 15.009 12.4056V12.4046Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button onClick={() => handleExternalLinks("")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M9.04525 5.99V8.738H7.03125V12.098H9.04525V22.084H13.1792V12.099H15.9543C15.9543 12.099 16.2142 10.488 16.3403 8.726H13.1962V6.428C13.1962 6.085 13.6462 5.623 14.0922 5.623H16.3463V2.125H13.2822C8.94225 2.125 9.04525 5.488 9.04525 5.99Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() =>
                      handleExternalLinks(
                        "https://x.com/_BHASHINI?t=UE9RHyJRZuZ9rl2_W7B_9g&s=09"
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M22 6.03265C21.2504 6.36003 20.4565 6.57469 19.644 6.66965C20.4968 6.16888 21.138 5.37476 21.448 4.43565C20.64 4.90598 19.7587 5.23726 18.841 5.41565C18.4545 5.01086 17.9897 4.68904 17.4748 4.46983C16.9598 4.25062 16.4056 4.13862 15.846 4.14065C13.58 4.14065 11.743 5.95065 11.743 8.18065C11.743 8.49665 11.779 8.80565 11.849 9.10065C10.2236 9.02335 8.63212 8.60806 7.17617 7.88129C5.72022 7.15452 4.43176 6.13223 3.393 4.87965C3.02883 5.49405 2.83742 6.19543 2.839 6.90965C2.8397 7.57762 3.00683 8.23488 3.32529 8.82205C3.64375 9.40921 4.1035 9.90776 4.663 10.2727C4.01248 10.2517 3.37602 10.078 2.805 9.76565V9.81565C2.805 11.7737 4.22 13.4067 6.095 13.7787C5.74261 13.8722 5.37958 13.9195 5.015 13.9197C4.75 13.9197 4.493 13.8947 4.242 13.8447C4.51008 14.6526 5.02311 15.357 5.70982 15.86C6.39653 16.363 7.22284 16.6397 8.074 16.6517C6.61407 17.7762 4.82182 18.3837 2.979 18.3787C2.647 18.3787 2.321 18.3587 2 18.3227C3.88125 19.5134 6.06259 20.1439 8.289 20.1407C15.836 20.1407 19.962 13.9837 19.962 8.64465L19.948 8.12165C20.7529 7.55532 21.4481 6.8475 22 6.03265Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() =>
                      handleExternalLinks(
                        "https://www.instagram.com/_officialbhashini/"
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M16.017 2.125H7.947C6.37015 2.12685 4.85844 2.75414 3.74353 3.86924C2.62862 4.98433 2.00159 6.49615 2 8.073L2 16.143C2.00185 17.7198 2.62914 19.2316 3.74424 20.3465C4.85933 21.4614 6.37115 22.0884 7.948 22.09H16.018C17.5948 22.0881 19.1066 21.4609 20.2215 20.3458C21.3364 19.2307 21.9634 17.7188 21.965 16.142V8.072C21.9631 6.49515 21.3359 4.98344 20.2208 3.86853C19.1057 2.75362 17.5938 2.12659 16.017 2.125V2.125ZM19.957 16.142C19.957 16.6594 19.8551 17.1718 19.6571 17.6498C19.4591 18.1278 19.1689 18.5621 18.803 18.928C18.4371 19.2939 18.0028 19.5841 17.5248 19.7821C17.0468 19.9801 16.5344 20.082 16.017 20.082H7.947C6.90222 20.0817 5.90032 19.6665 5.16165 18.9276C4.42297 18.1888 4.008 17.1868 4.008 16.142V8.072C4.00827 7.02722 4.42349 6.02532 5.16235 5.28665C5.90122 4.54797 6.90322 4.133 7.948 4.133H16.018C17.0628 4.13327 18.0647 4.54849 18.8034 5.28735C19.542 6.02622 19.957 7.02822 19.957 8.073V16.143V16.142Z"
                        fill="white"
                      />
                      <path
                        d="M11.9823 6.94531C10.6137 6.94743 9.30184 7.4921 8.33421 8.45992C7.36658 9.42774 6.82216 10.7397 6.82031 12.1083C6.8219 13.4772 7.36634 14.7896 8.33421 15.7577C9.30209 16.7258 10.6144 17.2705 11.9833 17.2723C13.3524 17.2707 14.665 16.7262 15.6331 15.7581C16.6012 14.79 17.1457 13.4774 17.1473 12.1083C17.1452 10.7394 16.6003 9.4272 15.632 8.45951C14.6637 7.49182 13.3512 6.94764 11.9823 6.94631V6.94531ZM11.9823 15.2643C11.1456 15.2643 10.3431 14.9319 9.75139 14.3402C9.15971 13.7486 8.82731 12.9461 8.82731 12.1093C8.82731 11.2726 9.15971 10.4701 9.75139 9.87839C10.3431 9.28671 11.1456 8.95431 11.9823 8.95431C12.8191 8.95431 13.6216 9.28671 14.2132 9.87839C14.8049 10.4701 15.1373 11.2726 15.1373 12.1093C15.1373 12.9461 14.8049 13.7486 14.2132 14.3402C13.6216 14.9319 12.8191 15.2643 11.9823 15.2643Z"
                        fill="white"
                      />
                      <path
                        d="M17.1589 8.22009C17.8421 8.22009 18.3959 7.66627 18.3959 6.98309C18.3959 6.29992 17.8421 5.74609 17.1589 5.74609C16.4757 5.74609 15.9219 6.29992 15.9219 6.98309C15.9219 7.66627 16.4757 8.22009 17.1589 8.22009Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() =>
                      handleExternalLinks(
                        "https://www.linkedin.com/company/digiital-india-bhashini-division"
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M21.959 13.8453V21.2243H17.681V14.3393C17.681 12.6093 17.062 11.4293 15.514 11.4293C14.332 11.4293 13.628 12.2253 13.319 12.9943C13.206 13.2693 13.177 13.6523 13.177 14.0373V21.2243H8.897C8.897 21.2243 8.955 9.56434 8.897 8.35534H13.177V10.1793L13.149 10.2213H13.177V10.1793C13.745 9.30434 14.76 8.05334 17.033 8.05334C19.848 8.05334 21.959 9.89334 21.959 13.8453ZM4.421 2.15234C2.958 2.15234 2 3.11234 2 4.37534C2 5.61034 2.93 6.59934 4.365 6.59934H4.393C5.886 6.59934 6.813 5.61034 6.813 4.37534C6.787 3.11234 5.887 2.15234 4.422 2.15234H4.421ZM2.254 21.2243H6.532V8.35534H2.254V21.2243Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </p>
            </div>
          </div>
          </div>
          <div className=" w-[100%]">
            <hr className="w-full mx-auto border-gray-300 mb-[1.47rem] mt-4" />
            <div className="flex flex-col md:flex-row justify-between w-full">
              <p className="smallText hidden md:block"></p>
              <div className="flex gap-3 ml-auto">
                <Link to="/termsAndConditions" className="smallText">
                  Terms & Conditions
                </Link>
                <div className="w-[1px] h-[20px] bg-[#73B8F9]"></div>
                <Link to="privacy-policy" className="smallText">
                  Privacy Policy
                </Link>
                <div className="w-[1px] h-[20px] bg-[#73B8F9]"></div>
                <Link to="/connect" className="smallText">
                  Contact Us
                </Link>
              </div>
              {/* <p className="smallText block md:hidden text-center mt-[2.19rem]">© 2021 Bhashini. All rights reserved</p> */}

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
