import React from "react";
import assetsManager from "../../assets/assetsManager";
import Slider from "react-slick";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import pravaktaBanner1 from "../../assets/video/pravaktaBanner1.mp4";
import { Link } from "react-router-dom";
import { handleExternalLinks } from "../../utils/utilities";

const PravaktaBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return (
        <div className="h-[1rem] w-[1rem] bg-[#C4B5FD] rounded-full mt-2">
          <p className="hidden">{i + 1}</p>
        </div>
      );
    },
  };

  return (
    <Slider {...settings}>
      <div className="relative h-[388px] w-full overflow-hidden">
        <video
          autoPlay
          loop
          muted
          controls={false}
          playsInline
          className="absolute inset-0 w-full h-full object-cover z-[1]"
        >
          <source src={pravaktaBanner1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="absolute inset-0 z-[1] bg-black bg-opacity-30"
          style={{
            backgroundImage: "linear-gradient(45deg, black, transparent)",
          }}
        ></div>
        <div className="absolute inset-0 z-[2] flex items-center justify-center">
          <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 flex flex-col relative">
            <div className="flex flex-col gap-9 my-auto">
              <div className="my-auto text-white">
                <p className="heading1 font-medium">
                  Pravakta: Awareness & Outreach Programs
                </p>
                <p className="paragraph1 mt-4">
                  Minister{"'"}s Insights, Leadership, Global Voices, Awards,
                  Events and beyond...
                </p>
              </div>
            </div>
            <Breadcrumbs />
          </div>
        </div>
      </div>
      <div className="pravakta-banner2-bg !flex justify-between w-full relative  h-[388px] ">
        <div className="flex flex-col md:gap-6 gap-[.65rem] text-white md:w-2/3 w-full my-auto md:py-[6.31rem] md:px-[4rem] pt-[2.63rem] pb-[2.73rem] px-10">
          <p className="text-4xl md:text-2xl text-[.96rem]  font-semibold">
            Bhashini will enhance Digital India by integrating the National
            Knowledge Centre for improved information dissemination and
            connectivity.
          </p>
          <p className="md:text-lg text-[.48rem] font-medium">
            Ashwini Vaishnav, Minister for Railways, Communications, Electronics
            & IT
          </p>
          <button
            onClick={() =>
              handleExternalLinks(
                "https://www.businesstoday.in/bt-tv/video/elevating-digital-india-ashwini-vaishnaw-explores-bhashinis-role-upcoming-extension-394357-2023-08-16"
              )
            }
            target="_blank"
            className="px-6 py-3 bg-primary w-max rounded flex items-center gap-2 cursor-pointer"
          >
            <p className="pragraph2">Read more</p>
            <img src={assetsManager.rightArrow} alt="Right arrow" />
          </button>
        </div>
        <div className="w-2/5 hidden md:block absolute bottom-0 right-0">
          <img
            loading="lazy"
            alt="Bhashini banner title"
            className="float-right"
            src={assetsManager.pravaktaBanner2Img}
          />
        </div>
      </div>
      <div className="pravakta-banner3-bg !flex justify-between w-full relative h-[388px]">
        <div className="flex flex-col my-auto md:gap-6 gap-[.65rem] text-white md:w-2/3 w-full md:py-[6.31rem] md:px-[4rem] pt-[2.63rem] pb-[2.73rem] px-10">
          <p className="text-4xl sm:text-2xl text-[.96rem]  font-semibold">
            India’s digital leadership is truly remarkable, and BHASHINI stands
            out as a shining example of innovation.
          </p>
          <p className="md:text-lg text-[.48rem] font-medium">
            — Satya Nadella, CEO of Microsoft
          </p>
          <button
            onClick={() =>
              handleExternalLinks(
                "https://timesofindia.indiatimes.com/business/india-business/india-is-leading-the-world-with-digital-public-goods-and-solutions-like-bhashini/articleshow/107610800.cms"
              )
            }
            target="_blank"
            className="px-6 py-3 bg-primary w-max rounded flex items-center gap-2 cursor-pointer"
          >
            <p className="pragraph2">Read more</p>
            <img src={assetsManager.rightArrow} alt="Right arrow" />
          </button>
        </div>
        <div className="w-2/5 hidden md:block absolute bottom-0 right-0">
          <img
            loading="lazy"
            alt="Bhashini banner title"
            src={assetsManager.pravaktaBanner3Img}
          />
        </div>
      </div>
    </Slider>
  );
};

export default PravaktaBanner;
