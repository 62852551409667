import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { toast } from "react-toastify";
import axios from "axios";

const ContactUs = () => {
  const [timeoutIds, setTimeoutIds] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email_address: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      mobile_number: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, "Must be exactly 10 digits")
        .max(10, "Must be exactly 10 digits"),
      email_address: Yup.string()
        .email("Invalid Email address")
        .required("Email is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsSubmitting(true);
        const response = await axios.post(
          "https://dashboard-be.bhashini.co.in/v1/contact-ceo",
          values
        );
        console.log(response?.data?.message);
        toast.success(response?.data?.message);
        resetForm();
      } catch (error) {
        console.error("Something went wrong", error);
        toast.error("Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fields = [
      "first_name",
      "last_name",
      "mobile_number",
      "email_address",
      "subject",
    ];
    fields.forEach((field) => {
      if (formik.touched[field] && formik.errors[field]) {
        if (timeoutIds[field]) {
          clearTimeout(timeoutIds[field]);
        }
        const timeoutId = setTimeout(() => {
          formik.setFieldTouched(field, false);
          formik.setFieldError(field, "");
        }, 3000);
        setTimeoutIds((prevTimeoutIds) => ({
          ...prevTimeoutIds,
          [field]: timeoutId,
        }));
      }
    });
  }, [formik, timeoutIds]);

  useEffect(() => {
    return () => {
      Object.values(timeoutIds).forEach(clearTimeout);
    };
  }, [timeoutIds]);

  return (
    <div className="flex flex-col w-full gap-[6.25rem]">
      <div className="w-full text-white h-[24rem] md:px-[4rem] pl-8 contact-us-banner flex flex-col ">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Contact Us</p>
          <p className="paragraph1">
            Is your answer to your question missing? We are here to help you!
          </p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="md:mx-16 mx-4 w-full text-white flex flex-col md:flex-row">
        <div className="md:w-1/2 w-[90%] connect flex flex-col md:py-10 md:pl-10 p-6 md:gap-[5rem] gap-[4.17rem]">
          <div className="flex flex-col">
            <p className="heading2 font-medium">Contact Information</p>
            <p className="paragarh1 font-normal">
              Say something to start a live chat!
            </p>
          </div>
          <div className="flex gap-6">
            <svg
              className="hidden md:block"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                fill="white"
              />
            </svg>
            <p className="heading3 font-medium">
              ceo-dibd[at]digitalindia[dot]gov[dot]in
            </p>
          </div>
          <div className="flex flex-col gap-[3.25rem]">
            <div className="flex gap-6">
              <svg
                className="hidden md:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.0022 10.999H22.0022C22.0022 5.869 18.1292 2 12.9922 2V4C17.0542 4 20.0022 6.943 20.0022 10.999Z"
                  fill="white"
                />
                <path
                  d="M13.0003 7.99829C15.1033 7.99829 16.0003 8.89529 16.0003 10.9983H18.0003C18.0003 7.77329 16.2253 5.99829 13.0003 5.99829V7.99829ZM16.4223 13.4413C16.2301 13.2666 15.9776 13.1735 15.7181 13.1815C15.4585 13.1895 15.2123 13.2981 15.0313 13.4843L12.6383 15.9453C12.0623 15.8353 10.9043 15.4743 9.71228 14.2853C8.52028 13.0923 8.15928 11.9313 8.05228 11.3593L10.5113 8.96529C10.6977 8.78441 10.8064 8.53811 10.8144 8.27849C10.8225 8.01888 10.7292 7.76633 10.5543 7.57429L6.85928 3.51129C6.68432 3.31864 6.44116 3.20179 6.18143 3.18555C5.92171 3.1693 5.66588 3.25494 5.46828 3.42429L3.29828 5.28529C3.12539 5.4588 3.0222 5.68974 3.00828 5.93429C2.99328 6.18429 2.70728 12.1063 7.29928 16.7003C11.3053 20.7053 16.3233 20.9983 17.7053 20.9983C17.9073 20.9983 18.0313 20.9923 18.0643 20.9903C18.3088 20.9766 18.5396 20.8729 18.7123 20.6993L20.5723 18.5283C20.7417 18.3308 20.8276 18.0751 20.8115 17.8153C20.7954 17.5556 20.6788 17.3124 20.4863 17.1373L16.4223 13.4413Z"
                  fill="white"
                />
              </svg>
              <p className="heading3 font-medium">011-2430 1361</p>
            </div>
            <div className="flex gap-6">
              <svg
                className="hidden md:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12 12.75C11.4067 12.75 10.8266 12.5741 10.3333 12.2444C9.83995 11.9148 9.45543 11.4462 9.22837 10.8981C9.00131 10.3499 8.9419 9.74667 9.05765 9.16473C9.17341 8.58279 9.45913 8.04824 9.87869 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.1481 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.8241 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7954 12.749 12 12.75Z"
                  fill="white"
                />
              </svg>
              <p className="heading3 font-medium">
                Electronics Niketan, 6, CGO Complex, Lodhi Road, New Delhi -
                110003
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-[40%] w-[90%] md:px-16 px-4 py-10 bg-[#EFF3FE]">
          <div className="flex flex-col gap-9">
            <p className="heading2 font-medium text-black">Write to us</p>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-4"
            >
              <div className="flex gap-4">
                <div className="flex flex-col w-1/2">
                  <input
                    className="border border-gray-300 p-2 text-black"
                    type="text"
                    name="first_name"
                    placeholder="First Name *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.first_name}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col w-1/2">
                  <input
                    className="border border-gray-300 p-2 w-full text-black"
                    type="text"
                    name="last_name"
                    placeholder="Last Name *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.last_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col">
                <input
                  className="border border-gray-300 p-2 text-black"
                  type="text"
                  name="mobile_number"
                  placeholder="Mobile Number *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile_number}
                />
                {formik.touched.mobile_number && formik.errors.mobile_number ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.mobile_number}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <input
                  className="border border-gray-300 p-2 text-black"
                  type="email_address"
                  name="email_address"
                  placeholder="Email Address *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email_address}
                />
                {formik.touched.email_address && formik.errors.email_address ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.email_address}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <input
                  className="border border-gray-300 p-2 text-black"
                  type="text"
                  name="subject"
                  placeholder="Subject *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.subject}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <textarea
                  className="border border-gray-300 p-2 text-black"
                  name="message"
                  placeholder="Message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
              </div>
              <button
                disabled={isSubmitting}
                type="submit"
                className="bg-primary text-white py-2 px-4 rounded w-fit ml-auto"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
