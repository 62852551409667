import React, { createContext, useContext, useReducer } from "react";
import { reducer } from "./reducer";
import { initialState } from "./initialState";
const AppContext = createContext();

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider
      value={{
        //states
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

/**
 * 
 * @returns {object} state and dispatch
 * @description This is a custom hook that returns the gloabl state and dispatch from the context. Used to access the global state and dispatch from any component. Destrcuture the object to get the state and dispatch. On destructing the state object, you will get the initial state object. and the dispatch object is used to update the state.
 * @example
 * const {state, dispatch} = Globalstate();
 * console.log(state); // {isDarkMode: false}
 *  dispatch({
      type: condition.SETDARKMODE,
      payload: newDarkModeState,
    });
 *
 *  
 * 
 * 
 * 
 */

const Globalstate = () => useContext(AppContext);

export { Context, Globalstate, AppContext };