import React from "react";
// import assetsManager from "../../assets/assetsManager";

import assetsManager from "../../assets/assetsManager";
import Marquee from "react-fast-marquee";

const HomeBanner = () => {
  return (
    <Marquee pauseOnHover={true} speed={75}>
      <div className="md:w-full md:h-[24.75rem] h-[8.43rem] w-[23.43rem]">
        <img
          src={assetsManager.homeBanner1}
          alt="banner"
          className="w-full h-full"
          loading="lazy"
        />
      </div>
      <div className="md:w-full md:h-[24.75rem] h-[8.43rem] w-[23.43rem]">
        <img
          src={assetsManager.homeBanner3}
          alt="banner"
          className="w-full h-full"
          loading="lazy"
        />
      </div>
      <div className="md:w-full md:h-[24.75rem] h-[8.43rem] w-[23.43rem]">
        <img
          src={assetsManager.homeBanner2}
          alt="banner"
          className="w-full h-full"
          loading="lazy"
        />
      </div>
      <div className="md:w-full md:h-[24.75rem] h-[8.43rem] w-[23.43rem]">
        <img
          src={assetsManager.homeBanner4}
          alt="banner"
          className="w-full h-full"
          loading="lazy"
        />
      </div>
      <div className="md:w-full md:h-[24.75rem] h-[8.43rem] w-[23.43rem]">
        <img
          src={assetsManager.homeBanner5}
          alt="banner"
          className="w-full h-full"
          loading="lazy"
        />
      </div>
    </Marquee>
  );
};

export default HomeBanner;
