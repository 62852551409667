import React from 'react'

const Iift = () => {
    const images = [
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage1.JPG",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage10.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage14.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage11.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage13.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage26.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage14.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage16.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage18.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage19.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5049.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage29.jpeg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage20.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage24.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage25.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage4.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage5.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage6.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage7.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage8.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/IITF/IIFTImage9.jpg"
      ];
    
  return (
    <div className="flex flex-wrap gap-2 mx-16">
    {images.map((image, index) => (
      <div key={index} className="w-1/2 md:w-1/4 h-1/2">
        <a href={image} data-fancybox="gallery" className="block h-full">
          <img src={image} alt={`G20 Event ${index + 1}`} className="h-full" />
        </a>
      </div>
    ))}
  </div>
  )
}

export default Iift