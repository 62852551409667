import React from "react";
import {
  InstagramEmbed,
  LinkedInEmbed,
  XEmbed,
} from "react-social-media-embed";
import assetsManager from "../../../assets/assetsManager";

const TrendingSocialMedia = () => {
  return (
    <div className="md:mt-[6.31rem] my-4 w-full mt-[3.17rem] md:mx-0">
      <div className="flex flex-col">
        <p className="text-[2.5rem] font-bold text-center">
          Trending Social Media
        </p>
        <p className="heading3 font-medium text-center">Stay up to date</p>
      </div>
      <div className="flex gap-8 mt-9 md:flex-row flex-col w-full flex-wrap justify-center">
        <div className="flex flex-col gap-3" style={{ height: "500px" }}>
          <div className="w-full flex items-center justify-center">
            <img
              src={assetsManager.instagramFullLogo}
              loading="lazy"
              alt="instagram"
            />
          </div>
          <InstagramEmbed
            url="https://www.instagram.com/p/C7sXkpBs9bM"
            width={345}
            className="h-full m-auto"
          />
        </div>
        <div className="flex flex-col gap-3" style={{ height: "500px" }}>
          <div className="w-full flex items-center justify-center">
            <img
              src={assetsManager.linkedInFullLogo}
              loading="lazy"
              alt="linkedin"
            />
          </div>
          <LinkedInEmbed
            className="border border-gray-200 h-full m-auto"
            url="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7214220584799387648"
            width={345}
          />
        </div>
        <div className="flex flex-col gap-3" style={{ height: "450px" }}>
          <div className="w-full flex items-center justify-center">
            <img
              src={assetsManager.twitterFullLogo}
              loading="lazy"
              alt="twitter"
              className="h-[1.8rem]"
            />
          </div>
          <XEmbed
            url="https://twitter.com/_BHASHINI/status/1808823637946122397"
            width={345}
            className="h-full m-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default TrendingSocialMedia;
