import React from "react";

const SprintTimelines = () => {
  const tableData = [
    {
      id: 1,
      activity: "Launch of Bhashini-sprint",
      timelines: "11th March 2024",
    },
    {
      id: 2,
      activity: "Last date of Registration- Submission of ideas",
      timelines: "15th March 2024",
    },{
      id: 3,
      activity: "Initial Screening of applications & declaration of results (Stage 1)",
      timelines: "26th March 2024",
    },{
      id: 4,
      activity: "Evaluation of (Stage 2) Initial prototypes and result declaration",
      timelines: "2nd & 3rd April 2024",
    },{
      id: 5,
      activity: "Grand Finale (Stage 3) & Declaration of winners",
      timelines: "15th April 2024",
    },
  ];

  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold ">
        Dates & Deadlines:
        <span className="text-primary">Bhashini Sprint</span>
      </p>
      <div className="w-full bg-[#EFF3FE] px-[2.3rem] py-6">
        <table className="w-full">
          <thead>
            <tr className="bg-[#DFEAF5]">
              <th className="text-left smallText p-4">Sr. No..</th>
              <th className="text-left smallText p-4">Activity</th>
              <th className="text-left smallText p-4">Timelines</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data) => (
              <tr
                key={data.id}
                className="border border-[#EAECF0]  bg-[#F9FAFB]"
              >
                <td className="p-4 paragraph2">{data.id}</td>
                <td className="p-4 paragraph2">{data.activity}</td>
                <td className="p-4 paragraph2">{data.timelines}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SprintTimelines;
