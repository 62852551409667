import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

import { initReactI18next } from "react-i18next";
import { localesManager } from './assets/locales/localesManager';

i18n.use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources:{
      as: {
        ...localesManager.as
      },
      bn: {
        ...localesManager.bn
      },
        en: {
            ...localesManager.en
        
        },
        hi:{
            ...localesManager.hi
        },
        doi:{
            ...localesManager.doi
        },
        gu:{
          ...localesManager.gu
        },
        kn:{
          ...localesManager.kn
        },
        ks: {

        },
        gom:{
          ...localesManager.gom
        },
        mai:{
          ...localesManager.mai
        },
        ml:{
          ...localesManager.ml
        },
        mr:{
          ...localesManager.mr
        },
        ne:{
          ...localesManager.ne
        },
        or:{
          ...localesManager.or
        },
        pa:{
          ...localesManager.pa
        },
        sa:{
          ...localesManager.sa
        },
        sd:{
          ...localesManager.sd
        },
        ta:{
          ...localesManager.ta
        },
        te:{
          ...localesManager.te
        },
        ur:{
          ...localesManager.ur
        }
    },
    interpolation: {
  
  keySeparator: '.', // if you want to re-enable it (not "true", but actual separator value)
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;