import React from "react";
import withLayout from "../../hocs/withLayout";
import BhashiniEcosystem from "../../components/BhashiniEcosystemModal/BhashiniEcosystemModal";
import OurObjectives from "../../components/OurObjectives/OurObjectives";
import VisionAndMission from "../../components/VisionAndMission/VisionAndMission";
import AboutUs from "../../components/AboutUs/AboutUs";
import AboutBanner from "../../components/AboutBanner/AboutBanner";
import OurTeam from "../../components/OurTeam/OurTeam";
import OurJourney from "../../components/OurJourney/OurJourney";
// import OurJourney from "../../components/OurJourney/OurJourney";

const   About = () => {
  return <>
  About content
  </>
};
export const aboutNavItems = [
  {
    label: "About Us",
    path: "about-bhashini",
    component: AboutUs,
  },
  {
    label: "Vision & Mission",
    path: "vision-mission",
    component: VisionAndMission,
  },
  {
    label: "Our Objectives",
    path: "our-objectives",
    component: OurObjectives,
  },
  {
    label: "Our Team",
    path: "our-team",
    component: OurTeam,
  },
  {
    label: "Bhashini Ecosystem",
    path: "bhashini-ecosystem",
    component: BhashiniEcosystem,
  },
  {
    label: "Bhashini At Work",
    path: "bhashini-at-work",
    component: OurJourney,
  }
];


export default withLayout(aboutNavItems, <AboutBanner />)(About);
