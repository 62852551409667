import React from 'react'
import EcoSystemCarousel from './EcoSystemCarousel'
import { useTranslation } from 'react-i18next'
const CollabrativeEcosystem = () => {
  const {t} = useTranslation();
  return (
    <div className="2xl:container">

    <div  className='flex flex-col gap-9 mx-7 md:mx-16 mt-[6.25rem]'>
        <div className="flex flex-col gap-4">
            <p className='heading1 text-center font-semibold text-[#242424]'>
            Collaborative Ecosystem of    Bhashini Sahyogi
            </p>
            <p className='heading3 text-center'>
            {t("homePage.collaborativeEcosystem.content")}
            </p>
        </div>
        <EcoSystemCarousel/>
    </div>
    </div>
  )
}

export default CollabrativeEcosystem