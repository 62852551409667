import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const TranslationServiceProvider = () => {
  const services = [
    {
      name: "VTV",
      description: "Video to Video",
    },
    {
      name: "VTT",
      description: "Video to Text",
    },
    {
      name: "TTT",
      description: "Text to Text",
    },
    {
      name: "TTS",
      description: "Text to Speech",
    },
    {
      name: "STT",
      description: "Speech to Text",
    },
    {
      name: "STS",
      description: "Speech to Speech",
    },
    {
      name: "OCR",
      description: "Optical Character Recognition",
    },
    {
      name: "DOC-OCR",
      description: "Document Optical Character Recognition",
    },
  ];

  const tsps = [
    {
      companyName: "3S Studio Pvt Ltd",
      servicesOffered: "TTS, VTV, TTT",
    },
    {
      companyName: "Black Letters Lingual Solutions LLP",
      servicesOffered: "TTT, DOC/OCR",
    },
    {
      companyName: "CoRover AI",
      servicesOffered: "TTS, STT, STS, VTV, TTT, DOC/OCR",
    },
    {
      companyName: "Crescendo Transcription Pvt Ltd",
      servicesOffered: "TTS, STT, VTT, TTT, DOC/OCR",
    },
    {
      companyName: "Crystal Hues Limited",
      servicesOffered: "TTS, STT, VTV, VTT, TTT, DOC/OCR",
    },
    {
      companyName: "Devnagri AI Pvt Ltd",
      servicesOffered: "TTS, STT, VTV, VTT, TTT, DOC/OCR",
    },
    {
      companyName: "E Bhasha Setu Language Services Pvt Ltd",
      servicesOffered: "SST, TTT, DOC/OCR",
    },
    {
      companyName: "Ewan Business Solutions Pvt Ltd",
      servicesOffered: "TTS, STT, TTT, DOC/OCR",
    },
    {
      companyName: "Fidel Softech Limited",
      servicesOffered: "STT, STS, VTV, TTT",
    },
    {
      companyName: "KeyPoint Technologies India Pvt Ltd",
      servicesOffered: "TTS, STT, VTV, VTT, TTT, DOC/OCR",
    },
    {
      companyName: "Meet Monk",
      servicesOffered: "TTS, STT, STS",
    },
    {
      companyName: "Megdap Innovation Labs Private Limited",
      servicesOffered: "TTS, STT, STS, TTT, DOC/OCR",
    },
    {
      companyName: "Moraine Group",
      servicesOffered: "TTT",
    },
    {
      companyName: "Ninestars Information Technologies Pvt. Limited",
      servicesOffered: "TTS, STT, STS, TTT, DOC/OCR",
    },
    {
      companyName: "Panacea BPO Services Private Limited",
      servicesOffered: "TTS, STT, STS, TTT, DOC/OCR",
    },
    {
      companyName: "Process Nine Technologies Private Limited",
      servicesOffered: "TTS, STT, STS, VTV, TTT, DOC/OCR",
    },
    {
      companyName: "Reverie Language Technologies",
      servicesOffered: "TTS, STT, STS, VTV, TTT, DOC/OCR",
    },
    {
      companyName: "Tarento Technologies",
      servicesOffered: "TTS, STT, VTT, TTT, DOC/OCR",
    },
    {
      companyName: "VerboLabs",
      servicesOffered: "TTS, STT, VTV, VTT, TTT, DOC/OCR",
    },
    {
      companyName: "Zest",
      servicesOffered: "TTS, STT, TTT, DOC/OCR",
    },
  ];

  return (
    <div className="w-full flex-col flex gap-[6.25rem]">
      <div className="w-full text-white h-[24rem] px-[9.06rem] pl-8 pr-4 md:pr-44  flex flex-col translation-service-provider">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Translation Service Provider</p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="2xl:container flex flex-col gap-[6.25rem] ">
        <div className="flex flex-col md:mx-16 mx-4">
          <div className="flex flex-col gap-16">
            <div className="flex flex-col">
              <p className="text-[#242424] text-[2rem] font-semibold">
                Bhashini’s Empanelled Translations and Vetting Agencies
              </p>
              <p className="text-[#616263] text-[1.25rem] font-medium">
                Translations Services offered by TSPs
              </p>
            </div>
            <div className="flex flex-wrap gap-6 ">
              {services.map((services, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col shadow-xl pt-[3.68rem] pr-[3rem] pb-[3.75rem] pl-[1.875rem] w-full md:w-[20%] relative border-l-[6px] border-[#0663c7] rounded"
                  >
                    <p className="text-[1.25rem] font-semibold">
                      {services.name}
                    </p>
                    <p className="font-medium">{services.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col  md:mx-16 mx-4 gap-[1.81rem]">
          <p className="text-[2rem] font-semibold">
            Translation Service Provider (TSPs)
          </p>
          <div className="w-full bg-[#EFF3FE] px-[2.3rem] py-6">
            <table className="w-full">
              <thead>
                <tr className="bg-[#0D3AA9] text-white">
                  <th className="text-left smallText p-4">Sr. No.</th>

                  <th className="text-left smallText p-4">Comapny Name</th>
                  <th className="text-left smallText p-4">
                    Translation Services Offered
                  </th>
                </tr>
              </thead>
              <tbody>
                {tsps.map((data, i) => (
                  <tr
                    key={i}
                    className="border border-[#EAECF0]  bg-[#F9FAFB] text-[#424242] text-[.875rem] font-semibold"
                  >
                    <td className="p-4 paragraph2">{i + 1}</td>
                    <td className="p-4 paragraph2">{data.companyName}</td>
                    <td className="p-4 paragraph2">{data.servicesOffered}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranslationServiceProvider;
