import React, { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import AccessbilityBar from "./AccessbilityBar/AccessbilityBar";
import assetsManager from "../../assets/assetsManager";
import { Globalstate } from "../../context/context";

const Navbar = ({ skipToContent }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [itemHovered, setItemHovered] = useState(null);
  const { state } = Globalstate();
  const { isDarkMode } = state;

  // Lock/Unlock body scroll when menu is opened/closed
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  // Toggle menu open/close state
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  // Navigation items with potential dropdown items
  const navItems = [
    { title: "Home", link: "/", dropdownItems: [], tooltipTitle: "Home" },
    {
      title: "About",
      link: "/about-bhashini",
      dropdownItems: [
        { title: "About Us", link: "/about-bhashini" },
        { title: "Vision & Mission", link: "/vision-mission" },
        { title: "Our Team", link: "/our-team" },
        { title: "Our Objectives", link: "/our-objectives" },
        { title: "Bhashini Ecosystem", link: "/bhashini-ecosystem" },
        { title: "Bhashini At Work", link: "/bhashini-at-work" },
      ],
      tooltipTitle: "About",
    },
    {
      title: "Arpan",
      link: "/services",
      dropdownItems: [
        { title: "Services", link: "/services" },
        { title: "Our Products", link: "/product" },
        { title: "Careers", link: "/career" },
        { title: "Tender/EOI", link: "/tender" },
        // { title: "On Premise", link: "/on-premise" },
      ],
      tooltipTitle: "Arpan",
    },
    {
      title: "Sahyogi",
      link: "/sahyogi",
      dropdownItems: [
        {
          title: "About Sahyogi",
          link: "/sahyogi",
        },
        {
          title: "Bhashini Samudaye",
          link: "/samudaye",
        },
        {
          title: "Be our Sahyogi",
          link: "/be-our-sahyogi",
        },
        {
          title: "Translation Service Providers",
          link: "/sahyogi/translation-service-providers",
        },
        {
          title: "Startups",
          link: "/sahyogi/startup",
        },
        {
          title: "Anusandhan Mitra",
          link: "/sahyogi/anushandhan-mitra",
        },
        {
          title: "Sahyogi Projects",
          link: "/sahyogi-projects",
        },
      ],
      tooltipTitle: "Discover our Associates",
    },
    {
      title: "Sanchalak",
      link: "/sanchalak",
      dropdownItems: [],
      tooltipTitle: "Drivers, Services and Offerings",
    },
    {
      title: "Prayog",
      link: "/parikshan-app",
      dropdownItems: [],
      tooltipTitle: "Explore our reference applications",
    },
    {
      title: "Pravakta",
      link: "featured",
      dropdownItems: [
        { title: "Featured", link: "/featured" },
        { title: "Minister's Insights", link: "/ministers-insights" },
        { title: "Leadership Corner", link: "/leadership-corner" },
        { title: "Global Voices", link: "/global-voices" },
        { title: "Awards & Recognition", link: "/awards-recognition" },
        { title: "Bhashini in News", link: "/bhashini-in-news" },
        { title: "Workshops", link: "/workshops" },
      ],
      tooltipTitle: "Arpan",
    },
  ];

  // Component for rendering logos
  const Logo = memo(({ src, alt, height, width, className }) => (
    <img
      loading="lazy"
      src={src}
      alt={alt}
      height={height}
      width={width}
      className={className}
    />
  ));
  Logo.displayName = "Logo";
  // Component for rendering navigation links and their dropdowns
  const NavLink = memo(({ item, onClose }) => (
    <div className="relative group">
      <div className="flex items-center">
        <Link
          to={item.link}
          className="py-[.5rem] font-medium text-[#6A6A6A] dark:text-white hover:text-primary transition-colors duration-300 ease-in-out"
          onClick={onClose}
        >
          {item.title}
        </Link>
        {item.dropdownItems.length > 0 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 text-gray-500 cursor-pointer ml-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 011.414-1.414L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-.707.293z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      {item.dropdownItems.length > 0 && (
        <div className="absolute top-full z-10 left-0 py-2 bg-white shadow-md rounded-md w-max opacity-0 invisible transform translate-y-2 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:visible group-hover:translate-y-0">
          {item.dropdownItems.map((dropdownItem, i) => (
            <Link
              key={i}
              to={dropdownItem.link}
              className="block w-full px-4 py-2 text-gray-800 hover:bg-[#D6DEF5]"
              onClick={onClose}
            >
              {dropdownItem.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  ));

  NavLink.displayName = "NavLink";

  return (
    <div className="flex flex-col w-full h-full text-white">
      <AccessbilityBar skipToContent={skipToContent} />
      <div className="2xl:container">
        <div className="flex justify-between items-center px-2 md:px-16 py-[1.125rem]">
          <div className="flex items-center w-full md:w-fit">
            <button className="md:hidden text-black mr-3" onClick={toggleMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            {isDarkMode ? (
              <Logo
                src={assetsManager.meityDarkLogo}
                alt="logo"
                height={61}
                width={186}
                className="hidden md:block"
              />
            ) : (
              <Logo
                src={assetsManager.meityLogo}
                alt="logo"
                height={61}
                width={186}
                className="hidden md:block"
              />
            )}
            <Link to="/" className="ml-auto">
              <Logo
                src={
                  isDarkMode
                    ? assetsManager.bhashiniDarkLogo
                    : assetsManager.digitalIndiaLogo
                }
                alt="bhashini logo"
                height={34}
                width={73}
                className="block md:hidden"
              />
            </Link>
            <div className="hidden md:block h-10 w-0.5 bg-[#EBEBEB] dark:bg-white/10 my-1 mx-3"></div>
            <div className="text-black dark:text-white md:flex flex-col hidden">
              <p className="font-bold">National Language Translation Mission</p>
              <p className="italic">
                Harnessing technology to transcend language barriers
              </p>
            </div>
          </div>
          <div className="flex items-center ml-auto">
            <Logo
              src={
                isDarkMode
                  ? assetsManager.bhashiniDarkLogo
                  : assetsManager.digitalIndiaLogo
              }
              alt="bhashini logo"
              height={60}
              width={125}
              className="hidden md:block"
            />
          </div>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out ${
          isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={closeMenu}
      ></div>

      <div
        className={`fixed top-0 left-0 w-64 h-full bg-white text-black shadow-lg transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50 overflow-y-auto`}
      >
        <button
          className="absolute top-4 right-4 text-black"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="mt-16">
          {navItems.map((item) => (
            <div key={item.link} className="relative group">
              <div className="flex items-center">
                <Link
                  to={item.link}
                  className="block py-2 px-4 text-black hover:text-primary transition-colors duration-300 ease-in-out"
                  onClick={closeMenu}
                >
                  {item.title}
                </Link>
                {item.dropdownItems.length > 0 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 text-gray-500 cursor-pointer ml-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 011.414-1.414L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-.707.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              {item.dropdownItems.length > 0 && (
                <div className="pl-4">
                  {item.dropdownItems.map((dropdownItem, i) => (
                    <Link
                      key={i}
                      to={dropdownItem.link}
                      className="block w-full py-1 px-4 text-gray-800 hover:bg-gray-100"
                      onClick={closeMenu}
                    >
                      {dropdownItem.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
          <Link
            to={"https://bhashini.gov.in/bhashadaan"}
            className="block py-2 px-4 text-primary border-t border-gray-200 font-semibold"
            onClick={closeMenu}
            target="_blank"
          >
            BhashaDaan
          </Link>
        </div>
      </div>

      <div className="2xl:container">
        <div className="hidden md:flex md:items-center md:justify-between px-2 md:px-16 mb-[0.97rem]">
          <div className="flex items-center">
            <div className="flex gap-[2.13rem] flex-col md:flex-row">
              {navItems.map((item) => (
                <NavLink
                  key={item.link}
                  item={item}
                  onHover={setItemHovered}
                  onClose={closeMenu}
                  isHovered={itemHovered === item.title}
                />
              ))}
            </div>
          </div>
          <div className="flex gap-3">
            <Link
              target="_blank"
              to={"https://bhashini.gov.in/bhashadaan"}
              className="buttonSmall text-white bg-primary border border-primary font-semibold text rounded"
            >
              BhashaDaan
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
