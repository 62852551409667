import React from "react";
import HomeSummary from "../../components/HomeSummary/HomeSummary";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import WhatWeDo from "../../components/WhatWeDo/WhatWeDo";
import ExpandingService from "../../components/ExpandingServices/ExpandingServices";
import CollabrativeEcosystem from "../../components/CollaborativeEcosystem/CollabrativeEcosystem";
import HomeBhashaDaan from "../../components/HomeBhashaDaan/HomeBhashaDaan";
import LatestUpdates from "../../components/LatestUpdates/LatestUpdates";
import TrendingSocialMedia from "../../components/Footer/TrendingSocialMedia/TrendingSocialMedia";


const Home = () => {
  
  return (
      <div className="flex flex-col w-full">
      <HomeBanner />
      <LatestUpdates/>
      <HomeSummary />
      <WhatWeDo/>
      <ExpandingService/>
      <CollabrativeEcosystem/>
      <HomeBhashaDaan/>
      <TrendingSocialMedia/>
    
      

      </div>

  );
};

export default Home;
