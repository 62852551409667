import React from "react";
import assetsManager from "../../assets/assetsManager";
import Slider from "react-slick";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const AboutBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return (
        <div className="h-[1rem] w-[1rem] bg-[#C4B5FD] rounded-full mt-2">
          <p className="hidden">{i + 1}</p>
        </div>
      );
    },
  };
  return (
    <Slider {...settings}>
      <div className="about-page-banner1 !flex justify-between w-full relative h-full flex-col md:flex-row ">
        <div className="flex flex-col md:gap-6 gap-[.65rem] text-white md:w-2/3 w-full md:py-[6.31rem] md:px-[7.5rem] pt-[2.63rem] pb-[2.73rem] px-10">
          <p className="md:text-4xl text-[.96rem] font-semibold ">
            Empowering India Digitally: Bridging Language Gaps for All
          </p>
          <p className="md:text-lg text-[.48rem] font-medium">
            Bhashini aims to enable all indians easy access to the internet and
            digital services in their own language, and increase the content in
            Indian Languages.
          </p>
          <div className="mt-[0.87rem]">
            <Breadcrumbs />
          </div>
        </div>
        <div className="w-2/5  hidden md:block absolute bottom-0 right-0">
          <img
            loading="lazy"
            alt="Bhashini banner title"
            src={assetsManager.banner1RightImg}
          />
        </div>
      </div>
      <div className=" about-page-banner1 !flex justify-between w-full relative h-full">
        <div className="flex flex-col md:gap-6 gap-[.65rem] text-white md:w-2/3 w-full md:py-[6.31rem] md:px-[7.5rem] pt-[2.63rem] pb-[2.73rem] px-10">
          <p className="md:text-4xl text-[.96rem]  font-semibold">
            One Nation, Many Voices. Bhashini Connects Us All.
          </p>
          <p className="md:text-lg text-[.48rem] font-medium">
            Empowering communication and bridging the digital divide for a truly
            inclusive India.
          </p>
          <div className="mt-[2.87rem]">
            <Breadcrumbs />
          </div>
        </div>
        <div className="w-2/5  hidden md:block absolute bottom-0 right-0">
          <img
            loading="lazy"
            alt="Bhashini banner title"
            src={assetsManager.aboutBanner2}
          />
        </div>
      </div>
      <div className=" about-page-banner1 !flex justify-between w-full relative h-full">
        <div className="flex flex-col md:gap-6 gap-[.65rem] text-white md:w-2/3 w-full md:py-[6.31rem] md:px-[7.5rem] pt-[2.63rem] pb-[2.73rem] px-10">
          <p className="md:text-4xl text-[.96rem]  font-semibold">
            Transcend the Language Barrier with Bhashini
          </p>
          <p className="md:text-lg text-[.48rem] font-medium">
            Don&apos;t let language hold you back. Bhashini&apos;s AI tools
            empower you to speak freely and access everything in your preferred
            language.
          </p>
          <div className="mt-[2.87rem]">
            <Breadcrumbs />
          </div>
        </div>
        <div className="w-2/5 hidden md:block absolute bottom-0 right-0">
          <img
            loading="lazy"
            alt="Bhashini banner title"
            src={assetsManager.aboutBanner3}
          />
        </div>
      </div>
    </Slider>
  );
};

export default AboutBanner;
