import React, { useRef, useEffect } from "react";
import SamudayeForm from "./SamudayeForm";
import AboutSamudaye from "./AboutSamudaye";
import axios from "axios";
import { SAMUDAYE_BACKEND_URL } from "../../config/config";
import SamudayeBanner from "./SamudayeBanner";
import arrow from "../../assets/images/TechnologyDay/double_arrow.svg";

const Samudaye = () => {
  const [language, setLanguage] = React.useState([]);
  const [organizations, setOrganizations] = React.useState([]);
  const formRef = useRef(null); // Create a ref for the form

  useEffect(() => {
    const getLanguage = async () => {
      try {
        const res = await axios.get(`${SAMUDAYE_BACKEND_URL}/list_languages`);
        setLanguage(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getLanguage();
  }, []);

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        const res = await axios.get(`${SAMUDAYE_BACKEND_URL}/list_org_type `);
        setOrganizations(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getOrganizations();
  }, []);

  const scrollToSection = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the form
  };

  return (
    <div className="flex flex-col gap-16">
      <SamudayeBanner />
      <div
        className="mx-auto flex flex-col gap-3 oscillate cursor-pointer"
        onClick={scrollToSection}
      >
        <p className="text-[.85938]">Skip to the form</p>
        <div className="flex justify-center p-2 border border-[#0053ad] rounded-[.375rem] w-[2.625rem] mx-auto">
          <img src={arrow} alt="Arrow" />
        </div>
      </div>
      <div className="sm:px-16 px-4">
        <AboutSamudaye />
        <SamudayeForm
          language={language}
          organizations={organizations}
          ref={formRef}
        />{" "}
        {/* Pass the ref to the form */}
      </div>
    </div>
  );
};

export default Samudaye;
