import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import vaaniProjects from "../../assets/video/-1efd-4b04-a35b-e7b3664ac459.mp4";
import assetsManager from "../../assets/assetsManager";
import { handleExternalLinks } from "../../utils/utilities";

const SahyogiProjects = () => {
  return (
    <div className="w-full flex-col flex gap-[6.25rem]">
      <div className="sahyogi-projects-banner-bg">
        <div className="container w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 flex flex-col">
          <div className="my-auto text-white">
            <p className="heading1 font-medium">Sahyogi Projects</p>
            <p className="heading3">
              Empowering Collaboration for a Linguistically Inclusive Digital
              Future
            </p>
          </div>
          <Breadcrumbs />
        </div>
      </div>
      <div className="container">
        <div className="flex flex-col gap-9 items-center w-full">
          <div className="flex flex-col gap-2">
            <p className="text-[2.25rem] font-semibold text-[#2947A3] text-center">
              Sahyogi Projects for an Inclusive India
            </p>
            <p className="text-[1.125rem] font-semibold text-[#424242] text-center">
              Explore how Bhashini is partnering with top organizations to
              enhance language accessibility for all.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row w-full">
            <div className="flex relative h-[28rem] w-full sm:w-1/2">
              <video
                className="w-full  object-cover" // Use object-cover to ensure video scales properly
                autoPlay
                loop
                muted
                playsInline
                preload="auto"
              >
                <source src={vaaniProjects} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="absolute bottom-[10%] right-[10%] flex flex-col z-10 text-white">
                <p className="text-[3rem] font-[700] ">+ 150,000</p>
                <p className="text-[1.5rem] text-right">hours of audio</p>
              </div>
            </div>
            <div className="flex flex-col pl-[2.75rem] py-[2.88rem] pr-[2.19rem] sm:w-1/2 w-full">
              <div className="flex flex-row gap-[1.29rem]">
                <img
                  src={assetsManager.bhashiniLogo}
                  alt="bhashini logo"
                  height={43}
                  width={86}
                />
                <img
                  src={assetsManager.googleLogo}
                  alt="google logo"
                  height={43}
                  width={86}
                />
              </div>
              <div className="flex flex-col gap-2 mt-9">
                <p className="text-[2rem] font-medium text-[#000]">
                  Project Vaani
                </p>
                <p className="text-[.875rem]  text-[#242424]">
                  Capturing India&apos;s Diverse Language Landscape
                </p>
              </div>
              <p className="mt-4">
                Collaborative effort with Bhashini, Google, IISc, and ARTPARK,
                and Bhashini to create one of the largest datasets of Indian
                languages. It will collect over 150,000 hours of audio across
                all districts, capturing the rich linguistic diversity of India
                to improve language technologies and promote digital
                inclusivity.
              </p>
              <button
                onClick={() => handleExternalLinks("https://vaani.iisc.ac.in")}
                className="flex gap-2 items-center bg-[#2947A3] text-white text-[.875rem] font-semibold  py-3 px-6 rounded-[.375rem] mt-4 w-max ml-auto"
              >
                Explore Now
                <img src={assetsManager.rightArrow} alt="arrow right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SahyogiProjects;
