import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import VideoCard from "./VideoCard";

const MinisterInsights = () => {
  const minsterInsights = useScrollToRef();

  const VIDEO_DATA = [
    {
      title:
        "Elevating Digital India: Ashwini Vaishnaw Explores Bhashini's Role & Upcoming Extension",
      date: "Aug 16, 2023",
      duration: "1:20",
      videoUrl: "https://www.youtube.com/embed/dt5GaY_5svA",
    },
    {
      title:
        "PM Modi Uses AI Tool ‘Bhashini’ For The First Time | What Is ‘Bhashini’?",
      date: "Dec 18, 2023",
      duration: "4:25",
      videoUrl: "https://www.youtube.com/embed/oG_whhbr0sg",
    },
  ];

  return (
    <div className="flex flex-col gap-9" ref={minsterInsights}>
      <div className="flex flex-col gap-[.94rem]">
        <div>
          <p className="heading1 font-bold text-[#242424]">
            Insights from Government
          </p>
          <p className="heading3">
            Key speeches and messages from Indian government leaders about
            Bhashini.
          </p>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col flex-wrap gap-9 w-full">
        {VIDEO_DATA.map((videoData) => (
          <VideoCard
            key={videoData.videoUrl}
            date={videoData.date}
            duration={videoData.duration}
            title={videoData.title}
            videoUrl={videoData.videoUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default MinisterInsights;
