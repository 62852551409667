import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";

const Vatika = () => {
  const vatikaRef = useScrollToRef()
  return (
    <div className="flex flex-col gap-9" ref={vatikaRef}>
      <div>
        <p className="heading1 font-semibold text-[#242424]">
          Model & Data Vatika
        </p>
        <div className="w-full vatikaBg py-[3.75rem] px-[2.69rem] flex flex-col gap-9 text-white text-center">
          <p className="heading3 font-medium">
            Vatika, your comprehensive garden for models and datasets, designed
            to support various language tasks across multiple multilingual Indic
            languages. Stay tuned for the launch of Vatika, where cutting-edge
            resources and a user-friendly interface come together to enhance
            your research and projects.
          </p>
          <p className="heading1 font-semibold text-end">Coming Soon...</p>
        </div>
      </div>
    </div>
  );
};

export default Vatika;
