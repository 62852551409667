import React from "react";

const AboutSprint = () => {
  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold">
        Bhashini Sprint: <span className="text-primary">About</span>
      </p>
      <div className="flex flex-col gap-4">
        <p>
          Bhashini, the National Language Technology Mission (NLTM), was
          launched by the Prime Minister in July 2022 to provide language
          technology solutions as digital public goods through the Bhashini
          platform (https://bhashini.gov.in). This is aimed to be achieved by
          harnessing the emerging technologies such as AI/ML, NLP to develop and
          share open-source models, tools, and solutions (products and services)
          for Indian languages with ecosystem development involving startups,
          industries, academia, research groups, enthusiasts and State/Central
          Governments. The approach is to create large datasets in the Scheduled
          Indian Languages to train AI models for translation and conversion of
          speech to text and vice versa, including voice to voice translation
          for general usage as well as for specific domains/contexts like
          education, healthcare, and financial services etc.
        </p>
        <p>
          1000+ pre-trained AI models have been made available on Bhashini
          platform. These AI language models have also been exposed via Open
          Bhashini APIs for Bhashini ecosystem partners. The next steps include
          large applications of public relevance along with fine tune AI models,
          demonstrate capability and acquire implementation experience, so that
          implementation approaches may be developed for common language
          technology requirements like intelligent voice-based user interfaces,
          document translation and website translation at scale.
        </p>
        <p>
          An Independent Business Division (IBD), Digital India Bhashini
          Division (DIBD) has been set up under Digital India Corporation (DIC)
          to anchor the Mission Bhashini activities and to nurture the language
          technology ecosystem especially involving startups.
        </p>
        <p>
          DIBD invites solutions for following Five (05) problems statements in
          NLP domain to evolve an effective and indigenous solution(s) to
          language specific problems:
        </p>
        <p>
          The proposed BHASHINI-SPRINT with Five (05) pre-identified problem
          statements tries to tackle challenges relating to various areas and
          segments related to Bhashini Technology and services.
        </p>
        <p>The teams can choose to participate in one or all challenges.</p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="paragrah1 font-semibold">
          The BHASHINI-SPRINT is open for participation in teams having experts
          from all domains of a software development life cycle. It would be in
          three (3) stages:
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="bg-[#E8F3FF] py-6 px-6 flex flex-col gap-6">
          <p className="font-bold">Stage 1:</p>
          <p>
            Teams will have to propose the innovative and cutting-edge ideas of
            their solution along with a prototype as per problem statement
            identified. The top 5 teams per problem statement would be selected
            from this stage.
          </p>
        </div>
        <div className="bg-[#E8F3FF] py-6 px-6 flex flex-col gap-6">
          <p className="font-bold">Stage 2:</p>
          <p>
            The shortlisted teams from stage 1 will be given a chance to develop
            a prototype based on the problem statements mentioned in Annexure A.
            In this stage top 3 teams selected from previous stage per problem
            statement will be given INR 50 Thousand each as grant to develop an
            enhanced version of the prototype.
          </p>
        </div>
        <div className="bg-[#E8F3FF] py-6 px-6 flex flex-col gap-6">
          <p className="font-bold">Stage 3:</p>
          <p>
            Shortlisted teams from stage 2 will present the enhanced version and
            final product to the committee in grand finale, 1 team per problem
            statement will be selected in this stage as a winner and 1 team will
            be selected as runner up and a grant of total INR 7 lakhs (5 lakh
            for winning team+ 2 Lakh for the runners up) will be given to them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSprint;
