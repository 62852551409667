import React, { useState } from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import assetsManager from "../../assets/assetsManager";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { AttachFile } from "@material-ui/icons";
import axios from "axios";
import { AVINYA_BACKEND_URL } from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import EmailVerification from "./EmailVerification";

// EmailVerification Component

// FeatureCard Component
const FeatureCard = ({ imgSrc, text }) => (
  <div className="flex flex-col gap-4 items-center">
    <div
      className="h-[60px] w-[60px] rounded-full bg-primary flex items-center justify-center"
      style={{ boxShadow: "3px 3px 0px 0px #D6DEF5" }}
    >
      <img src={imgSrc} alt={text} className="h-[40px] w-[40px] rounded-full" />
    </div>
    <p className="para1 font-bold text-white">{text}</p>
  </div>
);

// Main AvinyaPrayogshala Component
const AvinyaPrayogshala = () => {
  const avinyaPrayogshalaRef = useScrollToRef();
  const [open, setOpen] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSendOTP = async (email) => {
    try {
      setIsSendingOtp(true);
      const response = await axios.get(
        `${AVINYA_BACKEND_URL}/prayogshala-um/api/otp/send?email=${email}`
      );
      toast.success(response?.data?.message || "OTP sent successfully!");
      console.log("sending otp");
      setIsOtpSent(true);
    } catch (err) {
      console.log(err, "OTP Send Error");
      toast.error(err?.response?.data?.message || "OTP send failed!");
      setIsOtpSent(false);
    } finally {
      setIsSendingOtp(false);
    }
  };

  // Validation Schema
  const validationSchema = Yup.object({
    organizationName: Yup.string().required(
      "Organization/Institute is required"
    ),
    spocName: Yup.string().required("SPOC Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobileNo: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Mobile No. is required"),
    file: Yup.mixed().required("File is required"),
  });

  // Formik for Organization Form
  const formik = useFormik({
    initialValues: {
      organizationName: "",
      spocName: "",
      remarks: "Initial onboarding request",
      email: "",
      mobileNo: "",
      file: null,
    },
    validationSchema,
    // Send the otp to the user
    onSubmit: async (values) => {
      setUserDetails(values);
      handleSendOTP(values.email);

      // Implement verify email logic here

      // const newFormData = new FormData();
      // const dataObject = {
      //   organizationName: values.organizationName,
      //   spocName: values.spocName,
      //   remarks: values.remarks,
      //   email: values.email,
      //   mobileNo: values.mobileNo,
      // };
      // const tempObject = new Blob([JSON.stringify(dataObject)], {
      //   type: "application/json",
      // });
      // newFormData.append("data", tempObject);
      // if (values.file) {
      //   newFormData.append("file", values.file);
      // }
      // try {
      //   setIsLoading(true);
      //   const response = await axios.post(
      //     `${AVINYA_BACKEND_URL}/prayogshala-um/api/onboarding/register`,
      //     newFormData
      //   );
      //   toast.success(response?.data?.message || "Registration successful!");
      //   setEmail(values.email); // Store email for verification
      //   setIsEmailVerification(true); // Show Email Verification screen
      // } catch (err) {
      //   console.log(err, "err");
      //   toast.error(err?.response?.data?.message || "Registration failed!");
      // } finally {
      //   setIsLoading(false);
      // }
    },
  });

  // Formik for OTP Verification (optional: can use another Formik instance)
  // For simplicity, we'll handle it without Formik

  // Handle Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Handle Dialog Close
  const handleClose = () => {
    setOpen(false);
    formik.resetForm(); // Reset the form when the dialog is closed
    setIsFormSubmitted(false);
    setFileName("");
  };

  // Handle File Change
  const handleFileChange = (files) => {
    setFileName(files[0]?.file.name);
    formik.setFieldValue("file", files[0]?.file);
  };

  const features = [
    {
      imgSrc: assetsManager.database,
      text: "Infrastructure Allotment",
    },
    {
      imgSrc: assetsManager.summarize,
      text: "Project Monitoring & Reporting",
    },
    {
      imgSrc: assetsManager.groups,
      text: "Team Management",
    },
  ];

  return (
    <div className="flex flex-col gap-[3.5rem]" ref={avinyaPrayogshalaRef}>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <p className="heading1 font-bold text-[#242424]">
            Bhashini’s Avinya Prayogshala
          </p>
          <p className="heading3 font-medium">Innovation Laboratory</p>
        </div>
        <div className="anivya-banner-bg h-[372px] w-[997px] flex flex-col justify-center gap-6 pl-[2.69rem]">
          <p className="sm:heading1 text-[1.5rem] font-bold text-white">
            Avinya Prayogshala
          </p>
          <p className="sm:heading3 text-[1rem] font-medium text-white w-1/3 sm:w-full">
            Avinya Paryogshala, a collaborative platform for Researchers.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="heading2 font-semibold">What is Avinya Prayogshala?</p>
        <p className="heading3 font-medium">
          Avinya Prayogshala, derived from the Sanskrit word &quot;Avinya&quot;
          meaning innovation, is a cutting-edge virtual lab environment within
          Prayogshala. It is dedicated to empowering researchers and
          organizations to experiment, develop, and refine language models and
          datasets. The platform serves as a collaborative space where users can
          leverage state-of-the-art tools and resources to push the boundaries
          of language technology.
        </p>
      </div>
      <div className="flex flex-col gap-12 items-center justify-center w-full bg-primaryDark2 py-12">
        <p className="heading2 font-semibold text-white">
          Features of Avinya Prayogshala
        </p>
        <div className="flex justify-between w-4/5 items-center">
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <FeatureCard imgSrc={feature.imgSrc} text={feature.text} />
              {index < features.length - 1 && (
                <img
                  src={assetsManager.dottedVerticalLines}
                  alt="Dotted Lines"
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-9 p-9 rounded-[.625rem] shadow-3xl">
        <p className="heading2 font-semibold text-primary">Research Groups</p>
        <div className="flex flex-col gap-6">
          <div className="flex gap-3">
            <p className="w-4/5 text-justify">
              Avinya Prayogshala offers research institutions an unparalleled
              platform to advance their work in language technologies. By
              registering on Avinya, institutions gain access to powerful tools
              for managing and experimenting with models and datasets,
              facilitating large-scale research initiatives. The platform&apos;
              bulk upload and download capabilities, coupled with comprehensive
              feedback logs, ensure that research outputs are both impactful and
              refined based on community insights. Collaborative features
              empower teams to work seamlessly together, while the secure
              experimentation environment allows for rigorous testing and
              validation. With Avinya Prayogshala, research institutions can
              drive innovation, share knowledge, and contribute significantly to
              the field of language technology.
            </p>
            <div className="rounded">
              <img src={assetsManager.aboutAvinya} alt="About Avinya" />
            </div>
          </div>
          <button
            className="bg-[#3568D4] py-3 px-6 rounded text-white w-fit hover:bg-[#3354b8] "
            onClick={handleClickOpen}
          >
            Get Started
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="organization-dialog-title"
            aria-describedby="organization-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            {!isFormSubmitted && !isOtpSent ? (
              <>
                <DialogTitle
                  id="organization-dialog-title"
                  sx={{
                    color: "#062c52",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                  }}
                >
                  Organization / Institute Details
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                  <DialogContent sx={{ paddingY: "0" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          name="organizationName"
                          label="Organization/Institute*"
                          fullWidth
                          variant="outlined"
                          value={formik.values.organizationName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.organizationName &&
                            Boolean(formik.errors.organizationName)
                          }
                          helperText={
                            formik.touched.organizationName &&
                            formik.errors.organizationName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          name="spocName"
                          label="SPOC Name*"
                          fullWidth
                          variant="outlined"
                          value={formik.values.spocName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.spocName &&
                            Boolean(formik.errors.spocName)
                          }
                          helperText={
                            formik.touched.spocName && formik.errors.spocName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="email"
                          label="Work Email ID*"
                          fullWidth
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="mobileNo"
                          label="Mobile No.*"
                          fullWidth
                          variant="outlined"
                          value={formik.values.mobileNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.mobileNo &&
                            Boolean(formik.errors.mobileNo)
                          }
                          helperText={
                            formik.touched.mobileNo && formik.errors.mobileNo
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <Select
                          labelId="country-select-label"
                          id="country-select"
                          name="country"
                          sx={{ width: "100%" }}
                          value={formik.values.country}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>Country</em>
                          </MenuItem>
                          <MenuItem value="India">India</MenuItem>
                          <MenuItem value="USA">USA</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        {formik.touched.country && formik.errors.country && (
                          <p className="text-red-600">
                            {formik.errors.country}
                          </p>
                        )}
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <DropzoneAreaBase
                          filesLimit={1}
                          acceptedFiles={["application/pdf"]}
                          showFileNamesInPreview={true}
                          showFileNames={true}
                          showAlerts={false}
                          Icon={AttachFile}
                          dropzoneText={
                            fileName
                              ? fileName
                              : "Upload Organization Official Letter"
                          }
                          classes={{
                            root: "dropzone with-blue-color",
                            textContainer: "dropzone-text",
                          }}
                          onAdd={(fileObjs) => handleFileChange(fileObjs)}
                          onDelete={(fileObj) =>
                            console.log("Removed File:", fileObj)
                          }
                          onAlert={(message, variant) =>
                            console.log(`${variant}: ${message}`)
                          }
                        />
                        {formik.touched.file && formik.errors.file && (
                          <p className="text-red-600">{formik.errors.file}</p>
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      marginTop: "3.19rem",
                      padding: "0 1.5rem 1.5rem 1.5rem",
                    }}
                  >
                    <Button
                      sx={{
                        "&:hover": {
                          backgroundColor: "#2947A3",
                        },
                        color: "#fff",
                        backgroundColor: "#2947A3",
                      }}
                      fullWidth
                      type="submit"
                      disabled={isSendingOtp}
                    >
                      {isSendingOtp ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Verify my Account"
                      )}
                    </Button>
                  </DialogActions>
                </form>
              </>
            ) : (
              <EmailVerification
                isFormSubmitted={isFormSubmitted}
                setIsFormSubmitted={setIsFormSubmitted}
                handleClose={handleClose}
                handleSendOTP={handleSendOTP}
                email={userDetails.email}
                userDetails={userDetails}
              />
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AvinyaPrayogshala;

// import React from "react";
// import useScrollToRef from "../../hooks/useScrollToRef";
// const AvniyaPragshala = () => {
//   const avinyaPrayogshalaRef = useScrollToRef();
//   return (
//     <div className="flex flex-col gap-9" ref={avinyaPrayogshalaRef}>
//       <div>
//         <p className="heading1 font-semibold text-[#242424]">
//           Bhashini’s Avinya Prayogshala
//         </p>
//         <div className="w-full anivyaBg py-[3.75rem] px-[2.69rem] flex flex-col gap-9 text-white text-center">
//           <p className="heading3 font-medium">
//             Avinya Paryogshala, a collaborative platform for Researchers Stay
//             tuned for the launch!
//           </p>
//           <p className="heading1 font-semibold text-end">Coming Soon...</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AvniyaPragshala;
