import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import assetsManager from "../../assets/assetsManager";
import useScrollToRef from "../../hooks/useScrollToRef";

const AboutUs = () => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const aboutUsRef = useScrollToRef()
  useEffect(() => {
    const root = document.getElementById("root");
    root.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleVideoPlay = () => {
    const video = document.getElementById("aboutUsVideo");
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="flex flex-col gap-[6.29rem]" ref={aboutUsRef}>
      <div className="flex flex-col gap-[.94rem]">
        <div>
          <p className="heading1 font-bold text-[#242424]">
            About Us
           
          </p>
          <p className="heading3">{t("aboutPage.aboutUs.subtitle")}</p>
        </div>
        <div className="relative w-full h-1/2">
          <video id="aboutUsVideo" className="w-full h-full" controls>
            <source
              src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/bhashini-video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <div
              className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 text-white cursor-pointer"
              onClick={handleVideoPlay}
            >
              <div className="text-center w-3/4">
                <button className="p-2 bg-white rounded-full">
                  <svg
                    className="w-12 h-12 fill-current text-blue-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </button>
                <p className="md:text-[2.25rem] text-[.875rem]  mt-2">
                  How do we help you to grow?
                </p>
                <p className="text-[.625rem] md:text-base mt-2 opacity-65">
                  Unlock Multilingual Communication: Bhashini aims to enable all
                  Indians easy access to internet and all digital services in
                  their own languages.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-9">
        <p className="heading1 font-bold text-[#242424]">
           Guiding Principles of Bhashini
        </p>
        <img
          src={assetsManager.guidingPrinciples}
          loading="lazy"
          alt="principles"
        />
      </div>
      <div className="flex flex-col gap-9">
        <p className="heading1 font-bold text-center md:text-left text-[#242424]">
        Bhashini{"'"}s Building Blocks
        </p>
        <img
          src={assetsManager.buildingBlocks}
          loading="lazy"
          alt="principles"
        />
      </div>
    </div>
  );
};

export default AboutUs;
