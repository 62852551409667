import React, { useEffect } from "react";

const ServiceDetail = ({ product, setSelectedService }) => {
  const serviceRef = React.useRef(null);
  useEffect(() => {
    serviceRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);


  const handleBackClick = () => {
    setSelectedService(null);
  };

  return (
    <div className="flex gap-6 items-start" ref= {serviceRef}>
      <svg
        className="cursor-pointer w-[10%]"
        onClick={handleBackClick}
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1818 22.8409L0.159091 11.8182L11.1818 0.795454L13.5227 3.11364L6.51136 10.125H23.5455V13.5114H6.51136L13.5227 20.5114L11.1818 22.8409Z"
          fill="#242424"
        />
      </svg>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col">
          <div className="flex items-center gap-4">
            <div className="flex flex-col  ">
              <p className="heading1 font-semibold">{product.name}</p>
              <p className="text-[1.125] font-normal">{product.desc}</p>
            </div>
          </div>
          <div className="mt-6">
            <p className="text-[1.25rem] font-semibold">
              Total Language Supported:{" "}
              <span className="font-semibold text-[1.75rem] text-primary">
                {product.languageSupport.length}
              </span>
            </p>
          </div>
          {product.cardContent.length > 0 && (
            <div className="mt-10 bg-[#13296C] flex w-full text-white justify-center py-[2.81rem]">
              {product.cardContent.map((content, index) => (
                <React.Fragment key={index}>
                  <div className="px-12 w-[48%] flex justify-center items-center ">
                    <div className="flex flex-col text-center">
                      <p className="text-[1.25rem] font-semibold">
                        {content.title}
                      </p>
                      <p>{content.subText}</p>
                    </div>
                  </div>
                  {index < product.cardContent.length - 1 && (
                    <div className="w-[.5%] h-[6.8rem] bg-white"></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-6">
          <p className="text-[1.5rem] font-medium">Supported Languages</p>
          <div className="flex flex-wrap gap-4">
            {product.languageSupport.map((language, index) => (
              <div key={index} className="flex items-center gap-2">
                <p className="py-3 px-6 rounded-[2.5rem] bg-[#f2ffe3] dark:text-black font-medium">
                  {language}
                </p>
              </div>
            ))}
          </div>
        </div>
        {product.languagesComingSoon.length > 0 && (
          <div className="flex flex-col gap-6">
            <p className="text-[1.5rem] font-medium">Languages Coming Soon</p>
            <div className="flex flex-wrap gap-4">
              {product.languagesComingSoon.map((language, index) => (
                <div key={index} className="flex items-center gap-2">
                  <p className="py-3 px-6 rounded-[2.5rem] bg-[#FCECE5] font-medium ">
                    {language}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceDetail;
