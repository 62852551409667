/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from "react";
import "../../components/GlobalFont.scss";
import shape1 from "../../assets/images/shapes/shape-1.svg";
import shape2 from "../../assets/images/shapes/shape-2.svg";

// import pmUses from "../../assets/images/video/thumbs/pm-uses-bhashini.jpg";

import ceoUses2 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_2.png";
import ceoUses3 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_3.png";
import ceoUses4 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_4.png";
import ceoUses5 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_5.png";
import ceoUses6 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_6.png";
import ceoUses7 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_7.png";
import ceoUses8 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_8.png";
import ceoUses9 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_9.png";
import ceoUses10 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_10.png";
import ceoUses13 from "../../assets/images/pravakta/main/ceo-speaks/ceo_speaks_13.png";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import ReactPlayer from "react-player";
import useMedia from "../../hooks/useMedia";

const EchoMediaHome = (props) => {
  const isMobile = useMedia("(max-width:700px)");

  useEffect(() => {
    const reftp = document.getElementById("main");
    if (props.skipContent === true) {
      reftp.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.skipContent]);

  Fancybox.bind("[data-fancybox]", {});
  const cardData = [
    {
      id: 1,
      title: "Bhashini is a national public digital platform for languages",
      videoUrl:
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/Amitabh%20Nag_short.mp4",
      imageUrl: ceoUses13,
    },
    {
      id: 2,
      title: "Bhashini is a national public digital platform for languages",
      videoUrl:
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Bhashini-in-action-videos/tice%20news%20ceo%20speaks.mp4",
      imageUrl: ceoUses13,
    },
    {
      id: 3,
      title: "Explained: What is Bhashini and How it can bridge the gap between Indian languages",
      videoUrl: "https://www.youtube.com/watch?v=enerB3GbDAQ",
      imageUrl: ceoUses2,
    },
    {
      id: 4,
      title: "#G20Summit | Bhashini CEO speaks at Digital India Experience Zone",
      videoUrl: "https://www.youtube.com/watch?v=JUJ-8MsD55A&t=1s",
      imageUrl: ceoUses3,

    },
    {
      id: 5,
      title: "National Technology Week 2023",
      videoUrl:
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/National%20Technology%20Week%202023.mp4",
      imageUrl: ceoUses4,

    },
    {
      id: 6,
      title: "Amitabh Nag, CEO, Bhashini, Digital India Corporation | GDC&I Summit 2023 | 3rd November 2023",
      videoUrl: "https://www.youtube.com/watch?v=Q92gK_ReYLg&t=487s",
      imageUrl: ceoUses5,
    },
    {
      id: 7,
      title: "Technology Sabha Bytes | Amitabh Nag - Bhashini, Digital India Corporation | AUG 2023 - Jaipur",
      videoUrl: "https://www.youtube.com/watch?v=AEc7J9BodQU",
      imageUrl: ceoUses6,

    },
    {
      id: 8,
      title: "#GenAICon2023 Session 6: Bhashini: Breaking the Language Barrier",
      videoUrl: "https://www.youtube.com/watch?v=adEbTv-9SWI",
      imageUrl: ceoUses7,
    },
    {
      id: 9,
      title: "EC | Amitabh Nag, Digital India Bhashini Division | Technology Sabha Jaipur | 25th August 2023",
      videoUrl: "https://www.youtube.com/watch?v=Ne0N3TdkvOE",
      imageUrl: ceoUses8,
    },
    {
      id: 10,
      title: "Interview | Mr Amitabh Nag - Digital India Bhashini Division | SMX CSR Summit",
      videoUrl: "https://www.youtube.com/watch?v=C5zc43qLzSc",
      imageUrl: ceoUses9,
    },
    {
      id: 11,
      title: "eGov Talks | Journey Towards Language Inclusivity in Citizen Service Delivery | Bhashini",
      videoUrl: "https://www.youtube.com/watch?v=vN5gxRhk4L8",
      imageUrl: ceoUses10,
    },
  ]


  return (
    <div className="bg-gray-100">
      <div className="hero__banner v3 shapeBase">
        <div className="hero__banner-container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="hero__banner-content">
                <div className="contentMain">
                  <h1 className="mainHeading">CEO Speaks</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shapeMain resMedia top left">
          <img className="u-image img-fluid" src={shape1} alt="shape 1" />
        </div>
        <div className="shapeMain resMedia bottom right">
          <img className="u-image img-fluid" src={shape2} alt="shape 2" />
        </div>
      </div>

      <div className="card__view commonPadding">
        <div className="container">
          <div className="flex">
            <div className="col-12">
              <div className="card__view-container accordionView">
                <div className="flex flex-wrap gap-1 ">
                  {
                    cardData.map((data, index) => (
                      <div className="w-[30%]" key={index}>
                        <div className="galleryView">
                          <div className="galleryView__main" style={{ height: "337px" }}>
                            {isMobile ? (
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                className="VideoContainer"
                                controls={true}
                                url={data.videoUrl}
                                config={{
                                  file: {
                                    attributes: { controlsList: "nofullscreen" },
                                  },
                                }}
                              />
                            ) : (
                              <a
                                href={data.videoUrl}
                                className="galleryView__main-link videoIcon"
                                data-fancybox="gallery"
                                data-caption={data.title}
                                width="100%"
                              >
                                <img src={data.imageUrl} className="img-fluid u-image" />
                              </a>
                            )}
                            <h3 className="galleryView__head">{data.title}</h3>
                          </div>
                        </div>
                      </div>
                    ))

                  }
                   {/* <div className="lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
              <div className="galleryView">
                <div className="galleryView__main h-84">
                  {isMobile ? (
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      className="VideoContainer"
                      controls={true}
                      url={
                        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/Amitabh%20Nag_short.mp4"
                      }
                      config={{
                        file: {
                          attributes: { controlsList: "nofullscreen" },
                        },
                      }}
                    />
                  ) : (
                    <a
                      href="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/Amitabh%20Nag_short.mp4"
                      className="galleryView__main-link videoIcon"
                      data-fancybox="gallery"
                      data-caption="Bhashini is national public Digital Platform for languages"
                      width="100%"
                    >
                      <img
                        src={ceoUses13}
                        className="img-fluid w-full h-auto"
                      />
                    </a>
                  )}
                  <h3 className="galleryView__head">
                    Bhashini is a national public digital platform for languages
                  </h3>
                </div>
              </div>
            </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                    >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={
                              "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Bhashini-in-action-videos/tice%20news%20ceo%20speaks.mp4"
                            }
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Bhashini-in-action-videos/tice%20news%20ceo%20speaks.mp4"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="Bhashini is national public Digital Platform for languages"
                            // autoplay=""
                            width="100%"
                            // controls=""
                          >
                            <img
                              src={ceoUses13}
                              className="img-fluid u-image"
                            />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          Bhashini is a national public digital platform for
                          languages
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={
                              "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/Amitabh%20Nag_short.mp4"
                            }
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/Amitabh%20Nag_short.mp4"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="Bhashini is national public Digital Platform for languages"
                            // autoplay=""
                            width="100%"
                            // controls=""
                          >
                            <img
                              src={ceoUses13}
                              className="img-fluid u-image"
                            />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          Bhashini is a national public digital platform for
                          languages
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={"https://www.youtube.com/watch?v=enerB3GbDAQ"}
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=enerB3GbDAQ"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="Explained: What Is Bhashini And How It Can Bridge The Gap Between Indian languages"
                            width="100%"
                          >
                            <img src={ceoUses2} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          Explained: What is Bhashini and How it can bridge the
                          gap between Indian languages
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={
                              "https://www.youtube.com/watch?v=JUJ-8MsD55A&t=1s"
                            }
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=JUJ-8MsD55A&t=1s"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="#G20Summit | Bhashini CEO speaks at Digital India Experience Zone"
                            width="100%"
                          >
                            <img src={ceoUses3} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          #G20Summit | Bhashini CEO speaks at Digital India
                          Experience Zone
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={
                              "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/National%20Technology%20Week%202023.mp4"
                            }
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/National%20Technology%20Week%202023.mp4"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="National Technology Week 2023"
                            width="100%"
                          >
                            <img src={ceoUses4} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          National Technology Week 2023
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={
                              "https://www.youtube.com/watch?v=Q92gK_ReYLg&t=487s"
                            }
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=Q92gK_ReYLg&t=487s"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="Amitabh Nag, CEO, Bhashini, Digital India Corporation | GDC&I Summit 2023 | 3rd November 2023"
                            width="100%"
                          >
                            <img src={ceoUses5} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          Amitabh Nag, CEO, Bhashini, Digital India Corporation
                          | GDC&I Summit 2023 | 3rd November 2023
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={"https://www.youtube.com/watch?v=AEc7J9BodQU"}
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=AEc7J9BodQU"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="Technology Sabha Bytes | Amitabh Nag - Bhashini, Digital India Corporation | AUG 2023 - Jaipur"
                            width="100%"
                          >
                            <img src={ceoUses6} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          Technology Sabha Bytes | Amitabh Nag - Bhashini,
                          Digital India Corporation | AUG 2023 - Jaipur
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={"https://www.youtube.com/watch?v=adEbTv-9SWI"}
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=adEbTv-9SWI"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="#GenAICon2023 Session 6: Bhashini: Breaking the Language Barrier"
                            width="100%"
                          >
                            <img src={ceoUses7} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          #GenAICon2023 Session 6: Bhashini: Breaking the
                          Language Barrier
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={"https://www.youtube.com/watch?v=Ne0N3TdkvOE"}
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=Ne0N3TdkvOE"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="EC | Amitabh Nag, Digital India Bhashini Division | Technology Sabha Jaipur | 25th August 2023"
                            width="100%"
                          >
                            <img src={ceoUses8} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          EC | Amitabh Nag, Digital India Bhashini Division |
                          Technology Sabha Jaipur | 25th August 2023
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={"https://www.youtube.com/watch?v=C5zc43qLzSc"}
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=C5zc43qLzSc"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="Interview | Mr Amitabh Nag - Digital India Bhashini Division | SMX CSR Summit"
                            width="100%"
                          >
                            <img src={ceoUses9} className="img-fluid u-image" />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          Interview | Mr Amitabh Nag - Digital India Bhashini
                          Division | SMX CSR Summit
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={"https://www.youtube.com/watch?v=vN5gxRhk4L8"}
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=vN5gxRhk4L8"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="eGov Talks | Journey Towards Language Inclusivity in Citizen Service Delivery | Bhashini"
                            width="100%"
                         
                          >
                            <img
                              src={ceoUses10}
                              className="img-fluid u-image"
                            />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          eGov Talks | Journey Towards Language Inclusivity in
                          Citizen Service Delivery | Bhashini
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="galleryView">
                      <div
                        className="galleryView__main"
                        style={{ height: "337px" }}
                      >
                        {isMobile ? (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            className="VideoContainer"
                            controls={true}
                            url={
                              "https://www.youtube.com/watch?v=xgDHUnwpD8U&t=709s"
                            }
                            config={{
                              file: {
                                attributes: { controlsList: "nofullscreen" },
                              },
                              youtube: {
                                playerVars: {
                                  fs: 0, // Disable fullscreen mode
                                },
                              },
                            }}
                          />
                        ) : (
                          <a
                            href="https://www.youtube.com/watch?v=xgDHUnwpD8U&t=709s"
                            className="galleryView__main-link videoIcon"
                            data-fancybox="gallery"
                            data-caption="APAC National Cloud & Cyber Security Summit | Amitabh Nag, CEO, Bhashini, Digital India Corporation"
                            width="100%"
                          >
                            <img
                              src={ceoUses11}
                              className="img-fluid u-image"
                            />
                          </a>
                        )}
                        <h3 className="galleryView__head">
                          APAC National Cloud & Cyber Security Summit | Amitabh
                          Nag, CEO, Bhashini, Digital India Corporation
                        </h3>
                      </div>
                    </div>
                  </div> */}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EchoMediaHome;
