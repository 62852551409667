import { Grid, Typography, Box, TextField } from "@mui/material";
import React, { useState } from "react";
import FormField from "./FormField";

const AdditionalInformation = ({
  errors,
  formData,
  handleFileChange,
  handleChange,
  fileName,
}) => {
  return (
    <Grid container sx={{ gap: "1.12rem" }}>
      <Grid item xs={12}>
        <Typography>Upload Biodata (*pdf only)</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "0.5rem",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => document.getElementById("file-input").click()}
        >
          <Typography sx={{ flexGrow: 1 }}>
            {fileName ? fileName : "Attach here"}
          </Typography>
          {/* SVG icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.2495 2.625C13.5103 2.62303 12.778 2.76756 12.095 3.05025C11.4119 3.33295 10.7917 3.74819 10.27 4.272L3.37755 11.166C3.1661 11.377 3.04716 11.6634 3.04688 11.9622C3.04659 12.2609 3.165 12.5476 3.37605 12.759C3.58709 12.9704 3.87349 13.0894 4.17224 13.0897C4.47098 13.09 4.7576 12.9715 4.96905 12.7605L11.8645 5.8635C12.506 5.27379 13.3505 4.95475 14.2217 4.97305C15.0928 4.99135 15.9232 5.34556 16.5394 5.9617C17.1555 6.57783 17.5097 7.40821 17.528 8.27937C17.5463 9.15052 17.2273 9.99504 16.6375 10.6365L8.68305 18.591C8.46978 18.7897 8.18771 18.8979 7.89626 18.8928C7.60481 18.8876 7.32673 18.7696 7.12061 18.5634C6.91449 18.3573 6.79643 18.0792 6.79128 17.7878C6.78614 17.4963 6.89433 17.2143 7.09305 17.001L15.0475 9.0465C15.259 8.83526 15.3779 8.54867 15.378 8.24978C15.3781 7.9509 15.2595 7.6642 15.0483 7.45275C14.8371 7.24131 14.5505 7.12244 14.2516 7.1223C13.9527 7.12216 13.666 7.24076 13.4545 7.452L5.49855 15.4095C4.90883 16.051 4.58979 16.8955 4.60809 17.7666C4.62639 18.6378 4.98061 19.4682 5.59674 20.0843C6.21287 20.7004 7.04326 21.0547 7.91441 21.073C8.78556 21.0913 9.63009 20.7722 10.2715 20.1825L18.226 12.228C18.7499 11.7067 19.1654 11.0867 19.4483 10.4039C19.7313 9.72116 19.8761 8.98909 19.8745 8.25C19.873 6.75865 19.2798 5.32883 18.2253 4.27428C17.1707 3.21974 15.7409 2.62659 14.2495 2.625Z"
              fill="#5E5C5C"
            />
          </svg>
        </Box>
        {/* Hidden file input */}
        <input
          type="file"
          accept=".pdf"
          id="file-input"
          name="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          error={errors?.relevant_links}
          helperText={errors?.relevant_links}
          value={formData.relevant_links}
          onChange={handleChange}
          name="relevant_links"
          label="Relevant Links (if any)"
          placeholder="Provide links to any published works, linkedin profile, articles, blogs, or books."
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            color: "#646464",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "1.5rem",
          }}
        >
          Tell us about yourself.
        </Typography>
        <TextField
          value={formData.brief_description}
          name="brief_description"
          onChange={handleChange}
          error={errors?.breif_description}
          helperText={errors?.brief_description}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />

        {/* <FormField
          error={errors?.relevant_links}
          helperText={errors?.relevant_links}
          value={formData.relevant_links}
          onChange={handleChange}
          name=" relevant_links"
          label="Relevant Links (if any)"
          placeholder="Tell Us About Yourself."
        /> */}
      </Grid>
    </Grid>
  );
};

export default AdditionalInformation;
