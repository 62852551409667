import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const ServiceBanner = () => {
  return (
    <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4  flex flex-col service-banner-bg">
      <div className="my-auto text-white">
        <p className="heading1 font-medium">Services</p>
        <p className="paragraph1">
          We provide a full array of services to overcome language barriers and
          improve accessibility.
        </p>
      </div>
      <Breadcrumbs />
    </div>
  );
};

const ProductBanner = () => {
  return (
    <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4  flex flex-col service-banner-bg">
      <div className="my-auto text-white">
        <p className="heading1 font-medium">Products</p>
        <p className="paragraph1">
          Innovative applications tailored for our diverse linguistic landscape.
        </p>
      </div>
      <Breadcrumbs />
    </div>
  );
};

const CareersBanner = () => {
  return (
    <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 careersBannerBg flex flex-col ">
      <div className="my-auto text-white">
        <p className="heading1 font-medium">Careers</p>
        <p className="paragraph1">
          Join us in the mission to transcend language barriers.
        </p>
      </div>
      <Breadcrumbs />
    </div>
  );
};

const TenderBanner = () => {
  return (
    <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 tenderBannerBg flex flex-col ">
      <div className="my-auto text-white">
        <p className="heading1 font-medium">Tenders/EOI’s</p>
        {/* <p className="paragraph1">
      We provide a full array of services to overcome language barriers and improve accessibility.
      </p> */}
      </div>
      <Breadcrumbs />
    </div>
  );
};

const OnPremiseBanner = () => {
  return (
  <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 onpremBannerBg flex flex-col service-banner-bg">
      <div className="my-auto text-white">
        <p className="heading1 font-medium">On Premise</p>
        <p className="paragraph1">
          We provide a full array of services to overcome language barriers and
          improve accessibility.
        </p>
      </div>
      <Breadcrumbs />
    </div>
  );
};

const banner = {
  arpan: <ServiceBanner/>,
  services: <ServiceBanner />,
  product: <ProductBanner />,
  career: <CareersBanner />,
  tender: <TenderBanner />,
  "on-premise": <OnPremiseBanner />,
};

const ArpanBanner = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    const path = location.pathname;
    const pathArray = path.split("/");
    setCurrentPath(pathArray[pathArray.length - 1]);
  }, [location]);
  return banner[currentPath] || "Default Banner";
};

export default ArpanBanner;
