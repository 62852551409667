import React from "react";
import assetsManager from "../../assets/assetsManager";
import useScrollToRef from "../../hooks/useScrollToRef";




// function to make ALL CAPS words bold
const boldCapsWords = (text) => {
  return text.split(' ').map((word, index) => {
    if (word === word.toUpperCase() && word.match(/[A-Z]/)) {
      return <strong key={index}>{word}</strong>;
    } else {
      return word;
    }
  }).reduce((prev, curr) => [prev, ' ', curr]);
};

const OurObjectives = () => {
  const ourObjectivesRef = useScrollToRef();

  const objectives = [
    {
      content: "Long-term STRATEGY for developing sustainable Indian language technology, solutions and ecosystem technology"
    },
    {
      content: "ADOPTION of Indian language technology, solutions for easier access to the internet."
    },
    {
      content: "GROWTH of Indian language content & Technology over Internet"
    },
    {
      content: "Harness economies of scale in Indian language technologies, solutions, applications DATA SETS and (AI)MODELS."
    },
    {
      content: "Enable CUTTING-EDGE RESEARCH in IL technologies evolutionary and revolutionary technologies."
    },
    {
      content: "Promote and facilitate INDIGENOUS INTELLECTUAL PROPERTY (IP) generation"
    },
    {
      content: "Encourage, ENABLE AND INCENTIVIZE the Transfer of Technologies (ToT)"
    },
    {
      content: "Enable collaborations and partnership programs with NATIONAL AND INTERNATIONAL AGENCIES"
    },
    {
      content: "Catalysing collaborative RESEARCH, COMMERCIALIZATION AWARENESS, AND CAPACITY BUILDING"
    },
    {
      content: "To adopt and implement the DATA POLICY for the Mission"
    }
  ];

  return (
    <div className="flex flex-col gap-9" ref={ourObjectivesRef}>
      <p className="heading1 font-bold text-center md:text-left text-[#242424]">
        Our Objectives
      </p>
      <div className="flex gap-5 flex-wrap">
        {objectives.map((objective, index) => (
          <div
            key={index}
            className="flex flex-col gap-6 px-6 pt-[1.75rem] pb-[8rem] w-full md:w-[30%] shadow-lg relative"
          >
            <p className="text-[1.125rem]">{boldCapsWords(objective.content)}</p>
            <div className="absolute bottom-0 right-0">
              <img
                src={assetsManager.bullsEye}
                alt="objective"
                loading="lazy"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurObjectives;
