import React from "react";
import assetsManager from "../../assets/assetsManager";
import { handleExternalLinks } from "../../utils/utilities";
import useScrollToRef from "../../hooks/useScrollToRef";

const OurProduct = () => {
  const productRef = useScrollToRef();
  const products = [
    {
      name: "Anuvaad",
      shortDesc: "Web Service Text Translation",
      longDesc:
        "An innovative technology that seamlessly converts text from one language to another language.",
      img: assetsManager.anuvaad,
      link: "https://anuvaad.bhashini.gov.in/",
      comingSoon: false,
    },
    {
      name: "Chitraanuvaad",
      shortDesc: "Video translation",
      longDesc:
        "Chitraanuvaad is an AI-based open-source platform to perform video translation for various Bhartiya languages.",
      img: assetsManager.chitra,
      link: "https://dashboard.bhashini.co.in/user/register",
      comingSoon: false,
    },
    {
      name: "Lekhaanuvaad",
      shortDesc: "Document translation ",
      longDesc:
        "Document translation and digitization across various Bhartiya languages, ensuring clear and accurate communication.",
      img: assetsManager.lekhaanuvaad,
      link: "https://dashboard.bhashini.co.in/user/register",
      comingSoon: false,
    },
    {
      name: "Bhashini Translation Plugin",
      shortDesc: "Web Translation Plugin",
      longDesc:
        "Effortlessly translate webpage content across multiple Indic languages with our powerful plugin.",
      img: assetsManager.webtranslationplugin,
      link: "https://dashboard.bhashini.co.in/user/register",
      comingSoon: false,
    },
    {
      name: "Vaanianuvaad",
      shortDesc: "Speech-to-Speech translation",
      longDesc:
        "Real-time speech-to-speech translation for Bhartiya languages, facilitating seamless communication across different languages.",
      img: assetsManager.vanianuvaad,
      link: "#",
      comingSoon: true,
    },
    {
      name: "Bhashini WTMS",
      shortDesc: "Web Translation Plugin",
      longDesc:
        "Advanced AI-powered website translation plugin, translating content between English and 22 Bhartiya languages.",
      img: assetsManager.plugin,
      link: "",
      comingSoon: true,
    },
  ];

  return (
    <div className="flex flex-col gap-9" ref={productRef}>
      <div className="flex flex-col">
        <p className="heading1 font-bold text-[#242424]">
          Bhashini’s Arpan Applications
        </p>
        <p className="heading3 font-medium">
          Coming Soon as BETA on Prayog......
        </p>
      </div>
      <div className="flex flex-wrap gap-9">
        {products.map((product, index) => (
          <div
            key={index}
            className="md:w-[47%] w-full dark:border dark:border-gray-400 shadow-lg flex flex-col gap-5 h-auto"
          >
            <div className="h-[13.68rem]">
              <img
                src={product.img}
                alt={product.name}
                className="w-full h-full"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col gap-6 p-6">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <p className="heading2 font-semibold">{product.name}</p>
                  <p>{product.shortDesc}</p>
                </div>
                <p className="paragraph1 font-normal">{product.longDesc}</p>
              </div>
              {product.comingSoon ? (
                <p className="text-primary self-end">Coming Soon...</p>
              ) : (
                <button
                  onClick={() => handleExternalLinks(product.link)}
                  className="bg-primary py-3 px-6 rounded text-white w-fit self-end flex gap-2 items-center"
                >
                  {product.name === "Anuvaad"
                    ? "Explore Now"
                    : "Request Access"}
                  <img src={assetsManager.rightArrow} alt="arrow" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurProduct;
