import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const SamudayeBanner = () => {
  return (
    <div className="samudaye-banner-bg !flex justify-between w-full relative h-[24.25rem] ">
      <div className="container">
        <div className="flex flex-col gap-6 w-full md:w-3/5 md:py-[5.47rem] sm:px-8 px-4 py-14">
          <p className="text-[2rem] font-medium text-white">
            Be a part of the Bhashini Samudaye
          </p>
          <p className="heading3 font-medium text-white">
            Register to Join the Bhartiya Linguistic Expert Community
          </p>
          <div className="absolute h-full bottom-[-90%]">
            <Breadcrumbs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamudayeBanner;
