import React from "react";

const SprintProblemStatement = () => {
  const problemStatements = [
    {
      title: "Problem Statement 1",
      name: "BHASHINI NEGOTIATION ENGINE : DEVELOP A TECH FUNCTIONALITY TO BRIDGE THE LANGUAGE GAP IN A VOICE BASED NEGOTIATION SCENARIO",
      description:
        "A buyer (Language A) and seller (Language B) wants to finalize a deal after a negotiation. However, language barriers hinder smooth communication and potentially lead to misunderstandings and missed opportunities. Existing translation tools often lack the context and nuance required for complex negotiations also missing out on intent and sentiment. Current solutions may not capture regional dialects or cultural subtleties, potentially causing misinterpretations. Develop a functionality which can accurately translates not only words but also intent, sentiment, and cultural nuances between the seller and buyer facilitating a smoother negotiation and deal finalization.",
      desiredOutcome: [
        "The tool can act as a seamless translator, understanding and conveying the full context of the conversation between both the parties, regardless of language",
        "The tool accurately translates not only words but also intent, sentiment, and cultural nuances.",
      ],
    },
    {
      title: "Problem Statement 2",
      name: "DEVELOP A CHAT-BOT FACILITATING MULTILINGUAL CUSTOMER SUPPORT FOR THE SELLER AND BUYER",
      description:
        "Existing translation tools might struggle with technical jargon or customer emotions. Develop a chatbot to facilitate the process among the existing eco-system for an efficient execution. The chatbot should be able to translate text-to-text, voice-to-voice, voicetext-voice",
      desiredOutcome: [
        "The tool can act as a seamless translator, understanding and conveying the full context of the conversation between both the parties, regardless of language",
        "The tool accurately translates not only words but also intent, sentiment, and cultural nuances.",
      ],
    },
    {
      title: "Problem Statement 3",
      name: "DEVELOP A SOFTWARE FACILITATING MULTILINGUAL NEW BUSINESS ONBOARDING ON AN E-COMMERCE PLATFORM",
      description:
        "DEVELOP A SOFTWARE FACILITATING MULTILINGUAL NEW BUSINESS ONBOARDING ON AN E-COMMERCE PLATFORM",
      desiredOutcome: [
        "The tool can act as a seamless translator, understanding and conveying the full context of the conversation between both the parties, regardless of language",
        "The tool accurately translates not only words but also intent, sentiment, and cultural nuances.",
      ],
    },
    {
      title: "Problem Statement 4",
      name: "STREAMLINED INVENTORY MANAGEMENT WITH VOICE COMMANDS AND ADVANCED IMAGE DETECTION FOR SELLERS",
      description:
        "Manually managing inventory is time-consuming and error-prone, especially for small businesses with limited resources and limitation in reading and typing knowledge. Existing solutions often require manual data entry, hindering efficiency and accuracy. Propose a solution which can overcome this limitation. The tool can be integrated in the E-com platforms.",
      desiredOutcome: [
        "Perform real-time inventory checks and updates using voice commands in their preferred language, eliminating the need for manual data entry.",
        "Receive voice-based alerts and notifications when stock levels reach predefined thresholds, facilitating proactive inventory management.",
        "Offer customizable voice commands to streamline specific inventory management tasks, improving efficiency and reducing workload."
      ],
    },
    {
      title: "Problem Statement 5",
      name: "EMPOWERING VOICE-ACTIVATED SHOPPING IN AR/VR FOR MULTILINGUAL USERS",
      description:"The growing popularity of AR/VR technology offers a new paradigm for online shopping, providing immersive and interactive experiences. However, current solutions often lack accessibility for users who are not fluent in the platform's primary language. Develop a functionality to bridge this gap by developing a voice-activated shopping system within an AR/VR environment, specifically catering to users with diverse language needs.",
      desiredOutcome: [
        "Language barrier: Many potential AR/VR shoppers may not be comfortable using interfaces primarily in English or other dominant languages. The functionality should overcome this limitation.",
        "Limited voice interaction: Existing systems might not offer comprehensive voice command functionalities for searching, purchasing, and payment within AR/VR environments. This should be integrated.",
        "Inventory management across vendors: Integrating and searching inventory from multiple vendors can be complex, hindering product discovery and purchase completion. The functionality should address this as well."
      ],
    },
  ];


  return (
    <div className="flex flex-col  gap-9">
      <p className="heading1 font-semibold">
        Bhashini SPRINT:{ " "}
        <span className="text-primary">Date & Deadlines</span>
      </p>
      <div className="flex flex-col gap-[6.25rem]">
        {
          problemStatements.map((problemStatement, index) => (
            <div key={index} className="flex flex-col gap-[1.88rem]">
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-primary">{problemStatement.title}:</p>
                <p className="font-bold">
                  {problemStatement.name}
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <p className="font-semibold">Description:</p>
                <p>
                  {problemStatement.description}
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <p className="font-semibold">Desired Outcome:</p>
                {
                  problemStatement.desiredOutcome.map((outcome, index) => (
                    <p key={index}>
                      {`${index + 1}. ${outcome}`}
                    </p>
                  ))
                }
              </div>
            </div>
          ))
        }
        {/* <div className="flex flex-col gap-[1.88rem]">
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-primary">PROBLEM STATEMENT 1:</p>
            <p className="font-bold">
              BHASHINI NEGOTIATION ENGINE : DEVELOP A TECH FUNCTIONALITY TO
              BRIDGE THE LANGUAGE GAP IN A VOICE BASED NEGOTIATION SCENARIO
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="font-semibold">Description:</p>
            <p>
              A buyer (Language A) and seller (Language B) wants to finalize a
              deal after a negotiation. However, language barriers hinder smooth
              communication and potentially lead to misunderstandings and missed
              opportunities. Existing translation tools often lack the context
              and nuance required for complex negotiations also missing out on
              intent and sentiment. Current solutions may not capture regional
              dialects or cultural subtleties, potentially causing
              misinterpretations. Develop a functionality which can accurately
              translates not only words but also intent, sentiment, and cultural
              nuances between the seller and buyer facilitating a smoother
              negotiation and deal finalization.
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="font-semibold">Desired Outcome:</p>
            <p>
              1. The tool can act as a seamless translator, understanding and conveying the full context of the conversation between both the parties, regardless of language
            </p>
            <p>
              2. The tool accurately translates not only words but also intent, sentiment, and cultural nuances.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[1.88rem]">
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-primary">PROBLEM STATEMENT 2: </p>
            <p className="font-bold">
            DEVELOP A CHAT-BOT FACILITATING MULTILINGUAL CUSTOMER SUPPORT FOR THE SELLER AND BUYER
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="font-semibold">Description:</p>
            <p>
            Existing translation tools might struggle with technical jargon or customer emotions. Develop a chatbot to facilitate the process among the existing eco-system for an efficient execution. The chatbot should be able to translate text-to-text, voice-to-voice, voicetext-voice
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="font-semibold">Desired Outcome:</p>
            <p>
              1. The tool can act as a seamless translator, understanding and conveying the full context of the conversation between both the parties, regardless of language
            </p>
            <p>
              2. The tool accurately translates not only words but also intent, sentiment, and cultural nuances.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SprintProblemStatement;
