import React from "react";

const Timelines = () => {
  const tableData = [
    {
      id: 1,
      activity: "Launch of Innovation Challenge",
      timelines: "01st February 2024",
    },
    {
      id: 2,
      activity: "Problem statement wise query sessions",
      timelines: "02-05th February 2024",
    },
    {
      id: 3,
      activity: "Last date of Registration- Submission of ideas",
      timelines: "15th February 2024",
    },
    {
      id: 4,
      activity: "Initial Screening of applications (Stage 1)",
      timelines: "19th February 2024",
    },
    {
      id: 5,
      activity: "Declaration of Results (Stage 1 Qualifiers)",
      timelines: "20th February 2024",
    },
    {
      id: 6,
      activity: "Last Date for Initial Prototype submission (for Stage 2)",
      timelines: "26th February 2024",
    },
    {
      id:7,
      activity: "Evaluation of Stage 2 Initial prototypes",
      timelines: "27st February 2024",
    },
    {
      id: 8,
      activity: "Declaration of Results (Stage 2 Qualifiers)",
      timelines: "12th March 2024",
    },
    {
      id: 9,
      activity: "Last Date for Final Prototype submission (Stage 3)",
      timelines: "15th March 2024",
    },

    {
      id: 10,
      activity: "Evaluation of Stage 3 prototypes",
      timelines: "20th March 2024",
    },
    {
      id: 11,
      activity: "Declaration of Winning Teams",
      timelines: "22th March 2024",
    }
  ];

  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold ">
        Dates & Deadlines:
        <span className="text-primary">Bhashini Startup Velocity 1.0</span>
      </p>
      <div className="w-full bg-[#EFF3FE] px-[2.3rem] py-6">
        <table className="w-full">
          <thead>
            <tr className="bg-[#DFEAF5]">
              <th className="text-left smallText p-4">Sr. No..</th>
              <th className="text-left smallText p-4">Activity</th>
              <th className="text-left smallText p-4">Timelines</th>
            </tr>
          </thead>
          <tbody>
            {
              tableData.map((data) => (
                <tr key={data.id} className="border border-[#EAECF0]  bg-[#F9FAFB]">
                  <td className="p-4 paragraph2">{data.id}</td>
                  <td className="p-4 paragraph2">{data.activity}</td>
                  <td className="p-4 paragraph2">{data.timelines}</td>
                </tr>
              ))
            }
          
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Timelines;
