import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col gap-[6.25rem]">
  <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4  flex flex-col privacyPolicyBanner">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Privacy Policy</p>
        </div>
        <Breadcrumbs />
      </div>
    <div className="flex flex-col gap-6 mx-4 container">
      <p className="heading1 font-bold">Privacy Policy</p>
      <div className="flex flex-col gap-6">
        <p className="heading2 font-semibold text-primary">Site Visit Data</p>
        <div className="flex flex-col gap-3">
          <p className="paragraph1">
            Bhashini website does not automatically capture any specific
            personal information from you, (like name, phone number or email
            address) that allows us to identify you individually.
          </p>
          <p className="paragraph1">
            This website records your visit and logs the following information
            for statistical purposes, such as Internet protocol (IP) addresses,
            domain name, servers address, name of the top-level domain from
            which you access the Internet (for example, .gov, .com, .in, etc.),
            browser type, operating system, the date and time of the visit, the
            pages you have accessed, the documents downloaded and the previous
            Internet address from which you linked directly to the site. We make
            no attempt to link these addresses with the identity of individuals
            visiting our site unless an attempt to damage the site has been
            detected. We will not identify users or their browsing activities,
            except when a law enforcement agency may exercise a warrant to
            inspect the service providers logs.
          </p>
          <p className="paragraph1">
            If the Bhashini website requests you to provide personal
            information, you will be informed of the particular purposes for
            which the information is gathered and adequate security measures
            will be taken to protect your personal information. Bhashini does
            not sell or share any personally identifiable information
            volunteered on the Bhashini website to any third party, whether
            public or private. Any information provided to this website will be
            protected from loss, misuse, unauthorised access or disclosure,
            alteration or destruction.
          </p>
        </div>
        <div className="flex flex-col gap-3 ">
          <p className="heading2 font-semibold text-primary">Cookies</p>
          <p className="paragraph1">
            A cookie is a piece of software code that an Internet website sends
            to your browser when you access information in that site. This site
            does not use cookies.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="heading2 font-semibold text-primary">Email Management</p>
          <p className="paragraph1">
            Your email address will only be recorded if you choose to send a
            message. It will only be used for the purpose for which you have
            provided it and will not be added to a mailing list. Your email
            address will not be used for any other purpose, and will not be
            disclosed without your consent.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="heading2 font-semibold text-primary">Collection of Personal Information</p>
          <p className="paragraph1">
          If you are asked for any other personal information you will be informed how it will be used if you choose to give it. If at any time you believe the principles referred to in this privacy statement have not been followed, or have any other comments on these principles, please notify the Web Information Manager by sending an email to contact {" "} 
          <Link className="text-primary underline" to="mailto:bhashini[at]digitalindia.gov.in">bhashini[at]digitalindia[dot]gov[dot]in</Link>
        
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="heading2 font-semibold text-primary">Reasonable Security Practices</p>
          <p className="paragraph1">
          Reasonable security measures, such as administrative, technical, operational and physical controls, have been implemented to ensure the security of any personal information collected.
        
          </p>
        </div>
      </div>
    </div>

    </div>


  );
};

export default PrivacyPolicy;
