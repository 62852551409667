import React, { useState } from "react";
import assetsManager from "../../assets/assetsManager";
import useScrollToRef from "../../hooks/useScrollToRef";

const BhashiniEcosystem = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [hoveredModal, setHoveredModal] = useState(null);
  const bhashiniEcosystemRef = useScrollToRef()
  const toggleModal = (modalName) => {
    setActiveModal((prev) => (prev === modalName ? null : modalName));
  };
  const handleMouseEnter = (modalName) => {
    setHoveredModal(modalName);
  }
  const handleMouseLeave = () => {
    setHoveredModal(null);
  }


  return (
    <div className="flex flex-col gap-9" ref={bhashiniEcosystemRef}>
      <div className="flex flex-col gap-2">
        <p className="md:text-4xl text-[1.625rem] font-bold text-[#242424]">
          Bhashini{"'"}s Ecosystem
        </p>
        <p className=" paragraph1 font-normal">
          <span className="font-bold">Note:</span> Click on blocks for more
          information
        </p>
      </div>
      <div className="flex h-full gap-8">
        <div className="w-1/5">
          <div className="w-full flex h-full gap-4">
            {/* Meity */}
            <div
              onClick={() => toggleModal("meity")}
              className="relative text-white cursor-pointer md:h-full h-[105%] rounded meityBg px-[1.31rem] w-1/2 flex justify-center items-center"
            >
              <p className="paragraph1 hidden md:block font-semibold [writing-mode:vertical-lr] rotate-180">
                MeitY
              </p>
              <p className="paragraph1 font-medium [writing-mode:vertical-lr] rotate-180">
                Ministry of Electronics & Information Technology
              </p>
              {activeModal === "meity" && (
                <div className="shadow-3xl flex gap-9 flex-col absolute top-0 left-0 meityModalBg md:w-[300%] w-[700%] h-full z-10 px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                  <div className="w-full flex text-white cursor-pointer font-bold items-center justify-between">
                    <div className="w-full flex py-[0.38rem] px-3 bg-white rounded">
                      <img
                        className="m-auto"
                        src={assetsManager.meityLogo}
                        alt="bhashini"
                        height={200}
                        width={250}
                      />
                    </div>
                  </div>
                  <p className="text-sm text-left">
                    The Ministry of Electronics and Information Technology
                    (MeitY) is committed to promoting e-Governance to empower
                    citizens and support the sustainable growth of the
                    Electronics, IT, and ITeS industries. MeitY&apos;s
                    multifaceted approach includes human resource development,
                    promoting R&D and innovation, enhancing digital service
                    efficiency, and ensuring cybersecurity. Key objectives
                    include providing e-infrastructure for e-services, boosting
                    electronics manufacturing and IT-ITeS industry growth,
                    fostering R&D and innovation, supporting e-Skills and
                    knowledge networks, securing cyberspace, promoting inclusive
                    growth through ICT, and enhancing India{"'"}s role in global
                    Internet Governance.
                  </p>
                </div>
              )}
            </div>
            {/* DIC */}
            <div
              onClick={() => toggleModal("dic")}
              className="relative text-white cursor-pointer h-full rounded dicBg px-[1.31rem] w-1/2 flex justify-center items-center"
            >
              <p className="paragraph1 hidden md:block font-semibold [writing-mode:vertical-lr] rotate-180">
                DIC
              </p>
              <p className="paragraph1 font-medium [writing-mode:vertical-lr] rotate-180">
                Digital India Corporation
              </p>
              {activeModal === "dic" && (
                <div className="shadow-3xl flex gap-9 flex-col absolute top-0 left-0 dicModalBg md:w-[300%] w-[600%] h-full z-10 px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                  <div className="w-full flex text-white cursor-pointer font-bold items-center justify-between">
                    <div className="w-full flex py-[0.38rem] px-3 bg-white rounded">
                      <img
                        className="m-auto"
                        src={assetsManager.dicLogo}
                        alt="bhashini"
                        height={200}
                        width={250}
                      />
                    </div>
                  </div>
                  <p className="text-sm text-left">
                    Digital India Corporation (DIC) leads and guides in
                    realizing the vision, objectives and goals of the Digital
                    India program. It provides the strategic support to
                    Ministries/Departments of Centre/States for carrying forward
                    the mission of Digital India by way of Capacity Building for
                    e-Governance projects, promoting best practices, encouraging
                    Public-Private Partnerships (PPP), nurturing innovation and
                    technology in various domains.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-[80%] flex flex-col">
          {/* DIBD */}
          <div className="flex flex-col w-full">
            <div
              onClick={() => toggleModal("dibd")}
              className="relative w-full flex text-white cursor-pointer font-bold items-center justify-between rounded eco-system-header px-4 md:px-[2.87rem] pt-[2.19rem] pb-[1.75rem]"
            >
              <p className="md:text-lg text-[0.51rem] ecosystem-title uppercase">
                Digital India Bhashini Division
              </p>
              <div className="py-[0.38rem] px-3 bg-white rounded">
                <img src={assetsManager.digitalIndiaLogo} alt="bhashini" />
              </div>
              {activeModal === "dibd" && (
                <div className="shadow-3xl flex gap-9 flex-col absolute top-0 left-0 w-full z-10 eco-system-header px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                  <div className="w-full flex text-white cursor-pointer font-bold items-center justify-between">
                    <p className="heading3 ecosystem-title">
                      Digital India Bhashini Division
                    </p>
                    <div className="py-[0.38rem] px-3 bg-white rounded">
                      <img
                        src={assetsManager.digitalIndiaLogo}
                        alt="bhashini"
                      />
                    </div>
                  </div>
                  <p className="text-sm">
                    Digital India Bhashini Division is an Independent business
                    division under Digital India Corporation that is a not for
                    profit organization set up by the Ministry of Electronics
                    and Information Technology (MeitY), Govt. of India, under
                    Section 8 of Companies Act 2013. DIBD leads and guides in
                    realizing the vision, objectives and goals of the Digital
                    India program. It provides strategic support to
                    Ministries/Departments of Centre/States for carrying forward
                    the mission of Digital India by way of Capacity Building for
                    e-Governance projects, promoting best practices, encouraging
                    Public-Private Partnerships (PPP), nurturing innovation and
                    technology in various domains.
                  </p>
                </div>
              )}
            </div>
            {/* Bhahsini Vayvastha */}
            <div
              onClick={() => toggleModal("bhashiniVayvastha")}
               onMouseEnter={() => handleMouseEnter("bhashiniVayvastha")}
               onMouseLeave={() => handleMouseLeave("bhashiniVayvastha") }
               className={`cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6 ${
                 hoveredModal === "bhashiniVayvastha" ? "hover-background text-white" : ""
               }`}
            >
              <img
                src={assetsManager.wave1}
                className="absolute top-0"
                alt="bhashini"
              />
              <p className="md:text-base text-[.625rem] font-semibold">Bhashini Vayvastha</p>
              <img
                src={assetsManager.wave1}
                className="absolute top-0 right-[-50%]"
                alt="bhashini"
              />
              {activeModal === "bhashiniVayvastha" && (
                <div className="text-white  shadow-3xl flex gap-9 flex-col absolute top-0 left-0 w-full z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                  <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                    <p className="heading3 ecosystem-title text-center">
                      Bhashini Vayvastha
                    </p>
                    <p className="paragraph1 font-normal">Governance Program</p>
                  </div>
                  <div className="flex flex-wrap gap-[.62rem] m-auto">
                    <div className="flex py-2 px-4  bg-white text-black">
                      <p className="text-sm">API Management, Automation</p>
                    </div>
                    <div className="flex py-2 px-4  bg-white text-black">
                      <p className="text-sm">Leaderboard Dashboard</p>
                    </div>
                    <div className="flex py-2 px-4  bg-white text-black m-auto">
                      <p className="text-sm">Vision 2025, Monetisation</p>
                    </div>
                  </div>
                  <p className="text-sm">
                    The Governance of the Framework through Dashboard and
                    Leaderboards. It also is responsible for Vision,
                    Monetisation, and building the Talent pipeline for Bhashini.
                    Bhashini Sanchalak: To Build and manage the offering and
                    productionize it for use by the stakeholders
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-4 mt-4">
            <div className="flex flex-col gap-[1.62rem] w-2/5">
              {/*Bhashini Arpan */}
              <div
                onMouseEnter={() => handleMouseEnter("arpan")}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => toggleModal("arpan")}
                className={`cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6 ${ hoveredModal === "arpan" ? "hover-background text-white" : ""}`}
                // className="cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6"
              >
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0"
                  alt="bhashini"
                />
                <p className="md:text-base text-[.625rem] font-semibold">Bhashini Arpan</p>
                <p className="md:text-base text-[.375rem]">Our Offerings, Solutions, Products</p>
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0 right-[-50%]"
                  alt="bhashini"
                />
                {activeModal === "arpan" && (
                  <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-0 left-0 w-[250%] md:w-[100%] h-[700%] md:h-[410%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                    <div className="w-full flex cursor-pointer font-bold items-center justify-center">
                      <p className="heading3 ecosystem-title text-center">
                        Bhashini Arpan
                      </p>
                    </div>
                    <p className="text-sm">
                      Bhashini&apos; Arpan empowers your communication with a
                      range of industry-standard language services (ASR, TTS,
                      OCR, etc.) and cutting-edge beta applications. Explore
                      career opportunities, stay informed about tenders/EOI, and
                      on-premise features (coming soon)
                    </p>
                  </div>
                )}
              </div>
              {/* Bhashini Sanchalak */}
              <div
                onMouseEnter={() => handleMouseEnter("bhashiniSanchalak")}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => toggleModal("bhashiniSanchalak")}
                className={`cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniSanchalak" ? "hover-background text-white" : ""}`}
                // className="cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6"
              >
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0"
                  alt="bhashini"
                />

                <p className="md:text-base text-[.625rem] font-semibold">Bhashini Sanchalak</p>
                <p className="md:text-base text-[.375rem]">
                  Offerings, Drivers, & Operational Management
                </p>
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0 right-[-50%]"
                  alt="bhashini"
                />
                {activeModal === "bhashiniSanchalak" && (
                  <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-0 left-0 md:w-[200%] md:h-[300%] w-[250%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                    <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                      <p className="heading3 ecosystem-title text-center">
                        Bhashini Sanchalak
                      </p>
                      <p className="md:text-base text-[.625rem] font-normal">
                        Offerings, Drivers, & Operational Management
                      </p>
                    </div>
                    <div
                      className="flex flex-wrap gap-[.62rem] justify-center"
                      style={{
                        marginInline: "auto",
                      }}
                    >
                      <div className="flex py-2 px-4  bg-white text-black">
                        <p className="text-sm">Model & Data Vatika[ULCA]</p>
                      </div>
                      <div className="flex py-2 px-4  bg-white text-black">
                        <p className="text-sm">NHLT</p>
                      </div>
                    </div>
                    <p className="text-sm">
                      Sanchalak build and manage the offering and productionize
                      it for use by the stakeholders
                    </p>
                  </div>
                )}
              </div>
              {/* Bhashini Prayog */}
              <div
                onMouseEnter={() => handleMouseEnter("bhashiniPrayog")}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => toggleModal("bhashiniPrayog")}
                className={`cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniPrayog" ? "hover-background text-white" : ""}`}
                // className="cursor-pointer w-full relative bg-[#F7F7FE] dark:bg-black dark:border dark:border-gray-400 shadow-3xl text-center py-6"
              >
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0"
                  alt="bhashini"
                />
                <p className="md:text-base text-[.625rem] font-semibold">Bhashini Prayog</p>
                <p className="md:text-base text-[.375rem]">
                  Explore Reference Applications, Prayogshala, Vaktika
                </p>
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0 right-[-50%]"
                  alt="bhashini"
                />
                {activeModal === "bhashiniPrayog" && (
                  <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-[-500%] w-[300%]  md:top-0 md:left-0 md:w-[250%] md:h-[220%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                    <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                      <p className="md:text-base text-[.625rem] ecosystem-title text-center">
                        Bhashini Prayog
                      </p>
                      <p className="paragraph1 font-normal">
                        Explore Reference Applications, Prayogshala, Vaktika
                      </p>
                    </div>
                    <p className="text-sm">
                      The Bhashini Prayog project is a platform for language
                      experimentation, a virtual lab that consolidates services,
                      tools, and applications. Featuring products like
                      Chitralekha, Lekhaanuvaad, Anuvaad, Vaanianuvaad, Bhashini
                      Plugin, Bhashini Mobile App, Models & Dataset Vatika,
                      Prayogshala and more, it aims to be the leading
                      destination for language exploration and innovation. Users
                      can achieve their language-related objectives in a secure
                      and comprehensive environment.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {
              // Bhashini Sanyojak
            }
            <div className="w-[7%]">
              <div
                onMouseEnter={() => handleMouseEnter("bhashiniSanyojak")}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => toggleModal("bhashiniSanyojak")}
                className={`cursor-pointer text-black relative h-full rounded dark:bg-black dark:text-white dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl px-2 md:px-[1.31rem] w-1/2 flex justify-center items-center ${ hoveredModal === "bhashiniSanyojak" ? "hover-background text-white" : ""}`}
                // className="cursor-pointer text-black relative h-full rounded dark:bg-black dark:text-white dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl px-2 md:px-[1.31rem] w-1/2 flex justify-center items-center"
              >
                <p className="md:text-base text-[.625rem] font-semibold [writing-mode:vertical-lr] rotate-180">
                  Bhashini Sanyojak
                </p>
                {activeModal === "bhashiniSanyojak" && (
                  <div className="shadow-3xl text-white flex gap-9 flex-col absolute top-0 md:left-0 left-[-1000%] w-[20rem] h-[100%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                    <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                      <p className="heading3 ecosystem-title text-center">
                        Bhashini Sanyojak
                      </p>
                      <p className="md:text-base text-[.375rem] font-normal">
                        Empowering Customers
                      </p>
                    </div>
                    <p className="text-sm text-center">
                      Assist customers (government and private) in using the
                      Bhashini system under the Mitra, Sahyogi, and Udyat
                      programs. Resolve interface issues, collaborate with R&D
                      for domain-specific model training, support startups and
                      state governments, and manage Data Daan contributions.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2 flex flex-col gap-[1.62rem] relative">
              {/* Bhashini Sahyogi */}
              <div
                onMouseEnter={() => handleMouseEnter("bhashiniSahyogi")}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => toggleModal("bhashiniSahyogi")}
                className={`cursor-pointer w-full relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniSahyogi" ? "hover-background text-white" : ""}`}
                // className="cursor-pointer w-full relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6"
              >
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0"
                  alt="bhashini"
                />
                <p className="md:text-base text-[.625rem] font-semibold">Bhashini Sahyogi</p>
                <p className="md:text-base text-[.375rem]">
                  Our Partners, Collaborators, & Contributors
                </p>
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0 right-[-50%]"
                  alt="bhashini"
                />
                <div className="absolute z-[-1] bottom-[-35%] left-1/4 w-[.125rem] h-[5rem] bg-black"></div>
                <div className="absolute z-[-1] bottom-[-35%] left-3/4 w-[.125rem] h-[5rem] bg-black"></div>
                {activeModal === "bhashiniSahyogi" && (
                  <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-0 md:left-0 left-[-150%] right-[5%] md:right-0 md:w-[100%] md:h-[365%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                    <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                      <p className="heading3 ecosystem-title text-center">
                        Bhashini Sahyogi
                      </p>
                      <p className="paragraph1 font-normal">Our Partners</p>
                    </div>
                    <p className="text-sm">
                      Bhashini intends to have co-creation engagement for both
                      the adoption and creation of Bhashini offerings with State
                      Government, Startups, Industry, and NGOs.
                    </p>
                  </div>
                )}
              </div>
              <div className="flex gap-3">
                {/* bhashini Mitra */}
                <div
                  onMouseEnter={() => handleMouseEnter("bhashiniMitra")}
                  onMouseLeave={() => handleMouseLeave()}

                  onClick={() => toggleModal("bhashiniMitra")}
                  className={`cursor-pointer w-1/2 relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniMitra" ? "hover-background text-white" : ""}`}
                  // className="cursor-pointer w-1/2 relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6"
                >
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0"
                    alt="bhashini"
                  />
                  <p className="md:text-base text-[.625rem] font-semibold">Bhashini Mitra</p>
                  <p className="md:text-base text-[.375rem]">Helping Build Program</p>
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0 right-[-50%]"
                    alt="bhashini"
                  />
                  {activeModal === "bhashiniMitra" && (
                    <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-0 md:left-0 left-[-200%] w-[400%]  md:w-[200%] md:h-[300%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                      <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                        <p className="heading3 ecosystem-title text-center">
                          Bhashini Mitra
                        </p>
                        <p className="paragraph1 font-normal">
                          Helping Build Programs
                        </p>
                      </div>
                      <p className="text-sm">
                        Bhashini would seek help for data, application,
                        knowledge, and Research in the program to help Bhashini
                        Sanchalak.
                      </p>
                    </div>
                  )}
                </div>
                {/* Bhashini Udyat */}
                <div
                  onMouseEnter={() => handleMouseEnter("bhashiniUdyat")}
                  onMouseLeave={() => handleMouseLeave()}

                  onClick={() => toggleModal("bhashiniUdyat")}
                  className={`cursor-pointer w-1/2 relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniUdyat" ? "hover-background text-white" : ""}`}
                  // className="cursor-pointer w-1/2 relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6"
                >
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0"
                    alt="bhashini"
                  />
                  <p className="md:text-base text-[.625rem] font-semibold">Bhashini Udyat</p>
                  <p className="md:text-base text-[.375rem]">API Consumption Program</p>
                  <img
                    src={assetsManager.wave1}
                    className="absolute top-0 right-[-50%]"
                    alt="bhashini"
                  />
                  {activeModal === "bhashiniUdyat" && (
                    <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-0 left-[-500%] md:left-[-110%] md:w-[210%] h-[400%] md:h-[250%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                      <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                        <p className="heading3 ecosystem-title text-center">
                          Bhashini Udyat
                        </p>
                        <p className="paragraph1 font-normal">
                          API Consumption Program
                        </p>
                      </div>
                      <p className="text-sm">
                        Bhashini’s aspiration is that all the Central & State
                        Government Systems, Digital Public Goods, Industry,
                        Publishers, Industry, and Citizens consume Bhashini APIs
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* Bhashini Pravakta */}
              <div
                onMouseEnter={() => handleMouseEnter("bhashiniPravakta")}
                onMouseLeave={() => handleMouseLeave()}

                onClick={() => toggleModal("bhashiniPravakta")}
                className={`cursor-pointer w-full h-full relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniPravakta" ? "hover-background text-white" : ""}`}
                // className="cursor-pointer w-full h-full relative dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6"
              >
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0"
                  alt="bhashini"
                />
                <p className="md:text-base text-[.625rem] font-semibold">Bhashini Pravakta</p>
                <p className="md:text-base text-[.375rem]">Awareness and Outreach Program</p>
                <img
                  src={assetsManager.wave1}
                  className="absolute top-0 right-[-50%]"
                  alt="bhashini"
                />
                {activeModal === "bhashiniPravakta" && (
                  <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-[-100%] left-[-150%] md:left-0 md:w-[100%] md:h-[250%] z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                    <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                      <p className="md:text-base text-[.625rem] ecosystem-title text-center">
                        Bhashini Pravakta
                      </p>
                      <p className="paragraph1 font-normal">
                        Awareness and Outreach Program
                      </p>
                    </div>
                    <p className="text-sm">
                      The Awareness and Outreach Program with the
                      Industry/Associations, through Social Media, and also with
                      Students and Schools/Colleges/Universities.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {
            // Bhashini Sahayak
          }
          <div
            onMouseEnter={() => handleMouseEnter("bhashiniSahayak")}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => toggleModal("bhashiniSahayak")}
            className={`cursor-pointer w-full mt-4 relative  dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6 ${ hoveredModal === "bhashiniSahayak" ? "hover-background text-white" : ""}`}
            // className="cursor-pointer w-full mt-4 relative  dark:bg-black dark:border dark:border-gray-400 bg-[#F7F7FE] shadow-3xl text-center py-6"
          >
            <img
              src={assetsManager.wave1}
              className="absolute top-0"
              alt="bhashini"
            />
            <p className="md:text-base text-[.625rem] font-semibold">Bhashini Sahayak</p>
            <img
              src={assetsManager.wave1}
              className="absolute top-0 right-[-50%]"
              alt="bhashini"
            />
            {activeModal === "bhashiniSahayak" && (
              <div className="text-white shadow-3xl flex gap-9 flex-col absolute top-[-900%] md:top-[-390%] left-0 w-full z-10 bg-blue-gradient px-[2.87rem] pt-[2.19rem] pb-[1.75rem] rounded">
                <div className="w-full flex flex-col cursor-pointer font-bold items-center justify-center">
                  <p className="heading3 ecosystem-title text-center">
                    Bhashini Sahayak
                  </p>
                  <p className="paragraph1 font-normal">Support Function</p>
                </div>
                <div className="flex flex-wrap gap-[.62rem] m-auto justify-center">
                  <div className="flex py-2 px-4  bg-white text-black">
                    <p className="text-sm">Human Resource</p>
                  </div>
                  <div className="flex py-2 px-4  bg-white text-black">
                    <p className="text-sm">Legal & RTI Compliance</p>
                  </div>
                  <div className="flex py-2 px-4  bg-white text-black">
                    <p className="text-sm">Admin</p>
                  </div>
                  <div className="flex py-2 px-4  bg-white text-black m-auto">
                    <p className="text-sm">Finance</p>
                  </div>
                </div>
                <p className="text-sm">
                  Bhashini Sahyak is the support backbone of Bhashini,
                  encompassing Human Resource, Legal & RTI Compliance, Admin,
                  and Finance functions. Human Resource handles recruitment and
                  employee relations, Legal ensures regulatory adherence, Admin
                  oversees daily operations, and Finance manages budgeting and
                  financial reporting. Together, these functions ensure the
                  smooth and efficient operation of Bhashini.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BhashiniEcosystem;
