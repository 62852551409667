import React, { useRef } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ReactPlayer from "react-player";

const CeoSpeaks = () => {
  const videoData = [
    {
      id: 1,
      url: "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Bhashini-in-action-videos/tice%20news%20ceo%20speaks.mp4",
      titile: "Tice News CEO Speaks",
    },
    {
      id: 2,
      url: "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/Amitabh%20Nag_short.mp4",
      title: "Amitabh Nag",
    },
    {
      id: 3,
      url: "https://www.youtube.com/watch?v=enerB3GbDAQ",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 4,
      url: "https://www.youtube.com/watch?v=JUJ-8MsD55A&t=1s",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 5,
      url: "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/National%20Technology%20Week%202023.mp4",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 6,
      url: "https://www.youtube.com/watch?v=Q92gK_ReYLg&t=487s",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 7,
      url: "https://www.youtube.com/watch?v=AEc7J9BodQUs",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 8,
      url: "https://www.youtube.com/watch?v=adEbTv-9SWI",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 9,
      url: "https://www.youtube.com/watch?v=Ne0N3TdkvOE",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 10,
      url: "https://www.youtube.com/watch?v=C5zc43qLzSc",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 11,
      url: "https://www.youtube.com/watch?v=vN5gxRhk4L8",
      title: "CEO Speaks - Bhashini Technologies",
    },
    {
      id: 12,
      url: "https://www.youtube.com/watch?v=xgDHUnwpD8U&t=709s",
      title: "CEO Speaks - Bhashini Technologies",
    },
  ];
  const playerRef = useRef(null);

  const handleVideoPlay = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause(); // Pause the previous video
    }
  };

  return (
    <div className="flex flex-col gap-[5.5rem]">
      <div className="w-full text-white h-[24rem] px-[9.06rem] pl-8 pr-44  flex flex-col pravakta-banner-bg">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">CEO Speaks</p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-wrap gap-3 mx-16">
        {videoData.map((data) => (
          <div className="w-[30%] flex flex-col " key={data.id}>
            <ReactPlayer
              width="100%"
              height="100%"
              controls={true}
              url={data.url}
              onPlay={() => handleVideoPlay(data.url)}
              config={{
                file: {
                  attributes: { controlsList: "nofullscreen" },
                },
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CeoSpeaks;
