import React from "react";
import assetsManager from "../../assets/assetsManager";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HomeBhashaDaan = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full home-bhashadaan bg-no-repeat bg-cover md:bg-center px-7 md:px-16 pt-[4.44rem] mt-[6.25rem] flex justify-between flex-col md:flex-row">
     
      <div className="flex flex-col w-full md:w-3/4">
        <div className="flex flex-col gap-11 mb-[4.44rem]">
          <div className="flex flex-col gap-4 ">
            <p className="md:text-4xl text-[1.625rem] font-bold">
              {t("homePage.bhashadaanBanner.title")}
            </p>
            <p className="heading3 font-semibold">
              {t("homePage.bhashadaanBanner.subtitle")}
            </p>
          </div>
          <Link
            to="https://bhashini.gov.in/bhashadaan/en/home"
            className="bg-primary text-center w-full px-12 py-4 rounded md:w-fit"
          >
            
            <p className="text-white font-semibold">
              {t("homePage.bhashadaanBanner.buttonText")}
            </p>
          </Link>
        </div>
      </div>
      <div className="w-full">
        <img
          loading="lazy"
          src={assetsManager.boloSunoIndia}
          alt="bhashadaan"
          className="md:h-full ml-auto "
        />
      </div>
    </div>
  );
};

export default HomeBhashaDaan;
