import React from 'react'

const SprintGuidelines = () => {
  const rules = [
    "If Participating Team is not yet registered, they are still allowed to participate, but are required to get registered if they get selected for the final submission.",
    "If individuals are associated with any company, then they will have to provide an NOC from their company stating that the respective company will have no right on the prize money and/or IPR. Furthermore, the individuals must apprise the employer through NOC or otherwise of the registration of a new entity.",
    "During the Bhashini-sprint, the team leader shall be considered as the Single Point of Contact (SPOC) for all engagements & communication by the organizing team. Furthermore, the team leader cannot be changed during the Bhashini-sprint.",
    "The team leader and participants will be required to use their Email ID and mobile number for the purpose of team registration.",
    "For any update regarding the Bhashini-sprint, participants will have to refer to DIBD/BHASHINI.",
    "All communication between the Bhashini-sprint organizing team and team leader shall happen via the registered Email ID only. This will be the only form of communication and any other forms of communication will not be entertained.",
    "The teams shall not display any existing solution or collaborate with companies that have existing solutions. Such entries, if identified, shall be liable for disqualification.",
    "Any outcome of this initiative shall only be consumed by the participating team for the purpose of the Bhashini-sprint.",
    "Teams shall maintain detailed documentation of their idea, prototype, and solution at all stages of the Bhashini-sprint for reference and record purpose. The Bhashini-sprint organizing team reserves the right to review these documents any time during the program.",
    "Any changes in approach to the shortlisted ideas during stage 1 and stage 2 of the Bhashini-sprint will undergo deliberation by the Bhashini-sprint organizing team.",
    "Teams are allowed removal/voluntary withdrawal of team members, only once, during the program before stage 2. Any such step will have to be disclosed to the Bhashini-sprint organizing team for approval. No other form of team modification will be entertained.",
    "The funding under Bhashini-sprint shall be consumed for development of the solution only. The teams will be required to provide Fund Utilization Certificate along with project completion certificate before the next stage, balance amount for the said challenge can be utilized for further updates and upgrades as requested by DIBD on the date decided & communicated by the Bhashini-sprint organizing team.",
    "Winner(s) and Runners-up will retain the rights of the solution/product developed as part of the Bhashini-sprint. The winner(s) and the runner(s)-up will however have to confirm to the terms and conditions defined for the Bhashini-sprint during the competition and post winning the award.",
    "The solution should not violate/breach/copy any idea/concept/product already copyrighted, patented or existing in this segment of the market.",
    "Anyone found to be non-compliant, may get their participation canceled.",
    "Bhashini-sprint jury will take the final call for any unforeseen situation.",
    "For any dispute redressal, CEO DIBD’s decision will be the final verdict on the matter.",
    "The solution/Product so developed would be deployed in the chosen cloud environment and used for the Union/State/UT government entities.",
    "The winning entities and the runner’s up would support the product for four (4) years from the go live period.",
    "The winning entities would be supported with a fixed amount on cost plus basis to sustenance and management of the product.",
    "Going forward any new enhancements, features, innovations in the solution/product during the O&M phase would always be released to the chosen cloud environment.",
    "However, the winning entities would be free to market the product to any entity outside Union/State/UT Government Organizations of India.",
    "For any enhancement or customizations, the corresponding man-month efforts submitted in the change request will be calculated based on the applicable pre-determined blended rates provided by the winning entity. Bhashini will take forward in discovery of rates with the winner(s)."
];

  return (
    <div className="flex flex-col gap-9">
    <p className="heading1 font-semibold">
      Rules and Guidelines:
      <span className="text-primary">Bhashini Sprint</span>
    </p>
    <div className="flex flex-col gap-5">
      {
        rules.map((rule, index) => (
          <div key={index} className="flex gap-2">
          
            <p className="text-sm text-gray-700"> {`${index+1}. ${rule}`}  </p>
          </div>
        ))
      }
    </div>
  </div>
  )
}

export default SprintGuidelines