import React from "react";
import ReactPlayer from "react-player";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const News = () => {
  const newsLinks = [
    "https://www.youtube.com/watch?v=_INkjHbPiCc",
    "https://www.youtube.com/watch?v=efs37UVP10Y&t=24s",
    "https://www.youtube.com/watch?v=dt5GaY_5svA&t=1s",
    "https://www.youtube.com/watch?v=-KJl9PdpHl4",
    "https://www.youtube.com/watch?v=q2lr-deU0WA&t=62s",
    "https://www.youtube.com/watch?v=eBKe9vckDuw",
    "https://www.youtube.com/watch?v=DUNIr8foCz4&t=29s",
    "https://www.youtube.com/watch?v=beJawY8YXjQ",
    "https://www.youtube.com/watch?v=CHdLMhgT_SA&t=3s",
  ];

  return (
    <div>
      <div className="w-full text-white h-[24rem] px-[9.06rem] pl-8 pr-44 serviceBanner flex flex-col pravakta-banner-bg">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Bhashini In News</p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-wrap gap-3 mx-16 mt-[6.25rem]">
        {newsLinks.map((data, index) => (
          <div className="w-[30%] flex flex-col " key={index}>
            <ReactPlayer
              width="100%"
              height="100%"
              controls={true}
              url={data}
              config={{
                file: {
                  attributes: { controlsList: "nofullscreen" },
                },
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
