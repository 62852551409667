import React from "react";
import withLayout from "../../hocs/withLayout";
import PravaktaBanner from "../../components/PravaktaBanner/PravaktaBanner";
import Featured from "../../components/Pravakta/Featured";
import MinisterInsights from "../../components/Pravakta/MinisterInsights";
import LeadershipCorner from "../../components/Pravakta/LeadershipCorner";
import AwardsRecognition from "../../components/Pravakta/AwardsRecognition";
import GlobalVoices from "../../components/Pravakta/GlobalVoices";
import BhashiniNews from "../../components/Pravakta/BhashiniNews";
import Workshops from "../../components/Pravakta/Workshops";
// import OurJourney from "../../components/OurJourney/OurJourney";

const Pravakta = () => {
  return <>About content</>;
};
export const pravaktaNavItems = [
  {
    label: "Featured",
    path: "featured",
    component: Featured,
  },
  {
    label: "Minister's Insights",
    path: "ministers-insights",
    component: MinisterInsights,
  },
  {
    label: "Leadership Corner",
    path: "leadership-corner",
    component: LeadershipCorner,
  },
  {
    label: "Global Voices",
    path: "global-voices",
    component: GlobalVoices,
  },
  {
    label: "Awards & Recognition",
    path: "awards-recognition",
    component: AwardsRecognition,
  },
  {
    label: "Bhashini in News",
    path: "bhashini-in-news",
    component: BhashiniNews,
  },
  {
    label: "Workshops",
    path: "workshops",
    component: Workshops,
  },
];

export default withLayout(pravaktaNavItems, <PravaktaBanner />)(Pravakta);
