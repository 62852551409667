import React, { useEffect, useState } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import rdProjects from "../../utils/rdProject";
import useScrollToRef from "../../hooks/useScrollToRef";
import Marquee from "react-fast-marquee";

const RDProjectDetail = () => {
  const [project, setProject] = useState(null);
  const rdProjectDetailsRef = useScrollToRef();

  useEffect(() => {
    const path = window.location.pathname;
    const id = parseInt(path.split("/").pop()); // Parse the id to an integer
    const selectedProject = rdProjects.find((project) => project.id === id);
    setProject(selectedProject);
  }, []);

  const formatDescription = (desc) => {
    if (!desc) return "";

    // Convert newlines to <br/>
    let formattedDesc = desc.replace(/\n/g, "<br/>");

    // Convert pointers (e.g., "* some text") to list items
    formattedDesc = formattedDesc.replace(
      /(\* .+?)(<br\/>|\n|$)/g,
      "<li>$1</li>"
    );

    // Wrap list items in <ul> if there are any <li> elements
    if (formattedDesc.includes("<li>")) {
      formattedDesc = "<ul>" + formattedDesc + "</ul>";
    }

    return formattedDesc;
  };

  console.log(project);

  return (
    <>
      <div
        className="w-full flex-col flex gap-[4.5rem]"
        ref={rdProjectDetailsRef}
      >
        <div className="flex flex-col">
          <div className="w-full text-white h-[24rem] px-[9.06rem] pl-8 pr-44 flex flex-col anushandhan-mitra-banner-bg">
            <div className="my-auto text-white">
              <p className="heading1 font-medium">Mitra: Anushandhan Mitra</p>
            </div>
            <Breadcrumbs />
          </div>
        </div>
        <div className="flex flex-col gap-[3.12rem] mx-16">
          <div>
            <p className="text-[1.5rem] font-semibold ">
              {project && project.name}
            </p>
            <p>{project && project.shortDesc}</p>
          </div>
          <div className="flex justify-between">
            {project && project.professors && (
              <div className="w-1/2 flex gap-12">
                {project.professors.map((professor, index) => (
                  <div key={index} className="flex flex-col gap-1">
                    <p className="paragraph2 font-medium">{professor.name}</p>
                    <p className="text-[#656565] paragraph2">
                      {professor.designation}
                    </p>
                    <p className="text-[#656565] paragraph2">
                      {professor.email}
                    </p>
                    <p className="text-[#656565] paragraph2">
                      {professor.phoneNo}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <p className="text-[1.5rem] font-semibold">Project Description</p>
            {project && (
              <p
                dangerouslySetInnerHTML={{
                  __html: formatDescription(project.desc),
                }}
              />
            )}
          </div>
          {project && project.img && project.img.length > 0 && (
            <div className="flex flex-col gap-[1.62rem]">
              <p className="text-[1.5rem] font-semibold">
                Implementing Agencies
              </p>
            </div>
          )}
        </div>
      </div>

      {project && project.img && project.img.length > 0 && (
        <div className="w-full">
          {project.isOneAgency ? (
            <>
              <img src={project.img} alt={project.img} className="px-16" />
            </>
          ) : (
            <>
              <Marquee>
                <img src={project.img} alt={project.img} className="w-full" />
              </Marquee>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RDProjectDetail;
