import React from "react";
import assetsManager from "../../../assets/assetsManager";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { handleExternalLinks } from "../../../utils/utilities";
const GovtOrg = () => {
  const orgs = [
    {
      img: assetsManager.org1,
      alt: "govtOrg",
      path: "https://negd.gov.in/",
    },
    {
      img: assetsManager.org2,
      alt: "govtOrg",
      path: "https://msh.meity.gov.in/",
    },
    {
      img: assetsManager.org3,
      alt: "govtOrg",
      path: "https://www.india.gov.in/",
    },
    {
      img: assetsManager.org4,
      alt: "govtOrg",
      path: "https://data.gov.in/",

    },
    {
      img: assetsManager.org5,
      alt: "govtOrg",
      path: "https://www.mygov.in/",

    },
    {
      img: assetsManager.org6,
      alt: "govtOrg",
      path: "https://www.meity.gov.in/",
  
    },
    {
      img: assetsManager.org7,
      alt: "govtOrg",
      path: "https://dic.gov.in/",
  
    }

  ]


  return (
    <div className="w-full dark:bg-white my-[5.12rem]">
      <Marquee pauseOnHover={true} play= {true}>
        {orgs.map((org, index) => (
          <div className="mr-[6.25rem]" key={index}>
          <button onClick={()=>handleExternalLinks(org.path)} >
            <img
              loading="lazy"
              src={org.img}
              alt={org.alt}
              className="w-full "
            />
          </button>
          </div>
        ))}

       

      
      </Marquee>
    </div>
  );
};

export default GovtOrg;
