import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AVINYA_BACKEND_URL } from "../../config/config";
import { toast } from "react-toastify";

const EmailVerification = ({
  isFormSubmitted,
  setIsFormSubmitted,
  email,
  userDetails,
  handleSendOTP,
  handleClose,
}) => {
  const [otp, setOtp] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isOtpSubmitting, setIsOtpSubmitting] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  const [timer, setTimer] = useState(60); // 5 minutes = 300 seconds
  const [otpError, setOtpError] = useState(false);
  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const handleChange = (e) => {
    const value = e.target.value;
    // Allow only digits and restrict length to 6
    if (value.length <= 6 && /^[0-9]*$/.test(value)) {
      setOtp(value);
      // Set error if OTP length is less than 6
      setOtpError(value.length !== 6);
    }
  };

  // Format time in mm:ss format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Handle OTP Verification
  const handleVerifyOTP = async () => {
    try {
      setIsOtpSubmitting(true);
      const response = await axios.post(
        `${AVINYA_BACKEND_URL}/prayogshala-um/api/otp/validate?email=${email}&otp=${otp}`
      );
      if (response.data.message === "Invalid OTP.") {
        throw new Error(response?.data?.message || "Invalid OTP");
      }
      toast.success(response?.data?.message || "Email verified successfully!");
      setIsOtpVerified(true);
    } catch (err) {
      console.log(err, "OTP Verification Error");
      toast.error(err?.response?.data?.message || "OTP Verification failed!");
    } finally {
      setIsOtpSubmitting(false);
    }
  };

  const handleFormSubmit = async () => {
    const newFormData = new FormData();
    const dataObject = {
      organizationName: userDetails.organizationName,
      spocName: userDetails.spocName,
      remarks: userDetails.remarks,
      email: userDetails.email,
      mobileNo: userDetails.mobileNo,
    };
    const tempObject = new Blob([JSON.stringify(dataObject)], {
      type: "application/json",
    });
    newFormData.append("data", tempObject);
    if (userDetails.file) {
      newFormData.append("file", userDetails.file);
    }
    try {
      setIsFormSubmitting(true);
      const response = await axios.post(
        `${AVINYA_BACKEND_URL}/prayogshala-um/api/onboarding/register`,
        newFormData
      );
      toast.success(response?.data?.message || "Registration successful!");
      setIsFormSubmitted(true);
      setReferenceNo(response?.data?.result?.referenceNo);
      toast.info("Redirecting to page in 3 seconds...");
      setTimeout(() => {
        window.location.reload(); // Reload the page after 3 seconds
      }, 3000);
    } catch (err) {
      console.log(err, "err");
      toast.error(err?.response?.data?.message || "Registration failed!");
    } finally {
      setIsFormSubmitting(false);
    }
  };

  return !isFormSubmitted ? (
    <form>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                color: "#062C52",
                fontFamily: "Noto Sans",
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Verification Code
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "#646464",
                fontFamily: "Noto Sans",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
              }}
              color="primary"
            >
              {`We've sent a verification code to ${email} to verify your email address.`}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Enter OTP"
              variant="outlined"
              fullWidth
              value={otp}
              onChange={handleChange}
              error={otpError} // Show error if OTP length is not 6
              helperText={otpError ? "OTP must be exactly 6 digits" : ""}
            />
            <Typography
              component="p"
              sx={{
                color: "#8F8F8F",
                fontFamily: "Noto Sans",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                marginTop: "1.19rem",
              }}
              color="primary"
            >
              Didn’t receive any code?{" "}
              <button
                disabled={isOtpSubmitting || timer > 0}
                onClick={() => {
                  handleSendOTP(email);
                  setTimer(60); // Reset timer to 5 minutes
                }}
                style={{
                  color: isOtpSubmitting || timer > 0 ? "#A0A0A0" : "#2947A3",
                  cursor: `${
                    isOtpSubmitting || timer > 0 ? "not-allowed" : "pointer"
                  }`,
                  textDecoration: "underline",
                }}
              >
                Resend
              </button>
              {timer > 0 && (
                <span style={{ marginLeft: "0.5rem", color: "#2947A3" }}>
                  {formatTime(timer)}
                </span>
              )}
              {isOtpSubmitting && (
                <CircularProgress
                  size={15}
                  color="inherit"
                  sx={{
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!otp || isOtpSubmitting || isOtpVerified}
          sx={{
            "&:hover": {
              backgroundColor:
                !otp || isOtpSubmitting || isOtpVerified
                  ? "#EEF5FC"
                  : "#2947A3",
            },
            color: "#fff",
            backgroundColor: `${
              !otp || isOtpSubmitting || isOtpVerified ? "#EEF5FC" : "#2947A3"
            }`,
          }}
          onClick={handleVerifyOTP}
        >
          Verify OTP
        </Button>
        <Button
          disabled={isFormSubmitting || !isOtpVerified}
          sx={{
            "&:hover": {
              backgroundColor:
                isFormSubmitting || !isOtpVerified ? "#EEF5FC" : "#0671E0",
            },
            color: "#fff",
            backgroundColor: `${
              isFormSubmitting || !isOtpVerified ? "#EEF5FC" : "#0671E0"
            }`,
          }}
          onClick={handleFormSubmit}
        >
          {isFormSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
      </DialogActions>
    </form>
  ) : (
    <>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                color: "#062C52",
                fontFamily: "Noto Sans",
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Your Application request has been successfully submitted!
            </Typography>
            <Typography
              component="p"
              sx={{
                marginTop: "1rem",
                color: "#05A660",
                fontFamily: "Noto Sans",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "600",
              }}
              color="primary"
            >
              {`Reference No: ${referenceNo}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="p"
              sx={{
                color: "#8F8F8F",
                fontFamily: "Noto Sans",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                marginTop: "1rem",
              }}
              color="primary"
            >
              We have received your application. Our Bhashini team will review
              your application and will get back to you. For further updates
              please check your inbox. Your Reference ID for future
              communication is {referenceNo}.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            "&:hover": {
              backgroundColor: "#2947A3",
            },
            color: "#fff",
            backgroundColor: "#2947A3",
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default EmailVerification;
