import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import assetsManager from "../../assets/assetsManager";

const LatestUpdate = () => {
  const updates = [
    {
      link: "/sahyogi",
      text: "Be a part of Bhashini Sahyogi. Share your use cases to build a Voice-Powered Multilingual India!",
    },
  ];

  return (
    <div className="dark:text-white dark:bg-[#404148] bg-[#EEF4FF]">
      <div className=" relative w-full  flex flex-col md:flex-row justify-between items-center gap-3">
        <div className="flex gap-2 items-center w-full text-center md:w-[30%] bg-[#2F57CF] py-[1.03rem] px-[3.12rem] text-white">
          <img src={assetsManager.star} alt="updates" />
          <p className="heading3 font-medium  w-max">LATEST UPDATES</p>
        </div>

        <Marquee
          gradient={false}
          speed={40}
          pauseOnHover={true}
          className="md:px-16 md:py-[.625rem] px-4 py-2"
        >
          {updates.map((update, index) => (
            <Link
              to={update.link}
              key={index}
              className="w-full text-center m-auto flex-shrink-0 font-bold text-sm md:text-base mr-11 relative after:content-['|'] after:ml-4 after:text-black after:dark:text-white"
              style={{
                marginRight: index !== updates.length - 1 ? "11px" : "",
              }}
            >
              {update.text}
            </Link>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default LatestUpdate;
