import React from "react";

const InternationalMotherLanguagesDay = () => {
  const images = [
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5220.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5265.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5295.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5309.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5317.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5341.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5368.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5393.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5442.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5488.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5523.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5531.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5555.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5573.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5576.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5586.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5596.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5637.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5644.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5650.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5668.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5698.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5709.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5716.JPG",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/Mother Language Day/JMD_5724.JPG",
  ];
  return (
    <div className="flex flex-wrap gap-2 mx-16">
      {images.map((image, index) => (
        <div key={index} className="w-1/2 md:w-1/4 h-1/2">
          <a href={image} data-fancybox="gallery" className="block h-full">
            <img src={image}  className="h-full" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default InternationalMotherLanguagesDay;
