import React, { useRef } from "react";
import assetsManager from "../../assets/assetsManager";
import { useTranslation } from "react-i18next";
import { Globalstate } from "../../context/context";

const WhatWeDo = () => {
  const {
    state: { isDarkMode },
  } = Globalstate();
  const { t } = useTranslation();
  const ref = useRef();
  const data = [
    {
      title: t("homePage.whatWeDo.cards.card1.title"),
      description: t("homePage.whatWeDo.cards.card1.content"),
      image: assetsManager.educationBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card2.title"),
      description: t("homePage.whatWeDo.cards.card2.content"),
      image: assetsManager.healthBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card3.title"),
      description: t("homePage.whatWeDo.cards.card3.content"),
      image: assetsManager.agricultureBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card4.title"),
      description: t("homePage.whatWeDo.cards.card4.content"),
      image: assetsManager.publicServices,
    },
    {
      title: t("homePage.whatWeDo.cards.card5.title"),
      description: t("homePage.whatWeDo.cards.card5.content"),
      image: assetsManager.banksAndFinancial,
    },
    {
      title: t("homePage.whatWeDo.cards.card6.title"),
      description: t("homePage.whatWeDo.cards.card6.content"),
      image: assetsManager.ecommerceBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card7.title"),
      description: t("homePage.whatWeDo.cards.card7.content"),
      image: assetsManager.lawEnforcement,
    },
    {
      title: t("homePage.whatWeDo.cards.card8.title"),
      description: t("homePage.whatWeDo.cards.card8.content"),
      image: assetsManager.manufacturingBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card9.title"),
      description: t("homePage.whatWeDo.cards.card9.content"),
      image: assetsManager.retailBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card10.title"),
      description: t("homePage.whatWeDo.cards.card10.content"),
      image: assetsManager.judiciaryBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card11.title"),
      description: t("homePage.whatWeDo.cards.card11.content"),
      image: assetsManager.transportationBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card12.title"),
      description: t("homePage.whatWeDo.cards.card10.content"),
      image: assetsManager.goverenanceBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card13.title"),
      description: t("homePage.whatWeDo.cards.card13.content"),
      image: assetsManager.entertainmentBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card14.title"),
      description: t("homePage.whatWeDo.cards.card14.content"),
      image: assetsManager.itServicesBg,
    },
    {
      title: t("homePage.whatWeDo.cards.card15.title"),
      description: t("homePage.whatWeDo.cards.card15.content"),
      image: assetsManager.oilAndNaturalGasBg,
    },
    {
      title: "Tourism",
      description:
        "What if you could foster better communication, enrich experiences and make travel more accessible for everyone?. While travelling , what if you don’t feel like you’re on different city/place due to language barriers.",
      image: assetsManager.tourismBg,
    },
   
  ];

  

  return (
    <div className="flex flex-col gap-1">
      <div className="2xl:container">
        <div className="flex flex-col md:mt-[6.25rem] mt-[3.13rem] md:mx-16 mx-4 items-center justify-center gap-2">
          <p className="heading1 font-bold text-primary">
            What We Do
          </p>
          <p className="heading3 font-semibold text-center">
            Reimagining the User’s Journey using Multilingual Voice as a medium
          </p>
          <p className="heading3 font-medium text-center">
            BHASHINI aims to transcend language barriers, ensuring that every
            citizen can effortlessly access digital services in their own
            language. Using voice as a medium, BHASHINI has the potential to
            bridge language as well as the digital divide. Launched by Honourable
            PM Shri Narendra Modi in July 2022 under the National Language
            Technology Mission, BHASHINI aims to provide technology translation
            services in 22 scheduled Indian languages.
          </p>

          
        </div>
      </div>
      <p className="text-[1.375rem] font-semibold m-auto mt-6 z-[2]">
          AI for National Integration
          </p>
      <div className="text-white relative mt-[-3%]">
    
        {isDarkMode ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1930 123"
            className="absolute top-0 left-0 z-[1]"
          >
            <path
              d="M1930 -4.57764e-05L1930 23.1122C1804.39 56.4082 1435.3 123 963.792 123C492.283 123 124.802 56.4083 -8.73248e-06 23.1124L-1.0753e-05 0.00012295L1930 -4.57764e-05Z"
              fill="#404148"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1930 123"
            fill="blue"
            className="absolute top-0 left-0 z-[1]"
          >
            <path
              d="M1930 -4.57764e-05L1930 23.1122C1804.39 56.4082 1435.3 123 963.792 123C492.283 123 124.802 56.4083 -8.73248e-06 23.1124L-1.0753e-05 0.00012295L1930 -4.57764e-05Z"
              fill="white"
            />
          </svg>
        )}
        <div className="scrollable-2xl:container relative">
          <div className="scrollable flex gap-4">
            {[...data,...data].map((item, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${item.image})`,
                }}
                className="flex flex-col gap-[.75rem] bg-no-repeat bg-center justify-center items-center w-[21rem] px-9 py-[9.25rem] min-w-[21rem] bg-cover"
              >
                <p className="heading2 font-medium text-center">{item.title}</p>
                <p className="paragraph1 text-center">{item.description}</p>
              </div>
            ))}
            <div ref={ref} className="w-0 h-0"></div>
          </div>
        </div>
        {isDarkMode ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1930 124"
            fill="none"
            className="absolute bottom-0 right-0 z-[1]"
          >
            <path
              d="M4.41015e-06 124L0 104.356C125.607 69.5705 494.698 -0.000427246 966.208 -0.000427246C1437.72 -0.000427246 1805.2 69.5705 1930 104.356V124L4.41015e-06 124Z"
              fill="#404148"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1930 124"
            fill="none"
            className="absolute bottom-[-1%] right-0 z-[1]"
          >
            <path
              d="M4.41015e-06 124L0 104.356C125.607 69.5705 494.698 -0.000427246 966.208 -0.000427246C1437.72 -0.000427246 1805.2 69.5705 1930 104.356V124L4.41015e-06 124Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default WhatWeDo;
