import React from "react";
import samudayeVideo from "../../assets/video/samudayeVideo.mp4";
const AboutSamudaye = () => {
  return (
    <div className="xl:container">
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col gap-9 sm:w-3/5 w-full">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-[1.125rem] text-[#3a4a3c] font-normal">
                ABOUT
              </p>
              <p className="text-[1.75rem] text-[#0b1941] font-medium uppercase">
                Bhashini Samudaye | Linguistic Specialist
              </p>
            </div>
            <p className="heading3 text-[#3A3A3C]">
              Become a member of the Bhashini Samudaye and lend your expertise
              to meaningful initiatives that make a real impact. Contribute your
              skills while gaining invaluable experience, advancing your
              professional journey, and building a global network of recognition
              and connections.
            </p>
          </div>
          <p className="text-[#3a3a3c] font-medium">
            Register now and stay tuned for more!
          </p>
        </div>
        <div className="w-2/5">
          <video
            className="w-full"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          >
            <source src={samudayeVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default AboutSamudaye;
