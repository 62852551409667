import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className=" footer ">
      <div className="container text-white justify-between flex w-full flex-col md:flex-row gap-[1.88rem] md:gap-2 px-4 py-[3.75rem] md:py-[3.75rem] md:px-[3.75rem]">

      <div className="flex flex-col gap-2">
        <p className="heading3 font-medium">Need more information?</p>
        <p className="heading3">
        Write your concern to us, and we will respond back to you.
        </p>
      </div>
      <div>
        <Link
          to="/connect"
          className="w-max px-9 py-[0.88rem] text-center bg-primary rounded leading-10"
        >
          Contact Us
        </Link>
      </div>
      </div>
    </div>
  );
};

export default ContactUs;
