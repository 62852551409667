import React from "react";
import assetsManager from "../../assets/assetsManager";
import { useTranslation } from "react-i18next";

const HomeSummary = () => {
  const { t } = useTranslation();
  const summaryKPIS = [
    {
      title: t("languageSupported"),
      value: "22+",
      icon: assetsManager.languageSupported,
    },
    {
      title: "Languages Services",
      value: "15+",
      icon: assetsManager.totalServices,
    },
    {
      title: "Total Customer",
      value: "100+",
      icon: assetsManager.customers,
    },
    {
      title: "AI Models",
      value: "300+",
      icon: assetsManager.aiModels,
    },
    {
      title: "Inferencing Made So far",
      value: "100+ Million",
      icon: assetsManager.trends,
    },
    {
      title: "Bhashini Mobile App Downloads",
      value: "500K+",
      icon: assetsManager.appDownloads,
    },
  ];

  return (
    <div className="bg-primaryDark2 flex flex-col gap-4 px-4 md:px-16 py-[1.5rem] md:py-[2.81rem]">
      <div className="2xl:container grid grid-cols-1 md:grid-cols-3 gap-12 text-white">
        {summaryKPIS.map((item, index) => (
          <div key={index} className="flex gap-[1.125rem] w-full">
            <img
              src={item.icon}
              alt="icon"
              loading="lazy"
              height={64}
              width={64}
            />
            <div className="flex flex-col gap-1 justify-center">
              <p className="heading1 font-bold">{item.value}</p>
              <p className="heading3 font-medium">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeSummary;
