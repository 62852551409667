import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React from "react";
import FormField from "./FormField";
import { stateName } from "../../AppConstants";

const PersonalInfo = ({
  formData,
  errors,
  handleChange,
  isCheckedBox,
  setIsCheckbox,
}) => {
  console.log("isCheckedBox", isCheckedBox);
  const handleCheckbox = (e) => {
    console.log("e.target.checked", e.target.checked);
    setIsCheckbox(e.target.checked);
  };

  return (
    <Grid container sx={{ gap: "1.12rem" }}>
      <FormField
        isRequired={true}
        error={errors?.user_type}
        helperText={errors?.user_type}
        value={formData.user_type}
        onChange={handleChange}
        name="user_type"
        label="User Type"
        placeholder="User Type"
        isSelect
        options={[
          { value: "Individual", label: "Individual" },
          { value: "Organisation", label: "Organisation" },
        ]}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormField
            isRequired={true}
            error={errors?.first_name}
            helperText={errors?.first_name}
            value={formData.first_name}
            onChange={handleChange}
            name="first_name"
            label="First Name"
            type="text"
            placeholder="Enter your first name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            isRequired={true}
            error={errors?.last_name}
            helperText={errors?.last_name}
            value={formData.last_name}
            onChange={handleChange}
            name="last_name"
            label="Last Name"
            type="text"
            placeholder="Enter your last name"
          />
        </Grid>
      </Grid>
      <FormField
        isRequired={true}
        error={errors?.email}
        helperText={errors?.email}
        value={formData.email}
        name="email"
        onChange={handleChange}
        label="Primary Email"
        type="email"
        placeholder="Enter your primary email"
      />

      <FormField
        isRequired={true}
        error={errors?.phone_number}
        helperText={errors?.phone_number}
        value={formData.phone_number}
        onChange={handleChange}
        name="phone_number"
        label="Phone Number"
        type="tel"
        placeholder="Enter your phone number"
      />

      <FormField
        isRequired={true}
        error={errors?.state}
        helperText={errors?.state}
        value={formData.state}
        onChange={handleChange}
        options={stateName}
        name="state"
        label="State"
        placeholder="Select State"
        isSelect
      />
      <Grid item sm={12}>
        <FormGroup>
          <FormControlLabel
            required
            onChange={handleCheckbox}
            name="isCheckedBox"
            control={<Checkbox />}
            value={isCheckedBox}
            label="I've read and agree with your Terms of Services"
          />
        </FormGroup>
        {isCheckedBox === false && (
          <div style={{ color: "red", fontSize: "0.75rem" }}>
            Please agree with the terms of services
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
