import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import assetsManager from "../../assets/assetsManager";
import { handleExternalLinks } from "../../utils/utilities";

const ReadMoreOverlay = ({ title }) => (
  <div className="absolute bottom-0 left-0 w-full flex items-center bg-black bg-opacity-60 text-white cursor-pointer px-5 rounded-bl-xl rounded-br-xl h-[2.5rem]">
    <p className="truncate w-full" title={title}>
      {title}
    </p>
  </div>
);

const ImageCard = ({ src, imgSrc, alt, className, title }) => (
  <button
    onClick={() => handleExternalLinks(src)}
    className={`relative rounded-xl ${className}`}
  >
    <img src={imgSrc} alt={alt} className="rounded-xl h-full w-full" />
    <ReadMoreOverlay title={title} />
  </button>
);

const Featured = () => {
  const featuredRef = useScrollToRef();

  return (
    <div className="flex flex-col gap-9 w-full h-full" ref={featuredRef}>
      <div className="flex flex-col gap-4">
        <div>
          <p className="heading1 font-bold text-[#242424]">Featured</p>
          <p className="heading3">Bhashini in the spotlight</p>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col gap-4 h-full">
        <div className="sm:w-[65%] w-full h-full flex flex-col gap-9">
          <ImageCard
            src="https://www.hindustantimes.com/technology/pm-modi-uses-ai-tool-bhashini-at-kashi-tamil-sangamam-in-varanasi-what-is-it-101702837254702.html"
            imgSrc={assetsManager.kashiTamil1}
            alt="kashiTamil1"
            className="h-1/2"
            title="PM Modi uses AI-tool ‘Bhashini’ at Kashi Tamil Sangamam in Varanasi. What is it?"
          />
          <div className="flex sm:flex-row flex-col w-full h-1/2 gap-9">
            <ImageCard
              imgSrc={assetsManager.newsThumbnail10}
              alt="kashiTamil1"
              className="sm:w-1/2 w-full"
              title="AI technology can be implemented in courts to make lives easier for the common people: PM Modi"
              src="https://government.economictimes.indiatimes.com/news/governance/ai-technology-can-be-implemented-in-courts-to-make-lives-easier-for-the-common-people-pm-modi/107213719"
            />
            <ImageCard
              imgSrc={assetsManager.newsThumbnail5}
              alt="kashiTamil1"
              className="sm:w-1/2 w-full"
              title="What is ‘Bhashini’, the AI-powered tool that translated PM Narendra Modi’s speech to Tamil?"
              src="https://www.firstpost.com/explainers/what-is-bhashini-the-ai-powered-tool-that-translated-pm-narendra-modis-speech-to-tamil-13516022.html"
            />
          </div>
        </div>
        <div className="sm:w-[35%] w-full flex flex-col h-full gap-9">
          <ImageCard
            imgSrc={assetsManager.newsThumbnail6}
            alt="kashiTamil1"
            className="h-1/3 w-full"
            title="What is Bhashini: PM मोदी ने हिंदी में की तमिल जनता से बात, भाषण में पहली बार AI का इस्तेमाल"
            src="https://www.livehindustan.com/national/story-what-is-bhashini-ai-tool-artificial-intelligence-pm-narendra-modi-in-varanasi-kashi-tamil-sangamam-9082480.html"
          />
          <ImageCard
            imgSrc={assetsManager.newsThumbnail3}
            alt="kashiTamil1"
            className="h-1/3"
            title="Bhashini: Everything you need to know about AI language translation tool"
            src="https://www.business-standard.com/technology/tech-news/bhashini-everything-you-need-to-know-about-ai-language-translation-tool-123121800769_1.html"
          />
          <ImageCard
            imgSrc={assetsManager.newsThumbnail4}
            alt="kashiTamil1"
            className="h-1/3"
            title="Mann ki Baat: PM Modi highlights mental health, AI tool 'Bhashini' on his monthly radio programme"
            src="https://economictimes.indiatimes.com/news/india/mann-ki-baat-pm-modi-highlights-mental-health-ai-tool-bhashini-on-his-monthly-radio-programme/videoshow/106416564.cms?from=mdr"
          />
        </div>
      </div>
    </div>
  );
};

export default Featured;
