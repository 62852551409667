import meityLogo from "./images/meityLightLogo.png";
import bhashiniDarkLogo from "./images/bhashiniDarkLogo.png";
import sprintBanner from "./images/startup-velocity/sprintBanner.png";
import digitalIndiaLogo from "./images/digitalIndiaLogo.png";
import activeUsers from "./images/activeUsers.svg";
import totalServices from "./images/totalServices.svg";
import appDownloads from "./images/appDownloads.svg";
import languageSupported from "./images/languageSupported.svg";
import homeCarousel1 from "./images/homeCarousel1.png";
import dotIcon from "./images/dotIcon.svg";
import boloSunoIndia from "./images/boloSunoIndia.png";
import playStore from "./images/Google Play.png";
import appStore from "./images/appStore.png";
import mobileBannerImg from "./images/mobileBannerImg.webp";
import playStoreQr from "./images/playStoreQr.png";
import appStoreQr from "./images/appStoreQr.png";
import bhashiniLogo from "./images/bhashiniLogo.png";
import govtOrg from "../assets/images/govtOrg.png";
import asrIcon from "./images/asrIcon.png";
import ocrIcon from "./images/ocrIcon.png";
import ttsIcon from "./images/ttsIcon.png";
import nmtIcon from "./images/nmtIcon.png";
import vadIcon from "./images/vadIcon.png";
import transliterationIcon from "./images/transliterationIcon.png";
import tldIcon from "./images/tldIcon.png";
import aldIcon from "./images/aldIcon.png";
import itnIcon from "./images/itnIcon.png";
import punctuationIcon from "./images/punctuationIcon.png";
import profanityIcon from "./images/profanityIcon.png";
import denoiserIcon from "./images/denoiserIcon.png";
import nerIcon from "./images/nerIcon.png";
import gcIcon from "./images/gcIcon.png";
import speakerdetectionIcon from "./images/speakerdetectionIcon.png";
import glossaryIcon from "./images/glossaryIcon.png";
import tnIcon from "./images/tnIcon.png";
import speakerderiazationIcon from "./images/speakerderiazationIcon.png";
import bhashiniCircleLogo from "./images/bhashiniCircleLogo.svg";
import bullsEye from "./images/bullsEye.png";
import wave1 from "./images/bhashniEcoSystem/waves.png";
import wave2 from "./images/bhashniEcoSystem/wave2.png";
import banner1RightImg from "./images/aboutPage/banner1RightImg.webp";
import guidingPrinciples from "./images/aboutPage/guidingPrinciples.png";
import buildingBlocks from "./images/aboutPage/buildingBlocks.png";
import ceoImg from "./images/aboutPage/ceoImg.webp";
import rectangle from "./images/aboutPage/rectangle.png";
import semiRectangle from "./images/aboutPage/semiRectangle.png";
import productManagerImg from "./images/aboutPage/productManager.png";
import onboardManager1 from "./images/aboutPage/onboardManager1.png";
import onboardManager2 from "./images/aboutPage/onboardManager2.png";
import onboardManager3 from "./images/aboutPage/onboardManager3.png";
import executiveHr from "./images/aboutPage/executiveHr.png";
import multimediaManager from "./images/aboutPage/multimediaManager.png";
import multimediaAssistantManager from "./images/aboutPage/multimediaAssistantManager.png";
import personalAssistanceCeo from "./images/aboutPage/personalAssistanceCeo.png";
import financeManager from "./images/aboutPage/financeManager.png";
import collaborativeEcosystem2 from "./images/CollaborativeEcosystem/collaborativeEcosystem2.webp";
import collaborativeEcosystem3 from "./images/CollaborativeEcosystem/collaborativeEcosystem3.webp";
import collaborativeEcosystem4 from "./images/CollaborativeEcosystem/collaborativeEcosystem4.webp";
import collaborativeEcosystem5 from "./images/CollaborativeEcosystem/collaborativeEcosystem5.webp";
import collaborativeEcosystem6 from "./images/CollaborativeEcosystem/collaborativeEcosystem6.webp";
import collaborativeEcosystem7 from "./images/CollaborativeEcosystem/collaborativeEcosystem7.webp";
import collaborativeEcosystem8 from "./images/CollaborativeEcosystem/collaborativeEcosystem8.webp";
import aboutBanner2 from "./images/aboutPage/banner2RightImg.webp";
import aboutBanner3 from "./images/aboutPage/banner3RightImg.webp";
import agricultureBg from "./images/whatWeDo/agricultureBg.webp";
import banksAndFinancial from "./images/whatWeDo/banksAndFinancial.webp";
import ecommerceBg from "./images/whatWeDo/ecommerceBg.webp";
import educationBg from "./images/whatWeDo/educationBg.webp";
import entertainmentBg from "./images/whatWeDo/entertainmentBg.webp";
import goverenanceBg from "./images/whatWeDo/governanceBg.webp";
import lawEnforcement from "./images/whatWeDo/lawEnforcement.webp";
import healthBg from "./images/whatWeDo/healthBg.webp";
import itServicesBg from "./images/whatWeDo/itServicesBg.webp";
import judiciaryBg from "./images/whatWeDo/judiciaryBg.webp";
import manufacturingBg from "./images/whatWeDo/manufacturingBg.webp";
import oilAndNaturalGasBg from "./images/whatWeDo/oilAndNaturalGasBg.webp";
import publicServices from "./images/whatWeDo/publicServices.webp";
import retailBg from "./images/whatWeDo/retailBg.webp";
import transportationBg from "./images/whatWeDo/transportationBg.webp";
import linkedInLogo from "./images/linkedInLogo.png";
import ald from "./images/arpan/ald.png";
import searchIcon from "./images/arpan/searchIcon.svg";
import tickIcon from "./images/arpan/tickIcon.svg";
import createAccountIcon from "./images/arpan/createAccountIcon.svg";
import fillApplicationIcon from "./images/arpan/fillApplicationIcon.svg";
import anuvaad from "./images/arpan/anuvaad.webp";
import chitra from "./images/arpan/chitra@4x-8 (1).webp";
import lekhaanuvaad from "./images/arpan/lekhaanuvaad.webp";
import plugin from "./images/arpan/plugin.webp";
import twitterLogo from "./images/twitterLogo.png";
import tourismBg from "./images/tourismBg.png";
import latestUpdatesLeftButton from "./images/latestUpdatesLeftButton.svg";
import latestUpdatesRightButton from "./images/latestUpdatesRightButton.svg";
import sahyogiBannerRightImg from "./images/sahyogi/sahyogiBannerRightImg.webp";
import sahyogiCarousel1 from "./images/sahyogi/sahyogiCarousel1.webp";
import sahyogiCarousel2 from "./images/sahyogi/sahyogiCarousel2.webp";
import sahyogiCarousel3 from "./images/sahyogi/sahyogiCarousel3.webp";
import bhashiniStartup from "./images/sahyogi/bhashiniStartup.png";
import sahyogiAppMitra from "./images/sahyogi/sahyogiAppMitra.png";
import sahyogiDataMitra from "./images/sahyogi/sahyogiDataMitra.png";
import sahyogiGyanMitra from "./images/sahyogi/sahyogiGyanMitra.png";
import sahyogiAnushandhanMitra from "./images/sahyogi/sahyogiAnushandhanMitra.png";
import sachalakCarousel1 from "./images/sanchalak/sachalakCarousel1.png";
import sachalakCarousel2 from "./images/sanchalak/sachalakCarousel2.png";
import agencies1 from "./images/sahyogi/anushandhanMitra/agencies - 1.png";
import agencies2 from "./images/sahyogi/anushandhanMitra/agencies - 2.png";
import agencies3 from "./images/sahyogi/anushandhanMitra/agencies - 3.png";
import agencies4 from "./images/sahyogi/anushandhanMitra/agencies - 4.png";
import agencies5 from "./images/sahyogi/anushandhanMitra/agencies - 5.png";
import agencies6 from "./images/sahyogi/anushandhanMitra/agencies - 6.png";
import agencies7 from "./images/sahyogi/anushandhanMitra/agencies - 7.png";
import agencies8 from "./images/sahyogi/anushandhanMitra/agencies - 8.png";
import agencies9 from "./images/sahyogi/anushandhanMitra/agencies - 9.png";
import agencies10 from "./images/sahyogi/anushandhanMitra/agencies - 10.png";
import agencies12 from "./images/sahyogi/anushandhanMitra/agencies - 12.png";
import agencies13 from "./images/sahyogi/anushandhanMitra/agencies - 13.png";
import agencies14 from "./images/sahyogi/anushandhanMitra/agencies - 14.png";
import agencies15 from "./images/sahyogi/anushandhanMitra/agencies - 15.png";
import setuBhashinisprint from "./images/sanchalak/setuBhashinisprint.png";
import setuBhashiniTecho from "./images/sanchalak/setuBhashiniTecho.png";
import setuBhashiniStartupVelocity from "./images/sanchalak/setuBhashiniStartupVelocity.png";
import setuOndc from "./images/sanchalak/setuOndc.png";
import setuGrandInnovation from "./images/sanchalak/setuGrandInnovation.png";
import prayogOnboardingImg from "./images/prayog/prayogOnboardingImg.png";
import bharatPi from "./images/velocity/bharatPi.png";
import docTunes from "./images/velocity/docTunes.png";
import kiffer from "./images/velocity/kiffer.png";
import openAi from "./images/velocity/openAi.png";
import saddleHq from "./images/velocity/saddleHq.png";
import truper from "./images/velocity/truper.png";
import aadalatAi from "./images/velocity/aadalatAi.png";
import canebot from "./images/velocity/canebot.png";
import gnani from "./images/velocity/gnani.png";
import pulayatsaAi from "./images/velocity/pulayatsaAi.png";
import celestia from "./images/velocity/celestia.png";
import banner1Bsv from "./images/velocity/Banner 1-bsv.png";
import ceoSpeaks from "./images/pravakta/ceoSpeaks.png";
import bhashiniNews from "./images/pravakta/bhashiniNews.png";
import awardsAndAchivements from "./images/pravakta/awardsAndAchivements.png";
import bhashiniEvents from "./images/pravakta/bhashiniEvents.png";
import bhashiniGIC from "./images/startups/bhashiniGIC.png";
import ondc from "./images/startups/ondc.png";
import sprintLogo from "./images/startups/sprintLogo.png";
import sprintLogo1 from "./images/startup-velocity/sprintLogo.png";
import techathon from "./images/startups/techathon.png";
import startupVelocityLogo from "./images/startups/startupVelocityLogo.png";
import dicLogo from "./images/dicLogo.png";
import bhashaLoader from "./images/bhashaLoader.gif";
import ApplicationProcedures from "./images/arpan/ApplicationProcedures.png";
import bsvAbout from "./video/bsvBanner.mp4";
import confettiGif from "./images/startups/confettiGif.gif";
import linkedInFullLogo from "./images/linkedInFullLogo.png";
import twitterFullLogo from "./images/twitterFullLogo.png";
import instagramFullLogo from "./images/instagramFullLogo.png";
import homeBanner1 from "./images/homeBanner1.webp";
import homeBanner2 from "./images/homeBanner2.webp";
import homeBanner3 from "./images/homeBanner3.webp";
import homeBanner4 from "./images/homeBanner4.webp";
import homeBanner5 from "./images/homeBanner5.webp";
import vanianuvaad from "./images/arpan/vanianuvaad.webp";
import rectangleMobile from "./images/aboutPage/rectangleMobile.png";
import semiRectangleMobile from "./images/aboutPage/semiRectangleMobile.png";
import applicationProceduresMobile from "./images/arpan/applicationProceduresMobile.png";
import arrow from "./images/icons/arrow.svg";
import award1_1 from "./images/Awards/award1/award1_1.jpeg";
import award1_2 from "./images/Awards/award1/award1_2.jpeg";
import award1_3 from "./images/Awards/award1/award1_3.jpeg";
import award1_4 from "./images/Awards/award1/award1_4.jpeg";
import award1_5 from "./images/Awards/award1/award1_5.jpeg";
import award1_6 from "./images/Awards/award1/award1_6.jpeg";
import award1_7 from "./images/Awards/award1/award1_7.jpeg";
import award1_8 from "./images/Awards/award1/award1_8.jpeg";
import award1_9 from "./images/Awards/award1/award1_9.jpeg";
import award1_10 from "./images/Awards/award1/award1_10.jpeg";
import award1_11 from "./images/Awards/award1/award1_11.jpeg";
import award1_12 from "./images/Awards/award1/award1_12.jpeg";
import award1_13 from "./images/Awards/award1/award1_13.jpeg";
import award1_14 from "./images/Awards/award1/award1_14.jpeg";
import award1_15 from "./images/Awards/award1/award1_15.jpeg";
import award2_1 from "./images/Awards/award2/award2_1.jpeg";
import award2_2 from "./images/Awards/award2/award2_2.jpeg";
import award2_3 from "./images/Awards/award2/award2_3.jpeg";
import award2_4 from "./images/Awards/award2/award2_4.jpeg";
import award2_5 from "./images/Awards/award2/award2_5.jpeg";
import award2_6 from "./images/Awards/award2/award2_6.jpeg";
import award2_7 from "./images/Awards/award2/award2_7.jpeg";
import award2_8 from "./images/Awards/award2/award2_8.jpeg";
import award2_9 from "./images/Awards/award2/award2_9.jpeg";
import award2_10 from "./images/Awards/award2/award2_10.jpeg";
import IL01 from "./images/Awards/award3/IL01.jpeg";
import IL02 from "./images/Awards/award3/IL02.jpeg";
import IL03 from "./images/Awards/award3/IL03.jpeg";
import IL04 from "./images/Awards/award3/IL04.jpeg";
import IL05 from "./images/Awards/award3/IL05.jpeg";
import IL06 from "./images/Awards/award3/IL06.jpeg";
import IL07 from "./images/Awards/award3/IL07.jpeg";
import IL08 from "./images/Awards/award3/IL08.jpeg";
import IL09 from "./images/Awards/award3/IL09.jpeg";
import IL10 from "./images/Awards/award3/IL10.jpeg";
import IL11 from "./images/Awards/award3/IL11.jpeg";
import IL12 from "./images/Awards/award3/IL12.jpeg";
import IL13 from "./images/Awards/award3/IL13.jpeg";
import IL14 from "./images/Awards/award3/IL14.jpeg";
import IL15 from "./images/Awards/award3/IL15.jpeg";
import agencies11 from "./images/sahyogi/anushandhanMitra/agencies - 11.png";
import meityDarkLogo from "./images/meityDarkLogo.png";
import rightArrow from "./images/icons/rightArrow.svg";
import star from "./images/icons/star.svg";
import velocityLogo from "./images/startup-velocity/logo.png";
import adalat from "./images/startup-velocity/runnerups/adalat.svg";
import canbot from "./images/startup-velocity/runnerups/canbot.svg";
import celestia1 from "./images/startup-velocity/runnerups/celestial.svg";
import doctune1 from "./images/startup-velocity/runnerups/doctune1.svg";
import gnani1 from "./images/startup-velocity/runnerups/gnani.svg";
import pulasya from "./images/startup-velocity/runnerups/pulasya.svg";
import customers from "./images/customers.svg";
import trends from "./images/trends.svg";
import aiModels from "./images/aiModels.svg";
import org1 from "./images/govtOrg/org1.png";
import org2 from "./images/govtOrg/org2.png";
import org3 from "./images/govtOrg/org3.png";
import org4 from "./images/govtOrg/org4.png";
import org5 from "./images/govtOrg/org5.png";
import org6 from "./images/govtOrg/org6.png";
import org7 from "./images/govtOrg/org7.png";
import managerHr from "./images/aboutPage/managerHr.jpg";
import noOpenings from "./images/arpan/noOpenings.png";
import sahyogiTranslationServiceProviders from "./images/sahyogi/sahyogiTranslationServiceProviders.png";
import budget2024 from "./images/pravakta/Banner.png";
import assistantManagerAdministration from "./images/aboutPage/assistantManager(administration).jpg";
import technicalManager from "../assets/images/aboutPage/technicalManager.jpg";
import nandanKumar from "./images/aboutPage/nandanKumar.jpg";
import pravaktaBanner2bg from "../assets/images/pravakta/pravaktaBanner2bg.png";
import pravaktaBanner3bg from "../assets/images/pravakta/pravaktaBanner3Bg.png";
import pravaktaBanner2Img from "./images/pravakta/pravaktaBanner2Img.png";
import pravaktaBanner3Img from "./images/pravakta/pravaktaBanner3Img.png";
import kashiTamil1 from "./images/pravakta/featured/kashi tamil 1.png";
import trailBlazer1 from "./images/pravakta/Awards/trailBlazer1.png";
import impactAwards from "./images/pravakta/Awards/impactAwards.png";
import adoptionInAi from "./images/pravakta/Awards/adoptionInAi.png";
import samudayeBg from "./images/sahyogi/samudayeBg.png";
import leftArrow from "./images/icons/leftArrow.svg";
import newsThumbnail1 from "./images/pravakta/news/newsThumbnail1.png";
import newsThumbnail2 from "./images/pravakta/news/newsThumbnail2.png";
import newsThumbnail3 from "./images/pravakta/news/newsThumbnail3.png";
import newsThumbnail4 from "./images/pravakta/news/newsThumbnail4.png";
import newsThumbnail5 from "./images/pravakta/news/newsThumbnail5.png";
import newsThumbnail6 from "./images/pravakta/news/newsThumbnail6.png";
import newsThumbnail7 from "./images/pravakta/news/newsThumbnail7.png";
import newsThumbnail8 from "./images/pravakta/news/newsThumbnail8.png";
import newsThumbnail9 from "./images/pravakta/news/newsThumbnail9.png";
import newsThumbnail10 from "./images/pravakta/news/newsThumbnail10.png";
import newsThumbnail11 from "./images/pravakta/news/newsThumbnail11.png";
import newsThumbnail12 from "./images/pravakta/news/newsThumbnail12.png";
import eletsAtmaNirbhar from "./images/pravakta/Awards/eletsAtmaNirbhar.png";
import eletsEducation from "./images/pravakta/Awards/eletsEducation.png";
import eletsTechnology from "./images/pravakta/Awards/eletsTechnology.png";
import webtranslationplugin from "./images/arpan/webtranslationplugin.png";
import img1 from "./images/pravakta/workshop/img1.png";
import img2 from "./images/pravakta/workshop/img2.png";
import img3 from "./images/pravakta/workshop/img3.png";
import img4 from "./images/pravakta/workshop/img4.png";
import img5 from "./images/pravakta/workshop/img5.png";
import img6 from "./images/pravakta/workshop/img6.png";
import img7 from "./images/pravakta/workshop/img7.png";
import img8 from "./images/pravakta/workshop/img8.png";
import database from "./images/icons/database.svg";
import dottedVerticalLines from "./images/icons/dottedVerticalLines.svg";
import groups from "./images/icons/groups.svg";
import summarize from "./images/icons/summarize.svg";
import aboutAvinya from "./images/prayog/aboutAvinya.png";
import googleLogo from "./images/googleLogo.png";
const assetsManager = {
  meityLogo,
  digitalIndiaLogo,
  activeUsers,
  totalServices,
  appDownloads,
  languageSupported,
  homeCarousel1,
  dotIcon,
  boloSunoIndia,
  playStore,
  appStore,
  mobileBannerImg,
  playStoreQr,
  appStoreQr,
  govtOrg,
  asrIcon,
  ocrIcon,
  ttsIcon,
  nmtIcon,
  vadIcon,
  transliterationIcon,
  tldIcon,
  aldIcon,
  itnIcon,
  punctuationIcon,
  profanityIcon,
  denoiserIcon,
  nerIcon,
  gcIcon,
  speakerdetectionIcon,
  glossaryIcon,
  tnIcon,
  speakerderiazationIcon,
  bhashiniCircleLogo,
  bullsEye,
  wave1,
  wave2,
  banner1RightImg,
  guidingPrinciples,
  buildingBlocks,
  ceoImg,
  rectangle,
  semiRectangle,
  productManagerImg,
  onboardManager1,
  onboardManager2,
  onboardManager3,
  multimediaManager,
  multimediaAssistantManager,
  personalAssistanceCeo,
  financeManager,
  collaborativeEcosystem2,
  collaborativeEcosystem3,
  collaborativeEcosystem4,
  collaborativeEcosystem5,
  collaborativeEcosystem6,
  collaborativeEcosystem7,
  collaborativeEcosystem8,
  aboutBanner2,
  aboutBanner3,
  agricultureBg,
  banksAndFinancial,
  ecommerceBg,
  educationBg,
  entertainmentBg,
  goverenanceBg,
  lawEnforcement,
  healthBg,
  itServicesBg,
  judiciaryBg,
  manufacturingBg,
  oilAndNaturalGasBg,
  publicServices,
  retailBg,
  transportationBg,
  linkedInLogo,
  ald,
  searchIcon,
  tickIcon,
  createAccountIcon,
  fillApplicationIcon,
  anuvaad,
  chitra,
  lekhaanuvaad,
  vanianuvaad,
  plugin,
  twitterLogo,
  tourismBg,
  latestUpdatesLeftButton,
  latestUpdatesRightButton,
  sahyogiBannerRightImg,
  sahyogiCarousel1,
  sahyogiCarousel2,
  sahyogiCarousel3,
  sahyogiAnushandhanMitra,
  sahyogiAppMitra,
  sahyogiDataMitra,
  sahyogiGyanMitra,
  bhashiniStartup,
  sachalakCarousel1,
  sachalakCarousel2,
  agencies1,
  agencies2,
  agencies3,
  agencies4,
  agencies5,
  agencies6,
  agencies7,
  agencies8,
  agencies9,
  agencies10,
  setuBhashinisprint,
  setuBhashiniTecho,
  setuOndc,
  setuBhashiniStartupVelocity,
  setuGrandInnovation,
  executiveHr,
  prayogOnboardingImg,
  bharatPi,
  docTunes,
  kiffer,
  openAi,
  saddleHq,
  truper,
  aadalatAi,
  canebot,
  gnani,
  pulayatsaAi,
  celestia,
  banner1Bsv,
  ceoSpeaks,
  bhashiniNews,
  awardsAndAchivements,
  bhashiniEvents,
  bhashiniGIC,
  ondc,
  sprintLogo,
  techathon,
  startupVelocityLogo,
  dicLogo,
  bhashiniLogo,
  bhashaLoader,
  ApplicationProcedures,
  bsvAbout,
  confettiGif,
  linkedInFullLogo,
  twitterFullLogo,
  instagramFullLogo,
  homeBanner1,
  homeBanner2,
  homeBanner3,
  homeBanner4,
  homeBanner5,
  rectangleMobile,
  semiRectangleMobile,
  applicationProceduresMobile,
  arrow,
  sprintBanner,
  award1_1,
  award1_2,
  award1_3,
  award1_4,
  award1_5,
  award1_6,
  award1_7,
  award1_8,
  award1_9,
  award1_10,
  award1_11,
  award1_12,
  award1_13,
  award1_14,
  award1_15,
  award2_1,
  award2_2,
  award2_3,
  award2_4,
  award2_5,
  award2_6,
  award2_7,
  award2_8,
  award2_9,
  award2_10,
  IL01,
  IL02,
  IL03,
  IL04,
  IL05,
  IL06,
  IL07,
  IL08,
  IL09,
  IL10,
  IL11,
  IL12,
  IL13,
  IL14,
  IL15,
  agencies11,
  meityDarkLogo,
  bhashiniDarkLogo,
  rightArrow,
  star,
  velocityLogo,
  sprintLogo1,
  adalat,
  canbot,
  celestia1,
  doctune1,
  gnani1,
  pulasya,
  customers,
  trends,
  aiModels,
  org1,
  org2,
  org3,
  org4,
  org5,
  org6,
  org7,
  managerHr,
  noOpenings,
  sahyogiTranslationServiceProviders,
  budget2024,
  assistantManagerAdministration,
  agencies12,
  agencies13,
  agencies14,
  agencies15,
  technicalManager,
  nandanKumar,
  pravaktaBanner2bg,
  pravaktaBanner3bg,
  pravaktaBanner2Img,
  pravaktaBanner3Img,
  kashiTamil1,
  trailBlazer1,
  impactAwards,
  adoptionInAi,
  samudayeBg,
  leftArrow,
  newsThumbnail1,
  newsThumbnail2,
  newsThumbnail3,
  newsThumbnail4,
  newsThumbnail5,
  newsThumbnail6,
  newsThumbnail7,
  newsThumbnail8,
  newsThumbnail9,
  newsThumbnail10,
  newsThumbnail11,
  newsThumbnail12,
  eletsAtmaNirbhar,
  eletsEducation,
  eletsTechnology,
  webtranslationplugin,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  database,
  dottedVerticalLines,
  groups,
  summarize,
  aboutAvinya,
  googleLogo,
};

export default assetsManager;
