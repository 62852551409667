import React from "react";
import assetsManager from "../../assets/assetsManager";
import { useTranslation } from "react-i18next";
import useScrollToRef from "../../hooks/useScrollToRef";

const VisionAndMission = () => {
  const vissionAndMissionRef = useScrollToRef();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-[.94rem]" ref={vissionAndMissionRef}>
      <div>
        <p className="heading1 font-semibold text-[#242424]">
          Vision & Mission
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex about-vision w-full px-4 md:px-[3.69rem] py-[3.19rem] justify-between">
          <div className="flex flex-col gap-6 md:w-3/5 text-white w-full">
            <p className="headline2 font-bold">
              {t("aboutPage.visionMission.visionTitle")}
            </p>
            <p className="paragraph1">
              {t("aboutPage.visionMission.visionContent")}
            </p>
          </div>
          <div className="md:w-1/4 hidden md:block">
            <img
              className="w-full"
              src={assetsManager.bhashiniCircleLogo}
              alt="vision"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex about-mission w-full px-4 md:px-[3.69rem] py-[3.19rem] justify-between">
          <div className="flex flex-col gap-6 md:w-3/5 text-white w-full">
            <p className="headline2 font-bold">
              {t("aboutPage.visionMission.missionTitle")}
            </p>
            <p className="paragraph1">
              {t("aboutPage.visionMission.missionContent")}
            </p>
          </div>
          <div className="md:w-1/4 hidden md:block">
            <img
              className="w-full"
              src={assetsManager.bhashiniCircleLogo}
              alt="vision"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionAndMission;
