import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();


  // Scroll to top on every route change
  useEffect(()=>{
    window.scrollTo(0,0)
  
  },[pathname])
  // useEffect(() => {
  //   console.log("Scrolling to top");
  //   setTimeout(() => {
  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   }, 100); // Adjust the delay as needed
  // }, [pathname]);
  

  return null;
};

export default ScrollToTop;
