import React from 'react'
import './Loader.css'
import assetsManager from '../../assets/assetsManager'

const Loader = () => {
  return (
    <div className="loader w-full h-full ">
        <img src=
        {assetsManager.bhashaLoader}
        alt="loader"
        className='m-auto'
        />
        </div>
    
  )
}

export default Loader