import React, { useEffect } from "react";
import useGetDateAndTime from "../../../hooks/useGetDateAndTime";
// import { Globalstate } from "../../../context/context";
// import { condition } from "../../../context/condition";
const AccessbilityBar = ({ skipToContent }) => {
  const [rootFontSize, setRootFontSize] = React.useState(16);
  const { date, time } = useGetDateAndTime();
  // const {dispatch,state} = Globalstate();
  // const {isDarkMode} = state;

  // ******* useEffect to set the root font size *******
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--root-font-size",
      `${rootFontSize}px`
    );
  }, [rootFontSize]);

  // ******* function to increase font size across the entire Application  *******
  const increaseFontSize = () => {
    setRootFontSize((prev) => prev + 1);
  };
  // ******* function to decrease font size  across the entire Application*******
  const decreaseFontSize = () => {
    setRootFontSize((prev) => prev - 1);
  };

  // function to set the font size to normal
  const handleFontSizeNormal = () => {
    setRootFontSize(16);
  };

  // const changeLanguage = (e) => {
  //   console.log(e.target.value, "value");
  //   i18n.changeLanguage(e.target.value);
  // };

  // TODO: Commented out dark mode functionality as it is not implemented yet
  // function to handle dark mode
  // const darkModeHandle = () => {
  //   const newDarkModeState = !isDarkMode;
  //   document.documentElement.classList.toggle("dark", newDarkModeState);
  //   dispatch({
  //     type: condition.SETDARKMODE,
  //     payload: newDarkModeState,
  //   });
  // };

  return (
    <div className="w-full bg-primaryDark2 ">
      <div className="2xl:container">
        <div className="flex md:px-16  px-4 py-[0.78rem] justify-between">
          <div className=" flex gap-2 items-center smallText font-semibold">
            <p>{date}</p>
            <p>{time}</p>
          </div>
          <div className="flex gap-6 h-full items-center">
            <button
              onClick={skipToContent}
              className="smallText font-medium text-[#EBEBEB] hidden md:block"
            >
              Skip to main content
            </button>
            <div className="h-5  w-0.5 bg-[#EBEBEB] dark:bg-white/10 hidden md:block "></div>
            <div className="h-full md:flex flex-row hidden   gap-3 ">
              <button
                className="h-full flex items-center gap-2"
                onClick={decreaseFontSize}
              >
                <span>
                  A<sup>-</sup>
                </span>
              </button>
              <button
                className="h-full flex items-center gap-2"
                onClick={handleFontSizeNormal}
              >
                A
              </button>
              <button
                className="h-full flex items-center gap-2"
                onClick={increaseFontSize}
              >
                <span>
                  A<sup>+</sup>
                </span>
              </button>
            </div>
            {/* <div className="h-5  w-0.5 bg-[#EBEBEB] dark:bg-white/10 md:block"></div> */}
            {/* <div onClick={darkModeHandle} className="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M12.263 20.8334C16.8654 20.8334 20.5964 17.1025 20.5964 12.5001C20.5964 7.89771 16.8654 4.16675 12.263 4.16675C7.66065 4.16675 3.92969 7.89771 3.92969 12.5001C3.92969 17.1025 7.66065 20.8334 12.263 20.8334ZM12.263 19.5834V5.41675C16.175 5.41675 19.3464 8.58806 19.3464 12.5001C19.3464 16.4121 16.175 19.5834 12.263 19.5834Z"
              fill="#EBEBEB"
            />
          </svg>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessbilityBar;
