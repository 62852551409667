import React from "react";

const ArticleCard = ({ title, date, thumbnail }) => (
  <div className="w-full flex flex-col shadow-xl relative rounded-xl">
    <div className="w-full h-[16.9375rem] relative rounded-xl">
      <img
        src={thumbnail}
        alt="article"
        className="absolute inset-0 z-10 rounded-tl-xl rounded-tr-xl"
      />
    </div>
    <div className="w-full bg-white p-4 rounded-xl flex flex-col">
      <p className="font-bold truncate" title={title}>
        {title}
      </p>
      <div className="flex justify-between w-full items-center">
        <p className="text-gray-600">{date}</p>
      </div>
    </div>
  </div>
);

export default ArticleCard;
