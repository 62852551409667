// src/hocs/withLayout.js
import React from "react";
import Layout from "../components/Layout/Layout";

/**
 * @description A higher-order component that wraps the provided component with the layout. Layout will be rendered with the provided navigation items to the left and the main content to the right.
 * @param {Object[]} navItems - The navigation items to be displayed in the layout along with the main content.
 * @returns {HigherOrderComponent} A higher-order component that wraps the provided component with the layout.
 * @example
 * import React from "react";
  import withLayout from "../../hocs/withLayout";
  const AboutUs = ()=>{
    return <div className="flex flex-col gap-[.94rem]">
      <div>
        <p className="heading1 font-bold">
          About <span className="text-primary">Bhashini</span>
        </p>
        <p className="heading3">
        Know more about us
        </p>
      </div>
      <video className="w-full h-1/2" controls >
        <source src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/bhashini-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
        </video>
    </div>
}
  const About = () => {
    return <div>About Page Content</div>;
  };
  const aboutNavItems = [
      {
          label: "About Us",
          content: <AboutUs/>
      },
      {
        label: "Vision & Mission",
        content: "Vision & Mission Content"
    }
  ];

  export default withLayout(aboutNavItems)(About);

*/
const withLayout = (navItems,bannerComponent) => (WrappedComponent) => {
  const ComponentWithLayout = (props) => (
    <Layout navItems={navItems} bannerComponent={bannerComponent}>
      <WrappedComponent {...props} />
    </Layout>
  );

  ComponentWithLayout.displayName = `WithLayout(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return ComponentWithLayout;
};

export default withLayout;
