import React, { Suspense, lazy } from "react";
import ServiceDetail from "../ServiceDetail/ServiceDetail";
import { services } from "../../utils/services";
import useScrollToRef from "../../hooks/useScrollToRef";
const ServiceChart = lazy(() => import("../ServiceChart/ServiceChart"));

const OurServices = () => {
  const [selectedService, setSelectedService] = React.useState(null);
  const serviceRef = useScrollToRef();
  const handleDetailClick = (selectedService) => {
    if (!selectedService.isAvailable) {
      return;
    }
    console.log(selectedService);
    const selectedProduct = services.find(
      (service) => service.name === selectedService.name
    );
    setSelectedService(selectedProduct);
  };

  return selectedService ? (
    <ServiceDetail
      product={selectedService}
      setSelectedService={setSelectedService}
    />
  ) : (
    <div className="flex flex-col gap-9 h-full" ref={serviceRef}>
      <div className="flex flex-col gap-4">
        <p className="md:text-4xl text-[1.625rem] font-bold text-[#242424]">
          Bhashini’s Translation Services
        </p>
      </div>
      <Suspense fallback={<div>Loading Chart...</div>}>
        <ServiceChart />
      </Suspense>
      <div className=" flex gap-9 flex-wrap w-full">
        {services.map((service, index) => (
          <div
            key={index}
            onClick={() => handleDetailClick(service)}
            className={` flex flex-col p-9 gap-4 shadow-3xl w-full md:w-[30%] ${
              service.isAvailable
                ? "hover:bg-[#EEF2FF] cursor-pointer"
                : " cursor-not-allowed"
            }`}
          >
            <img alt={service.name} src={service.icon} height={44} width={44} />
            <div className="flex flex-col gap-1">
              <p className="heading3 font-semibold">{service.abbr}</p>
              <p className="paragraph2 font-semibold">{service.name}</p>
            </div>
            {service.isAvailable ? (
              <div className="flex justify-between grow">
                <p className="text-primaryDark1 cursor-pointer mt-auto flex  justify-between w-full">
                  View More Details
                  <svg
                    className="mt-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                  >
                    <path
                      d="M16.082 7.72656L1.08203 7.72656"
                      stroke="#2947A3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.0312 1.701L16.0813 7.725L10.0312 13.75"
                      stroke="#2947A3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
            ) : (
              <p className="text-primaryDark1">Coming Soon...</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
