import React from 'react'

const Gpai = () => {
    const images = [
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/_MG_4872.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/_MG_4886.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/_MG_4897.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/_MG_4945.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/_MG_4983.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_4927.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_4937.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_4940.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5032.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5043.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5049.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5064.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5195.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5274.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5289.jpg",
        "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/Events/GPAI/IMG_5315.jpg"
      ];
      
  return (
    <div className="flex flex-wrap gap-2 mx-16">
    {images.map((image, index) => (
      <div key={index} className="w-1/2 md:w-1/4 h-1/2">
        <a href={image} data-fancybox="gallery" className="block h-full">
          <img src={image} alt={`G20 Event ${index + 1}`} className="h-full" />
        </a>
      </div>
    ))}
  </div>
  )
}

export default Gpai