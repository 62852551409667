import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";

const slideData = [
  {
    image: assetsManager.homeCarousel1,
    title: "MINISTRIES / DEPARTMENTS",
    description:
      "They shall empower Bhashini through identification of data resources that can be harvested and support Bhashini by aligning their language focused initiatives with that of Bhashini....",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem2,
    title: "State and Language Missions",
    description:
      "State and language missions shall play a critical role in Bhashini. They will enable Bhashini through identification of usable data sources from state government entities & other sources and also drive crowd sourcing efforts through standard Bhashini tools.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem3,
    title: "Startups",
    description:
      "Startups will be the driving force behind exciting new use cases for Indian language technology. Startups will be encouraged to create innovative applications by using Bhashini’s resources.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem4,
    title: "Private companies with large digital reach",
    description:
      "Industry powers Bhashini's growth. They contribute data, build core technology, offer computing power, and share open-source data to enrich the Bhashini repository.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem5,
    title: "MSMEs",
    description:
      "Indian MSMEs that may want to use data, NLP products and services of Bhashini to deliver innovative solutions to the market.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem6,
    title: "Data collection and curation companies",
    description:
      "They shall assist Bhashini through collection and validation of the dataset contributions using ULCA compliant tools.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem7,
    title: "Publishers",
    description:
      "Bhashini collaborates with publishers to enrich its offerings. Publishers contribute large-scale copyright-free Indian language content, ensuring intellectual property protection.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
  {
    image: assetsManager.collaborativeEcosystem8,
    title: "Citizens",
    description:
      "Citizens shall be provided easy tools and motivated to contribute towards the languages of their liking through Bhashini’s crowd sourcing platform. They will be the prime beneficiaries of this whole initiative.",
    link: "/be-our-sahyogi",
    linkText: "Be Our Sahyogi",
  },
];

const EcoSystemCarousel = () => {



  

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  
    
    customPaging: function (i) {
      return (
        <div className="h-[1rem] w-[1rem] bg-[#C4B5FD] rounded-full mt-2">
          <p className="hidden">{i + 1}</p>
        </div>
      );
    },
  };

  return (
    <Slider {...settings}>
      {slideData.map((slide, index) => (
        <div
          key={index}
          className="!flex relative h-[25rem] md:h-[25rem] bg-white flex-col md:flex-row"
        >
          <div className="md:w-1/2 w-full h-1/2 md:h-full">
            <img
              src={slide.image}
              loading="lazy"
              alt="ministries"
              className="w-full h-full rounded"
            />
          </div>
          <div className="hidden md:block relative md:w-1/2 w-full h-full bg-white">
            <div className=" md:absolute relative right-0">
              <img
                loading="lazy"
                src={assetsManager.dotIcon}
                alt="dot"
                height={150}
                width={150}
              />
            </div>
            <div className="absolute left-0 bottom-0">
              <img
                loading="lazy"
                src={assetsManager.dotIcon}
                alt="dot"
                height={150}
                width={150}
              />
            </div>
          </div>
          <div className="left-[5%] top-[20%] w-[90%] px-4 absolute md:left-[45%] md:top-[10%] flex flex-col gap-[1.12rem] md:gap-6 bg-white dark:bg-[#404148] md:w-1/2 py-6 md:py-[3.06rem] md:px-[3.25rem] shadow-2xl rounded-[.5rem] ">
            <p className="dark:text-[#887651] text-primaryDark1 text-[1.125rem] font-semibold ">
              {slide.title}
            </p>
            <p className="md:text-lg text-[.75rem] font-medium">
              {slide.description}
            </p>
            <Link
              to={slide.link}
              className="text-primary text-[.875rem] flex gap-2 items-center font-bold"
            >
              {slide.linkText}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_2549_14227)">
                  <path
                    d="M8.45 14.2992L14.7484 7.99922L8.4484 1.69922M1.25 7.99922H14.75H1.25Z"
                    stroke="#2947A3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2549_14227">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default EcoSystemCarousel;
