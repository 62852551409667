import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import FormField from "./FormField";

const ProfessionalDetails = ({
  formData,
  errors,
  organizations,
  handleChange,
}) => {
  return formData.user_type === "Organisation" ? (
    // Organization-specific form fields
    <Grid container sx={{ gap: "1.12rem" }}>
      <>
        <InputLabel htmlFor="organization_institute">
          Organization/Institute Type
          <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Select
          id="organization_institute"
          name="organization_institute"
          onChange={handleChange}
          value={formData.organization_institute}
          renderValue={(selected) => {
            if (!selected) {
              return <em>Select</em>;
            }
            return selected;
          }}
          defaultValue={""}
          fullWidth
          label="Organization/Institute Type"
          sx={{
            width: "200%",
          }}
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {organizations?.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </>

      <FormField
        isRequired={true}
        onChange={handleChange}
        value={formData.organization_institute_name}
        name="organization_institute_name"
        label="Organization/Institute Name"
        type="text"
        placeholder="Enter your organization/institute name"
        error={errors?.organization_institute_name}
        helperText={errors?.organization_institute_name}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormField
            isRequired={true}
            onChange={handleChange}
            value={formData.designation}
            name="designation"
            label="Designation"
            type="text"
            placeholder="Enter your designation"
            error={errors?.designation}
            helperText={errors?.designation}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Work Experience (Years)*
          </Typography>
          <TextField
            id="work_experience"
            name="work_experience"
            type="number"
            value={formData.work_experience}
            onChange={handleChange}
            fullWidth
            error={errors?.work_experience}
            helperText={errors?.work_experience}
            placeholder="Enter your work experience"
          />

          {/* <FormField
            isRequired={true}
            onChange={handleChange}
            value={formData.work_experience}
            name="work_experience"
            label="Work Experience (Years)"
            placeholder="Enter your work experience"
            error={errors?.work_experience}
            helperText={errors?.work_experience}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    // Individual-specific form fields
    <Grid container sx={{ gap: "1.12rem" }}>
      <Grid item sm={12}>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Work Experience (Years)*
        </Typography>
        <TextField
          id="work_experience"
          name="work_experience"
          type="number"
          value={formData.work_experience}
          onChange={handleChange}
          fullWidth
          error={errors?.work_experience}
          helperText={errors?.work_experience}
          placeholder="Enter your work experience"
        />
      </Grid>
      <Grid item sm={12}>
        <FormField
          isRequired={false}
          onChange={handleChange}
          value={formData.portfolio_link}
          name="portfolio_link"
          label="Portfolio Link (Optional)"
          placeholder="Enter portfolio link"
          error={errors?.portfolio_link}
          helperText={errors?.portfolio_link}
        />
      </Grid>
    </Grid>
  );
};

export default ProfessionalDetails;
