import React from "react";
import withLayout from "../../hocs/withLayout";

import assetsManager from "../../assets/assetsManager";
import AboutSprint from "../AboutSprint/AboutSprint";
import SprintStages from "../SprintStages/SprintStages";
import SprintAwardsOutComes from "../SprintAwardsOutComes/SprintAwardsOutComes";
import SprintProblemStatement from "../SprintProblemStatement/SprintProblemStatement";
import SprintEligibility from "../SprintEligibility/SprintEligibility";
import SprintGuidelines from "../SprintRuleGuidelines/SprintGuidelines";
import { SprintResult } from "../SprintResult/SprintResult";
import SprintTimelines from "../SprintTimelines/SprintTimelines";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// import OurJourney from "../../components/OurJourney/OurJourney";

const BhashiniSprint = () => {
  return <>About content</>;
};
export const bhashiniSprintItem = [
  {
    label: "About Sprint",
    path: "about-sprint",
    component: AboutSprint,
  },
  {
    label: "Stages",
    path: "sprint-stages",
    component: SprintStages,
  },
  {
    label: "Awards & Outcomes",
    path: "sprint-awards-outcomes",
    component: SprintAwardsOutComes,
  },
  {
    label: "Timelines",
    path: "sprint-timelines",
    component: SprintTimelines,
  },
  {
    label: "Problem Statement",
    path: "sprinth-problem-statement",
    component: SprintProblemStatement,
  },
  {
    label: "Eligibility",
    path: "sprint-eligibility",
    component: SprintEligibility,
  },
  {
    label: "Rules & Regulations",
    path: "sprint-guidelines",
    component: SprintGuidelines,
  },
  {
    label: "Result",
    path: "sprint-result",
    component: SprintResult,
  },
];

const VelocityBanner = () => {
  return (
    <div className="velocity-banner !flex justify-between w-full relative h-full flex-col md:flex-row ">
    <div className="flex flex-col md:gap-6 gap-[.65rem] text-black md:w-2/3 w-full md:py-[6.31rem] md:px-[4rem] pt-[2.63rem] pb-[2.73rem] px-10">
      <div className="flex flex-col gap-[2.75rem]">
        <div>
          <p className="md:text-4xl text-[.96rem] font-semibold ">
          Bhashini Sprint
          </p>
          <p className="md:text-lg text-[.48rem] font-medium">
          Fosters innovation in specific domains like e-commerce, retail, and D2C.
          </p>
        </div>
        <p className="text-[2.5rem] text-[#0053AD]">
        Stage 2 results are out!!
        </p>
      </div>
      <div className="mt-[0.87rem]">
        <Breadcrumbs />
      </div>
    </div>
    <div className=" hidden md:block absolute bottom-[15%] right-0">
      <img
        loading="lazy"
        alt="Bhashini banner title"
        width={523}
        height={326}
        src={assetsManager.sprintLogo1}
      />
    </div>
  </div>
  );
};

export default withLayout(
  bhashiniSprintItem,
  <VelocityBanner />
)(BhashiniSprint);
