import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import VideoCard from "./VideoCard";

const GlobalVoices = () => {
  const globalVoicesRef = useScrollToRef();
  const VIDEO_DATA = [
    {
      title:
        "Satya Nadella ने National Language Translation Mission Bhashini के बारे में की बात | BQ Prime Hindi",
      date: "Jan 3, 2023",
      duration: "1:32",
      videoUrl: "https://www.youtube.com/embed/_INkjHbPiCc",
    },
    {
      title:
        "OpenAI VP S Narayanan Praises India's Role In Shaping AI's Future At Global Summit In Delhi",
      date: "Jul 3, 2024",
      duration: "5:10",
      videoUrl: "https://www.youtube.com/embed/bP_SzP0JSqA?si=cdQ488D483euPvgT",
    },
    {
      title:
        "BHASHINI application is so great: Indonesian delegate at 46th WHC Meeting",
      date: "Aug 10, 2024",
      duration: "0:44",
      videoUrl: "https://www.youtube.com/embed/d-XzHiFQtZI",
    },
    {
      title:
        "''BHASHINI is incredible'' : Delegate from Mauritius at 46th WHC meeting",
      date: "Aug 10, 2024",
      duration: "0:58",
      videoUrl: "https://www.youtube.com/embed/L-7qrnXN0mM",
    },
  ];

  return (
    <div className="flex flex-col gap-9" ref={globalVoicesRef}>
      <div className="flex flex-col gap-[.94rem]">
        <div>
          <p className="heading1 font-bold text-[#242424]">
            Global Envy, India’s Pride
          </p>
          <p className="heading3">Echoes of Bhashini{"'"}s Impact Worldwide.</p>
        </div>
      </div>
      <div className="flex w-full gap-9 flex-wrap">
        {VIDEO_DATA.map((videoData) => (
          <VideoCard
            key={videoData.videoUrl}
            videoUrl={videoData.videoUrl}
            title={videoData.title}
            date={videoData.date}
            duration={videoData.duration}
          />
        ))}
      </div>
    </div>
  );
};

export default GlobalVoices;
