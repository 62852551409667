import React from "react";

const SprintStages = () => {
  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold">
        Stages: <span className="text-primary">Bhashini Sprint</span>
      </p>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">Stage 1</p>
        <p >
          The intent of this stage is to invite bright minds to collaborate and
          participate to propose innovative and cutting-edge ideas for their
          solution using Bhashini APIs for AI based translations models. The
          ideas submitted across problem statements will be evaluated, and top
          five (5) teams per problem statement will be selected by a Selection
          Committee composed of experts from academia, industry, and government.
          Each selected team will get a chance to migrate to stage 2 to present
          the basic prototype.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">Stage 2</p>
        <p>
          This is the critical phase of the BHASHINI-SPRINT to build upon the
          idea and concept. This would help to develop and enhance various
          features of the prototype of the solution. The teams would present
          their prototypes to a distinguished Jury to select top three (3)
          entries. Each selected team will receive INR 50 Thousand to build
          their solution following the design principles and best practices. If
          needed, there will be mentors assigned to the teams to help develop
          the best solution as per the need of the DIBD.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">Stage 3</p>
        <p>
          Shortlisted teams from Stage-2 will get a chance of a lifetime to
          deploy a fully functioning product based on their working prototype at
          the DIBD during this stage. The deployable solution will be presented
          to the Grand Jury. The solutions would be evaluated based on
          parameters that will include Innovation, Replicability, Scalability,
          Usability, Ease of deployment/roll-out, Potential risks involved in
          implementation of the solution. The winning teams with the best-judged
          solution will get a contract to deploy their solution for use by
          Government of India, State Government entities for a period of 4 years
          and will also be given a total of Rs INR 7 lakhs (5 lakh for winning
          team+ 2 Lakh for the runners up) per problem statement as a grant
          towards Innovation, operations, and maintenance of the solution for
          the DIBD. The Manpower services and engagement required for
          institutions other than those who are under DIBD, their contract would
          be done separately. All teams including the winning teams and
          runners-up shall be free to market the product to any entity outside
          Union/State/UT Government Organizations of India by hosting it in an
          environment other than that for the Government.
        </p>
      </div>
    </div>
  );
};

export default SprintStages;
