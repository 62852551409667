import React, { useEffect } from "react";
import withLayout from "../../hocs/withLayout";
import  {useNavigate} from "react-router-dom"


import ParikshanApp from "../ParikshanApp/ParikshanApp";
import AvinyaPrayogshala from "../AvniyaPrayogShala/AvniyaPragshala";
import AbhiyantrikeePrayogshala from "../AbhiyantrikeePrayogshala/AbhiyantrikeePrayogshala";
import Vatika from "../Vatika/Vatika";
import PrayogBanner from "../PrayogBanner/PrayogBanner";
import { Tooltip } from "@mui/material";
// import OurJourney from "../../components/OurJourney/OurJourney";

const Prayog = () => {
  const navigate = useNavigate();
   
  useEffect(() => {
    navigate("/prayog/parikshan-app");
 }, [navigate]);

  return <>About content</>;
};
export const prayogItems = [
  {
    label: <Tooltip title="Reference Application" arrow> Parikshan App</Tooltip>,
    path: "parikshan-app",
    component: ParikshanApp,
  },
  {
    label: <Tooltip title="Model & Data Garden" arrow> Vatika</Tooltip>,
    path: "vatika",
    component: Vatika,
  },
  {
    label: <Tooltip title="Engineering Laboratory " arrow> Abhiyantrikee Prayogshala</Tooltip>,
    path: "Abhiyantrikee-Prayogshala",
    component: AbhiyantrikeePrayogshala,
  },
  {
    label: <Tooltip title="Innovation Laboratory" arrow> Avinya Prayogshala</Tooltip>,
    path: "avinya-prayogshala",
    component: AvinyaPrayogshala,
  },
  
];

export default withLayout(prayogItems, <PrayogBanner />)(Prayog);
