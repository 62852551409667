import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BhashiniSetu from "../../components/BhashiniSetu/BhashiniSetu";

const Sanchalak = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    customPaging: function (i) {
      return (
        <div className="h-[1rem] w-[1rem] bg-[#C4B5FD] rounded-full mt-2">
          <p className="hidden">{i + 1}</p>
        </div>
      );
    },
  };

  return (
    <div className="w-full flex-col flex gap-[4.5rem]">
      <div className="sanchalak-banner-bg">
        <div className="container w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4 flex flex-col">
          <div className="my-auto text-white">
            <p className="heading1 font-medium">
              Sanchalak: Drivers & Operational Management
            </p>
            <p className="heading3">
              Empowering Drivers & Businesses with Sanchalak
            </p>
          </div>
          <Breadcrumbs />
        </div>
      </div>
      <div className="container">
        <div className="flex flex-col md:mx-16 mx-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2 text-center">
              <p className="heading1 font-semibold text-[#242424]">
                Bhashini Sanchalak
              </p>
              <p className="heading3 font-medium">
                Sanchalak build and manage the offering and productionize it for
                use by the stakeholders
              </p>
            </div>
            <Slider {...settings}>
              <div className="!flex relative h-[25rem] flex-col md:flex-row">
                <div className="md:w-1/2 w-full h-full">
                  <img
                    src={assetsManager.sachalakCarousel1}
                    loading="lazy"
                    alt="ministries"
                    className="w-full h-full"
                  />
                </div>
                <div className="relative md:w-1/2 w-full h-full bg-white">
                  <div className="md:absolute relative right-0">
                    <img
                      loading="lazy"
                      src={assetsManager.dotIcon}
                      alt="dot"
                      height={150}
                      width={150}
                    />
                  </div>
                  <div className="absolute left-0 bottom-0">
                    <img
                      loading="lazy"
                      src={assetsManager.dotIcon}
                      alt="dot"
                      height={150}
                      width={150}
                    />
                  </div>
                </div>
                <div className="left-[5%] top-[12%] w-[90%] px-4 absolute md:left-[45%] md:top-[10%] flex flex-col gap-6 bg-white dark:bg-black md:w-1/2 py-[3.06rem] md:px-[3.25rem] shadow-2xl rounded-[.5rem]">
                  <p className="text-primaryDark1 font-semibold">
                    Universal Language Contribution API
                  </p>
                  <p className="heading3 font-medium">
                    ULCA is a standard API and open scalable data platform
                    supporting various types of datasets for Indian languages
                    datasets and models.
                  </p>
                  <Link
                    to="https://bhashini.gov.in/ulca"
                    target="_blank"
                    className="text-primary"
                  >
                    Explore ULCA Platform
                  </Link>
                </div>
              </div>
              <div className="!flex relative h-[25rem] flex-col md:flex-row">
                <div className="md:w-1/2 w-full h-full">
                  <img
                    src={assetsManager.sachalakCarousel2}
                    loading="lazy"
                    alt="ministries"
                    className="w-full h-full"
                  />
                </div>
                <div className="relative md:w-1/2 w-full h-full bg-white">
                  <div className="md:absolute relative right-0">
                    <img
                      loading="lazy"
                      src={assetsManager.dotIcon}
                      alt="dot"
                      height={150}
                      width={150}
                    />
                  </div>
                  <div className="absolute left-0 bottom-0">
                    <img
                      loading="lazy"
                      src={assetsManager.dotIcon}
                      alt="dot"
                      height={150}
                      width={150}
                    />
                  </div>
                </div>
                <div className="left-[5%] top-[12%] w-[90%] px-4 absolute md:left-[45%] md:top-[10%] flex flex-col gap-6 bg-white dark:bg-black md:w-1/2 py-[3.06rem] md:px-[3.25rem] shadow-2xl rounded-[.5rem]">
                  <p className="text-primaryDark1 font-semibold">
                    Bhashini SETU
                  </p>
                  <p className="heading3 font-medium">
                    One architecture, endless possibilities. Bhashini SETU
                    connects linguistics & tech for a multilingual future.
                  </p>
                </div>
              </div>
            </Slider>
          </div>

          <div className="flex flex-col gap-9 mt-[6.25rem]">
            <div className="flex flex-col text-center">
              <p className="text-[1.5rem] font-medium text-[#3A3A3C]">
                BHASHINI SETU
              </p>
              <p className="heading1 font-semibold">
                Unified Architecture for Bhashini SETU
              </p>
              <p className="heading3 font-medium">
                Bringing Linguistic community and technology together.
              </p>
              <p className="text-[1.125rem]">
                Click on the blocks for more details.
              </p>
            </div>
          </div>
          <BhashiniSetu />
        </div>
      </div>
    </div>
  );
};

export default Sanchalak;
