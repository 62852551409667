import React from "react";

const StartupResult = () => {
  const stage = [
    {
      id: 1,
      problemStatement: "Problem Statement 1",
      qualifier: "DocTunes",
    },
    {
      id: 2,
      problemStatement: "Problem Statement 1",
      qualifier: "Adalat AI",
    },
    {
      id: 3,
      problemStatement: "Problem Statement 1",
      qualifier: "Banva Technologies Service Pvt Ltd.",
    },
    {
      id: 4,
      problemStatement: "Problem Statement 2",
      qualifier: "DocTunes",
    },
    {
      id: 5,
      problemStatement: "Problem Statement 2",
      qualifier: "Trupeer.ai",
    },
    {
      id: 6,
      problemStatement: "Problem Statement 2",
      qualifier: "CivicCraft Online Solutions Pvt. Ltd.",
    },
    {
      id: 7,
      problemStatement: "Problem Statement 3",
      qualifier: "Kiffer solutions private limited",
    },
    {
      id: 8,
      problemStatement: "Problem Statement 3",
      qualifier: "Pulastya AI Private Limited",
    },
    {
      id: 9,
      problemStatement: "Problem Statement 3",
      qualifier: "VITT AI",
    },
    {
      id: 10,
      problemStatement: "Problem Statement 4",
      qualifier: "Gnani Innovations Private Limited",
    },
    {
      id: 11,
      problemStatement: "Problem Statement 4",
      qualifier: "Reverie Language Technologies",
    },
  ];
  const stage2 = [
    {
      id: 1,
      teamLeader: "Dewang Bhardwaj",
      regType: "Startup",
      startupName: "DocTunes",
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      id: 2,
      teamLeader: "Dewang Bhardwaj",
      regType: "Startup",
      startupName: "DocTunes",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 3,
      teamLeader: "Sanid MTP",
      regType: "Startup",
      startupName: "Wizard Flair PVT LTD",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 4,
      teamLeader: "N Jayaprakash",
      regType: "Company",
      startupName: "NetPhenix IT Solutions Private Limited",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 5,
      teamLeader: "Kunal Anand",
      regType: "Startup",
      startupName: "aiBaat",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 6,
      teamLeader: "C Venkata Subramanyam",
      regType: "Startup",
      startupName: "Atomstate Technologies Private Limited",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 7,
      teamLeader: "Gursimranjeet Singh Ajimal",
      regType: "Startup",
      startupName: "VOCBOT.AI TECHNOLOGY PVT LTD",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 8,
      teamLeader: "Akash Paul",
      regType: "Individual/Academics",
      startupName: "SaddleHQ",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 9,
      teamLeader: "Ananth Nagaraj",
      regType: "Startup",
      startupName: "Gnani Innovations Private Limited",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 10,
      teamLeader: "Jayaprakash",
      regType: "Company",
      startupName: "NetPhenix IT Solutions Private Limited",
      problemStatement: "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
    {
      id: 11,
      teamLeader: "Chandra Mouli",
      regType: "Startup",
      startupName: "FusionAIx",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 12,
      teamLeader: "Amarjot Singh",
      regType: "Others",
      startupName: "Skylark Labs India Pvt. Ltd.",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 13,
      teamLeader: "Rashid Ahmad",
      regType: "Startup",
      startupName: "eBhasha Setu Language Services pvt Ltd",
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      id: 14,
      teamLeader: "Mayuresh A Nirhali",
      regType: "Company",
      startupName: "Reverie Language Technologies",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 15,
      teamLeader: "Rajat Joshi",
      regType: "Startup",
      startupName: "EmergeFlow Technologies Pvt. Ltd.",
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      id: 16,
      teamLeader: "Utkarsh Saxena",
      regType: "Startup",
      startupName: "Adalat AI",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 17,
      teamLeader: "Mayuresh Nirhali",
      regType: "Company",
      startupName: "Reverie Language Technologies",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 18,
      teamLeader: "Vedansh Goswami",
      regType: "Startup",
      startupName: "CELESTIA TECH DIGITAL SOLUTIONS LLP",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 19,
      teamLeader: "bharath",
      regType: "Startup",
      startupName: "nuronics",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 20,
      teamLeader: "bharath",
      regType: "Startup",
      startupName: "Nuronics Labs pvt Ltd",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 21,
      teamLeader: "Ritesh Kumar",
      regType: "Startup",
      startupName: "UnReaL-TecE LLP",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 22,
      teamLeader: "Raman Aggarwal",
      regType: "Startup",
      startupName: "Jupitice Justice Technologies Pvt. Ltd.",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 23,
      teamLeader: "Shivali Goyal",
      regType: "Startup",
      startupName: "Trupeer.ai",
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      id: 24,
      teamLeader: "Varun Hemachandran",
      regType: "Company",
      startupName: "Vayam Forum for Citizenship ('OpenNyAI')",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 25,
      teamLeader: "Antariksha Joshi",
      regType: "Company",
      startupName: "Banva Technologies Service Pvt Ltd.",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 26,
      teamLeader: "Pankaj Kumar",
      regType: "Startup",
      startupName: "Kiffer solutions private limited",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 27,
      teamLeader: "Chirag",
      regType: "Individual/Academics",
      startupName: "Black Order",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 28,
      teamLeader: "Aryaman Kataruka",
      regType: "Startup",
      startupName: "HopinAI",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 29,
      teamLeader: "R Animesh",
      regType: "Startup",
      startupName: "CivicCraft",
      problemStatement: "Next-Gen Multilingual Audiobook Converter Application",
    },
    {
      id: 30,
      teamLeader: "KARTHICKJOTHI M",
      regType: "Startup",
      startupName: "ALLYTRIZ TECHNOLOGIES",
      problemStatement: "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
    {
      id: 31,
      teamLeader: "JagannadhaSutradharudu Teki",
      regType: "Startup",
      startupName: "Edgeble AI",
      problemStatement: "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
    {
      id: 32,
      teamLeader: "Kirty Datar",
      regType: "Startup",
      startupName: "CaneBOT®, a brand of Canectar Foods Pvt Ltd",
      problemStatement: "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
    {
      id: 33,
      teamLeader: "Sarang Pathrabe",
      regType: "Startup",
      startupName: "Tapi Technoserves Pvt Ltd",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 34,
      teamLeader: "Prabhu Stavarmath",
      regType: "Startup",
      startupName: "Bharat Pi",
      problemStatement: "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
    {
      id: 35,
      teamLeader: "Shivam Dwivedi",
      regType: "Company",
      startupName: "Pulastya AI Private Limited",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 36,
      teamLeader: "Udit Sharma",
      regType: "Individual/Academics",
      startupName: "Sol Miners",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 37,
      teamLeader: "Nirmal Mitra",
      regType: "Startup",
      startupName: "Go 2 market India Pvt ltd",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 38,
      teamLeader: "Abhijit Majumder",
      regType: "Startup",
      startupName: "DoctorSae",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 39,
      teamLeader: "Sandeep Tanwar",
      regType: "Startup",
      startupName: "nuronics",
      problemStatement: "Live Multilingual Speech-to-Text Transcription and Management System",
    },
    {
      id: 40,
      teamLeader: "Anshuman Maheshwari",
      regType: "Startup",
      startupName: "FNF Labs",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 41,
      teamLeader: "Animesh Mohanty",
      regType: "Individual/Academics",
      startupName: "Vidyuth",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 42,
      teamLeader: "Dewang Bhardwaj",
      regType: "Startup",
      startupName: "DocTunes",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 43,
      teamLeader: "Utkarsh Pratap Singh",
      regType: "Startup",
      startupName: "Eupheus",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 44,
      teamLeader: "Kamesh Gaur",
      regType: "Startup",
      startupName: "Volopay",
      problemStatement: "AI Bot building/Management Framework application",
    },
    {
      id: 45,
      teamLeader: "Ankit Bansal",
      regType: "Individual/Academics",
      startupName: "kws",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 46,
      teamLeader: "Rahul Raj",
      regType: "Individual/Academics",
      startupName: "INDIAN INSTITUTE OF TECHNOLOGY MADRAS",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 47,
      teamLeader: "SHUBHAM MAURYA",
      regType: "Individual/Academics",
      startupName: "None",
      problemStatement: "Voice-Activated Human-Machine Interaction Hardware Concept",
    },
    {
      id: 48,
      teamLeader: "Ayushman",
      regType: "Individual/Academics",
      startupName: "mBillion",
      problemStatement: "Cross-Language Translation for Mobile Calls",
    },
    {
      id: 49,
      teamLeader: "Chaitanya Krishnan",
      regType: "Startup",
      startupName: "Interweave Tech Pvt. Ltd.",
      problemStatement: "Voice Driven Management information System",
    },
    {
      id: 50,
      teamLeader: "Dibyendu",
      regType: "Company",
      startupName: "OTIPL",
      problemStatement: "Voice Driven Management information System",
    },
  ];
  

  return (
    <div className="flex flex-col gap-[7.37rem]">
      <div className="flex flex-col gap-9">
        <p className="heading1 font-semibold ">
          Result: Stage 2: Qualifiers of Grand Finale
        </p>
        <div className="w-full bg-[#EFF3FE] px-[2.3rem] py-6">
          <table className="w-full">
            <thead>
              <tr className="bg-[#DFEAF5]">
                <th className="text-left smallText p-4">Sr. No.</th>

                <th className="text-left smallText p-4">Problem</th>
                <th className="text-left smallText p-4">Qualifier</th>
              </tr>
            </thead>
            <tbody>
              {stage.map((data) => (
                <tr
                  key={data.id}
                  className="border border-[#EAECF0]  bg-[#F9FAFB]"
                >
                  <td className="p-4 paragraph2">{data.id}</td>
                  <td className="p-4 paragraph2">{data.problemStatement}</td>
                  <td className="p-4 paragraph2">{data.qualifier}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col gap-9">
        <p className="heading1 font-semibold ">
          Result: Stage 1: Screened in Teams for Evaluation
        </p>
        <div className="w-full bg-[#EFF3FE] px-[2.3rem] py-6">
          <table className="w-full">
            <thead>
              <tr className="bg-[#DFEAF5]">
                <th className="text-left smallText p-4">Sr. No..</th>
                <th className="text-left smallText p-4">Team Leader</th>
                <th className="text-left smallText p-4">Reg Type</th>
                <th className="text-left smallText p-4">
                  Startup/Company Name
                </th>
                <th className="text-left smallText p-4">Problem Statement</th>
              </tr>
            </thead>
            <tbody>
              {stage2.map((data) => (
                <tr
                  key={data.id}
                  className="border border-[#EAECF0]  bg-[#F9FAFB]"
                >
                  <td className="p-4 paragraph2">{data.id}</td>
                  <td className="p-4 paragraph2">{data.teamLeader}</td>
                  <td className="p-4 paragraph2">{data.regType}</td>
                  <td className="p-4 paragraph2">{data.startupName}</td>
                  <td className="p-4 paragraph2">{data.problemStatement}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StartupResult;
