// src/components/Layout.js
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const Layout = ({ navItems, bannerComponent }) => {
  return (
    <div className="flex flex-col gap-11  md:gap-[6.25rem]">
      <div className="w-full md:h-[24.25rem]  ">{bannerComponent}</div>
      <div className="flex flex-col md:flex-row  gap-12 md:px-16 px-4 w-full">
        <div className="flex flex-col md:w-[22%] w-full ">
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              className={({ isActive }) =>
                `${
                  isActive
                    ? "bg-primaryLight4 dark:bg-black dark:text-white  border-l-2 border-primary  font-bold"
                    : ""
                } hover:bg-primaryLight4 hover:dark:bg-black py-[1.38rem] pl-4 pr-8 text-black dark:text-white dark dark:border dark:border-gray-400`
              }
              to={item.path}
            >
              <p className="paragrah1 ">{item.label}</p>
            </NavLink>
          ))}
        </div>
        <main className="md:w-[80%] w-full">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
