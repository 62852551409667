import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";


const AbhiyantrikeePrayogshala = () => {
  const abhiyantrikeePrayogshalaRef = useScrollToRef()
  return (
    <div className="flex flex-col gap-9" ref={abhiyantrikeePrayogshalaRef}>
      <div>
        <p className="heading1 font-semibold text-[#242424]">
          Bhashini’s{" "} Abhiyantrikee Prayogshala
        </p>
        <div className="w-full abhiyantrikBg py-[3.75rem] px-[2.69rem] flex flex-col gap-9 text-white text-center">
          <p className="heading3 font-medium">
            Abhiyantrikee Prayogshala, a versatile platform for experimenting
            with different models and creating customized pipelines. It supports
            Bhashini&apos;s tech team in fine-tuning, developing new
            functionalities, and enhancing the platform. Users from various
          backgrounds can register to leverage its capabilities, providing
            feedback to improve research and development activities.
            Additionally, the general public can use this virtual lab to explore
            AI models, experiment with features, and contribute valuable
            insights for future improvements. Stay tuned for the launch!
          </p>
          <p className="heading1 font-semibold text-end">Coming Soon...</p>
        </div>
      </div>
    </div>
  );
};

export default AbhiyantrikeePrayogshala;
