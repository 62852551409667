import * as Yup from "yup";

// Define validation schema for step 0
export const step0Schema = Yup.object().shape({
  user_type: Yup.string().required("User Type is required"),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  phone_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter a 10-digit phone number")
    .required("Phone Number is required"),
  state: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Please enter a valid state")
    .required("State is required"),
  isCheckedBox: Yup.boolean().oneOf([true], "You must agree to the terms"),
});

// Define validation schema for step 1
export const step1Schema = Yup.object().shape({
  otp: Yup.string().required("Verification code is required"),
});

// Define step 2 schema with conditional validation
export const step2Schema = Yup.object().shape({
  user_type: Yup.string().required("User Type is required"),
  organization_institute: Yup.mixed().when("user_type", {
    is: "Organisation",
    then: () =>
      Yup.mixed().test(
        "is-valid-org",
        "Organization/Institute Type is required",
        (value) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return typeof value === "string" && value.trim().length > 0;
        }
      ),
    otherwise: () => Yup.mixed().notRequired(),
  }),
  organization_institute_name: Yup.string().when("user_type", {
    is: "Organisation",
    then: () =>
      Yup.string().required("Organization/Institute Name is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  designation: Yup.string().when("user_type", {
    is: "Organisation",
    then: () => Yup.string().required("Designation is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  portfolio_link: Yup.string().when("user_type", {
    is: "Individual",
    then: () => Yup.string().url("Please enter a valid URL").nullable(),
    otherwise: () => Yup.string().notRequired(),
  }),
  work_experience: Yup.number().required("Work Experience is required"),
});

// Define validation schema for step 3
export const step3Schema = Yup.object().shape({
  primary_language_expertise: Yup.string().required(
    "Primary Language Expertise is required"
  ),
  primary_proficiency_level: Yup.string().required(
    "Primary Proficiency Level is required"
  ),
});

// Define validation schema for step 4
export const step4Schema = Yup.object().shape({
  file: Yup.mixed().required("File is required"),
});

// Define a function to get the schema based on the active step
export const getValidationSchema = (step) => {
  switch (step) {
    case 0:
      return step0Schema;
    case 1:
      return step1Schema;
    case 2:
      return step2Schema;
    case 3:
      return step3Schema;
    case 4:
      return step4Schema;
    default:
      return Yup.object();
  }
};
