import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import VideoCard from "./VideoCard";

const VIDEO_DATA = [
  {
    title: "AI Alliance Pune: CEO BHASHINI uses Speech to Speech application",
    date: "Mar 27, 2024",
    duration: "2:57",
    videoUrl: "https://www.youtube.com/embed/Ww79X0aCrxQ",
  },
  {
    title:
      "Amitabh Nag, CEO, Bhashini, Digital India Corporation | GDC&I Summit 2023 | 3rd November 2023",
    date: "Nov 12, 2023",
    duration: "16:35",
    videoUrl: "https://www.youtube.com/embed/Q92gK_ReYLg",
  },
  {
    title:
      "EC | Amitabh Nag, Digital India Bhashini Division | Technology Sabha Jaipur | 25th August 2023",
    date: "Sep 2, 2023",
    duration: "3:11",
    videoUrl: "	https://www.youtube.com/embed/Ne0N3TdkvOE",
  },
  {
    title: "#GenAICon2023 Session 6: Bhashini: Breaking the Language Barrier",
    date: "Dec 21, 2023",
    duration: "22:05",
    videoUrl: "https://www.youtube.com/embed/adEbTv-9SWI",
  },
  {
    title:
      "Technology Sabha Bytes | Amitabh Nag - Bhashini, Digital India Corporation | AUG 2023 - Jaipur",
    date: "August 7, 2024",
    duration: "10:00",
    videoUrl: "https://www.youtube.com/embed/AEc7J9BodQU",
  },
];

const LeadershipCorner = () => {
  const leadershipCorner = useScrollToRef();

  return (
    <div className="flex flex-col gap-9" ref={leadershipCorner}>
      <div className="flex flex-col gap-[.94rem]">
        <div>
          <p className="heading1 font-bold text-[#242424]">CEO{"'"}s Speak</p>
          <p className="heading3">Insights from Bhashini’s CEO.</p>
        </div>
      </div>
      <div className="flex w-full gap-9 flex-wrap">
        {VIDEO_DATA.map((videoData) => (
          <VideoCard
            key={videoData.videoUrl}
            videoUrl={videoData.videoUrl}
            title={videoData.title}
            date={videoData.date}
            duration={videoData.duration}
          />
        ))}
      </div>
    </div>
  );
};

export default LeadershipCorner;
