import React from "react";
import assetsManager from "../../assets/assetsManager";
import { handleExternalLinks } from "../../utils/utilities";
import { Link } from "react-router-dom";
import useScrollToRef from "../../hooks/useScrollToRef";

const OurCareers = () => {
  const careersRef = useScrollToRef();
  const isCurrentlyHiring = false;
  const jobOpenings = [];

  return (
    <div className="flex flex-col gap-[6.25rem]" ref={careersRef}>
      <div className="flex flex-col gap-9">
        <div className="flex flex-col gap-1">
          <p className="heading1 font-semibold">Current Job Openings</p>
          {isCurrentlyHiring ? (
            <p className="heading3 font-medium">
              Explore our current job openings to find the ideal role where your
              expertise
            </p>
          ) : (
            <p className="heading3 font-medium">
              Stay tuned for upcoming job vacancies.
            </p>
          )}
        </div>
        {isCurrentlyHiring ? (
          <div className="flex flex-wrap gap-4">
            {jobOpenings.map((job, index) => (
              <div
                key={index}
                className="p-6  shadow-md flex dark:border dark:border-gray-400 flex-col gap-8 md:w-[30%] w-full"
              >
                <div className="flex flex-col gap-2">
                  <p className="font-semibold paragraph1">{job.title}</p>
                  <p className="font-normal">
                    Number of vacancies: <span>{job.vacancies}</span>
                  </p>
                </div>
                <div className="grow flex ">
                  <Link
                    onClick={() =>
                      handleExternalLinks(
                        "https://ora.digitalindiacorporation.in/#"
                      )
                    }
                    className="text-primary text-right w-full mt-auto"
                  >
                    Click here to Apply
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-full">
            <img
              src={assetsManager.noOpenings}
              alt="No Job Openings"
              loading="lazy"
              className="m-auto"
            />
          </div>
        )}
      </div>
      {isCurrentlyHiring && (
        <div className="flex flex-col gap-9 ">
          <div className="flex-flex-col gap-3">
            <p className="heading1 font-semibold">Application Procedures</p>
            <p className="heading3 font-medium text-[#767F8C]">
              Candidates interested in our vacancies will be redirected to the
              Digital India Corporation Online Recruitment Portal to complete
              their application process.
            </p>
          </div>
          <div className="w-full hidden md:block">
            <img
              src={assetsManager.ApplicationProcedures}
              alt="Application Procedures"
              loading="lazy"
            />
          </div>
          <div className="w-full block md:hidden m-auto">
            <img
              src={assetsManager.applicationProceduresMobile}
              className="m-auto"
              alt="Application Procedures"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OurCareers;
