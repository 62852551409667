import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";
import { docsManager } from "../../assets/documents/docsManager";

const Workshops = () => {
  const workshopsRef = useScrollToRef();
  const workshopData = [
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img1,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img2,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img3,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img4,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img5,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img6,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img7,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img8,
    },
  ];

  return (
    <div className="flex flex-col gap-9" ref={workshopsRef}>
      <div className="flex justify-between">
        <div className="flex flex-col gap-[.94rem]">
          <div>
            <p className="heading1 font-bold text-[#242424]">
              Anusandhan Mitra Workshops
            </p>
            <p className="heading3">
              NMT Evaluation Workshop, IIT Bombay (10th - 12th July, 2024)
            </p>
          </div>
        </div>
        <Link
          target="_blank"
          to={docsManager.nmtEvaluation}
          className="flex gap-2 bg-primary py-[.88rem] px-[2.25rem] text-white rounded-[.375rem] items-center h-min hover:bg-[#2b4592]"
        >
          <p className="font-semibold">More Details</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <g clipPath="url(#clip0_5179_8527)">
              <path
                d="M2.75 19.9375H4.8125V22H2.75C1.2332 22 0 20.7668 0 19.25V2.75C0 1.2332 1.2332 0 2.75 0H9.86133C10.5918 0 11.2922 0.287891 11.8078 0.803516L15.6965 4.69219C16.2121 5.20781 16.5 5.9082 16.5 6.63867V13.0625H14.4375V6.875H11C10.2395 6.875 9.625 6.26055 9.625 5.5V2.0625H2.75C2.37188 2.0625 2.0625 2.37188 2.0625 2.75V19.25C2.0625 19.6281 2.37188 19.9375 2.75 19.9375ZM7.5625 15.125H8.9375C10.2652 15.125 11.3438 16.2035 11.3438 17.5312C11.3438 18.859 10.2652 19.9375 8.9375 19.9375H8.25V21.3125C8.25 21.6906 7.94063 22 7.5625 22C7.18437 22 6.875 21.6906 6.875 21.3125V15.8125C6.875 15.4344 7.18437 15.125 7.5625 15.125ZM8.9375 18.5625C9.50898 18.5625 9.96875 18.1027 9.96875 17.5312C9.96875 16.9598 9.50898 16.5 8.9375 16.5H8.25V18.5625H8.9375ZM13.0625 15.125H14.4375C15.5762 15.125 16.5 16.0488 16.5 17.1875V19.9375C16.5 21.0762 15.5762 22 14.4375 22H13.0625C12.6844 22 12.375 21.6906 12.375 21.3125V15.8125C12.375 15.4344 12.6844 15.125 13.0625 15.125ZM14.4375 20.625C14.8156 20.625 15.125 20.3156 15.125 19.9375V17.1875C15.125 16.8094 14.8156 16.5 14.4375 16.5H13.75V20.625H14.4375ZM17.875 15.8125C17.875 15.4344 18.1844 15.125 18.5625 15.125H20.625C21.0031 15.125 21.3125 15.4344 21.3125 15.8125C21.3125 16.1906 21.0031 16.5 20.625 16.5H19.25V17.875H20.625C21.0031 17.875 21.3125 18.1844 21.3125 18.5625C21.3125 18.9406 21.0031 19.25 20.625 19.25H19.25V21.3125C19.25 21.6906 18.9406 22 18.5625 22C18.1844 22 17.875 21.6906 17.875 21.3125V15.8125Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_5179_8527">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>

      <div className="flex w-full gap-9 flex-wrap justify-between">
        {workshopData.map((workshop) => (
          <div
            className="sm:w-[45%] w-full flex flex-col shadow-xl relative rounded-xl"
            key={workshop.imgSrc}
          >
            <div className="w-full h-[13.9375rem] relative rounded-tl-xl rounded-tr-xl overflow-hidden">
              <img
                src={workshop.imgSrc}
                alt="workshop"
                className="absolute inset-0 z-10 rounded-tl-xl rounded-tr-xl w-full h-full object-cover"
              />
            </div>
            <div className="w-full bg-white p-4 rounded-bl-xl rounded-br-xl flex flex-col">
              <p className="font-bold truncate" title={workshop.title}>
                {workshop.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Workshops;
