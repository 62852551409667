import React, { useRef } from "react";
import arrow from "../../assets/images/TechnologyDay/double_arrow.svg";
import feature5 from "../../assets/images/TechnologyDay/feature1.svg";
import feature4 from "../../assets/images/TechnologyDay/feature4.svg";
import feature3 from "../../assets/images/TechnologyDay/feature3.svg";
import { Typography } from "@mui/material";
import TechnologyDayForm from "./TechnologyDayForm/TechnologyDayForm";
import "./technologyDay.scss";
import assetsManager from "../../assets/assetsManager";

const TechnologydayFeature = [
  {
    id: 1,
    heading: "Your vision for building a multilingual India.",
    para: "How can technology improve communication in your community by using your native language?",
    image: feature4,
  },
  {
    id: 2,
    heading:
      "Overcoming language barriers in daily life and work with voice as a medium.",
    para: "What challenges do you encounter in daily life or work due to language limitations?",
    image: feature5,
  },
  {
    id: 3,
    heading: "Improving your everyday communication experience.",
    para: "How could Bhashini's tools be used to improve your day to day life activities in your language?",
    image: feature3,
  },
];

const TechnologyDay = () => {
  const sectionRef = useRef(null);

  // Function to handle scrolling to the section
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="TechnologyDay__banner">
        <div className="TechnologyDay__content flex flex-col md:flex-row">
          <div className="TechnologyDay__text">
            <div className="TechnologyDay__heading text-3xl md:text-4xl lg:text-5xl font-bold">
              Be a part of the{" "}
              <span className="text-blue-600 pl-1">Bhashini Sahyogi</span>{" "}
              <br />
              Community on this
              <span className="text-blue-600 pl-2">National Technology Day</span>{" "}
            </div>
            <div className="TechnologyDay__subheading mt-3 text-lg md:text-xl">
              Share your use cases to build a Voice-Powered Multilingual India!
            </div>
          </div>
          <div className="TechnologyDay__image mt-4 md:mt-0 md:ml-4">
            <img src={assetsManager.sahyogiBannerRightImg} alt="Right Image" />
          </div>
        </div>
        <div className="TechnologyDay__arrow cursor-pointer" onClick={scrollToSection}>
          <p>Skip to the form</p>
          <img src={arrow} alt="Arrow" />
        </div>

        <div className="TechnologyDay__Container py-8">
          <div className="TechnologyDay__Content1 text-center md:text-left">
            <div className="TechnologyDay__Header text-2xl font-bold">
              “Be Our Sahyogi”
            </div>
            <Typography variant="body2" sx={{ color: "black", marginTop: "15px" }}>
              Bhashini calls upon the esteemed organizations of our nation to
              contribute your use cases across various sectors.
            </Typography>
            <Typography variant="body2" sx={{ color: "black" }}>
              As a Sahyogi, your contributions will be instrumental in driving
              multilingual technological advancements, enhancing governance, and
              re-imagining the service delivery.
            </Typography>
            <Typography variant="body2" sx={{ color: "black" }}>
              Bhashini will identify the most impactful use cases and turn them
              into hackathons and Grand Innovation Challenges (GICs) to find
              solutions, together.
            </Typography>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap">
              {TechnologydayFeature.map((data, index) => (
                <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-4">
                  <div className="TechnologyDay__Box p-6 border rounded-lg shadow-lg ">
                    <div className="TechnologyDay__Boxheading text-xl font-semibold mb-2">
                      {data.heading}
                    </div>
                    <div className="TechnologyDay__BoxText mb-4">{data.para}</div>
                    <img className="TechnologyDay__BoxImg mt-auto" src={data?.image} alt="Feature" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div ref={sectionRef} id="target-section" className="mt-8">
        <TechnologyDayForm />
      </div>
    </>
  );
};

export default TechnologyDay;
