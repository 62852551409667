import React from "react";
import { docsManager } from "../../assets/documents/docsManager";
import { Link } from "react-router-dom";
import useScrollToRef from "../../hooks/useScrollToRef";

const TenderEOI = () => {
  const tenderRef = useScrollToRef();
  const tenderData = [
    {
      id: 1,
      title:
        "Request for Proposals (RFE) for System Integrators Multimodal Multilingual Solution",
      desc: [
        {
          id: 1,
          title:
            "The Digital India BHASHINI Division is seeking industry consultation for the Request for Empanelment (RFE) of System Integrators for Voice/Text Multilingual Solution for the BHASHINI project.",
          link: docsManager.empanelmentForChatBot,
        },
      ],
    },
    {
      id: 2,
      title:
        "Industry Consultation for RFE for BHASHINI System Integrators for VoiceText Multilingual Solution for the BHASHINI project",
      desc: [
        {
          id: 1,
          title:
            "The Digital India BHASHINI Division is seeking industry consultation for the Request for Empanelment (RFE) of System Integrators for Voice/Text Multilingual Solution for the BHASHINI project.",
          link: docsManager.industryConsultation,
        },
      ],
    },
    {
      id: 3,
      title: "RFE for Technical Manpower",
      desc: [
        {
          id: 1,
          title:
            "DIBD invites application for RFE for Technical Manpower Deployment",
          link: docsManager.techManPower,
        },
      ],
    },
    {
      id: 4,
      title: "Request For empanelment for HEI’s/Standalone Incubators.",
      desc: [
        {
          id: 1,
          title: "Request For empanelment for HEI’s/Standalone Incubators.",
          link: docsManager.HEIREF1,
        },
        {
          id: 1,
          title:
            "Corrigendum 1 to RFE for Empanelment of Higher Educational Institutions/ incubators in DIBD.",
          link: docsManager.cori1,
        },
        {
          id: 2,
          title:
            "Corrigendum 2 to RFE for Empanelment of Higher Educational Institutions/ incubators in DIBD.",
          link: docsManager.c2HeiFinal,
        },
        {
          id: 3,
          title:
            "Corrigendum 3 to RFE for Empanelment of Higher Educational Institutions/ incubators in DIBD.",
          link: docsManager.c3fr99,
        },
        {
          id: 4,
          title:
            "Corrigendum 4 to RFE for Empanelment of Higher Educational Institutions/ incubators in DIBD.",
          link: docsManager.hei16Jul,
        },
      ],
    },

    {
      id: 5,
      title: "RFE for Media and Event Management Services",
      desc: [
        {
          id: 1,
          title:
            "Corrigendum 1 for “Request for Empanelment for Media and Event Management Services for Bhashini Project” for DIBD.",
          link: docsManager.CorrigendumSocialMedia,
        },
        {
          id: 2,
          title:
            "Corrigendum 2 for “Request for Empanelment for Media and Event Management Services for Bhashini Project” for DIBD.",
          link: docsManager.CorrigendumSocialMedia2,
        },
        {
          id: 3,
          title:
            "Corrigendum 3 for “Request for Empanelment for Media and Event Management Services for Bhashini Project” for DIBD.",
          link: docsManager.CorrigendumSocialMedia3,
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col gap-9" ref={tenderRef}>
      <div>
        <p className="heading1 font-semibold text-[#242424]">
          Bhashini’s Tenders & EOI
        </p>
        <div className="w-full">
          <table className="w-full">
            <thead>
              <tr className="bg-[#DFEAF5] border-b-2 border-primary">
                <th className="text-left smallText p-4">Sr. No.</th>
                <th className="text-left smallText p-4">
                  Tender/EOI/RFQ/RFI Title
                </th>
                <th className="text-left smallText p-4">Description</th>
                <th className="text-left smallText p-4">
                  Reference Files/Link
                </th>
              </tr>
            </thead>
            <tbody>
              {tenderData.map((tender) =>
                tender.desc.map((desc, index) => (
                  <tr
                    key={desc.id}
                    className="border border-[#EAECF0] bg-[#F9FAFB]"
                  >
                    {index === 0 && (
                      <>
                        <td
                          rowSpan={tender.desc.length}
                          className="p-4 paragraph2 border-r-2  border-b-2 border-primary border-l-2 "
                        >
                          {tender.id}
                        </td>
                        <td
                          rowSpan={tender.desc.length}
                          className="p-4 paragraph2 border-r-2  border-b-2 border-primary"
                        >
                          {tender.title}
                        </td>
                      </>
                    )}
                    <td className="p-4 paragraph2 border-b-2 border-primary">
                      {desc.title}
                    </td>
                    <td className="p-4 paragraph2 border-r-2  border-b-2 border-l-2 border-primary">
                      <Link
                        target="_blank"
                        to={desc.link}
                        className="text-primary font-semibold "
                      >
                        View Pdf
                      </Link>
                    </td>
                  </tr>
                ))
              )}
              {/* Placeholder for additional tenders test */}
            </tbody>
          </table>
        </div>
        <Link
          to="/archived-tender"
          className="block py-2 px-4 text-white border-t bg-primary font-semibold mt-5 w-max rounded"
        >
          View Archived Tender
        </Link>
      </div>
    </div>
  );
};

export default TenderEOI;
