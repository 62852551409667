import { condition } from "./condition";
export const reducer = (state, action) => {
  switch (action.type) {
    case condition.SETDARKMODE:
      return {
        ...state,
        isDarkMode: action?.payload,
      };
    default:
      return state;
  }
};
