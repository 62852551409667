export const handleExternalLinks = (link) => {
  if (
    window.confirm(
      "You are being redirected to an external website. Please note that Bhashini Website cannot be held responsible for external websites content & privacy policies."
    )
  ) {
    window.open(link, "_blank");
  }
};

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
