import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const DashboardData = () => {
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabs =
    searchParams.get("request") ===
    "6c54629d236d2aca8e56ae8efa9d29f5f86da27d77c1de593b5d43c209ecfb8ad9ba70fc5cd7ba2cab78070a9b16776fd5d85602e433e308235570509a630549"
      ? "6c54629d236d2aca8e56ae8efa9d29f5f86da27d77c1de593b5d43c209ecfb8ad9ba70fc5cd7ba2cab78070a9b16776fd5d85602e433e308235570509a630549"
      : null;

  useEffect(() => {
    fetchData(); // Fetch data when component mounts
  }, [activeTabs]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://dashboard-be.bhashini.co.in/v1/fetch_technology_form"
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const DownloadDocument = async (fileName) => {
    try {
      const response = await axios.get(
        `https://dashboard-be.bhashini.co.in/technology_day/download?filename=${fileName}`,
        {
          responseType: "blob", // Set responseType to 'blob' to handle binary data
          headers: {
            "Content-Type": "application/octet-stream",
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      }); // Create a Blob object from the response data
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob object
      const link = document.createElement("a"); // Create an anchor element
      link.href = url; // Set the href attribute of the anchor element to the URL
      link.setAttribute("download", fileName); // Set the download attribute to specify the file name
      document.body.appendChild(link); // Append the anchor element to the DOM
      link.click(); // Programmatically click the anchor element to trigger the download
      document.body.removeChild(link); // Remove the anchor element from the DOM after the download is initiated
      toast.success("Document Downloaded");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {activeTabs && (
        <div className="p-4">
          <h2 className="text-2xl font-bold my-4">Technology Day Form Data Table</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border text-left align-top">#</th>
                  <th className="px-4 py-2 border text-left align-top">Organisation Name</th>
                  <th className="px-4 py-2 border text-left align-top">Organisation Type</th>
                  <th className="px-4 py-2 border text-left align-top">State</th>
                  <th className="px-4 py-2 border text-left align-top">Representative Name</th>
                  <th className="px-4 py-2 border text-left align-top">Organisation Website</th>
                  <th className="px-4 py-2 border text-left align-top">GST Number</th>
                  <th className="px-4 py-2 border text-left align-top">Official Mail ID</th>
                  <th className="px-4 py-2 border text-left align-top">Personal Mail ID</th>
                  <th className="px-4 py-2 border text-left align-top">Contact</th>
                  <th className="px-4 py-2 border text-left align-top">Use Case Title</th>
                  <th className="px-4 py-2 border text-left align-top">Sector</th>
                  <th className="px-4 py-2 border text-left align-top">Document</th>
                  <th className="px-4 py-2 border text-left align-top">Details</th>
                </tr>
              </thead>
              <tbody>
                {data?.formList?.map((item, index) => (
                  <tr key={item.id} className="hover:bg-gray-100">
                    <td className="px-4 py-2 border text-left align-top">{index + 1}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.organisation_name}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.organisation_type}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.state}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.representative_name}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.organisation_website}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.gst_number}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.organisation_mail}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.personal_mail}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.contact_number}</td>
                    <td className="px-4 py-2 border text-left align-top">{item?.usecase_title}</td>
                    <td className="px-4 py-2 border text-left align-top">{item.sector}</td>
                    <td className="px-4 py-2 border text-left align-top">
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={() => DownloadDocument(item?.filename)}
                      >
                        {item.filename}
                      </button>
                    </td>
                    <td className="px-4 py-2 border text-left align-top">{item.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardData;
