import React from "react";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const G20EventPage = () => {
  const images = [
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-1.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-2.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-3.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-4.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-5.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-6.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-7.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-8.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-9.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-10.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-11.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-12.jpeg",
    "https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/images/g20/g20-13.jpeg",
  ];

  return (
    <div className="flex flex-wrap gap-2 mx-16">
      {images.map((image, index) => (
        <div key={index} className="w-1/2 md:w-1/4 h-1/2">
          <a href={image} data-fancybox="gallery" className="block h-full">
            <img src={image} alt={`G20 Event ${index + 1}`} className="h-full" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default G20EventPage;
