import React from "react";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const Sahyogi = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    customPaging: function (i) {
      return (
        <div className="h-[1rem] w-[1rem] customDot bg-[#C4B5FD] rounded-full mt-2">
          <p className="hidden">{i + 1}</p>
        </div>
      );
    },
  };

  const sliderItems = [
    {
      imgSrc: assetsManager.sahyogiCarousel1,
      shortDesc: "",
      title: "Bhashini Mitra",
      description:
        "Bhashini would seek help for data, application, knowledge, and Research in the program to help Bhashini Sanchalak.",
      link: "/sahyogi/anushandhan-mitra",
    },
    {
      imgSrc: assetsManager.sahyogiCarousel2,
      shortDesc: "",
      title: "Bhashini Startups",
      description:
        "Bhashini empowers startups to tackle challenges and deliver quality use cases with reimagined user journeys.",
      link: "/sahyogi/startup",
    },
    {
      imgSrc: assetsManager.sahyogiCarousel3,
      shortDesc: "",
      title: "Bhashini Udyat",
      description:
        "Bhashini’s aspiration is that all the Central & State government system, digital public goods, industry, publishers, and citizens consume Bhashini APIs",
      comingSoon: true,
    },
    {
      imgSrc: assetsManager.samudayeBg,
      shortDesc:
        "Unite. Innovate. Transform: Building a Global Community of Bhartiya Linguistic Specialists for a Better Tomorrow",
      title: "Bhashini Samudaye | Linguistic Specialist",
      description:
        "As a Linguistic Specialist, collaborate on innovative projects, access exclusive resources, and share your expertise with Bhashini team. ",
      comingSoon: false,
      link: "/samudaye",
    },
  ];

  const products = [
    {
      name: "Bhashini’s Startup",
      shortDesc: "Empowering NLP Innovation",
      longDesc:
        "Bhashini empowers startups to tackle challenges and deliver quality use cases with reimagined user journeys..",
      img: assetsManager.bhashiniStartup,
      link: "/sahyogi/startup",
      isAvailable: true,
    },
    {
      name: "Translation Service Provider",
      shortDesc: "Translation Services with Agencies Listing",
      longDesc: "",
      img: assetsManager.sahyogiTranslationServiceProviders,
      link: "/sahyogi/startup",
      isAvailable: true,
    },
    {
      name: "Anushandhan Mitra",
      shortDesc: "Research Partners",
      longDesc:
        "Anushandhan Mitra are partners who collaborate with us to develop technical resources for the Bhashini platform.",
      img: assetsManager.sahyogiAnushandhanMitra,
      link: "/sahyogi/anushandhan-mitra",
      isAvailable: true,
    },
    {
      name: "Data Mitra",
      shortDesc: "Data collection and curation companies",
      longDesc:
        "Data Mitra are organizations that collaborate with us by providing data for collection and curation, enabling data usage in Bhashini.",
      img: assetsManager.sahyogiDataMitra,
      link: "#",
      isAvailable: false,
    },
    {
      name: "App Mitra",
      shortDesc: "Applications Provider",
      longDesc:
        "App Mitra of Bhashini are collaborators who identify use cases and provide periodic solutions.",
      img: assetsManager.sahyogiAppMitra,
      link: "#",
      isAvailable: false,
    },
    {
      name: "Gyan Mitra",
      shortDesc: "Knowledge Provider",
      longDesc:
        "Gyan Mitra are partners who collaborate with us to provide technical and functional expertise for executing DIBD's objectives.",
      img: assetsManager.sahyogiGyanMitra,
      link: "#",
      isAvailable: false,
    },
  ];

  return (
    <div className="flex flex-col gap-[6.25rem]">
      <Slider {...settings}>
        <div className="samudaye-banner-bg !flex justify-between w-full relative h-[24.25rem] ">
          <div className="container">
            <div className="flex flex-col gap-6 w-full md:w-3/5 md:py-[5.47rem] sm:px-8 px-4 py-14">
              <p className="text-[2rem] font-medium text-white">
                Be a part of the Bhashini Samudaye Community
              </p>
              <p className="heading3 font-medium text-white">
                Register to Join the Bhartiya Linguistic Specialist Community
              </p>
              <Link
                to="/samudaye"
                className="buttonSmall bg-primary text-white font-semibold rounded mt-4 w-fit flex gap-3"
              >
                Join Bhashini Samudaye
                <img src={assetsManager.rightArrow} alt="arrow" />
              </Link>
              <div className="absolute h-full bottom-[-90%]">
                <Breadcrumbs />
              </div>
            </div>
          </div>
        </div>
        <div className="sahyogi-banner-bg !flex justify-between w-full relative h-[24.25rem] ">
          <div className="container">
            <div className="flex flex-col gap-6 w-full md:w-3/5 md:py-[5.47rem] md:px-16 px-4 py-14">
              <p className="heading1 font-semibold text-primary">
                Be a part of the BHASHINI Sahyogi Community
              </p>
              <p className="heading3 font-medium">
                Share your use cases to build a Voice-Powered Multilingual India
              </p>
              <Link
                to="/be-our-sahyogi"
                className="buttonSmall bg-primary text-white font-semibold rounded mt-4 w-fit"
              >
                Be Our Sahyogi
              </Link>
              <div className="absolute h-full bottom-[-90%]">
                <Breadcrumbs />
              </div>
            </div>
            <div className="w-2/5 hidden md:block absolute bottom-0 right-0">
              <img
                loading="lazy"
                alt="Bhashini banner title"
                src={assetsManager.sahyogiBannerRightImg}
              />
            </div>
          </div>
        </div>
      </Slider>

      <div className="container">
        <div className="flex flex-col gap-[6.25rem] md:mx-16 mx-4">
          <div className="flex flex-col gap-9">
            <div className="flex flex-col gap-2 text-center">
              <p className="heading1 font-semibold text-[#242424]">
                Bhashini Sahyogi
              </p>
              <p className="heading3 font-medium">
                Co-creation Engagement for both the adoption and creation of
                Bhashini offering with Mitra, Startups, & Udyat.
              </p>
            </div>
            <Slider {...settings}>
              {sliderItems.map((item, index) => (
                <div
                  key={index}
                  className="!flex relative h-[25rem] flex-col md:flex-row"
                >
                  <div className="md:w-1/2 w-full h-full">
                    <img
                      src={item.imgSrc}
                      loading="lazy"
                      alt="ministries"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="relative md:w-1/2 w-full h-full bg-white">
                    <div className="md:absolute relative right-0">
                      <img
                        loading="lazy"
                        src={assetsManager.dotIcon}
                        alt="dot"
                        height={150}
                        width={150}
                      />
                    </div>
                    <div className="absolute left-0 bottom-0">
                      <img
                        loading="lazy"
                        src={assetsManager.dotIcon}
                        alt="dot"
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                  <div className="left-[5%] top-[12%] w-[90%] px-4 absolute md:left-[45%] md:top-[10%] flex flex-col gap-6 bg-white dark:bg-black md:w-1/2 py-[3.06rem] md:px-[3.25rem] shadow-2xl rounded-[.5rem] ">
                    <div className="flex flex-col gap-[.62rem]">
                      <p className="text-primary font-semibold ">
                        {item.title}
                      </p>
                    </div>
                    <p className="heading3 font-medium">{item.description}</p>
                    {item.comingSoon ? (
                      <p className="text-[1.25rem] font-semibold text-primary">
                        Coming Soon...
                      </p>
                    ) : (
                      <Link
                        to={item.link}
                        className="bg-primary py-[.75rem] px-[1.5rem] text-white w-fit rounded"
                      >
                        View More
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="flex flex-col gap-[2.19rem]">
            <div className="flex flex-col gap-2 text-center">
              <p className="heading1 font-semibold text-[#242424]">
                Mitra: The Force Behind Bhashini&apos; Innovation
              </p>
              <p className="heading3 font-medium">
                Discover our Mitras, who empowers our journey
              </p>
            </div>
            <div className="flex flex-wrap gap-9">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="md:w-[30%] w-full shadow-3xl flex flex-col gap-5 dark:border dark:border-gray-400"
                >
                  <div className="h-[16rem]">
                    <img
                      src={product.img}
                      alt={product.name}
                      className="w-full h-full"
                    />
                  </div>
                  <div className="p-6 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <p className="heading2 font-semibold">{product.name}</p>
                      <p className="text-primary font-semibold">
                        {product.shortDesc}
                      </p>
                      <p>{product.longDesc}</p>
                    </div>
                    {product.isAvailable ? (
                      <Link
                        to={product.link}
                        className="bg-primary text-white text-center py-2 px-6 w-fit"
                      >
                        View More
                      </Link>
                    ) : (
                      <p className="text-[1.25rem] font-semibold text-primary">
                        Coming Soon...
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sahyogi;
