import React from "react";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check"; // Import the check icon

const CustomStepIcon = ({ active, completed, icon, isLastStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: "50%",
          backgroundColor: completed ? "green" : active ? "#fff" : "#fff",
          border: `1px solid ${active ? "green" : "#263238"}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: completed ? "#fff" : active ? "green" : "#263238",
          fontWeight: "bold",
          fontSize: "1rem",
          position: "relative",
          zIndex: 1,
        }}
      >
        {completed ? (
          <CheckIcon sx={{ fontSize: "1rem", color: "#fff" }} />
        ) : (
          icon
        )}
      </Box>
      {!isLastStep && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: "50%",
            height: "500%", // Make the vertical bar height responsive
            width: "2px",
            backgroundColor: "#e3e3e3",
            transform: "translateX(-50%)",
            zIndex: 0,
          }}
        />
      )}
    </Box>
  );
};

export default CustomStepIcon;
