import React from "react";
import withLayout from "../../hocs/withLayout";
import AboutBsv from "../AboutBsv/AboutBsv";
import Stages from "../Stages/Stages";
import Timelines from "../Timelines/Timelines";
import StartupEligibility from "../StartupEligibility/StartupEligibility";
import StartupContact from "../StartUpContact/StartupContact";
import StartupResult from "../StartUpResult/StartupResult";
import StartUpVelocityWinners from "../StartUpVelocityWinners/StartUpVelocityWinners";
import assetsManager from "../../assets/assetsManager";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// import OurJourney from "../../components/OurJourney/OurJourney";

const BhashiniStartupVelocity = () => {
  return <>About content</>;
};
export const bhashiniStartupItems = [
  {
    label: "About BSV",
    path: "about-bsv",
    component: AboutBsv,
  },
  {
    label: "Stages",
    path: "stages",
    component: Stages,
  },
  {
    label: "Timelines",
    path: "timelines",
    component: Timelines,
  },
  {
    label: "Eligibility",
    path: "eligibility",
    component: StartupEligibility,
  },
  {
    label: "Contact Us",
    path: "Contact Us",
    component: StartupContact,
  },
  {
    label: "Result",
    path: "result",
    component: StartupResult,
  },
  {
    label: "Winners",
    path: "winners",
    component: StartUpVelocityWinners,
  },
  // {
  //   label: "Our Journey",
  //   path: "our-journey",
  //   component: OurJourney,
  // }
];

const VelocityBanner = () => {
  return (
    <div className="velocity-banner !flex justify-between w-full relative h-full flex-col md:flex-row ">
      <div className="flex flex-col md:gap-6 gap-[.65rem] text-black md:w-2/3 w-full md:py-[6.31rem] md:px-[4rem] pt-[2.63rem] pb-[2.73rem] px-10">
        <div className="flex flex-col gap-[2.75rem]">
          <div>
            <p className="md:text-4xl text-[.96rem] font-semibold ">
              Bhashini Startup Velocity 1.0
            </p>
            <p className="md:text-lg text-[.48rem] font-medium">
              Accelerating Language Technology Solutions for Digital India.
            </p>
          </div>
          <p className="text-[2.5rem] text-[#0053AD]">
            Grand Finale results are out!!
          </p>
        </div>
        <div className="mt-[0.87rem]">
          <Breadcrumbs />
        </div>
      </div>
      <div className=" hidden md:block absolute bottom-0 right-0">
        <img
          loading="lazy"
          alt="Bhashini banner title"
          width={523}
          height={326}
          src={assetsManager.velocityLogo}
        />
      </div>
    </div>
  );
};

export default withLayout(
  bhashiniStartupItems,
  <VelocityBanner />
)(BhashiniStartupVelocity);
