import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
  StepConnector,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import PersonalInfo from "./PersonalInfo";
import CustomStepIcon from "./CustomStepIcon";
import { getValidationSchema } from "./formikConfig";
import EmailVerification from "./EmailVerification";
import ProfessionalDetails from "./ProfessionalDetails";
import LanguageExpertise from "./LanguageExpertise";
import axios from "axios";
import { SAMUDAYE_BACKEND_URL } from "../../config/config";
import { toast } from "react-toastify";
import AdditionalInformation from "./AdditionalInformation";

const CustomStepConnector = styled(StepConnector)(() => ({
  display: "none",
}));

const CustomStepLabel = styled(StepLabel)(() => ({
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  position: "relative",
  "& .MuiStepLabel-label": {
    marginLeft: "1rem",
    textAlign: "left",
  },
  "& .MuiStepIcon-root": {
    order: 2,
    position: "relative",
    zIndex: 1,
  },
}));

const SamudayeForm = forwardRef(({ language, organizations }, ref) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    user_type: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    state: "",
    organization_institute: organizations,
    organization_institute_name: "",
    designation: "",
    work_experience: "",
    domain_expertise: "",
    primary_language_expertise: "",
    secondary_language_expertise: "",
    ternary_language_expertise: "",
    primary_proficiency_level: "",
    secondary_proficiency_level: "",
    ternary_proficiency_level: "",
    relevant_links: "",
    portfolio_link: "",
    linkedin_profile: "",
    breif_description: "",
  });
  const [otherExpertise, setOtherExpertise] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState("");
  const [isSubmittingOtp, setIsSubmittingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(null);
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);

  const steps = [
    { label: "Personal Info" },
    { label: "Verification" },
    { label: "Professional Details" },
    { label: "Language Expertise" },
    { label: "Additional Information" },
  ];

  const validateForm = async () => {
    const schema = getValidationSchema(activeStep);
    try {
      // Validate based on the active step
      if (activeStep === 1) {
        // For step 1, validate OTP separately
        await schema.validate({ otp }, { abortEarly: false });
      } else {
        // For other steps, validate formData
        await schema.validate(formData, { abortEarly: false });
      }
      setErrors({});
      return true;
    } catch (err) {
      console.log(err, "err");
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the field being changed
    }));
  };
  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    setOtp(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the field being changed
    }));
  };

  const handleSubmit = async () => {
    const form = new FormData();
    form.append("user_type", formData.user_type);
    form.append("first_name", formData.first_name);
    form.append("last_name", formData.last_name);
    form.append("official_email", formData.email);
    form.append("phone_number", formData.phone_number);
    form.append("state", formData.state);
    form.append("organization_institute", formData.organization_institute);
    form.append(
      "organization_institute_name",
      formData.organization_institute_name
    );
    form.append("designation", formData.designation);
    form.append("work_experience", parseInt(formData.work_experience));
    form.append("domain_expertise", formData.domain_expertise);
    form.append(
      "primary_language_expertise",
      formData.primary_language_expertise
    );
    form.append(
      "secondary_language_expertise",
      formData.secondary_language_expertise
    );
    form.append(
      "ternary_language_expertise",
      formData.ternary_language_expertise
    );
    form.append(
      "primary_proficiency_level",
      formData.primary_proficiency_level
    );
    form.append(
      "secondary_proficiency_level",
      formData.secondary_proficiency_level
    );
    form.append(
      "ternary_proficiency_level",
      formData.ternary_proficiency_level
    );
    form.append("relevant_links", formData.relevant_links);
    form.append("portfolio_link", formData.portfolio_link);
    form.append("linkedin_profile", formData.linkedin_profile);
    form.append("brief_description", formData.breif_description);
    form.append("file", formData.file);
    if (
      otherExpertise &&
      formData.domain_expertise === "Other (please specify)"
    ) {
      form.append("domain_expertise", otherExpertise);
    }

    try {
      setIsFinalSubmit(true);
      await axios.post(
        `${SAMUDAYE_BACKEND_URL}/linguistics_registration`,
        form
      );
      toast.success("Form submitted successfully");
      // Show redirecting toast and reload the page after 3 seconds
      toast.info("Redirecting you in 3 seconds");
      setTimeout(() => {
        window.location.reload(); // Reload the page after 3 seconds
      }, 3000);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsFinalSubmit(false);
    }
  };

  const handleNext = async () => {
    const isValid = await validateForm();
    if (isValid) {
      switch (activeStep) {
        case 0:
          if (!isCheckbox) {
            setIsCheckbox(false);
            return;
          }
          sendOtp();
          ref.current.scrollIntoView({ behavior: "smooth" });
          break;
        case 1:
          verifyOtp();
          ref.current.scrollIntoView({ behavior: "smooth" });

          break;
        case 2:
          setActiveStep((prev) => prev + 1);
          ref.current.scrollIntoView({ behavior: "smooth" });
          break;
        case 3:
          setActiveStep((prev) => prev + 1);
          ref.current.scrollIntoView({ behavior: "smooth" });
          break;
        case 4:
          // Submit the form
          handleSubmit();
          console.log("Form submitted", formData);
          break;
        default:
          setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep((prevStep) => prevStep - 2);
    } else {
      setActiveStep((prev) => prev - 1);
    }
    setIsCheckbox(null);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PersonalInfo
            formData={formData}
            errors={errors}
            handleChange={handleChange}
            isCheckedBox={isCheckbox}
            setIsCheckbox={setIsCheckbox}
          />
        );
      case 1:
        return (
          <EmailVerification
            formData={formData}
            errors={errors}
            handleOtpChange={handleOtpChange}
            resendOtp={resendOtp}
            sendOtp={sendOtp}
          />
        );
      // Add other steps similarly
      case 2:
        return (
          <ProfessionalDetails
            formData={formData}
            errors={errors}
            organizations={organizations}
            handleChange={handleChange}
          />
        );
      case 3:
        return (
          <LanguageExpertise
            formData={formData}
            errors={errors}
            handleChange={handleChange}
            language={language}
            otherExpertise={otherExpertise}
            setOtherExpertise={setOtherExpertise}
          />
        );
      case 4:
        return (
          <AdditionalInformation
            errors={errors}
            formData={formData}
            handleFileChange={handleFileChange}
            handleChange={handleChange}
            fileName={formData.file?.name}
          />
        );
      default:
        return <Typography>Unknown Step</Typography>;
    }
  };

  const sendOtp = async () => {
    try {
      setIsSubmittingOtp(true);
      await axios.post(`${SAMUDAYE_BACKEND_URL}/linguistics_regn_send_otp`, {
        email_id: formData.email,
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      toast.success("OTP sent successfully");
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsSubmittingOtp(false);
    }
  };

  const verifyOtp = async () => {
    if (!otp) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        otp: "Verification code is required",
      }));
      return;
    }
    try {
      setIsVerifyingOtp(true);
      await axios.post(`${SAMUDAYE_BACKEND_URL}/verify_otp`, {
        email_id: formData.email,
        otp: otp,
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      toast.success("OTP verified successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.detail);
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  return (
    <div
      className="w-full h-full bg-no-repeat bg-cover pb-[2rem] mt-[6.25rem] flex flex-col gap-9 xl:container"
      ref={ref}
    >
      <div className="flex flex-col gap-4">
        <Typography
          sx={{
            color: "#150202",
            fontFamily: "Noto Sans",
            fontSize: "1.75rem",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "1.6875rem",
            fontVariant: "all-small-caps",
          }}
        >
          Bhashini Samudaye - Linguistic Specialist Registration
        </Typography>
        <Typography
          sx={{
            color: "#3A3A3C",
            fontFamily: "Noto Sans",
            fontSize: "1.125rem",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "1.6875rem",
          }}
        >
          Join the Community Empowering India’s Linguistic Future
        </Typography>
      </div>

      <Container sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "flex-start",
            gap: "3.56rem",
            flexGrow: 1,
          }}
        >
          <Stepper
            sx={{
              height: "100%",
              "& .MuiStep-root": {
                flexBasis: "20%",
                marginBottom: "1.88rem",
              },
            }}
            activeStep={activeStep}
            orientation="vertical"
            connector={<CustomStepConnector />}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <CustomStepLabel
                  StepIconComponent={(props) => (
                    <CustomStepIcon
                      {...props}
                      isLastStep={index === steps.length - 1}
                    />
                  )}
                >
                  <Typography
                    sx={{
                      marginRight: "1.88rem",
                      textAlign: "right",
                      color: "#7c8387",
                      fontFamily: "Noto Sans",
                      fontSize: "1rem",
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`Step ${index + 1}`}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "right",
                      marginRight: "1.88rem",
                      color: "#000",
                      fontFamily: "Noto Sans",
                      fontSize: "1.125rem",
                      fontWeight: activeStep === index ? "600" : "500",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {step.label}
                  </Typography>
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
          <Box
            // ref={formRef.current}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              gap: "1.12rem",
              padding: "1.5rem",
              backgroundColor: "#fff",
              borderRadius: "1.125rem",
              boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.06)",
            }}
          >
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1.12rem",
              }}
            >
              {activeStep > 0 && (
                <Button onClick={handleBack} disabled={activeStep === 0}>
                  Back
                </Button>
              )}
              <Button
                sx={{
                  "&:hover": {
                    backgroundColor: "#2947A3",
                  },
                  marginLeft: "auto",
                  display: "flex",
                  height: "3.4375rem",
                  padding: "0.875rem 2rem",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  gap: "0.75rem",
                  borderRadius: "0.375rem",
                  backgroundColor: "#2947A3",
                  color: "#fff",
                  //                   border-radius: 0.375rem;
                  // background: var(--Brand-Color-Maincolor, #2947A3);
                }}
                onClick={handleNext}
                disabled={
                  activeStep === 1
                    ? isVerifyingOtp
                    : activeStep === 0
                    ? isSubmittingOtp
                    : isFinalSubmit
                }
              >
                {activeStep === 1 ? (
                  isVerifyingOtp ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Next"
                  )
                ) : activeStep === 0 ? (
                  isSubmittingOtp ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Send OTP"
                  )
                ) : activeStep === steps.length - 1 ? (
                  isFinalSubmit ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Submit"
                  )
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
});
SamudayeForm.displayName = "SamudayeForm";

export default SamudayeForm;
