import React, { useState } from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import assetsManager from "../../assets/assetsManager";
import ArticleCard from "./ArticleCard";
import { handleExternalLinks } from "../../utils/utilities";
import { NEWS_DATA } from "../../AppConstants";
import VideoCard from "./VideoCard";

const BhashiniNews = () => {
  const bhahsiniNewsRef = useScrollToRef();

  const ITEMS_PER_PAGE = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState("");

  const totalPages = Math.ceil(NEWS_DATA.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setInputPage(""); // Clear the input field after navigating
    }
  };

  const handleInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handleInputPageSubmit = () => {
    const page = parseInt(inputPage, 10);
    if (!isNaN(page)) {
      handlePageChange(page);
    }
  };

  const currentData = NEWS_DATA.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="flex flex-col gap-9" ref={bhahsiniNewsRef}>
      <div className="flex flex-col gap-[.94rem]">
        <div className="flex flex-col">
          <p className="heading1 font-bold text-[#242424]">Bhashini in News</p>
        </div>
        <div className="flex justify-between">
          <p className="heading3 font-medium text-[#242424]">
            Stay Updated with Bhashini{"'"}s Latest
          </p>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col flex-wrap gap-9 justify-between w-full">
        {currentData.map((newsData) =>
          newsData.type === "article" ? (
            <button
              className="sm:w-[45%] w-full"
              onClick={() => handleExternalLinks(newsData.articleUrl)}
              key={newsData.articleUrl}
            >
              <ArticleCard
                key={newsData.articleUrl}
                title={newsData.title}
                date={newsData.date}
                articleUrl={newsData.articleUrl}
                thumbnail={newsData.thumbnail}
              />
            </button>
          ) : (
            newsData.type === "video" && (
              <VideoCard
                date={newsData.date}
                duration={newsData.duration}
                title={newsData.title}
                videoUrl={newsData.videoUrl}
                key={newsData.videoUrl}
              />
            )
          )
        )}
      </div>
      <div className="flex gap-2 items-center self-end">
        <div
          className="flex p-2 justify-center items-center rounded cursor-pointer"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <img src={assetsManager.leftArrow} alt="left arrow" />
        </div>

        <div className="flex gap-2">
          {[...Array(totalPages)].map((_, index) => (
            <div
              key={index}
              className={`rounded flex py-[.38rem] px-[.5rem] cursor-pointer ${
                currentPage === index + 1 ? "bg-[#ddd]" : ""
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              <p className="text-[.875rem]">{index + 1}</p>
            </div>
          ))}
        </div>
        <div
          className="flex p-2 justify-center items-center rounded cursor-pointer"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <img src={assetsManager.rightArrow} alt="right arrow" />
        </div>
        <div className="flex items-center">
          <p className="mr-2 text-[#7E838B]"> Showing</p>
          <input
            type="number"
            value={inputPage}
            onChange={handleInputChange}
            placeholder="Page #"
            className="w-[4rem] p-1 border rounded"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleInputPageSubmit();
              }
            }}
          />
          <button
            onClick={handleInputPageSubmit}
            className="ml-2 p-2 bg-primary text-white rounded"
          >
            Go
          </button>
        </div>
      </div>
    </div>
  );
};

export default BhashiniNews;
