import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import rdProjects from "../../utils/rdProject";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";

const AnushandhanMitra = () => {
  

  return (
    <div className="w-full flex-col flex gap-[4.5rem]">
      <div className="w-full text-white h-[24rem] px-[9.06rem] pl-8 pr-44  flex flex-col anushandhan-mitra-banner-bg">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Mitra: Anushandhan Mitra</p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-col md:mx-16 mx-4">
        <div className="flex flex-col item-center justify-center our-research-partners w-full h-[22rem] text-center text-white">
          <p className="heading1 font-medium">
            Anushandhan Mitra: Our Research Partners
          </p>
          <p className="font-medium">Empowering Data and Model Development</p>
        </div>
      </div>
      <div className="flex flex-col text-center md:mx-16 mx-4 gap-9">
        <p className="text-[2rem] font-semibold">
          Explore our <span className="text-primary">R&D Projects</span>
        </p>
        <div className="flex flex-wrap gap-9">
          {rdProjects.map((product, index) => (
            <div
              key={index}
              className="md:w-[30%] dark:border dark:border-gray-400 w-full shadow-lg flex flex-col gap-5 text-left"
            >
              <div className="flex flex-col gap-6 p-6 flex-1">
                <div className="flex flex-col">
                  <p className="text-[1.25rem] font-semibold">{product.name}</p>
                </div>
                {product.professors.map((professor, index) => (
                  <div key={index} className="flex flex-col gap-1">
                    <p className="paragraph2 font-medium">{professor.name}</p>
                    <p className="text-[#656565] paragraph2">
                      {professor.designation}
                    </p>
                  </div>
                ))}
                <div className="flex-1 flex">
                <Link
                to={`/sahyogi/anushandhan-mitra/${product.id}`}

                  className="bg-primary py-3 px-6 rounded text-white w-fit h-fit mt-auto ml-auto"
                >
                  Explore Now
                </Link>
                </div>
             
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnushandhanMitra;
