import React from "react";

export const SprintResult = () => {
  const stage2Winner = [
    {
      id: 1,
      problemStatementName: "Develop a chat-bot facilitating multilingual customer support for seller and buyer",
      qualifier: [
        {
          id: 1,
          qualifierName: "HPE PVT LTD",
        },
        {
          id: 2,
          qualifierName: "Grossky Software Services",
        },
        {
          id: 3,
          qualifierName: "Pulastya AI Private Limited",
        },
      ]
    },
    {
      id: 2,
      problemStatementName: "Bhashini Negotiation Engine :Tech Functionality to bridge the language gap during buyer-seller negotiations",
      qualifier: [
        {
          id: 1,
          qualifierName: "Atomstate",
        },
        {
          id: 2,
          qualifierName: "CodeSage",
        },
        {
          id: 3,
          qualifierName: "Khyal_AI",
        },
      ]
    },
    {
      id: 3,
      problemStatementName: "Develop a software facilitating multilingual new business onboarding on E-commerce platform",
      qualifier: [
        {
          id: 1,
          qualifierName: "Q-star",
        },
        {
          id: 2,
          qualifierName: "CivicCraft Online Solutions Private Limilted",
        },
      ]
    },
    {
      id: 4,
      problemStatementName: "Streamlined Inventory management with Voice Commands and advanced image detection for sellers.    ",
      qualifier: [
        {
          id: 1,
          qualifierName: "Stocksens Solutions ",
        },
        {
          id: 2,
          qualifierName: "EAGLE EYE - Voice-Enabled Inventory Management System with Advanced Image Recognition",
        },
        {
          id: 3,
          qualifierName: "Quantum Creators ",
        },
        {
          id: 4,
          qualifierName: "AI Pragati Pioneers",
        }
      ]
    },
    {
      id: 5,
      problemStatementName: "Functionality empowering voice-activated shopping in AR/VR for multilingual users.",
      qualifier: [
        {
          id: 1,
          qualifierName: "The boys 002",
        },
        {
          id: 2,
          qualifierName: "SpeakIt"
        }
      ]
    }
  ]

  // const stage1Winner = [
  //   {
  //     id: 1,
  //     problemStatementName: "Develop a chat-bot facilitating multilingual customer support for seller and buyer",
  //     qualifier: [
  //       {
  //         id: 1,
  //         qualifierName: "HPE PVT LTD",
  //       },
  //       {
  //         id: 2,
  //         qualifierName: "PolyGlot",
  //       },
  //       {
  //         id: 3,
  //         qualifierName: "Multilingo",
  //       },
  //       {
  //         id: 4,
  //         qualifierName: "VoiceVerse",
  //       },
  //       {
  //         id: 5,
  //         qualifierName: "Tech Tribe",
  //       },
  //       {
  //         id: 6,
  //         qualifierName: "Facilitator",
  //       },
  //       {
  //         id: 7,
  //         qualifierName: "Grossky Software Services",
  //       },
  //       {
  //         id: 8,
  //         qualifierName: "XENO",
  //       },
  //       {
  //         id: 9,
  //         qualifierName: "Pulastya AI Private Limited",
  //       },
  //       {
  //         id: 10,
  //         qualifierName: "Team Lexical",
  //       },
  //       {
  //         id: 11,
  //         qualifierName: "Team Arjun",
  //       },
  //       {
  //         id: 12,
  //         qualifierName: "Sale Buddy",
  //       },
  //       {
  //         id: 13,
  //         qualifierName: "VyaparSahayak",
  //       },
  //       {
  //         id: 14,
  //         qualifierName: "Civiccraft Online Solutions Private Limited",
  //       }
  //     ]
  //   },
  //   {
  //     id: 2,
  //     problemStatementName: "Bhashini Negotiation Engine :Tech Functionality to bridge the language gap during buyer-seller negotiations",
  //     qualifier: [
  //       {
  //         id: 1,
  //         qualifierName: "Atomstate",
  //       },
  //       {
  //         id: 2,
  //         qualifierName: "CodeSage",
  //       },
  //       {
  //         id: 3,
  //         qualifierName: "Khyal_AI",
  //       },
  //     ]
  //   },
  //   {
  //     id: 3,
  //     problemStatementName: "Develop a software facilitating multilingual new business onboarding on E-commerce platform",
  //     qualifier: [
  //       {
  //         id: 1,
  //         qualifierName: "Q-star",
  //       },
  //       {
  //         id: 2,
  //         qualifierName: "CivicCraft Online Solutions Private Limilted",
  //       },
  //     ]
  //   },
  //   {
  //     id: 4,
  //     problemStatementName: "Streamlined Inventory management with Voice Commands and advanced image detection for sellers.    ",
  //     qualifier: [
  //       {
  //         id: 1,
  //         qualifierName: "Stocksens Solutions ",
  //       },
  //       {
  //         id: 2,
  //         qualifierName: "EAGLE EYE - Voice-Enabled Inventory Management System with Advanced Image Recognition",
  //       },
  //       {
  //         id: 3,
  //         qualifierName: "Quantum Creators ",
  //       },
  //       {
  //         id: 4,
  //         qualifierName: "AI Pragati Pioneers",
  //       }
  //     ]
  //   },

  // ]



  return (
    <div className=" flex flex-col gap-[6.25rem]">


    <div className="flex flex-col gap-9">
      <div className="flex flex-col gap-9">
        <p className="heading1 font-semibold">Result: Stage 2</p>
      </div>
      <div className="w-full bg-[#EFF3FE] px-[2.3rem] py-6">
          <table className="w-full">
            <thead>
              <tr className="bg-[#DFEAF5]">
                <th className="text-left smallText p-4">Sr. No.</th>

                <th className="text-left smallText p-4">Problem Statement</th>
                <th className="text-left smallText p-4">Qualifier</th>
              </tr>
            </thead>
            <tbody>
              {stage2Winner.map((data) => (
                <tr
                  key={data.id}
                  className="border border-[#EAECF0]  bg-[#F9FAFB]"
                >
                  <td className="p-4 paragraph2">{data.id}</td>
                  <td className="p-4 paragraph2">{data.problemStatementName}</td>
                  <td className="p-4 paragraph2 flex flex-col gap-9">
                    {data.qualifier.map((qualifier) => (
                      <p key={qualifier.id}>{qualifier.qualifierName}</p>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </div>
    </div>
  );
};
