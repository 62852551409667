import React from "react";

const SprintAwardsOutComes = () => {
  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold">
        Bhashini Sprint:{" "}
        <span className="text-primary">Awards And Outcomes</span>
      </p>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">
          Fast Track your future:
        </p>
        <p>
          A platform to innovate and deploy the solution for usage across
          government entities.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">
          Customers Outreach:
        </p>
        <p>
          A high viewership platform provides you with the opportunity to
          showcase and promote your innovation to leaders from organizations
          across Indian Industry Sectors.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">
          Grow your expectations:
        </p>
        <p>
          Opportunity to meet peers in the field and get to know the latest
          advancement in the Ecosystem. Your peers in this program are the
          region’s finest. They are an important part of the experience, so we
          ensure you work with the very best players.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">
          Recognition and Reward:
        </p>
        <p>
          Win lucrative prize money at various stages of the program with a GIA
          for a total amount of INR 7 lakhs (5 lakh for winning team+ 2 Lakh for
          the runners up) per problem statement.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">
          Build Credentials and Testimonials for your product:
        </p>
        <p>
          The program has the potential to facilitate widespread adoption of
          your product across various government departments, enhancing its
          visibility. Moreover, upon successful implementation in diverse
          government sectors, your product may garner credentials, allowing you
          to approach government customers with verified testimonials.
        </p>
      </div>
    </div>
  );
};

export default SprintAwardsOutComes;
