import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import startupCards from "../../assets/images/startups/startupCards.png";
import assetsManager from "../../assets/assetsManager";
import { Link } from "react-router-dom";
const StartupHomePage = () => {
  const cardData = [
    {
      heading: " Bhashini Startup Velocity 1.0",
      description:
        "Accelerates language tech innovation through targeted startup programmes.",
      logo: assetsManager.startupVelocityLogo,
      link: "/sahyogi/startup/about-bsv",
    },
    {
      heading: "Bhashini Sprint",
      description:
        "Fosters innovation in specific domains like e-commerce, retail, and D2C.",
      logo: assetsManager.sprintLogo,
      link: "/sahyogi/startup/about-sprint",
    },
    {
      heading: "Bhasha Techathon",
      description: "Partners Sponsored Intitaives",
      logo: assetsManager.techathon,
      link: "https://bhashatechathon.com/",
    },
    {
      heading: "Retail Commerce | D2C",
      description:
        "Accelerates language tech innovation through targeted startup programmes.",
      logo: assetsManager.ondc,
      link: "https://hack2skill.com/build-for-bharat-hackathon-ondc-google-cloud",
    },
    {
      heading: "Grand Innovation Challenge",
      description:
        "It targets two problems: simultaneous speech translation and OCR-based translation and response for written communications",
      logo: assetsManager.bhashiniGIC,
      link: "https://innovateindia.mygov.in/bhashini-challenge/",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-[6.25rem]">
      <div className="w-full text-white h-[24rem] md:px-16 px-4  flex flex-col startup-banner-bg">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Mitra: Bhashini Startups</p>
          <p className="paragraph1">
            Bhashini fosters NLP startups, simplifying processes across sectors
            with innovative solutions, empowering impactful user experiences.
          </p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-col gap-[6.25rem] items-center font-semibold w-full ">
        <div className="flex flex-col gap-9 w-full items-center mx-16" >
        <p className="text-[2.25rem] text-center text-primary">
          Here’s How Bhashini empowers Startup
        </p>
        <div className="flex flex-wrap flex-col md:flex-row gap-10 justify-center w-[90%]">
          <div className="flex flex-col gap-[3.38rem]  md:w-[30%] w-[90%] border border-gray-100 shadow-lg ">
            <div className="w-full">
              <img
                src={startupCards}
                alt="ceo speaks"
                className="h-[7.9rem] w-full"
              />
            </div>
            <div className="flex flex-col gap-3 text-center  mx-4 mb-[3.88rem]">
              <p className="text-[1.5rem] text-primary">
                Hackathons and Challenges
              </p>
              <p className="paragraph1 font-normal">
                Bhashini involves startups in 35 challenge rounds and
                hackathons, providing platforms for demonstration, feedback, and
                recognition.
              </p>
            </div>
          </div>
          <div className="flex flex-col  gap-[3.38rem] md:w-[30%] w-[90%] border border-gray-100 shadow-lg ">
            <div className="w-full">
              <img
                src={startupCards}
                alt="ceo speaks"
                className="h-[7.9rem] w-full"
              />
            </div>
            <div className="flex flex-col gap-3 text-center  mx-4">
              <p className="text-[1.5rem] text-primary">
                Demand-Driven Ecosystem
              </p>
              <p className="paragraph1 font-normal">
                Open-source datasets are accessible while applications developed
                can remain proprietary, fostering commercial viability.
              </p>
            </div>
          </div>
          <div className="flex flex-col  m-auto gap-[3.38rem] md:w-[30%] w-[90%] border border-gray-100 shadow-lg ">
            <div className="w-full">
              <img
                src={startupCards}
                alt="ceo speaks"
                className="h-[7.9rem] w-full"
              />
            </div>
            <div className="flex flex-col gap-3 text-center mx-4 mb-[3.88rem]">
              <p className="text-[1.5rem] text-primary">
                Provision of Resources and Technological Infrastructure
              </p>
              <p className="paragraph1 font-normal">
                Access to resources and technical facilities like cloud
                platforms.
              </p>
            </div>
          </div>
        </div>
        </div>
        <div className="flex flex-col gap-9 m-auto text-center">
          <div className="flex flex-col gap-2">
          <p className="heading1 font-semibold text-primary">
            Bhashini Startup Initiative
          </p>
          <p className="heading3">
            Catalyzing growth and innovation for language tech startups in
            India.
          </p>
          </div>
          <div className="flex flex-wrap flex-col md:flex-row gap-4 justify-center">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="md:w-[30%] m-auto md:m-0 w-[90%]  flex flex-col gap-[2.38rem] p-6 border border-gray-200 shadow-lg rounded"
              >
                <img
                  src={card.logo}
                  alt="Bhashini Startups"
                  className="h-[11rem] w-[21rem] m-auto"
                />
                <div className="flex flex-col gap-2 w-full text-left">
                  <p className="heading3 ">{card.heading}</p>
                  <p className="font-normal">{card.description}</p>
                  <Link
                    to={card.link}
                    className="text-primary text-right"
                  >
                    View More Detail
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupHomePage;
