import tenderEoi1 from "./tenderEoi1.pdf";
import tenderEoi2 from "./tenderEoi2.docx";
import tenderEoi3 from "./tenderEoi3.pdf";
import tenderEoi4 from "./tenderEoi4.pdf";
import tenderEoi5 from "./tenderEoi5.pdf";
// import tenderEoi6 from "./tenderEoi6.pdf"
import tenderEoi7 from "./tenderEoi7.pdf";
import tenderEoi8 from "./tenderEoi8.pdf";
import tenderEoi9 from "./tenderEoi9.pdf";
import tenderEoi10 from "./tenderEoi10.pdf";
import rfeDibd from "./RFEDIBD.pdf";
import CorrigendumSocialMedia from "./Corrigendum Social Media.pdf";
import CorrigendumSocialMedia2 from "./Corrigendum 2 Media and Event.pdf";
import CorrigendumSocialMedia3 from "./Corrigendum 3 Media and Event.pdf";
import HEIREF from "./HEIREF.pdf";
import HEIREF1 from "./HEIREF 1.pdf";

import cori1 from "./Cori 1.pdf";
import c3fr99 from "./c3-FR99.pdf";
import c2HeiFinal from "././C2_HEI-FINAL-signed.pdf";
import hei16Jul from "./Adobe Scan 16 Jul 2024.pdf";
import techManPower from "./RFE-TECHMANPOWER-VFINAL.pdf";
import empanelmentForChatBot from "../documents/Draft Request for Empanelment for Chatbot.pdf";
import nmtEvaluation from "./NMT Evaluation Workshop MoM-cum-Report_approved 1.pdf";

import industryConsultation from "./Industry Consultation for RFE for BHASHINI System Integrators for VoiceText Multilingual Solution for the BHASHINI project.pdf";
export const docsManager = {
  tenderEoi1,
  tenderEoi2,
  tenderEoi3,
  tenderEoi4,
  tenderEoi5,
  // tenderEoi6,
  tenderEoi7,
  tenderEoi8,
  tenderEoi9,
  tenderEoi10,
  rfeDibd,
  CorrigendumSocialMedia,
  CorrigendumSocialMedia2,
  CorrigendumSocialMedia3,
  HEIREF,
  hei16Jul,
  cori1,
  c3fr99,
  c2HeiFinal,
  industryConsultation,
  HEIREF1,
  techManPower,
  empanelmentForChatBot,
  nmtEvaluation,
};
