// FormComponent.js
import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./TechnologyDayForm.scss";
import { toast } from "react-toastify";

import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const IndianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

const Categories = [
  "Education",
  "Environment",
  "Health",
  "Agriculture & Livelihood",
  "Public Services Delivery & Grievances",
  "Law Enforcement",
  "Business",
  "Transportation",
  "Governance",
  "Judiciary",
  "Finance",
  "Retail & E-commerce",
  "Travel & Tourism",
  "Telecommunication",
  "Disaster Management",
  "Others",
];

const TechnologyDayForm = () => {
  const [formData, setFormData] = useState({
    org_name: "",
    orgType: "Central Government",
    state: "",
    representative_name: "",
    org_website: "",
    gst_number: "",
    official_mailid: "",
    personal_mailid: "",
    contact: "",
    sector: "Education",
    usecase: "",
    document: null,
    detail: "",
    confirmation: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const refs = {
    org_name: useRef(null),
    state: useRef(null),
    representative_name: useRef(null),
    org_website: useRef(null),
    gst_number: useRef(null),
    official_mailid: useRef(null),
    personal_mailid: useRef(null),
    contact: useRef(null),
    usecase: useRef(null),
    sector: useRef(null),
    otherSector: useRef(null),
    document: useRef(null),
    detail: useRef(null),
    confirmation: useRef(null),
  };

  const handleOrgTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      orgType: value,
      // Reset state when orgType changes
      state: "",
    });
  };

  const handleSectorTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      sector: value,
      // Reset state when orgType changes
      otherSector: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue =
      type === "checkbox"
        ? checked
        : type === "file"
        ? e.target.files[0]
        : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the specific error for this field
    });
  };
  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!formData.org_name.trim() || formData.org_name === "") {
      errors.org_name = "Organisation name is required";
    }

    if (formData.orgType === "State Government") {
      if (!formData.state.trim() || formData.state === "") {
        errors.state = "State is required";
      }
    }

    if (
      !formData.representative_name.trim() ||
      formData.representative_name === ""
    ) {
      errors.representative_name = "Name is required";
    }

    if (!formData.org_website.trim() || formData.org_website === "") {
      errors.org_website = "Organisation website is required";
    }
    if (!formData.gst_number.trim() || formData.gst_number === "") {
      errors.gst_number = "GST Number is required";
    } else if (formData.gst_number.length !== 15) {
      errors.gst_number = "Invalid GST Number. Please enter a valid 15 digit GST";
    }
    if (!formData.official_mailid.trim() || formData.official_mailid === "") {
      errors.official_mailid = "Official mail id is required";
    } else if (!emailPattern.test(formData.official_mailid)) {
      errors.official_mailid = "Invalid Email Id. Please enter a valid email";
    }
    if (!formData.personal_mailid.trim() || formData.personal_mailid === "") {
      errors.personal_mailid = "Personal mail id is required";
    } else if (!emailPattern.test(formData.personal_mailid)) {
      errors.personal_mailid = "Invalid Email format. Please enter a valid email";
    }
    if (!formData.contact.trim() || formData.contact === "") {
      errors.contact = "Contact number is required";
    } else if (!phonePattern.test(formData.contact)) {
      errors.contact = "Contact number must be 10 digits long";
    }
    if (!formData.usecase.trim() || formData.usecase === "") {
      errors.usecase = "Usecase title is required";
    }

    if (!formData.sector.trim() || formData.sector === "") {
      errors.sector = "sector is required";
    }

    if (formData.sector === "Others") {
      if (!formData.otherSector.trim() || formData.otherSector === "") {
        errors.otherSector = "Sector is required";
      }
    }

    if (!formData.document || formData.document === null) {
      errors.document = "File upload is required";
    }

    if (!formData.detail.trim() || formData.detail === "") {
      errors.detail = "Detail is required";
    }

    if (formData.confirmation === false) {
      errors.confirmation = "Please check to confirm the above information";
    }

    return errors;
  };
  const [fileKey, setFileKey] = useState(Date.now()); // New state for key property
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const formDataToSend = new FormData();

        formDataToSend.append("organisation_name", formData.org_name);
        formDataToSend.append("organisation_type", formData.orgType);
        formDataToSend.append("state", formData.state);
        // formDataToSend.append(keyName, formData.organization_name);
        formDataToSend.append(
          "representative_name",
          formData.representative_name
        );
        // formDataToSend.append('representative_name', formData.full_name);
        formDataToSend.append("organisation_website", formData.org_website);
        formDataToSend.append("gst_number", formData.gst_number);
        // formDataToSend.append('organisation', formData.organization_name);
        formDataToSend.append("organisation_mail", formData.official_mailid);
        formDataToSend.append("personal_mail", formData.personal_mailid);
        formDataToSend.append("contact_number", formData.contact);
        formDataToSend.append(
          "sector",
          formData.sector === "Others" ? formData.otherSector : formData.sector
        );
        formDataToSend.append("usecase_title", formData.usecase);
        formDataToSend.append("file", formData.document);
        formDataToSend.append("details", formData.detail);

        // Send POST request using Axios
        const response = await axios.post(
          "https://dashboard-be.bhashini.co.in/v1/technology-day/submit",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle response
        if (response.status === 200) {
          console.log("Data sent successfully", response.message);
          toast.success(response.data?.message);
          setErrors({});
             setFormData({
        org_name: "",
        orgType: "Central Government",
        state: "",
        representative_name: "",
        org_website: "",
        gst_number: "",
        official_mailid: "",
        personal_mailid: "",
        contact: "",
        sector: "",
        usecase: "",
        document: null,
        detail: "",
        confirmation: false,
      });
        } else {
          toast.error(response.data?.detail);
        }
      } catch (error) {
        console.error("Error sending data:", error);
        toast.error("Something went wrong.Please try again later.");
        setLoading(false);
      }
      finally{
        setLoading(false);
      }
   
    } else {
      setErrors(validationErrors);
      const firstErrorField = Object.keys(validationErrors)[0];
      if (firstErrorField && refs[firstErrorField].current) {
        console.log("firstErrorField", firstErrorField, refs[firstErrorField])
        refs[firstErrorField].current.scrollIntoView({ behavior: "smooth" });
      }
      setLoading(false); // Reset loading state in case of validation errors
    }  setFileKey(Date.now()); // Reset file input key
    // Add your form submission logic here
  };

  return (
    <div className="TechnologyDay__FormBackground">
      <div className="TechnologyDay__Content1">
        <div className="TechnologyDay__Header">
          Share your thoughts and help us find solutions together!
        </div>
        <div className="TechnologyDay__Text">
          Your contributions will guide us in creating multilingual solutions
          that truly meet the needs of diverse communities all over India.
        </div>
      </div>
      <Container>
        <form onSubmit={handleSubmit} className="TechnologyDay__FormContainer">
          <div className="FormComponent__Text" style={{ marginBottom: "20px" }}>
            Organisation Details
          </div>
          <Grid container spacing={2} justifyContent="start">
            <Grid item xs={12} sm={6}>
              <TextField
              inputRef={refs.org_name}
                fullWidth
                label="Organisation name"
                variant="outlined"
                name="org_name"
                value={formData.org_name}
                onChange={handleChange}
                helperText={errors.org_name}
                error={
                  errors.org_name &&
                  formData.org_name === "" &&
                  Boolean(errors.org_name)
                  
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label" >
                  Type of Organisation
                </InputLabel>
                <Select
                  inputRef={refs.orgType}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.orgType}
                  onChange={handleOrgTypeChange}
                  name="orgType"
                  label="Type of Organisation"
                  
                >
                  <MenuItem value="Central Government">
                    Central Government
                  </MenuItem>
                  <MenuItem value="State Government">State Government</MenuItem>
                  <MenuItem value="Public Sector">Public Sector</MenuItem>
                  <MenuItem value="Private Organisation">
                    Private Organisations
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formData?.orgType === "State Government" && (
              <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select State
                  </InputLabel>
                  <Select
                    inputRef={refs.state}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.state}
                    onChange={handleChange}
                    name="state"
                    label="Select State"
                    error={
                      errors.state &&
                      formData.state === "" &&
                      Boolean(errors.state)
                    }
                  >
                    <MenuItem value="">Select State</MenuItem>
                    {IndianStates.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
              <TextField
                fullWidth
                inputRef={refs.representative_name}
                helperText={errors.representative_name}
                label="Representative name"
                variant="outlined"
                name="representative_name"
                value={formData.representative_name}
                onChange={handleChange}
                error={
                  errors.representative_name &&
                  formData.representative_name === "" &&
                  Boolean(errors.representative_name)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
              <TextField
              inputRef={refs.org_website}
                fullWidth
                helperText={errors.org_website}
                label="Organisation Website"
                variant="outlined"
                name="org_website"
                value={formData.org_website}
                onChange={handleChange}
                error={
                  errors.org_website &&
                  formData.org_website === "" &&
                  Boolean(errors.org_website)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
              <TextField
                inputRef={refs.gst_number}
              helperText={errors.gst_number}
                fullWidth
                label="GST Number"
                variant="outlined"
                name="gst_number"
                value={formData.gst_number}
                onChange={handleChange}
                error={
                  errors.gst_number &&
                  formData.gst_number === "" &&
                  Boolean(errors.gst_number) ||
                  (errors.gst_number &&
                  formData.gst_number.length !== 15 &&
                  Boolean(errors.gst_number))

                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} sx={{marginTop:"10px"}}>
            <TextField
              fullWidth
              label="PAN Number"
              variant="outlined"
              name="casestudy_title"
              value={formData.casestudy_title}
              onChange={handleChange}
              required
            />
          </Grid> */}

            <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
              <div className="FormComponent__Text">Contact Details</div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={refs.official_mailid}
              helperText={errors.official_mailid}
                fullWidth
                label="Official Mail ID"
                variant="outlined"
                name="official_mailid"
                value={formData.official_mailid}
                onChange={handleChange}
                error={
                  errors.official_mailid &&
                  formData.official_mailid === "" &&
                  Boolean(errors.official_mailid) ||
                  (errors.official_mailid &&
                  !formData.official_mailid.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
                  Boolean(errors.official_mailid))

                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={refs.personal_mailid}
                helperText={errors.personal_mailid}
                fullWidth
                label="Personal Mail Id"
                variant="outlined"
                name="personal_mailid"
                value={formData.personal_mailid}
                onChange={handleChange}
                error={
                  errors.personal_mailid &&
                  formData.personal_mailid === "" &&
                  Boolean(errors.personal_mailid) ||
                  (errors.personal_mailid &&
                  !formData.personal_mailid.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
                  Boolean(errors.personal_mailid))

                }
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
              <TextField
                inputRef={refs.contact}
                helperText={errors.contact}
                fullWidth
                label="Contact Number"
                variant="outlined"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                error={
                  errors.contact &&
                  formData.contact === "" &&
                  Boolean(errors.contact) ||
                  (errors.contact &&
                  formData.contact.length !== 10 &&
                  Boolean(errors.contact))

                }
              />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ marginTop: "20px" }}>
              <div className="FormComponent__Text">Use Case Details</div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={refs.usecase}
                fullWidth
                helperText={errors.usecase}
                label="Use Case Title"
                variant="outlined"
                name="usecase"
                value={formData.usecase}
                onChange={handleChange}
                error={
                  errors.usecase &&
                  formData.usecase === "" &&
                  Boolean(errors.usecase)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Sector
                </InputLabel>
                <Select
                  inputRef={refs.sector}

                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.sector}
                  onChange={handleSectorTypeChange}
                  name="sector"
                  label="Select Sector"
                  error={
                    errors.sector &&
                    formData.sector === "" &&
                    Boolean(errors.sector)
                  }
                >
                  {Categories.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {formData?.sector === "Others" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  inputRef={refs.otherSector}
                  helperText={errors.otherSector}
                  fullWidth
                  label="Enter your sector"
                  variant="outlined"
                  name="otherSector"
                  value={formData.otherSector}
                  error={
                    errors.otherSector &&
                    formData.otherSector === "" &&
                    Boolean(errors.otherSector)
                  }
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <div
                style={{
                  backgroundColor: "#f4f4f4",
                  padding: "20px",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <div className="textFieldValidation" ref={refs.document}>
                  <div
                    style={{
                      maxWidth: "600px",
                      margin: "0 auto",
                      padding: "20px",
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "black" }}>
                      Upload document
                      {/* <Tooltip title="Please note: If you wish to upload more than one file, you must create a PDF containing both images before uploading.">
                      <IconButton color="inherit">
                        <InfoOutlinedIcon style={{ fontSize: "18px" }} />
                      </IconButton>
                    </Tooltip> */}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "black" }}>
                      PDF, PPT, Doc
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ paddingTop: "15px", color: "#4D4D4D" }}
                    >
                      Drag to upload
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ paddingTop: "5px", color: "#4D4D4D" }}
                    >
                      or
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      endIcon={<CloudUploadIcon />}
                      component="label"
                      sx={{ marginY: 2, fontWeight: "600", borderWidth: 2 }}
                    >
                      Upload
                      <input
                        ref={refs.document}
                        key={fileKey} 
                        type="file"
                        style={{ display: "none" }}
                         accept=".ppt, .pptx, .pdf, .doc, .docx"
                        onChange={handleChange}
                        name="document"
                      />
                    </Button>

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {formData.document && (
                        <>
                          <span
                            style={{
                              fontSize: "16px",
                              fontFamily: "RobotoBold",
                              color: "black",
                            }}
                          >
                            {formData.document.name}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {errors.document && formData.document === null && (
                    <span className="error text-red-500">{errors.document}</span>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <TextField
                inputRef={refs.detail}
              helperText={errors.detail}  
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                margin="normal"
                label="Enter Details"
                name="detail"
                value={formData.detail || ""}
                onChange={handleChange}
                error={
                  errors.detail &&
                  formData.detail === "" &&
                  Boolean(errors.detail)
                }
              />
            </Grid>
            <Grid xs={12} sm={12} sx={{ margin: "10px 0px 10px 17px" }}>
              {/* <Typography variant="body2" sx={{color:"black"}}>By selecting ‘I Read’ below, I have reviewed and read the Guidelines for the Use Case Submission</Typography> */}
            </Grid>
            <Grid xs={12} sm={12} sx={{ margin: "17px 0px 17px 17px" }}>
              <Accordion sx={{ backgroundColor: "rgb(244, 244, 244)" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  // sx={{backgroundColor:"#C1C1C2", width: "97%",margin: "25px 0px 15px 15px "}}
                >
                  <Typography variant="body2">
                    Guidelines for the Use Case Submission
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    1. We warmly invite a diverse spectrum of organizations from
                    Central, State, Public, and Private sectors to join us in a
                    collaborative effort to innovate and contribute.
                  </Typography>
                  <Typography variant="body2">
                    2. To streamline the process, all use case submissions
                    should be made exclusively through our official website
                    portal.
                  </Typography>
                  <Typography variant="body2">
                    3. A dedicated panel of experts will thoughtfully review
                    each submission. We look forward to recognizing and
                    implementing outstanding use cases that align with our
                    initiative’s goals.
                  </Typography>
                  <Typography variant="body2">
                    4. BHASHINI reserves the right to finely tune contributions
                    to meet our criteria, ensuring that every selected use case
                    reflects our vision for innovation and impact.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="I have reviewed and read the guidelines for the use case submission"
                name="confirmation"
                checked={formData.confirmation}
                onChange={handleChange}
                sx={{ color: "black" }}
              />
            </Grid>
            {errors.confirmation && formData?.confirmation === false && (
              <span style={{ color: "red", marginLeft: "17px" }}>
                {errors.confirmation}
              </span>
            )}

            <Grid item xs={12} sx={{ textAlign: "end" }}>
              <Button variant="contained" color="primary" type="submit">
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  " Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default TechnologyDayForm;
