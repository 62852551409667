import React from "react";

const Stages = () => {
  return (
    <div className="flex flex-col gap-9">
      <p className="heading1 font-semibold">
        Stages:{" "}
        <span className="text-primary">Bhashini Startup Velocity 1.0</span>
      </p>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">
          PROGRAM LAUNCH AND REGISTRATION
        </p>
        <p className="font-semibold">
          The BSV 1.0 will start from on 01st Feb, 2024. The Teams can register
          until 10th Feb,2024
        </p>
        <p>
          To encourage participation from across academia and industry,
          participating teams are not mandatorily required to be registered
          Indian Companies / startups to apply for the challenge. However, the
          10 teams shortlisted at screening Stage will be required to register
          themselves as Indian Startups/Company and submit the proof of having
          applied at stage of submission of Prototype. It is expected that by
          the time of selection at the final stage, necessary registration will
          be completed to facilitate signing of the contract for deployment of
          the solution for use by the Government. Purpose of the DPIIT
          registration as startup is to empower Bhashini onboarded startups in
          the market.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">SCREENING STAGE</p>
        <p>
          The intent of this stage is to invite bright minds to collaborate and
          participate to propose innovative and cutting-edge ideas for their
          solution using Bhashini APIs for AI based translations models. The
          ideas along with features and innovative idea in Various Indian
          language will be evaluated, and top Five (5) teams will be selected by
          a Selection Committee composed of experts from academia, industry, and
          government. Each selected team will get a chance to migrate to stage 2
          to present the basic prototype.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="heading3 text-primary font-semibold">PROTOTYPE STAGE</p>
        <p>
          This is the critical phase of the Innovation Challenge to build upon
          the idea and concept and develop and enhance various features of the
          prototype of the solution. The teams would present their prototypes to
          a distinguished Jury to select top Three (3) entries. Each selected
          team will receive INR 1 Lakhs to build their solution following the
          design principles and best practices. If needed, there will be mentors
          assigned to the teams to help develop the best solution as per the
          need of the DIBD.
        </p>
      </div>
    </div>
  );
};

export default Stages;
