import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./ourJourney.css";
import useScrollToRef from "../../hooks/useScrollToRef";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="cursor-pointer px-[1.48rem] py-[.59rem] bg-[#3E66DF] z-[2] absolute h-fit w-fit right-0 transform bottom-[10%]"
    >
      <div className="bg-[#3E66DF] w-5 h-5 flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M14.2327 12.503L7.50827 6.03834C7.32311 5.86077 7.22125 5.62334 7.22125 5.37018C7.22125 5.11688 7.32311 4.8796 7.50827 4.70174L8.0975 4.13558C8.28236 3.95744 8.52949 3.85938 8.79283 3.85938C9.05617 3.85938 9.303 3.95744 9.48801 4.13558L17.4945 11.8323C17.6803 12.0108 17.782 12.2492 17.7812 12.5026C17.782 12.7572 17.6804 12.9953 17.4945 13.1739L9.49547 20.8632C9.31045 21.0413 9.06363 21.1394 8.80014 21.1394C8.5368 21.1394 8.28997 21.0413 8.10481 20.8632L7.51572 20.297C7.1324 19.9285 7.1324 19.3286 7.51572 18.9603L14.2327 12.503Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="cursor-pointer px-[1.48rem] pt-[.25rem] py-[.59rem] bg-[#3E66DF] bg-transparent block absolute h-fit w-fit z-[2] left-0 transform bottom-[10%]"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M10.7673 12.497L17.4917 18.9617C17.6769 19.1392 17.7787 19.3767 17.7787 19.6298C17.7787 19.8831 17.6769 20.1204 17.4917 20.2983L16.9025 20.8644C16.7176 21.0426 16.4705 21.1406 16.2072 21.1406C15.9438 21.1406 15.697 21.0426 15.512 20.8644L7.50548 13.1677C7.31973 12.9892 7.21802 12.7508 7.21875 12.4974C7.21802 12.2428 7.31959 12.0047 7.50548 11.8261L15.5045 4.13683C15.6895 3.95869 15.9364 3.86063 16.1999 3.86063C16.4632 3.86063 16.71 3.95869 16.8952 4.13683L17.4843 4.70299C17.8676 5.07149 17.8676 5.67137 17.4843 6.03973L10.7673 12.497Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

function Slide({ header, points, backgroundClass }) {
  return (
    <div className={`relative ${backgroundClass} h-full text-white`}>
      <div className="absolute bottom-[10%] left-[10%] z-10 w-[60%] flex">
        <div className="w-[53.43rem] h-[2.43rem] absolute bottom-0 left-[-20%] bg-[#3E66DF] bg-opacity-40"></div>
        <div className="flex flex-col gap-[1.12rem] w-full z-10">
          <p className="text-[1.5rem] font-medium">{header}</p>
          <div className="bg-[#03022D] text-white p-6 flex flex-col w-full">
            {points.map((point, index) => (
              <p key={index}>{point}</p>
            ))}
          </div>
        </div>
        <div className="w-[200%] h-[2.43rem] absolute bottom-0 left-1/2 bg-[#3E66DF] bg-opacity-40"></div>
      </div>
    </div>
  );
}

export default function ImageSlider() {
  const settings = {
    dots: false,
    arrows: true,
    centerMode: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const slideArr = [
    {
      header: "Data Preparation",
      points: [
        "Collect Data (In-House and Crowdsourced)",
        "Clean and Label Data",
        "Enhance Data (Augmentation)",
        "Split Data (Training, Validation, Testing)",
        "Document Metadata",
      ],
      backgroundClass: "journey1",
    },
    {
      header: "Model Training",
      points: [
        "Data Preprocessing",
        "Model Training",
        "Hyperparameter Tuning",
        "Model Evaluation and Validation",
        "Training Monitoring and Logging",
        "Model Packaging for Deployment",
      ],
      backgroundClass: "journey2",
    },
    {
      header: "API Development",
      points: [
        "Design API Endpoints",
        "Implement Business Logic",
        "Integrate with Databases",
        "Ensure Security",
        "Testing and Validation",
        "Documentation",
      ],
      backgroundClass: "journey3",
    },
    {
      header: "Deployment",
      points: [
        "API Integration for Exploration",
        "Reference Application Development",
        "Mobile Application Deployment",
        "Scalability",
        "Monitoring and Maintenance",
      ],
      backgroundClass: "journey6",
    },
    {
      header: "Feedback Collection",
      points: [
        "  Functionality",
        "Performance",
        "Test Edge Cases",
        "Find Gaps",
      ],
      backgroundClass: "journey4",
    },
    {
      header: "Model Finetuning",
      points: [
        "Collect Domain-Specific Data",
        "Preprocess Data",
        "Tune Parameters",
        "Retrain Model",
        "Capture Learnings for Platform Services ",
        "Redeploy Models",
      ],
      backgroundClass: "journey5",
    },
    {
      header: "Add AI Models to Improve Platforms",
      points: [
        "Develop Pre-Processor Models",
        "Develop Post-Processor Models",
        "Develop Application ",
        "Support Main Models (ASR, TTS, Translation)",
      ],
      backgroundClass: "journey8",
    },
  ];
  const ourJourneyRef = useScrollToRef()

  return (
    <div className="flex flex-col our-journey-container h-full" ref={ourJourneyRef}>
      <p className="heading1 font-semibold mb-9 text-[#242424]">
        Bhashini At Work
      </p>

      <Slider {...settings}>
        {slideArr.map((slide, index) => (
          <Slide
            key={index}
            header={slide.header}
            points={slide.points}
            backgroundClass={slide.backgroundClass}
          />
        ))}
      </Slider>
    </div>
  );
}
