import React from "react";
import assetsManager from "../../../assets/assetsManager";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
// import { Link } from "react-router-dom";
import il from "../../../assets/images/Awards/ImpactLeaderThumbnail.jpeg"
const DigitalTrail = () => {
  // const imgLinks = [
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_1,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_2,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_3,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_4,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_5,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_6,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_7,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_8,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_9,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_10,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_11,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_12,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_13,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_14,
  //   },
  //   {
  //     title: "Digital Trailblazer Award",
  //     img: assetsManager.award1_15,
  //   },
  // ];

  // const imgLinks2 = [
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_1,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_2,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_3,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_4,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_5,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_6,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_7,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_8,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_9,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_10,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_11,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_12,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_13,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_14,
  //   },
  //   {
  //     title: "ELETS Atamnirbhar Awards",
  //     img: assetsManager.award2_15,
  //   },
  // ];

  // const imgLinks3 = [
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL01,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL02,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL03,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL04,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL05,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL06,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL07,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL08,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL09,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL10,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL11,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL12,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL13,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL14,
  //   },
  //   {
  //     title: "Impact Leader of the Year",
  //     img: assetsManager.IL15,
  //   },
  // ];

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4  flex flex-col  pravakta-banner-bg">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">
            Awards
          </p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-wrap mx-16 my-[100px]">
      <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <img
                    src={assetsManager.award1_3}
                    className="w-full h-48 object-cover"
                    alt="G20"
                  />
               
                <div className="p-4">
                  <h3 className="text-lg font-semibold">Digital Trailblazer Award 2024</h3>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <img
                    src={assetsManager.award2_1}
                    className="w-full h-48 object-cover"
                    alt="G20"
                  />
               
                <div className="p-4">
                  <h3 className="text-lg font-semibold">ELETS Aatmanirbhar Awards 2024</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <img
                    src={il}
                    className="w-full h-48 object-cover"
                    alt="G20"
                  />
               
                <div className="p-4">
                  <h3 className="text-lg font-semibold">Impact Leader of the Year</h3>
                </div>
              </div>
            </div>
            
      </div>
      {/* <div className="flex flex-col mx-16 gap-4">
        <p className="heading1">Digital Trailblazer Award</p>
        <div className="flex flex-wrap gap-2 mx-16">
          {imgLinks.map((image, index) => (
            <div key={index} className="w-1/2 md:w-[30%] h-1/2">
              <a
                href={image.img}
                data-fancybox="gallery"
                className="block h-full"
              >
                <img
                  src={image.img}
                  alt={`G20 Event ${index + 1}`}
                  className="h-full"
                />
              </a>
            </div>
          ))}
        </div>
      </div>


      <div className="flex flex-col mx-16 gap-4">
        <p className="heading1">ELETS Atamnirbhar Awards </p>
        <div className="flex flex-wrap gap-2 mx-16">
          {imgLinks2.map((image, index) => (
            <div key={index} className="w-1/2 md:w-[30%] h-1/2">
              <a
                href={image.img}
                data-fancybox="gallery"
                className="block h-full"
              >
                <img
                  src={image.img}
                  alt={`G20 Event ${index + 1}`}
                  className="h-full"
                />
              </a>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default DigitalTrail;
