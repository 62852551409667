import assetsManager from "./assets/assetsManager";
import { shuffleArray } from "./utils/utilities";

export const NEWS_DATA = [
  {
    title:
      "Indian language AI tool ‘Bhashini’ used to translate Prime Minister Narendra Modi’s speech",
    date: "Dec 18, 2023",
    thumbnail: assetsManager.newsThumbnail1,
    articleUrl:
      "https://www.thehindu.com/sci-tech/technology/indian-language-ai-tool-bhashini-used-to-translate-prime-minister-narendra-modis-speech/article67649545.ece",
    type: "article",
  },
  {
    title:
      "PM Modi uses AI-tool ‘Bhashini’ at Kashi Tamil Sangamam in Varanasi. What is it?",
    date: "Dec 18, 2023",
    thumbnail: assetsManager.newsThumbnail2,
    articleUrl:
      "https://www.hindustantimes.com/technology/pm-modi-uses-ai-tool-bhashini-at-kashi-tamil-sangamam-in-varanasi-what-is-it-101702837254702.html",
    type: "article",
  },
  {
    title:
      "Bhashini: Everything you need to know about AI language translation tool",
    date: "Dec 18, 2023",
    thumbnail: assetsManager.newsThumbnail3,
    articleUrl:
      "https://www.business-standard.com/technology/tech-news/bhashini-everything-you-need-to-know-about-ai-language-translation-tool-123121800769_1.html",
    type: "article",
  },
  {
    title:
      "Mann ki Baat: PM Modi highlights mental health, AI tool 'Bhashini' on his monthly radio programme",
    date: "Dec 31, 2023",
    thumbnail: assetsManager.newsThumbnail4,
    articleUrl:
      "https://economictimes.indiatimes.com/news/india/mann-ki-baat-pm-modi-highlights-mental-health-ai-tool-bhashini-on-his-monthly-radio-programme/videoshow/106416564.cms?from=mdr",
    type: "article",
  },
  {
    title:
      "What is ‘Bhashini’, the AI-powered tool that translated PM Narendra Modi’s speech to Tamil?",
    date: "Dec 18, 2023",
    thumbnail: assetsManager.newsThumbnail5,
    articleUrl:
      "https://www.firstpost.com/explainers/what-is-bhashini-the-ai-powered-tool-that-translated-pm-narendra-modis-speech-to-tamil-13516022.html",
    type: "article",
  },
  {
    title:
      "What is Bhashini: PM मोदी ने हिंदी में की तमिल जनता से बात, भाषण में पहली बार AI का इस्तेमाल",
    date: "Dec 18, 2023",
    thumbnail: assetsManager.newsThumbnail6,
    articleUrl:
      "https://www.livehindustan.com/national/story-what-is-bhashini-ai-tool-artificial-intelligence-pm-narendra-modi-in-varanasi-kashi-tamil-sangamam-9082480.html",
    type: "article",
  },
  {
    title:
      "PM Modi addresses last 'Mann Ki Baat' of 2023; urges youth to explore AI tools",
    date: "Dec 31, 2023",
    thumbnail: assetsManager.newsThumbnail7,
    articleUrl:
      "https://www.ptcnews.tv/nation/pm-modi-addresses-last-mann-ki-baat-of-2023-urges-youth-to-explore-ai-tools-2059001",
    type: "article",
  },
  {
    title: `"This is a new beginning…,” PM Modi uses AI tool ‘Bhashini’ while delivering speech in Varanasi`,
    date: "Dec 18, 2023",
    thumbnail: assetsManager.newsThumbnail8,
    articleUrl:
      "https://timesofindia.indiatimes.com/videos/news/this-is-a-new-beginning-pm-modi-uses-ai-tool-bhashini-while-delivering-speech-in-varanasi/videoshow/106074820.cms",
    type: "article",
  },
  // {
  //   title: `"This is a new beginning…,” PM Modi uses AI tool ‘Bhashini’ while delivering speech in Varanasi`,
  //   date: "Dec 18, 2023",
  //   thumbnail: assetsManager.newsThumbnail9,
  //   articleUrl:
  //     "https://timesofindia.indiatimes.com/videos/news/this-is-a-new-beginning-pm-modi-uses-ai-tool-bhashini-while-delivering-speech-in-varanasi/videoshow/106074820.cms",
  //   type: "article",
  // },
  {
    title: `AI technology can be implemented in courts to make lives easier for the common p .. Read more at: https://government.economictimes.indiatimes.com/news/governance/ai-technology-can-be-implemented-in-courts-to-make-lives-easier-for-the-common-people-pm-modi/107213719`,
    date: "Jan 29, 2024",
    thumbnail: assetsManager.newsThumbnail10,
    articleUrl:
      "https://government.economictimes.indiatimes.com/news/governance/ai-technology-can-be-implemented-in-courts-to-make-lives-easier-for-the-common-people-pm-modi/107213719",
    type: "article",
  },
  {
    title:
      "Modi’s Address on Diamond Jubilee of the Supreme Court : Key Takeaways",
    date: "Jan 29, 2024",
    thumbnail: assetsManager.newsThumbnail11,
    articleUrl:
      "https://www.thejaipurdialogues.com/society/modis-address-on-diamond-jubilee-of-the-supreme-court-key-takeaways/#google_vignette",
    type: "article",
  },
  {
    title: "Bridging Language Barriers and Beyond: Amitabh Nag, CEO, Bhashini",
    date: "Jan 5, 2024",
    thumbnail: assetsManager.newsThumbnail12,
    articleUrl:
      "https://ilougemedia.com/bridging-language-barriers-and-beyond-amitabh-nag-ceo-bhashini/s",
    type: "article",
  },
  {
    title:
      "Satya Nadella ने National Language Translation Mission Bhashini के बारे में की बात | BQ Prime Hindi",
    date: "Jan 3, 2023",
    duration: "1:32",
    videoUrl: "https://www.youtube.com/embed/Ww79X0aCrxQ",
    type: "video",
  },
  {
    title:
      "“Game changer in Indian politics” Annamalai hails PM Modi for using AI tools for speeches",
    date: "Dec 18, 2023",
    duration: "3:24",
    videoUrl: "https://www.youtube.com/embed/efs37UVP10Y&t=24s",
    type: "video",
  },
  {
    title:
      "Satya Nadella ने National Language Translation Mission Bhashini के बारे में की बात | BQ Prime Hindi",
    date: "Jan 3, 2023",
    duration: "1:32",
    videoUrl: "https://www.youtube.com/embed/dt5GaY_5svA&t=1s",
    type: "video",
  },
  {
    title: `"Elevating Digital India: Ashwini Vaishnaw Explores Bhashini's Role & Upcoming Extension`,
    date: "Aug 16, 2023",
    duration: "1:20",
    videoUrl: "https://www.youtube.com/embed/dt5GaY_5svA&t=1s",
    type: "video",
  },
  {
    title:
      "How is India training AI to speak regional languages? | Crowdsourced Data | Bhashini",
    date: "Sep 16, 2023",
    duration: "8:02",
    videoUrl: "https://www.youtube.com/embed/-KJl9PdpHl4",
    type: "video",
  },
  {
    title:
      "Amitabh Nag ने 'Bhashini App' के बारे में बताया, एक-दूसरे की भाषाओं को समझने की खास पहल | ABP Live",
    date: "Sep 9, 2023",
    duration: "7:38",
    videoUrl: "https://www.youtube.com/embed/q2lr-deU0WA&t=62s",
    type: "video",
  },
  {
    title: "#G20Summit | Team BHASHINI at Digital India Experience Zone",
    date: "Sep 8, 2023",
    duration: "2:08",
    videoUrl: "https://www.youtube.com/embed/DUNIr8foCz4&t=29s",
    type: "video",
  },
  {
    title: "What is Bhashini? #TMS",
    date: "Dec 21, 2023",
    duration: "3:08",
    videoUrl: "https://www.youtube.com/embed/beJawY8YXjQ",
    type: "video",
  },
  {
    title:
      "AI Bhashini के CEO अमिताभ नाग से डीडी न्यूज़ की ख़ास बातचीत, PM ने MKB में किया था ज़िक्र",
    date: "Jan 3, 2023",
    duration: "1:32",
    videoUrl: "https://www.youtube.com/embed/CHdLMhgT_SA&t=3s",
    type: "video",
  },
];

shuffleArray(NEWS_DATA);

export const stateName = [
  {
    label: "Andaman and Nicobar",
    value: "Andaman and Nicobar",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    label: "Assam",
    value: "Assam",
  },
  {
    label: "Bihar",
    value: "Bihar",
  },
  {
    label: "Chandigarh",
    value: "Chandigarh",
  },
  {
    label: "Chhattisgarh",
    value: "Chhattisgarh",
  },
  {
    label: "Dadra and Nagar Haveli",
    value: "Dadra and Nagar Haveli",
  },
  {
    label: "Daman and Diu",
    value: "Daman and Diu",
  },
  {
    label: "Delhi",
    value: "Delhi",
  },
  {
    label: "Goa",
    value: "Goa",
  },
  {
    label: "Gujarat",
    value: "Gujarat",
  },
  {
    label: "Haryana",
    value: "Haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "Himachal Pradesh",
  },
  {
    label: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
  },
  {
    label: "Jharkhand",
    value: "Jharkhand",
  },
  {
    label: "Karnataka",
    value: "Karnataka",
  },
  {
    label: "Kerala",
    value: "Kerala",
  },
  {
    label: "Ladakh",
    value: "Ladakh",
  },
  {
    label: "Lakshadweep",
    value: "Lakshadweep",
  },
  {
    label: "Madhya Pradesh",
    value: "Madhya Pradesh",
  },
  {
    label: "Maharashtra",
    value: "Maharashtra",
  },
  {
    label: "Manipur",
    value: "Manipur",
  },
  {
    label: "Meghalaya",
    value: "Meghalaya",
  },
  {
    label: "Mizoram",
    value: "Mizoram",
  },
  {
    label: "Nagaland",
    value: "Nagaland",
  },
  {
    label: "Odisha",
    value: "Odisha",
  },
  {
    label: "Puducherry",
    value: "Puducherry",
  },
  {
    label: "Punjab",
    value: "Punjab",
  },
  {
    label: "Rajasthan",
    value: "Rajasthan",
  },
  {
    label: "Sikkim",
    value: "Sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "Tamil Nadu",
  },
  {
    label: "Telangana",
    value: "Telangana",
  },
  {
    label: "Tripura",
    value: "Tripura",
  },
  {
    label: "Uttar Pradesh",
    value: "Uttar Pradesh",
  },
  {
    label: "Uttarakhand",
    value: "Uttarakhand",
  },
  {
    label: "West Bengal",
    value: "West Bengal",
  },
];
