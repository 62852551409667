import React from "react";
import assetsManager from "../../assets/assetsManager";

const AboutBsv = () => {
  return (
    <div className="flex flex-col gap-[6.22rem]">
      <div className="flex flex-col gap-9">
        <p className="heading1 font-semibold">
          Bhashini Startup Velocity 1.0:{" "}
          <span className="text-primary">About</span>
        </p>
        <video className="w-full h-[50%]" controls>
          <source src={assetsManager.bsvAbout} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p>
          Embark on a journey of innovation and problem-solving with the Grand
          Innovation Challenge, an unprecedented opportunity to address critical
          issues in Bhashini Technology and services. With six pre-identified
          problem statements spanning various areas and segments, this challenge
          invites teams to push the boundaries of creativity and expertise in
          the software development life cycle. Participation is open to teams
          with diverse skill sets, bringing together experts from all domains to
          collaborate and innovate. Each team can choose to tackle one or two
          challenges, delving deep into the heart of the identified problems.
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <p>
          The challenge unfolds in three stages, each designed to nurture and
          showcase the ingenuity of participating teams:
        </p>
        <div className="flex flex-col gap-4">
          <div className="bg-[#E8F3FF] py-6 px-6 flex flex-col gap-6">
            <p className="font-bold">Screening Stage:</p>
            <p>
              Teams kickstart their journey by proposing innovative solutions
              and presenting prototypes aligned with the identified problem
              statements. The top 5 teams per problem statement will advance to
              the next stage, setting the stage for fierce competition and
              groundbreaking ideas.
            </p>
          </div>
          <div className="bg-[#E8F3FF] py-6 px-6 flex flex-col gap-6">
            <p className="font-bold">Prototype Stage:</p>
            <p>
              Shortlisted teams from the screening stage receive the opportunity
              to refine their prototypes, bringing their ideas to life with
              precision and creativity. With a grant of 2 lacs per problem
              statement, teams embark on the journey of prototype development,
              striving for excellence and innovation.
            </p>
          </div>

          <div className="bg-[#E8F3FF] py-6 px-6 flex flex-col gap-6">
            <p className="font-bold">Grand Finale:</p>
            <p>
              The culmination of months of dedication and hard work, the Grand
              Finale is where dreams become reality. Teams present their
              enhanced prototypes and final products to a distinguished
              committee, showcasing the evolution of their solutions. With one
              team per problem statement emerging victorious, the Grand Finale
              is a celebration of innovation, excellence, and the limitless
              potential of human ingenuity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBsv;
