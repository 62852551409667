import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
    const location = useLocation();
    const [currentPathArray, setCurrentPathArray] = useState([]);

    useEffect(() => {
        const path = location.pathname;
        const pathArray = path.split('/').filter(Boolean); // Filter out empty strings
        setCurrentPathArray(['home', ...pathArray]);
    }, [location]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const createPath = (index) => {
        if (index === 0) {
            return '/';
        }
        return '/' + currentPathArray.slice(1, index + 1).join('/');
    };

    return (
        <div className="md:flex flex-row text-inherit hidden mb-4">
            {currentPathArray.map((path, index) => (
                <React.Fragment key={index}>
                    <Link to={createPath(index)} className="text-sm">
                        {capitalizeFirstLetter(path)}
                    </Link>
                    {index < currentPathArray.length - 1 && (
                        <div className="mx-2"> » </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumbs;
