import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";

const AvinyaPrayogshala = () => {
  const avinyaPrayogshalaRef = useScrollToRef();
  return (
    <div className="flex flex-col gap-9" ref={avinyaPrayogshalaRef}>
      <div>
        <p className="heading1 font-semibold text-[#242424]">
          Bhashini’s  Avinya Prayogshala
        </p>
        <div className="w-full anivyaBg py-[3.75rem] px-[2.69rem] flex flex-col gap-9 text-white text-center">
          <p className="heading3 font-medium">
            Avinya Paryogshala, a collaborative platform for Researchers Stay
            tuned for the launch!
          </p>
          <p className="heading1 font-semibold text-end">Coming Soon...</p>
        </div>
      </div>
    </div>
  );
};

export default AvinyaPrayogshala;
