import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col gap-[6.25rem]">
      <div className="w-full text-white h-[24rem] md:px-[9.06rem] md:pl-8 px-4  flex flex-col termAndConditionsBanner">
        <div className="my-auto text-white">
          <p className="heading1 font-medium">Terms and Conditions</p>
        </div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-col gap-4 mx-4 container">
        <p className="paragraph1">
          This website is designed, developed and maintained and its contents
          are provided by Bhashini.
        </p>
        <p className="paragraph1">
          Though all efforts have been made to ensure the accuracy and currency
          of the contents of this website, the same should not be construed as a
          statement for any legal purposes. In case of any doubt, users are
          advised to send an email to{" "}
          <Link
            className="text-primary underline"
            to="mailto:ceo-dibd@digitalindia.gov.in"
          >
            ceo-dibd[at]digitalindia[dot]gov[dot]in
          </Link>
        </p>
        <p className="paragraph1">
          Under no circumstances will Bhashini be liable for any expense, loss
          or damage, including indirect or consequential loss or damage (without
          any limit), or any expense, loss or damage whatsoever, arising from
          the use or the loss of use of data arising out of or in connection
          with the use of the contents of this website.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
