import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { services } from "../../utils/services";
import assetsManager from "../../assets/assetsManager";

const ExpandingService = () => {
  const { t } = useTranslation();



  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sortedServices = [...services].sort((a) => (a.abbr ? -1 : 1));
  return (
    <div className="2xl:container">

    <div className="md:mt-[2.37rem] mt-[1.88rem] flex flex-col gap-[3.25rem] md:mx-16">
      <p className="heading1 font-bold text-center text-[#242424]">
        Our Services
      </p>
      {/* <div className="flex justify-between md:flex-row flex-col gap-8"> */}
      <Slider {...settings}>
        {sortedServices.map((item, index) => (
          <div key={index} className="flex flex-col gap-4 bg-white dark:bg-[#404148]">
            <img src={item.icon} alt="icon" className="m-auto" loading="lazy" />
            <div className="flex flex-col">
              <p className="heading3 font-semibold text-center">
                {item.abbr}
              </p>
              <p className={`text-center ${item.abbr.length === 0 ? "heading3 font-semibold" : "paragraph2"}`}>{item.name}</p>
            </div>
          </div>
        ))}
      </Slider>
      {/* </div> */}
      <Link to="/services" className="buttonLarge  bg-primary text-white m-auto rounded gap-2 flex">
        {t("homePage.expandingServices.buttonText")}
        <img src={assetsManager.rightArrow} alt="arrow" />
      </Link>
    </div>
    </div>
  );
};

export default ExpandingService;
