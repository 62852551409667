import React from "react";
import useScrollToRef from "../../hooks/useScrollToRef";
import assetsManager from "../../assets/assetsManager";

const Workshops = () => {
  const workshopsRef = useScrollToRef();
  const workshopData = [
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img1,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img2,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img3,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img4,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img5,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img6,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img7,
    },
    {
      title: "NMT Evaluation Workshop at VMCC, IIT Bombay",
      imgSrc: assetsManager.img8,
    },
  ];

  return (
    <div className="flex flex-col gap-9" ref={workshopsRef}>
      <div className="flex flex-col gap-[.94rem]">
        <div>
          <p className="heading1 font-bold text-[#242424]">
            Anusandhan Mitra Workshops
          </p>
          <p className="heading3">NMT Evaluation Workshop</p>
        </div>
      </div>
      <div className="flex w-full gap-9 flex-wrap">
        {workshopData.map((workshop) => (
          <div
            className="sm:w-[45%] w-full flex flex-col shadow-xl relative rounded-xl"
            key={workshop.imgSrc}
          >
            <div className="w-full h-[13.9375rem] relative rounded-tl-xl rounded-tr-xl overflow-hidden">
              <img
                src={workshop.imgSrc}
                alt="workshop"
                className="absolute inset-0 z-10 rounded-tl-xl rounded-tr-xl w-full h-full object-cover"
              />
            </div>
            <div className="w-full bg-white p-4 rounded-bl-xl rounded-br-xl flex flex-col">
              <p className="font-bold truncate" title={workshop.title}>
                {workshop.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Workshops;
