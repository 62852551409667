import React from "react";
import assetsManager from "../../assets/assetsManager";
import { handleExternalLinks } from "../../utils/utilities";
import useScrollToRef from "../../hooks/useScrollToRef";

const TeamMember = ({
  name,
  role,
  email,
  linkedin,
  isLinkedInAvailable,
  img,
}) => {
  const formattedEmail = email.replace("@", "[at]").replace(/\./g, "[dot]");

  return (
    <div className="flex flex-col gap-4 w-fit grow md:grow-0">
      <div className="flex justify-center">
        <img
          className="rounded-full m-auto h-[175px]"
          loading="lazy"
          src={img}
          alt="team"
          height={176}
          width={175}
        />
      </div>
      <p className="heading2 font-medium text-primary text-center">{name}</p>
      <p className="paragraph1 text-center dark:text-white">{role}</p>
      <p className="text-center dark:text-white">
        <a href={`mailto:${email}`} className="bhashini-skip-translation">
          {formattedEmail}
        </a>
      </p>
      {isLinkedInAvailable ? (
        <button
          onClick={() => handleExternalLinks(linkedin)}
          className="bg-white flex gap-2 items-center font-semibold justify-center text-primary border-2 border-primary rounded-[6.25rem] px-4 py-2 m-auto w-full"
        >
          Connect with{" "}
          <img src={assetsManager.linkedInLogo} alt="arrow" loading="lazy" />
        </button>
      ) : (
        <div className="bg-white flex gap-2 items-center font-semibold justify-center text-gray-400 border-2 border-gray-400 rounded-[6.25rem] px-4 py-2 m-auto w-full cursor-not-allowed">
          Coming Soon
          <img src={assetsManager.linkedInLogo} alt="arrow" loading="lazy" />
        </div>
      )}
    </div>
  );
};

const OurTeam = () => {
  const teams = [
    {
      title: "Product & Technical Team",
      members: [
        {
          name: "Shailendra Pal Singh",
          role: "Product Manager",
          email: "shailendra.dic@digitalindia.gov.in",
          linkedin:
            "https://www.linkedin.com/in/shailendra-pal-singh-a7379027/",
          isLinkedInAvailable: true,
          img: assetsManager.productManagerImg,
        },
        {
          name: "Prashant Verma",
          role: "Manager, Technical Solutions",
          email: "prashant.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/prashant-verma-a6788518/",
          isLinkedInAvailable: true,
          img: assetsManager.technicalManager,
        },
        // Add other product team members here
      ],
    },
    {
      title: "Onboarding & Support Team",
      members: [
        {
          name: "Ajay Singh Rajawat",
          role: "Manager, Onboard & Support",
          email: "ajay.rajawat@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/ajayplusinnovation/",
          isLinkedInAvailable: true,
          img: assetsManager.onboardManager1,
        },
        {
          name: "Aparna Singh",
          role: "Manager, Onboard & Support",
          email: "aparnasingh.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/aparna-singh-4012787/",
          isLinkedInAvailable: true,
          img: assetsManager.onboardManager2,
        },
        {
          name: "Priya Verma",
          role: "Manager, Onboard & Support",
          email: "priyaverma.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/priyav29/",
          isLinkedInAvailable: true,
          img: assetsManager.onboardManager3,
        },
        // Add other onboarding & support team members here
      ],
    },
    {
      title: "Awareness & Coordination / Engagement Team",
      members: [
        {
          name: "Lureiliu Kamei",
          role: "Manager, Awareness & Coordination",
          email: "lureilliu.paul@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/lureiliu-kamei-281035ba/",
          isLinkedInAvailable: true,
          img: assetsManager.multimediaManager,
        },
        {
          name: "Harsh Verma",
          role: "Assistant Manager, Multimedia",
          email: "harshverma.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/harsh-verma-2644672b/",
          isLinkedInAvailable: true,
          img: assetsManager.multimediaAssistantManager,
        },
        {
          name: "Nandan Kumar",
          role: "Ecosystem Engagement Manager",
          email: "nandan.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/nandan-kumar-368111105/",
          isLinkedInAvailable: true,
          img: assetsManager.nandanKumar,
        },
        // Add other awareness & coordination team members here
      ],
    },
    {
      title: "Finance, Administration & Human Resource",
      members: [
        {
          name: "Pinky Sharma",
          role: "Deputy Manager, Finance",
          email: "pinky.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/pinky-sharma-7a1814312/",
          isLinkedInAvailable: true,
          img: assetsManager.financeManager,
        },
        {
          name: "Astha Popli",
          role: "Executive HR",
          email: "aastha.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/aastha-popli-149811217/",
          isLinkedInAvailable: true,
          img: assetsManager.executiveHr,
        },
        {
          name: "Deepika Pathak",
          role: "HR Manager",
          email: "deepika.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/deepika-pathak-a221953a/",
          isLinkedInAvailable: true,
          img: assetsManager.managerHr,
        },
        {
          name: "Mohit Kumar",
          role: "Assistant Manager(Administration)",
          email: "mohitk.dic@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/mohit-kumar-1a6b6b1b4/",
          isLinkedInAvailable: true,
          img: assetsManager.assistantManagerAdministration,
        },
        // Add other finance, administration & HR team members here
      ],
    },
    {
      title: "Personal Assistant to CEO",
      members: [
        {
          name: "Ravi Prakash Mishra",
          role: "Assistant Manager, PA to CEO",
          email: "mishra.ravi@digitalindia.gov.in",
          linkedin: "https://www.linkedin.com/in/ravimishra0509/",
          isLinkedInAvailable: true,
          img: assetsManager.personalAssistanceCeo,
        },
        // Add other personal assistance to CEO team members here
      ],
    },
  ];

  const ourTeamRef = useScrollToRef();

  return (
    <div
      className="flex flex-col gap-[6.25rem] w-full text-black rounded"
      ref={ourTeamRef}
    >
      <div className="flex flex-col gap-9">
        <div className="flex flex-col gap-1">
          <p className="heading1 font-bold dark:text-white text-[#242424]">
            Meet Our Dynamic Team
          </p>
          <p>Bhashini{"'"}s Team</p>
        </div>
        <div className="bg-[#E6EBF8] flex flex-col md:flex-row justify-between text-white relative">
          <div className="flex flex-col gap-4 md:my-[3.0rem] mb-[.87rem] px-[2rem] py-[2.5rem] bg-primary rounded md:w-1/2 w-[95%] m-auto z-10">
            <p className="heading2 font-bold">Shri Amitabh Nag</p>
            <div className="flex flex-col gap-4">
              <p>Chief Executive Officer, Digital India Bhashini Division</p>
              <p>
                Email:
                <a
                  href="mailto:ceo-dibd@digitalindia.gov.in"
                  className="bhashini-skip-translation"
                >
                  ceo-dibd[at]digitalindia[dot]gov[dot]in
                </a>
              </p>
              <p>Phone: 011-24301361</p>
              <div className="flex gap-4">
                <div
                  className="rounded-full bg-white p-2"
                  onClick={() =>
                    handleExternalLinks(
                      " https://www.linkedin.com/in/amitabh-nag-56039b5"
                    )
                  }
                >
                  <img src={assetsManager.linkedInLogo} alt="linkedin" />
                </div>{" "}
                <div
                  className="rounded-full bg-white p-2"
                  onClick={() =>
                    handleExternalLinks("https://x.com/amitabhnag")
                  }
                >
                  <img src={assetsManager.twitterLogo} alt="linkedin" />
                </div>
              </div>
            </div>
          </div>
          <div className="self-center w-[47%]">
            <img
              loading="lazy"
              src={assetsManager.ceoImg}
              alt="team"
              className="m-auto"
            />
          </div>
          <div className="absolute top-[61.4%] left-[-2%]  md:hidden">
            <img src={assetsManager.rectangleMobile} alt="team" />
          </div>
          <div className=" md:hidden absolute right-[0%] top-[-5%]">
            <img src={assetsManager.semiRectangleMobile} alt="team" />
          </div>
          <div className="absolute top-[-5%] left-[-2%] hidden  md:block">
            <img src={assetsManager.rectangle} alt="team" />
          </div>
          <div className=" md:block absolute right-[2%] hidden top-[-5%]">
            <img src={assetsManager.semiRectangle} alt="team" />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[6.25rem] w-full text-[#242424] rounded">
        {teams.map((team, index) => (
          <div key={index} className="flex flex-col gap-9">
            <p className="heading1 font-semibold dark:text-white text-center md:text-left">
              {team.title}
            </p>
            <div className="flex flex-wrap gap-9">
              {team.members.map((member, index) => (
                <TeamMember key={index} {...member} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
