// styles.js

export const stepLabelTypography = {
  color: "#7c8387",
  fontFamily: "Noto Sans",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};

export const stepTitleTypography = {
  color: "#1E1E1E",
  fontFamily: "Noto Sans",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};

export const requiredStyle = {
  color: "red",
  marginLeft: "0.25rem",
};
