import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import About, { aboutNavItems } from "./pages/About/About";
import Arpan, { apranItems } from "./pages/Arpan/Arpan";
import Loader from "./components/Loader/Loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Prayog, { prayogItems } from "./components/Prayog/Prayog";
import BhashiniStartupVelocity, {
  bhashiniStartupItems,
} from "./components/BhashiniStartupVelocity/BhashiniStartupVelocity";
import BhashiniSprint, {
  bhashiniSprintItem,
} from "./components/BhashiniSprint/BhashiniSprint";
import StartupHomePage from "./components/StartupHomePage/StartupHomePage";

import Home from "./pages/Home/Home";
import Sanchalak from "./pages/Sanchalak/Sanchalak";
import Sahyogi from "./pages/Sahyogi/Sahyogi";
import AnushandhanMitra from "./components/AnushandhanMitra/AnushandhanMitra";
import RDProjectDetail from "./components/RDProjectDetail/RDProjectDetail";
import ContactUs from "./components/ContactUs/ContactUs";
import TechnologyDay from "./components/TechnologyDay/TechnologyDay";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import InternationalMotherLanguagesDay from "./components/InternationalMotherLanguagesDay/InternationalMotherLanguagesDay";
import G20EventPage from "./components/G20/G20";
import Coscoda from "./components/Coscoda/Coscoda";
import Gpai from "./components/Gpai/Gpai";
import Iift from "./components/Iift/Iift";
import News from "./components/News/News";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import DigitalTrail from "./components/AwardsAndAchievements/DigitalTrail/DigitalTrail";
import DashboardData from "./components/DashboardData/DashboardData";
import { Footer } from "./components/Footer/Footer";
import TranslationServiceProvider from "./components/TranslationServiceProvider/TranslationServiceProvider";
import ArchivedTender from "./components/ArchivedTender/ArchivedTender";
import Pravakta, { pravaktaNavItems } from "./pages/Pravakta/Pravakta";
import Samudaye from "./components/Samudaye/Samudaye";
import Sitemap from "./components/Sitemap/Sitemap";
import SahyogiProjects from "./components/SahyogiProjects/SahyogiProjects";
/**
 * Main App Component
 * Sets up the router and defines the routes for the application.
 * Includes a Navbar, Footer, and uses Suspense for lazy-loaded components.
 * Also A fake loader is added to mimic the loading of the application.
 */
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // ******* Fake loader to mimic the loading of the application *******
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 200); // Delay for 1 seconds

    return () => clearTimeout(delay);
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader until loading is complete
  }

  const skipToContent = () => {
    console.log("skip to content");
    mainContentRef.current.focus();
    mainContentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col text-base  overflow-x-hidden dark:bg-[#404148] dark:text-white">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar skipToContent={skipToContent} />
        <div className="w-full h-full" ref={mainContentRef}>
          <Routes>
            {/* Home Route */}
            <Route path="/" element={<Home />} />

            {/* About Section */}
            <Route path="/" element={<About />}>
              {aboutNavItems.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>

            {/* Arpan Section */}
            <Route path="/" element={<Arpan />}>
              {apranItems.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>

            {/* /* Prayog Section */}
            <Route path="/" element={<Prayog />}>
              {prayogItems.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>
            <Route path="/sahyogi/startup" element={<StartupHomePage />} />

            {/* Pravakta Section */}
            <Route path="/" element={<Pravakta />}>
              {pravaktaNavItems.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>

            {/*  Bhashini Startup Veloctiy    */}
            <Route
              path="/sahyogi/startup"
              element={<BhashiniStartupVelocity />}
            >
              {bhashiniStartupItems.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>

            {/*  Bhashini Sprint Veloctiy    */}
            <Route path="/sahyogi/startup" element={<BhashiniSprint />}>
              {bhashiniSprintItem.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>

            {/*  Translation Service Provider    */}
            <Route
              path="/sahyogi/translation-service-providers"
              element={<TranslationServiceProvider />}
            />

            <Route
              path="/sahyogi/startup/bsv"
              element={<BhashiniStartupVelocity />}
            >
              {bhashiniStartupItems.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>

            {/* Individual Routes */}
            <Route path="/sanchalak" element={<Sanchalak />} />
            <Route path="/sahyogi" element={<Sahyogi />} />
            <Route
              path="/sahyogi/anushandhan-mitra"
              element={<AnushandhanMitra />}
            />
            <Route
              path="/sahyogi/anushandhan-mitra/:id"
              element={<RDProjectDetail />}
            />
            <Route path="/connect" element={<ContactUs />} />
            <Route path="/be-our-sahyogi" element={<TechnologyDay />} />
            {/* <Route path="/pravakta" element={<PravaktaPage />} />

            <Route path="/pravakta/media" element={<EchoMediaHome />} /> */}
            <Route
              path="/termsAndConditions"
              element={<TermsAndConditions />}
            />
            {/* <Route path="/ceo-speaks" element={<CeoSpeaks />} /> */}

            {/* <Route path="/pravakta/ceo-speaks" element={<CeoSpeaks />} />
            <Route path="/pravakta/events" element={<Events />} /> */}

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/international-mother-languages-day"
              element={<InternationalMotherLanguagesDay />}
            />
            <Route path="/g20" element={<G20EventPage />} />
            <Route path="/cocosda" element={<Coscoda />} />
            <Route path="/gpai" element={<Gpai />} />
            <Route path="/iitf" element={<Iift />} />
            <Route path="/news" element={<News />} />
            <Route path="/awards" element={<DigitalTrail />} />
            <Route path="/technology-day-form" element={<DashboardData />} />
            <Route path="/archived-tender" element={<ArchivedTender />} />
            <Route path="/samudaye" element={<Samudaye />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/sahyogi-projects" element={<SahyogiProjects />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
