import enJson from "./en/translation.json";
import hiJson from "./hi/translation.json";
import asJson from "./as/translation.json";
import bnJson from "./bn/translation.json";
import dogriJson from "../locales/doi/dogri.json";
import guJson from "../locales/gu/translation.json";
import knJson from "../locales/kn/translation.json";
import gomJson from "./gom/translation.json";
import maiJson from "./mai/translation.json";
import mlJson from "./ml/translation.json";
import mrJson from "./mr/translation.json";
import neJson from "./ne/translation.json";
import orJson from "./or/translation.json";
import paJson from "./pa/translation.json";
import saJson from "./sa/translation.json";
import sdJson from "./sd/translation.json";
import taJson from "./ta/translation.json";
import teJson from "./te/translation.json";
import urJson from "./ur/translation.json";

export const localesManager = {
  en: { ...enJson },
  hi: { ...hiJson },
  as: { ...asJson },
  bn: { ...bnJson },
  doi: { ...dogriJson },
  gu: { ...guJson },
  kn: { ...knJson },
  gom: { ...gomJson },
  mai: { ...maiJson },
  ml: { ...mlJson },
  mr: { ...mrJson },
  ne: { ...neJson },
  or: { ...orJson },
  pa: { ...paJson },
  sa: { ...saJson },
  sd: { ...sdJson },
  ta: { ...taJson },
  te: { ...teJson },
  ur: { ...urJson },
};
