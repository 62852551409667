// FormField.js
import React from "react";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { requiredStyle } from "./styles";

const FormField = ({
  name,
  id,
  label,
  type,
  placeholder,
  options,
  isSelect,
  onChange,
  value,
  error,
  helperText,
  isRequired,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor={id} sx={{ display: "block", mb: 1 }}>
        {label}
        {isRequired && <span style={requiredStyle}>*</span>}
      </InputLabel>
      {isSelect ? (
        <>
          <Select
            // onBlur={onBlur}
            error={error}
            value={value}
            name={name}
            renderValue={(selected) => {
              if (!selected) {
                return <em>Select</em>;
              }
              return selected;
            }}
            defaultValue={""}
            fullWidth
            onChange={onChange}
            sx={{
              width: isMobile ? "100%" : "200%",
            }}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <div style={{ color: "red", fontSize: "0.75rem" }}>
              {helperText}
            </div>
          )}
        </>
      ) : (
        <TextField
          // onBlur={onBlur}
          value={value}
          error={error}
          helperText={helperText}
          name={name}
          onChange={onChange}
          sx={{
            width: isMobile ? "100%" : "200%",
          }}
          type={type}
          variant="outlined"
          placeholder={placeholder}
        />
      )}
    </Grid>
  );
};

export default FormField;
